import { SignatureRecord } from "api/document";
import { PreviewDocumentWithSignatures } from "features/documents/pages/postDocumentRequest/components//PreviewDocumentWithSignatures";
import { ButtonStep } from "features/documents/pages/postDocumentRequest/components/ButtonStepGroup";
import { PostDocumentRequestTemplate } from "features/documents/pages/postDocumentRequest/components/PostDocumentRequestTemplate";
import { DocumentViewerSetting } from "features/documents/pages/postDocumentRequest/pages/preview/components/DocumentViewerSetting";
import { Form, Formik } from "formik";
import React from "react";
import { FormSubmissionHandler } from "types/common";
import {
  DocumentStatePayload,
  DocumentStatePayloadMutate,
} from "types/document";
import { MapSchema } from "types/mapSchema";
import * as yup from "yup";

// TODO add schema type with Map
const schema: yup.SchemaOf<any> = yup.object().shape({
  signatures: new MapSchema(yup.string().required(), yup.string().required()),
});

export type DocumentViewerForm = {
  signatures: SignatureRecord;
};

type DocumentViewerViewProps = {
  documentStatePayload: DocumentStatePayload;
  documentPath: string | undefined;
  mutate: DocumentStatePayloadMutate;
  signatureRecord: SignatureRecord;
};
export const DocumentViewerView = ({
  documentStatePayload,
  documentPath,
  mutate,
  signatureRecord,
}: DocumentViewerViewProps): JSX.Element => {
  const handleSubmit: FormSubmissionHandler<DocumentViewerForm> = async (
    values,
    { setSubmitting }
  ) => {};

  return (
    <div className="h-full">
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        initialValues={{ signatures: signatureRecord }}
        enableReinitialize
      >
        <Form autoComplete="off" noValidate className="h-full">
          <PostDocumentRequestTemplate
            leftComponent={
              <PreviewDocumentWithSignatures
                documentStatePayload={documentStatePayload}
                documentPath={documentPath}
                isPreview={true}
                step={ButtonStep.DONE}
              />
            }
            rightComponent={
              <DocumentViewerSetting
                documentStatePayload={documentStatePayload}
                mutate={mutate}
              />
            }
          />
        </Form>
      </Formik>
    </div>
  );
};
