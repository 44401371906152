import { Button } from "common/components/Button";
import { InputField } from "common/components/form";
import { Modal } from "common/components/Modal";
import { Formik, Form } from "formik";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormSubmissionHandler } from "types/common";
import * as yup from "yup";

type RenameFolderPopupProps = {
  isOpen: boolean;
  onSubmit: FormSubmissionHandler<RenameFolderFormProps>;
  onCancel: () => void;
  isSubmitting: boolean;
  initFolderName?: string | undefined;
};

export type RenameFolderFormProps = {
  folderName: string;
};

const schema: yup.SchemaOf<RenameFolderFormProps> = yup.object().shape({
  folderName: yup.string().required().max(256, "Max is 256 characters").trim(),
});

export const RenameFolderPopup = ({
  isOpen,
  onCancel,
  onSubmit,
  isSubmitting,
  initFolderName,
}: RenameFolderPopupProps): JSX.Element => {
  return (
    <Modal open={isOpen} onClose={onCancel} size="exl">
      <Content
        isOpen={isOpen}
        onCancel={onCancel}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        initFolderName={initFolderName}
      />
    </Modal>
  );
};

const Content = ({
  isOpen,
  onCancel,
  onSubmit,
  isSubmitting,
  initFolderName = "",
}: RenameFolderPopupProps): JSX.Element => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef?.current?.select();
  }, []);
  return (
    <Formik
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{ folderName: initFolderName }}
      onSubmit={onSubmit}
    >
      <Form>
        <span className="flex items-center leading-[22px] mb-4 font-medium">
          {t("dashboard.renameFolder.title")}
        </span>
        <InputField type="text" name="folderName" fieldRef={inputRef} />
        <div className="flex justify-between mt-12">
          <Button onClick={onCancel} variant="secondary" type="button">
            {t("common.cancel")}
          </Button>
          <Button variant="primary" disabled={isSubmitting} type="submit">
            {t("common.confirm")}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
