import { SignatureRecord } from "api/document";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { useMemo } from "react";
import { DocumentStatePayload } from "types/document";

export const useCurrentUserSignatures = (
  documentStatePayload: DocumentStatePayload
): {
  getCurrentUserSignedSignatureRecord: (
    allSignatureRecord: SignatureRecord
  ) => SignatureRecord;
  isAllSignaturedSigned: (signedSignatureRecord: SignatureRecord) => boolean;
} => {
  const currentUserProfile = useCurrentUserProfile();

  const currentUserSignatureIds = useMemo(
    () =>
      documentStatePayload.signatureSchemas
        .filter((schema) => schema.email === currentUserProfile?.email)
        .map((schema) => schema.id ?? ""),
    [currentUserProfile?.email, documentStatePayload.signatureSchemas]
  );

  const getCurrentUserSignedSignatureRecord = (
    allSignatureRecord: SignatureRecord
  ) => {
    const currentUserSignedSignatures = currentUserSignatureIds.reduce(
      (acc, key) => {
        const image = allSignatureRecord[key];
        if (!!image) {
          acc[key] = allSignatureRecord[key];
        }
        return acc;
      },
      {} as SignatureRecord
    );
    return currentUserSignedSignatures;
  };

  const isAllSignaturedSigned = (signedSignatureRecord: SignatureRecord) => {
    return (
      Object.keys(signedSignatureRecord).length ===
      currentUserSignatureIds.length
    );
  };
  return {
    getCurrentUserSignedSignatureRecord,
    isAllSignaturedSigned,
  };
};
