import { Popover } from "@headlessui/react";
import { documentAPI } from "api/document";
import { Card } from "common/components/Card";
import { StyledReactTooltip } from "common/components/StyledReactTooltip";
import { SvgMore } from "common/components/svg";
import { useAuth } from "common/hooks";
import { useCommonPopover } from "common/hooks/useCommonPopover";
import { useReactTooltipRebuild } from "common/hooks/useReactTooltipRebuild";
import { ToastContainerId } from "common/toast";
import { assertCurrentUserRole } from "common/utils/document";
import { useDiscardDocumentHandler } from "features/documents/hooks/useDiscardDocumentHandler";
import { useForwardDocumentHandler } from "features/documents/hooks/useForwardDocumentHandler";
import { useRenameFolderHandler } from "features/documents/hooks/useRenameFolderHandler";
import { ArchiveDashboardItemButton } from "features/documents/pages/documentManagement/components/ArchiveDocumentButton";
import { MenuButton } from "features/documents/pages/documentManagement/components/common";
import { RetrieveDashboardItemButton } from "features/documents/pages/documentManagement/components/RetrieveDocumentButton";
import { DocumentListMutatorContext } from "features/documents/pages/documentManagement/DocumentListMutatorContext";
import { useDeleteDashboardItemHandler } from "features/documents/pages/documentManagement/hooks/useDeleteDocumentHandler";
import { useMoveDashboardItemHandler } from "features/documents/pages/documentManagement/hooks/useMoveDashboardItemHandler";
import { isCompletedNoRejectStatus } from "features/documents/pages/postDocumentRequest/utils/common";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw, { styled } from "twin.macro";
import { DashboardResponse } from "types/common";
import { DocumentRole, DocumentStatus } from "types/document";

export type DocumentMenuProps = {
  isArchived: boolean;
  dashboardItem: DashboardResponse;
  currentDashboardFolderId: string | null;
  rootDashboardItems: DashboardResponse[];
  isGlobalSearch: boolean;
};

export const DocumentMenu = ({
  isArchived,
  dashboardItem,
  currentDashboardFolderId,
  rootDashboardItems,
  isGlobalSearch,
}: DocumentMenuProps): JSX.Element => {
  const { setReferenceElement, setPopperElement, styles, attributes } =
    useCommonPopover();
  const { currentUserProfile } = useAuth();
  const { mutate } = useContext(DocumentListMutatorContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useReactTooltipRebuild();

  const handleMoveToArchiveSection = () => {
    navigate("../bin");
  };

  const { handler: handleDeleteDocument } = useDeleteDashboardItemHandler(
    dashboardItem,
    mutate
  );
  const { handler: handleDiscardDocument } = useDiscardDocumentHandler(
    dashboardItem.id,
    dashboardItem.documentName ?? "",
    ToastContainerId.AfterLogin,
    mutate
  );

  const { handler: handleMoveDashboardItem } = useMoveDashboardItemHandler(
    dashboardItem,
    ToastContainerId.AfterLogin,
    isGlobalSearch,
    currentDashboardFolderId,
    handleMoveToArchiveSection,
    mutate
  );

  const { handler: handleRenameFolder } = useRenameFolderHandler(
    dashboardItem.id,
    dashboardItem.folderName,
    currentDashboardFolderId,
    handleMoveToArchiveSection,
    mutate
  );

  /*TODO: Update Forward popup later */
  const { handler: handleForwardDocument } = useForwardDocumentHandler(
    dashboardItem.id,
    dashboardItem.documentName ?? "",
    ToastContainerId.AfterLogin,
    () =>
      // can not use useDocument to prevent storybook error
      documentAPI.getLatestRef(dashboardItem.id),
    mutate
  );

  const isSender = assertCurrentUserRole(
    DocumentRole.SENDER,
    dashboardItem.recipients,
    dashboardItem.sender,
    currentUserProfile?.email
  );

  const isFolder = dashboardItem.isFolder;

  const canForward = useMemo(
    () =>
      isCompletedNoRejectStatus(dashboardItem.status, dashboardItem.recipients),
    [dashboardItem]
  );

  const handleClick = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
  };

  return (
    <Popover className="relative" onClick={handleClick}>
      <Popover.Button ref={setReferenceElement}>
        <SvgMore data-cy="more-button" />
      </Popover.Button>
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="z-popover !-translate-x-40 !translate-y-7 !translate-z-0"
      >
        {({ close }) => (
          <Card className="w-40 bg-white mx-7 -my-3 p-4 flex flex-col">
            {!isArchived && (
              <MenuButton
                className="mb-5"
                onClick={() => {
                  /*TODO: Handle open move document/folder popup*/
                  handleMoveDashboardItem();
                  close();
                }}
                data-cy="menu-button"
              >
                {t("dashboard.button.move")}
              </MenuButton>
            )}

            {isArchived ? (
              <RetrieveDashboardItemButton
                className="mb-5"
                dashboardItem={dashboardItem}
                afterClick={close}
                disabled={!!currentDashboardFolderId}
              />
            ) : isSender ? (
              isFolder ? (
                <>
                  <MenuButton
                    className="mb-5"
                    onClick={() => {
                      handleRenameFolder();
                      close();
                    }}
                    data-cy="menu-button"
                  >
                    {t("dashboard.button.rename")}
                  </MenuButton>
                  <Divider />
                </>
              ) : (
                <>
                  {/*reference: https://stackoverflow.com/questions/61115913/is-it-possible-to-render-a-tooltip-on-a-disabled-mui-button-within-a-buttongroup*/}
                  <div data-tip data-for="forwardTooltip">
                    <MenuButton
                      className="mb-5"
                      onClick={() => {
                        /*TODO: Handle open forward popup*/
                        handleForwardDocument();
                        close();
                      }}
                      disabled={!canForward}
                      data-cy="menu-button"
                    >
                      {t("dashboard.button.forward")}
                    </MenuButton>
                  </div>

                  <StyledReactTooltip
                    id="forwardTooltip"
                    effect="solid"
                    className="w-56"
                    offset={{ left: 125, top: 0 }}
                    disable={canForward}
                  >
                    {t("dashboard.tooltip.forward")}
                  </StyledReactTooltip>

                  <MenuButton
                    className="mb-5"
                    onClick={() => {
                      handleDiscardDocument();
                      close();
                    }}
                    disabled={dashboardItem.status === DocumentStatus.DISCARDED}
                    data-cy="menu-button"
                  >
                    {t("dashboard.button.discard")}
                  </MenuButton>
                  <Divider />
                </>
              )
            ) : (
              <></>
            )}
            {isArchived ? (
              <MenuButton
                onClick={() => {
                  handleDeleteDocument();
                  close();
                }}
                data-cy="delete-button"
              >
                {t("dashboard.button.delete")}
              </MenuButton>
            ) : (
              <ArchiveDashboardItemButton
                dashboardItem={dashboardItem}
                afterClick={close}
              />
            )}
          </Card>
        )}
      </Popover.Panel>
    </Popover>
  );
};

const Divider = styled.div(() => [tw`w-full border-t border-greyscale mb-5`]);
