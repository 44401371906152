import { PDFFile } from "features/documents/pages/documentRequest/types";

export const isFileDuplicate = (
  fileCheck: PDFFile,
  targetName: string,
  targetSize: number
) => {
  return fileCheck.name === targetName && fileCheck.size === targetSize;
};

export const NO_FILE_UPLOADED_ERROR = "docRequest.message.noFileUpload";
export const DUPLICATE_FILE_UPLOADED_ERROR =
  "docRequest.message.fileUploadDuplicate";
export const FILE_NOT_UPLOAD_COMPLETED_ERROR =
  "docRequest.message.fileUploadComplete";
export const FILE_TYPE_INVALID_ERROR = "docRequest.message.fileType";
export const FILE_SIZE_EXCEED_ERROR = "docRequest.message.fileSize";
export const TOO_MANY_FILES_ERROR = "docRequest.message.fileMany";
export const FILES_CAN_NOT_UPLOAD = "docRequest.message.filesCanNotUpload";
