import * as React from "react";
import { SVGProps } from "react";

const SvgBin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4V4Zm2 2h6V4H9v2ZM6.074 8l.857 12H17.07l.857-12H6.074Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBin;
