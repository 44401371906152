import classNames from "classnames";
import {
  StyledCheckbox,
  StyledCheckboxProps,
} from "common/components/Checkbox";
import { SignaturePxProps } from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { SvgFilePdf, SvgPencil } from "common/components/svg";
import {
  IconWrapper,
  StyledText,
} from "features/documents/pages/documentRequest/components/FileWithSignatureList";
import { PDFFile } from "features/documents/pages/documentRequest/types";
import { useField } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";

type FileAuthenWithSignatureListProps = {
  files: PDFFile[];
  signatures: SignaturePxProps[][];
  isPreview: boolean;
};

export const FileAuthenWithSignatureList = ({
  files,
  signatures,
  isPreview,
}: FileAuthenWithSignatureListProps): JSX.Element => {
  const signedNumMap = useMemo(() => {
    return signatures.reduce((acc, curr, index) => {
      acc.set(index, curr.filter((it) => !!it.value).length);
      return acc;
    }, new Map<number, number>());
  }, [signatures]);

  return (
    <div className="absolute overflow-auto h-[45%] w-full">
      {files?.length > 0 &&
        files
          .filter((file) => file.id)
          .map((file, index) => {
            return (
              <AuthenticateFileCheckboxField
                name="authenticatedFiles"
                signedNumber={signedNumMap.get(index) ?? 0}
                isPreview={isPreview}
                fileName={file.name}
                value={JSON.stringify(file)}
                key={index}
                className="my-0 mr-4"
              />
            );
          })}
    </div>
  );
};

type AuthenticateFileCheckboxFieldProps = {
  name: string;
  signedNumber: number;
  isPreview: boolean;
  fileName: string;
  value: string;
  className?: string;
} & StyledCheckboxProps;

export const AuthenticateFileCheckboxField = ({
  name,
  signedNumber,
  isPreview,
  fileName,
  value,
  variant = "primary",
  className,
}: AuthenticateFileCheckboxFieldProps) => {
  const [field] = useField({
    name,
    type: "checkbox",
    multiple: true,
    value,
  });
  const { t } = useTranslation();

  return (
    <FileItem
      role="listitem"
      className={field.checked && !isPreview ? "bg-background-grey" : ""}
      data-cy="file-item"
    >
      {!isPreview && (
        <StyledCheckbox
          {...field}
          className={classNames(
            className,
            "before:scale-0 checked:before:scale-100" // TODO twin.macro not well support pseudo element
          )}
          type="checkbox"
          variant={variant}
        />
      )}
      <SvgFilePdf className="mr-4 text-light-blue block my-auto min-w-[32px]" />
      <div className="my-auto max-w-[470px] text-ellipsis overflow-hidden whitespace-nowrap">
        {fileName}
      </div>
      {signedNumber ? (
        <>
          {!isPreview ? (
            <IconWrapper>
              <SvgPencil className="mr-2" />
              {signedNumber}
            </IconWrapper>
          ) : (
            <StyledText>
              {t("docRequest.confirmDetail.signatureSpecified", {
                signedNumber: signedNumber,
              })}
            </StyledText>
          )}
        </>
      ) : null}
    </FileItem>
  );
};

const FileItem = styled.label(() => [
  tw`flex justify-start items-center p-2 rounded-lg gap-2`,
]);
