import { Trans } from "react-i18next";

export const SearchResultPageTitle = ({
  titleKey,
  searchWord,
}: {
  titleKey: string;
  searchWord: string;
}): JSX.Element => {
  return (
    <Trans i18nKey={titleKey} values={{ searchWord: searchWord }}>
      <q className="text-light-blue" />
    </Trans>
  );
};
