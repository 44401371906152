import { Popover } from "@headlessui/react";
import { Card } from "common/components/Card";
import { Checkbox } from "common/components/Checkbox";
import SvgInformation from "common/components/svg/Information";
import { useCommonPopover } from "common/hooks/useCommonPopover";
import { RecipientFormValues } from "features/documents/pages/documentRequest/components/RecipientInputFields";
import { RecipientRole } from "features/documents/pages/documentRequest/components/RecipientRole";
import { SettingRolePermissionFormValue } from "features/documents/pages/documentRequest/types";
import { useFormikContext } from "formik";
import { t } from "i18next";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import tw, { styled } from "twin.macro";
import { DocumentRole } from "types/document";

type CheckboxOptionProps = {
  name: DocumentRole;
  isChecked: boolean;
};

const RoleItem = styled.div(() => [tw`flex items-center gap-2 mb-3`]);

/* TODO: (1)Not finished : tooltip style need to adjustment */

type ChooseRecipientRolePopupProps = {
  recipientInfo: RecipientFormValues;
  recipientRole: DocumentRole[];
  isSenderSameAsRecipient: boolean;
  isAllowDownload: boolean;
};

export const generateOptionLabel = (role: DocumentRole): string => {
  switch (role) {
    case DocumentRole.SIGNER:
      return t("common.role.signer");
    case DocumentRole.AUTHENTICATOR:
      return t("common.role.authenticator");
    case DocumentRole.VIEWER:
      return t("common.role.viewer");
    default:
      return "INVALID";
  }
};

const tooltipContent = "docRequest.recipientRole.tooltip";

export const ChooseRecipientRolePopup = ({
  recipientInfo,
  recipientRole,
  isSenderSameAsRecipient,
  isAllowDownload,
}: ChooseRecipientRolePopupProps): JSX.Element => {
  const { setReferenceElement, setPopperElement, styles, attributes } =
    useCommonPopover();
  const { t } = useTranslation();

  const initialSelectRoles = recipientRole.length !== 0 ? recipientRole : [];
  const findOptionIsChecked = (optionName: DocumentRole) => {
    return !!initialSelectRoles.find((role) => role === optionName);
  };
  const [isSignerChecked, setIsSignerChecked] = useState<boolean>(
    findOptionIsChecked(DocumentRole.SIGNER)
  );
  const [isAuthenticatorChecked, setIsAuthenticatorChecked] = useState<boolean>(
    findOptionIsChecked(DocumentRole.AUTHENTICATOR)
  );
  const [isViewerChecked, setIsViewerChecked] = useState<boolean>(
    findOptionIsChecked(DocumentRole.VIEWER)
  );

  const formik = useFormikContext<SettingRolePermissionFormValue>();

  const checkIsAnyOptionChecked = useCallback(() => {
    return isSignerChecked || isAuthenticatorChecked || isViewerChecked;
  }, [isSignerChecked, isAuthenticatorChecked, isViewerChecked]);

  const getCurrentSelectRoles = useCallback(() => {
    const optionStateArray: CheckboxOptionProps[] = [
      {
        name: DocumentRole.SIGNER,
        isChecked: isViewerChecked ? false : isSignerChecked,
      },
      {
        name: DocumentRole.AUTHENTICATOR,
        isChecked: isViewerChecked ? false : isAuthenticatorChecked,
      },
      {
        name: DocumentRole.VIEWER,
        isChecked: isViewerChecked,
      },
    ];

    const isCheckedOptions: DocumentRole[] = optionStateArray
      .filter((option) => option.isChecked)
      .map((checkedOption) => checkedOption.name);
    return isCheckedOptions;
  }, [isSignerChecked, isAuthenticatorChecked, isViewerChecked]);

  const handleSubmitRolesChecked = useCallback(
    (listRoles: DocumentRole[]) => {
      // TODO: Hot Fix For KT-809 Only happen on initial value:
      // In the last line, the roles value of the recipient is set to empty array (formik.setFieldValue),
      // but when the next recipient runs, the roles value of the previous recipient value is still undefined.
      const cloneListRoles = formik
        ? cloneDeep(formik.values.recipientRoles)
        : [];
      const currentRecipient = cloneListRoles?.find(
        (recipient) => recipient.recipient.email === recipientInfo.email
      );

      if (currentRecipient) {
        currentRecipient.roles = listRoles;
      } else {
        cloneListRoles?.push({
          recipient: { ...recipientInfo },
          roles: listRoles,
          canDownload: isAllowDownload,
        });
      }
      formik.setFieldValue("recipientRoles", cloneListRoles);
    },
    // TODO: hot fix
    // eslint-disable-next-line
    [isSignerChecked, isAuthenticatorChecked, isViewerChecked]
  );

  useEffect(() => {
    const currentSelectRoles = getCurrentSelectRoles();
    handleSubmitRolesChecked(currentSelectRoles);
  }, [
    isSignerChecked,
    isAuthenticatorChecked,
    isViewerChecked,
    handleSubmitRolesChecked,
    checkIsAnyOptionChecked,
    getCurrentSelectRoles,
  ]);

  return (
    <Popover>
      <Popover.Button
        className="cursor-pointer"
        data-cy="choose-roles-btn"
        ref={setReferenceElement}
      >
        <RecipientRole
          role={checkIsAnyOptionChecked() ? getCurrentSelectRoles() : []}
          defaultText={t("docRequest.button.chooseRecipientRole")}
          isCanEdit={true}
        />
      </Popover.Button>
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="z-popover roles-popup"
      >
        <Card className="w-44 bg-white mx-7 -my-3 p-4 flex flex-col">
          <div className="flex items-center mb-[18px]">
            <label className="font-medium text-base">
              {t("docRequest.recipientRole.title")}
            </label>
            {!isSenderSameAsRecipient && (
              <>
                <SvgInformation
                  className="ml-2.5 text-grey cursor-pointer svg-info"
                  data-tip
                  data-for="infoTooltip"
                />
                <ReactTooltip id="infoTooltip" className="w-72" effect="solid">
                  <div className="w-64">{t(tooltipContent)} </div>
                </ReactTooltip>
              </>
            )}
          </div>
          <React.Fragment>
            <RoleItem>
              <Checkbox
                name={generateOptionLabel(DocumentRole.SIGNER)}
                value={generateOptionLabel(DocumentRole.SIGNER)}
                isChecked={isSignerChecked}
                handleOnChange={() => {
                  setIsSignerChecked((prevState) => !prevState);
                  setIsViewerChecked(false);
                }}
              />
              <label htmlFor={generateOptionLabel(DocumentRole.SIGNER)}>
                {generateOptionLabel(DocumentRole.SIGNER)}
              </label>
            </RoleItem>
            <RoleItem>
              <Checkbox
                name={generateOptionLabel(DocumentRole.AUTHENTICATOR)}
                value={generateOptionLabel(DocumentRole.AUTHENTICATOR)}
                isChecked={isAuthenticatorChecked}
                handleOnChange={() => {
                  setIsAuthenticatorChecked((prevState) => !prevState);
                  setIsViewerChecked(false);
                }}
              />
              <label htmlFor={generateOptionLabel(DocumentRole.AUTHENTICATOR)}>
                {generateOptionLabel(DocumentRole.AUTHENTICATOR)}
              </label>
            </RoleItem>
            {!isSenderSameAsRecipient && (
              <RoleItem>
                <Checkbox
                  name={generateOptionLabel(DocumentRole.VIEWER)}
                  value={generateOptionLabel(DocumentRole.VIEWER)}
                  isChecked={isViewerChecked}
                  handleOnChange={() => {
                    setIsViewerChecked((prevState) => !prevState);
                    setIsSignerChecked(false);
                    setIsAuthenticatorChecked(false);
                  }}
                />
                <label htmlFor={generateOptionLabel(DocumentRole.VIEWER)}>
                  {generateOptionLabel(DocumentRole.VIEWER)}
                </label>
              </RoleItem>
            )}
          </React.Fragment>
        </Card>
      </Popover.Panel>
    </Popover>
  );
};
