import { SignatureRndHandlerBased } from "common/components/form/PDF/SignatureRndHandler/SignatureRndHandlerBased";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { useGenerateColorByString } from "common/hooks/useGenerateColorByString";
import { isEqualIgnoreCase } from "common/utils/common";
import { generateColorByString } from "enums/colors";
import { SignatureBoxContext } from "features/documents/components/SignatureBox/context/SignatureBoxContext";
import { SignatureBoxNotSignedAction } from "features/documents/components/SignatureBox/SignatureBoxNotSignedAction";
import { SignatureBoxNotSignedPreview } from "features/documents/components/SignatureBox/SignatureBoxNotSignedPreview";
import { SignatureBoxSignAction } from "features/documents/components/SignatureBox/SignatureBoxSignAction";
import { SignatureBoxSignedPreview } from "features/documents/components/SignatureBox/SignatureBoxSignedPreview";
import { ButtonStep } from "features/documents/pages/postDocumentRequest/components/ButtonStepGroup";
import { PDFSignatureSigningContext } from "features/documents/pages/postDocumentRequest/components/PDFSignatureSigning";
import React, { useContext, useMemo } from "react";
import {
  DocumentParticipant,
  ParticipantResponse,
  SignatureSchemas,
} from "types/document";

type PostDocumentRequestRndHandlerProps = {
  style: React.CSSProperties;
  children: React.ReactNode;
  onSignatureClick: (schema: SignatureSchemas) => void;
};

export const PostDocumentRequestRndHandler = React.forwardRef(
  (
    { style, children, ...rest }: PostDocumentRequestRndHandlerProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const {
      signatureSchemas,
      scale,
      isSignaturePreview,
      isSignatureDisplay,
      onSignatureClick,
      signatureMap,
      pageWidth,
      signers,
      step,
      sender,
      currentActiveBoxId,
      setStep,
    } = useContext(PDFSignatureSigningContext);

    const currUserEmail = useCurrentUserProfile()?.email ?? "";
    const currSigner = useMemo(
      () => signers.find((it) => it.email === currUserEmail),
      [currUserEmail, signers]
    );

    const isCurrUserSender = isEqualIgnoreCase(currUserEmail, sender?.email);

    const resizeConfig = {
      bottom: false,
      bottomLeft: false,
      bottomRight: false,
      left: false,
      right: false,
      top: false,
      topLeft: false,
      topRight: false,
    };

    const signerMap = useMemo(
      () =>
        signers.reduce((acc, signer) => {
          acc.set(signer.email, signer);
          return acc;
        }, new Map<string, DocumentParticipant>()),
      [signers]
    );
    const { color } = useGenerateColorByString(currSigner?.email);

    const getNonCurrUserSignBox = (
      schema: SignatureSchemas,
      setIsHover: (isHover: boolean) => void
    ): JSX.Element => {
      const currColor = generateColorByString(schema.email);
      const currSigner = signerMap.get(schema.email);
      const imageSrc = signatureMap[schema.id ?? ""];
      const fullName = currSigner?.profile.fullName ?? "";
      return (
        <SignatureBoxContext.Provider
          value={{
            color: currColor,
            width: schema.width,
            recipients: [],
            fullName,
            email: currSigner?.email ?? "",
            imageSrc,
            scale,
          }}
        >
          {(() => {
            switch (true) {
              case !isCurrUserSender && !imageSrc:
                return <></>;
              case !!imageSrc:
                return (
                  <SignatureBoxSignedPreview
                    onMouseEnter={() => {
                      setIsHover(true);
                    }}
                    onMouseLeave={() => {
                      setIsHover(false);
                    }}
                  />
                );
              case step === ButtonStep.START:
              case step === ButtonStep.DONE:
                return (
                  <SignatureBoxNotSignedPreview
                    isCrossShow={false}
                    displayMsg={currSigner?.profile.fullName ?? ""}
                    onMouseEnter={() => {
                      setIsHover(true);
                    }}
                    onMouseLeave={() => {
                      setIsHover(false);
                    }}
                  />
                );
            }
          })()}
        </SignatureBoxContext.Provider>
      );
    };

    const getCurrUserSignBox = (
      schema: SignatureSchemas,
      isHover: boolean,
      setIsHover: (isHover: boolean) => void
    ): JSX.Element => {
      const currImage = signatureMap[schema.id ?? ""];
      return (
        <SignatureBoxContext.Provider
          value={{
            color,
            width: schema.width,
            recipients: [],
            fullName: currSigner?.profile.fullName ?? "",
            email: currSigner?.email ?? "",
            imageSrc: currImage,
            isGradientBorderShow: currentActiveBoxId === schema.id,
            scale,
          }}
        >
          {(() => {
            switch (step) {
              case !currImage && ButtonStep.START:
                return (
                  <SignatureBoxNotSignedAction
                    isCrossShow={false}
                    isHover={isHover}
                    displayMsg={currSigner?.profile.fullName ?? ""}
                    onMouseEnter={() => {
                      setIsHover(true);
                    }}
                    onMouseLeave={() => {
                      setIsHover(false);
                    }}
                    onClick={() => {
                      setStep && setStep(ButtonStep.ACTION);
                      onSignatureClick(schema);
                    }}
                  />
                );
              case !isSignaturePreview && ButtonStep.ACTION:
                return (
                  <SignatureBoxSignAction
                    onClick={() => onSignatureClick(schema)}
                    onMouseEnter={() => {
                      setIsHover(true);
                    }}
                    onMouseLeave={() => {
                      setIsHover(false);
                    }}
                  />
                );
              case !!currImage && ButtonStep.START:
              case isSignaturePreview && ButtonStep.ACTION:
              case ButtonStep.DONE:
                return (
                  <SignatureBoxSignedPreview
                    onMouseEnter={() => {
                      setIsHover(true);
                    }}
                    onMouseLeave={() => {
                      setIsHover(false);
                    }}
                  />
                );
            }
          })()}
        </SignatureBoxContext.Provider>
      );
    };

    const isRejected = (email: string) => {
      return signerMap.get(email)?.response === ParticipantResponse.REJECTED;
    };

    return (
      <SignatureRndHandlerBased
        pageWidth={pageWidth}
        ref={ref}
        style={style}
        children={children}
        buttonComponent={(schema, index, isHover, setIsHover) => {
          if (isRejected(schema.email)) {
            return <></>;
          }

          return (
            <>
              {isEqualIgnoreCase(currUserEmail, schema?.email)
                ? getCurrUserSignBox(schema, isHover, setIsHover)
                : getNonCurrUserSignBox(schema, setIsHover)}
            </>
          );
        }}
        resizeConfig={resizeConfig}
        isSignatureDisplay={isSignatureDisplay}
        signatureSchemas={signatureSchemas}
        isDraggable={false}
        scale={scale}
      />
    );
  }
);
