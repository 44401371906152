import { BaseBreadCrumbs } from "common/components/Breadcrumbs/BaseBreadcrumbs";
import { useHistoryPaths } from "common/hooks/useHistoryPaths";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const breadcrumbNameMap: { [key: string]: string } = {
  "/documents": "dashboard.documents",
  "/documents/all": "dashboard.tab.all",
  "/documents/bin": "dashboard.tab.bin",
  "/documents/shared-with-me": "dashboard.tab.shareWithMe",
  "/documents/created-by-me": "dashboard.tab.createByMe",
  // "/admin-settings": "Admin Settings", // TODO TBD
};

export enum BreadCrumbsType {
  PAGE = "page",
  FOLDER = "folder",
}

type DashboardBreadcrumbsProps = {
  data: BreadCrumbItemProps[];
  onItemClick: (pathname: string, pathIndex?: number) => void;
  isOnSearchResultPage?: boolean;
  className?: string;
};

export type BreadCrumbItemProps = {
  label: string;
  value: string;
  style?: string;
};

export const DashboardBreadcrumbs = ({
  data,
  onItemClick,
  isOnSearchResultPage,
  className,
}: DashboardBreadcrumbsProps): JSX.Element => {
  const pathNames = useHistoryPaths();
  const { t } = useTranslation();

  const totalBreadcrumbsLength =
    data.length + pathNames.length + Number(!!isOnSearchResultPage);

  return (
    <BaseBreadCrumbs
      containerClassName={className}
      itemContainerClassName="hover:text-light-blue"
    >
      {/* Render initial paths */}
      {pathNames.map((_, index) => {
        const url = `/${pathNames.slice(0, index + 1).join("/")}`;
        const last = index === totalBreadcrumbsLength - 1;
        return (
          <React.Fragment key={index}>
            {last ? (
              <span data-cy="breadcrumb-link" key={index}>
                {t(breadcrumbNameMap[url])}
              </span>
            ) : (
              <NavLink
                key={index}
                to={url}
                onClick={() => onItemClick(url)}
                className="hover:underline"
              >
                {t(breadcrumbNameMap[url])}
              </NavLink>
            )}
          </React.Fragment>
        );
      })}

      {/* Render folder paths */}
      {isOnSearchResultPage ? (
        <span> {t("dashboard.breadcrumbs.searchResult")}</span>
      ) : data.length ? (
        data.map((path, index) => (
          <span
            data-cy="breadcrumb-link"
            key={path.value}
            onClick={() => onItemClick(path.value, index)}
            className={index < data.length - 1 ? "hover:underline" : ""}
          >
            {path.label}
          </span>
        ))
      ) : null}
    </BaseBreadCrumbs>
  );
};
