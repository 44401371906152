import { CrossButton } from "features/documents/components/SignatureBox/CrossButton";
import { useSignatureBox } from "features/documents/components/SignatureBox/hooks/useSignatureBox";
import {
  SignatureButtonContainer,
  SignatureButtonContentContainer,
  SignaturePencil,
} from "features/documents/components/SignatureBox/SignatureButtonBased";
import { SignatureButtonRecipientInfoLabelPopover } from "features/documents/components/SignatureBox/SignatureButtonRecipientInfoLabelPopover";
import { SignatureContent } from "features/documents/components/SignatureBox/SignatureCreateButton";
import { useContentRatio } from "features/documents/hooks/useContentRatio";
import { forwardRef, MouseEventHandler, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CommonMouseEvents } from "types/common";

type SignatureBoxNotSignedActionProps = {
  isCrossShow: boolean;
  onCrossClick?: MouseEventHandler<HTMLDivElement>;
  displayMsg: string;
  isHover: boolean;
  isGradientBorderShow?: boolean;
  isGradientEnable?: boolean;
} & CommonMouseEvents;

export const SignatureBoxNotSignedAction = forwardRef<
  HTMLDivElement,
  SignatureBoxNotSignedActionProps
>(
  (
    {
      onMouseEnter,
      onMouseLeave,
      onClick,
      onCrossClick,
      isCrossShow,
      displayMsg,
      isGradientBorderShow,
      isGradientEnable,
      isHover,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { color, width, scale, email, fullName } = useSignatureBox();
    const { scaleRatio, contentRef, mutate } = useContentRatio(
      width * scale,
      0.7
    );
    useEffect(() => {
      mutate();
      // Must disable to prevent infinite update
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayMsg, width, scale]);

    return (
      <SignatureButtonRecipientInfoLabelPopover
        fullName={fullName}
        email={email}
      >
        {({ setReferenceElement, setIsPopoverShow }) => (
          <SignatureButtonContainer
            ref={ref || setReferenceElement}
            color={color}
            data-cy="signature-btn"
            isBackgroundShow={true}
            isBorderShow={true}
            onMouseEnter={(e) => {
              onMouseEnter && onMouseEnter(e);
              setIsPopoverShow(true);
            }}
            onMouseLeave={(e) => {
              onMouseLeave && onMouseLeave(e);
              setIsPopoverShow(false);
            }}
            onClick={onClick}
            isGradientBorderShow={isGradientBorderShow}
            isGradientEnable={isGradientEnable}
          >
            <SignatureButtonContentContainer scaleRatio={scaleRatio}>
              <SignaturePencil
                className={`text-${color}`}
                scaleRatio={scaleRatio}
                data-cy="signature-box-pencil"
              />
              <SignatureContent ref={contentRef} className="grow">
                {isHover
                  ? t("docRequest.signatureBox.clickToSign")
                  : displayMsg}
              </SignatureContent>
              {isCrossShow && (
                <CrossButton scaleRatio={scaleRatio} onClick={onCrossClick} />
              )}
            </SignatureButtonContentContainer>
          </SignatureButtonContainer>
        )}
      </SignatureButtonRecipientInfoLabelPopover>
    );
  }
);
