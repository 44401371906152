import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { DocumentParticipant, ParticipantResponse } from "types/document";

type RecipientsRejectedFilterValues = {
  listRecipients: DocumentParticipant[];
};

const useRecipientsRejectedFilter = (
  isRejectSectionEnable: boolean,
  recipientValues: DocumentParticipant[]
): RecipientsRejectedFilterValues => {
  const [listRecipients, setListRecipients] =
    useState<DocumentParticipant[]>(recipientValues);
  useEffect(() => {
    if (isRejectSectionEnable) {
      const cloneListRecipients = cloneDeep(recipientValues);
      const listRejectRecipients = cloneListRecipients.filter(
        (recipient) => recipient.response === ParticipantResponse.REJECTED
      );
      setListRecipients(listRejectRecipients);
    } else {
      setListRecipients(recipientValues);
    }
  }, [setListRecipients, recipientValues, isRejectSectionEnable]);
  return { listRecipients };
};

export default useRecipientsRejectedFilter;
