import classNames from "classnames";
import { ChangeEvent } from "react";
import tw, { styled } from "twin.macro";

type CheckboxProps = {
  id?: string;
  name: string;
  value: string;
  disabled?: boolean;
  isChecked?: boolean;
  handleOnChange: (e: ChangeEvent<any>) => void;
  className?: string;
  role?: string;
} & StyledCheckboxProps;

export type StyledCheckboxProps = {
  variant?: "primary" | "secondary";
};

export const Checkbox = ({
  name,
  id,
  value,
  disabled,
  isChecked = false,
  handleOnChange,
  className,
  variant = "primary",
  role = "checkbox",
}: CheckboxProps) => {
  return (
    <>
      <StyledCheckbox
        name={name}
        className={classNames(
          className,
          "before:scale-0 checked:before:scale-100" // TODO twin.macro not well support pseudo element
        )}
        variant={variant}
        id={id}
        type="checkbox"
        value={value}
        disabled={disabled}
        checked={isChecked}
        onChange={handleOnChange}
        role={role}
      />
    </>
  );
};

export const StyledCheckbox = styled.input(
  ({ variant }: StyledCheckboxProps) => [
    tw`w-4 h-4 bg-white`,
    tw`appearance-none grid place-content-center`,
    variant === "primary" &&
      tw`rounded-sm border-solid border-grey border-[0.063rem] text-light-blue `,
    tw`before:content-[""] before:w-3 before:h-3  before:rounded-sm before:bg-light-blue`,
    tw`checked:border-current`,
  ]
);
