import { useModal } from "@liholiho/react-modal-hook";
import { Dimension } from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { PDFConfirmModal } from "features/documents/pages/documentRequest/pages/UploadDocument/components/PDFConfirmModal";
import { PDFFile } from "features/documents/pages/documentRequest/types";

type ShowModal = () => void;
export const usePDFConfirmModal = (
  files: PDFFile[],
  onAllLoadSuccess: (dimensions: Dimension[][]) => void
): [ShowModal] => {
  const [showModal, hideModal] = useModal(
    ({ in: isOpen }) => {
      return (
        <PDFConfirmModal
          isOpen={isOpen}
          hideModal={hideModal}
          files={files}
          onAllLoadSuccess={onAllLoadSuccess}
        />
      );
    },
    [files, onAllLoadSuccess]
  );
  return [showModal];
};
