import { Transition } from "@headlessui/react";
import { Header } from "common/components/Header";
import { GridMenuPopup } from "common/components/Header/GridMenuPopup";
import { UserSetting } from "common/components/Header/UserSetting";
import {
  KentroToastContainer,
  ToastCloseButton,
} from "common/components/KentroToastContainer";
import { Sidebar } from "common/components/Sidebar/Sidebar";
import SvgNotification from "common/components/svg/Notification";
import { VersionDisplay } from "common/components/VersionDisplay";
import { useGlobalSearchValue } from "common/hooks/useGlobalSearchValue";
import { ToastContainerId } from "common/toast";
import { isDetailPage, isDocRequestPage } from "common/utils/document";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import tw, { styled } from "twin.macro";

export const ItemWrapper = styled.div(() => [
  tw`flex flex-1 items-center cursor-pointer`,
]);
export const ItemText = styled.span(() => [tw`text-sm font-medium`]);
export const Divider = styled.div(() => [tw`h-[1px] bg-grey my-[10px]`]);

type DashboardLayoutProps = {
  className?: string;
  children: React.ReactNode;
};
export const DashboardLayout = ({
  className,
  children,
}: DashboardLayoutProps): JSX.Element => {
  const [isSidebarShowing, setIsSidebarShowing] = useState(true);
  const { pathname } = useLocation();
  const { setSearchValue, searchResults } = useGlobalSearchValue();
  const [isOnResetSearchBar, setIsOnResetSearchBar] = useState<boolean>(false);

  useEffect(() => {
    if (isDetailPage(pathname) || isDocRequestPage(pathname)) {
      setIsSidebarShowing(false);
    } else {
      setIsSidebarShowing(true);
    }
  }, [pathname]);

  // When refresh or change page , clear search bar
  const resetSearchBar = useCallback(() => {
    setIsOnResetSearchBar(true);
  }, [setIsOnResetSearchBar]);

  useEffect(() => {
    resetSearchBar();
  }, [pathname, resetSearchBar]);

  return (
    <>
      <div className={`${className} min-w-[1132px] flex flex-1`}>
        <Transition
          show={isSidebarShowing}
          enter="transition ease-in-out duration-200 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-150 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className="z-side-bar"
        >
          <Sidebar />
        </Transition>

        <GridContainer className="grow">
          <Header
            className="col-span-full"
            searchDocuments={searchResults}
            onMenuBarClick={() => setIsSidebarShowing(!isSidebarShowing)}
            isOnResetSearchValue={isOnResetSearchBar}
            setIsOnResetSearchValue={setIsOnResetSearchBar}
            setSearchBarValue={setSearchValue}
          >
            <GridMenuPopup />
            <VersionDisplay versionCondition=">= 2.0.0">
              <SvgNotification />
            </VersionDisplay>
            <UserSetting />
          </Header>
          <div className="relative flex flex-1 flex-col col-span-full bg-background-grey">
            <KentroToastContainer
              containerId={ToastContainerId.AfterLogin}
              position="top-center"
              enableMultiContainer
              autoClose={false}
              closeButton={ToastCloseButton}
            />
            {children}
          </div>
        </GridContainer>
      </div>
    </>
  );
};

const GridContainer = styled.div`
  ${tw`grid grid-cols-12 grid-rows-layout gap-x-5`}
`;
