import { UserIcon } from "common/components/UserIcon";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { Colors, generateColorByString } from "enums/colors";
import { useSortedRecipientList } from "features/documents/pages/postDocumentRequest/hooks/useSortedRecipientList";
import { useCallback, useMemo } from "react";
import { useSelector } from "redux/hooks";
import { currentPartySelector } from "redux/slices/party/selectors";
import { DashboardResponse } from "types/common";

/* Folder Management */

type InvolvedParticipantsCellProps = {
  value: DashboardResponse;
  isDiscarded: boolean;
};

export const InvolvedParticipantsCell = ({
  value,
  isDiscarded,
}: InvolvedParticipantsCellProps): JSX.Element => {
  const currentParty = useSelector(currentPartySelector);
  const currentUserProfile = useCurrentUserProfile();
  const sortedRecipients = useSortedRecipientList(
    value.recipients?.filter((it) => it.email !== value.sender?.email)
  );

  const participantsInTheSameParty = useMemo(() => {
    return [value.sender]
      .concat(
        sortedRecipients.filter((it) => it.party === currentParty?.x500Name)
      )
      .filter((it) => it?.email !== currentUserProfile?.email);
  }, [
    sortedRecipients,
    value.sender,
    currentParty?.x500Name,
    currentUserProfile,
  ]);
  const generateColorByEmail = useCallback(
    (email: string) => generateColorByString(email),
    []
  );

  return (
    <>
      {participantsInTheSameParty.slice(0, 5).map((it) => {
        if (it) {
          return (
            <UserIcon
              key={it.email}
              className="mr-1"
              variant={"initials"}
              content={it?.profile.fullName ?? ""}
              color={isDiscarded ? Colors.GREY : generateColorByEmail(it.email)}
            />
          );
        }
        return null;
      })}
    </>
  );
};
