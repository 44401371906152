import { useSignatureBox } from "features/documents/components/SignatureBox/hooks/useSignatureBox";
import { SignatureBoxActionBased } from "features/documents/components/SignatureBox/SignatureBoxActionBased";
import { SignatureButtonRecipientInfoLabelPopover } from "features/documents/components/SignatureBox/SignatureButtonRecipientInfoLabelPopover";
import { CommonMouseEvents } from "types/common";

type SignatureBoxSignActionProps = CommonMouseEvents;

export const SignatureBoxSignAction = ({
  onClick,
  onMouseEnter,
  onMouseLeave,
}: SignatureBoxSignActionProps) => {
  const { color, width, fullName, email, imageSrc, isGradientBorderShow } =
    useSignatureBox();
  return (
    <SignatureButtonRecipientInfoLabelPopover
      fullName={fullName}
      email={email}
      isPopoverShowByDefault={true}
    >
      {({ setReferenceElement }) => (
        <SignatureBoxActionBased
          onClick={onClick}
          color={color}
          width={width}
          isCrossShow={false}
          setReferenceElement={setReferenceElement}
          imageSrc={imageSrc}
          onMouseEnter={onMouseEnter}
          isGradientBorderShow={!!isGradientBorderShow}
          onMouseLeave={onMouseLeave}
        />
      )}
    </SignatureButtonRecipientInfoLabelPopover>
  );
};
