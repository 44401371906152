import { REFRESH_TOKEN_EXPIRE_TIME } from "constants/common";
import { MIME_RECORD } from "constants/file";

import { oneNetworkAPI } from "api/oneNetwork";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from "axios";
import {
  APIError,
  APIErrorResponse,
  ERROR_KEY_CORDA_NOTFOUND,
} from "common/errorHandling";
import { useAuth, useLogout } from "common/hooks";
import { ERROR_KEY_CONCURRENT_LOGIN } from "common/hooks/useAuthErrorHandler";
import { useConcurrentLoginErrorHandler } from "common/hooks/useConcurrentLoginErrorHandler";
import { TimeUnit } from "common/hooks/useCountDownTimer";
import { useRefreshPopupHandler } from "common/hooks/useRefreshPopupHandler";
import { getDateTimeAfter } from "common/utils/common";
import { KENTRO_ONE_NETWORK_DOMAIN } from "common/utils/cross-domain/react/config";
import { LOCAL_STORAGE_KEY } from "common/utils/cross-domain/react/helper";
import { postTokenHandler } from "common/utils/cross-domain/react/postTokenHandler";
import { useCrossDomainTokenListener } from "common/utils/cross-domain/react/receiveTokenHandler";

export type APIResponse<T> = T | APIError;

export type TokenResponse = {
  accessToken: string;
  refreshToken: string;
};

const instance = axios.create({
  baseURL: "/api/v1/",
  headers: {
    "content-type": MIME_RECORD.JSON,
  },
});

export const WithRequest = ({ children }: { children: React.ReactNode }) => {
  const logout = useLogout();
  const { show: showRefreshPopup } = useRefreshPopupHandler();
  const { show, hide } = useConcurrentLoginErrorHandler(() => {
    hide();
    logout();
  });

  const { setAccessToken, setRefreshToken, setTokenTimeout, clearAuthToken } =
    useAuth();

  useCrossDomainTokenListener();

  /**
   * A promise represent for refresh token api.
   * Make sure only first request call refresh token api
   * Other requests will wait for new pair token from first request to finish request
   */
  let refreshTokenPromise: Promise<TokenResponse> | null = null;

  const handlePostTokenWithError = async () => {
    try {
      await postTokenHandler();
    } catch (err) {
      console.error(err);
    }
  };

  const clearAuthAndLogout = () => {
    clearAuthToken();
    handlePostTokenWithError();
    // Child app will redirect to KON when both access & refresh token expired
    if (KENTRO_ONE_NETWORK_DOMAIN) {
      window.location.replace(KENTRO_ONE_NETWORK_DOMAIN);
    } else {
      console.error("INVALID CONFIG");
    }
  };

  const syncTokenCrossDomain = async (
    accessToken: string,
    refreshToken: string
  ) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    handlePostTokenWithError();
  };

  instance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    if (accessToken) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }
    return config;
  });

  instance.interceptors.response.use(
    (res) => {
      if (res.data) {
        const token = res.data as TokenResponse;
        if (token.accessToken && token.refreshToken) {
          const timeout = getDateTimeAfter(
            new Date(),
            Number(REFRESH_TOKEN_EXPIRE_TIME),
            TimeUnit.MINUTE
          ).getTime();

          setTokenTimeout(timeout.toString());
          syncTokenCrossDomain(token.accessToken, token.refreshToken);
        }
      }
      return res;
    },
    (error) => {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<APIErrorResponse>;
        if (axiosError.response) {
          // Refresh token in KS
          if (axiosError.response.status === 401) {
            const refreshToken = localStorage.getItem(
              LOCAL_STORAGE_KEY.REFRESH_TOKEN
            );

            // when refresh token expired (edit token manually)
            if (axiosError.response.data.path.includes("refresh")) {
              clearAuthAndLogout();
            }

            // when access token expired
            if (refreshToken) {
              /**
               * If already have a promise from one of requests call refresh token => return promise of that request
               * If not having any promise => call refresh token
               */
              refreshTokenPromise =
                refreshTokenPromise || oneNetworkAPI.refreshToken(refreshToken);

              // When promise is resolved, set refreshTokenPromise to initial value
              return refreshTokenPromise.then((res) => {
                refreshTokenPromise = null;
                syncTokenCrossDomain(res.accessToken, res.refreshToken);
                return instance.request(error.config);
              });
            } else {
              clearAuthAndLogout();
            }
            // when login fail
            throw error;
          }
          // Error from download api is not correct format => use this to handle concurrent login
          if (
            axiosError.response.data instanceof Blob &&
            axiosError.response.status === 403
          ) {
            show();
            throw error;
          }
          // BE trigger 403 but no errors return
          if (
            axiosError.response.data.errors === undefined &&
            axiosError.response.status === 403
          ) {
            throw error;
          }
          switch (axiosError.response.data.errors[0].key) {
            case ERROR_KEY_CONCURRENT_LOGIN:
              show();
              break;
            case ERROR_KEY_CORDA_NOTFOUND:
              showRefreshPopup();
              break;
            // Case reload page
            default:
              throw error;
          }
        }
      }
      throw error;
    }
  );

  return <>{children}</>;
};

export const request = async <RES, REQ = undefined>(
  url: string,
  method: Method = "GET",
  data?: REQ,
  config?: AxiosRequestConfig<REQ>
): Promise<RES> => {
  try {
    const { data: res } = await instance.request<
      RES,
      AxiosResponse<RES, REQ>,
      REQ
    >({
      ...(config || {}),
      url,
      method,
      data,
    });
    return res;
  } catch (error) {
    console.error(error);
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<APIErrorResponse>;
      if (axiosError.response) {
        throw new APIError(
          axiosError.response.data.errors,
          axiosError.response.status
        );
      }
    }
    throw new APIError([
      {
        enMessage: "unknown error",
        key: "Error.Unknown",
        valuesStr: [],
      },
    ]);
  }
};
