import { Button } from "common/components/Button";
import { Message } from "common/components/Message";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";

export enum ButtonStep {
  START = "START",
  ACTION = "ACTION",
  DONE = "DONE",
}

type ButtonStepGroupProps = {
  children: React.ReactNode;
  step: ButtonStep;
  setStep: Dispatch<SetStateAction<ButtonStep>>;
  hintMessage: string;
  isHitMessageDisplay: boolean;
  isError?: boolean;
  errorMessage?: string;
  isCurrentUserSenderSameAsRecipient?: boolean;
  onReject?: () => void;
  onDiscard?: () => void;
};

export const ButtonStepGroup = ({
  children,
  step,
  setStep,
  hintMessage,
  isHitMessageDisplay,
  isError,
  errorMessage,
  isCurrentUserSenderSameAsRecipient,
  onReject,
  onDiscard,
}: ButtonStepGroupProps) => {
  const { t } = useTranslation();
  const getButtonGroupByStep = () => {
    switch (step) {
      case ButtonStep.START:
        return isCurrentUserSenderSameAsRecipient ? (
          <div className="flex justify-between items-center">
            <Button
              variant="secondary"
              className="w-44"
              onClick={onDiscard}
              type="button"
            >
              {t("dashboard.button.discard")}
            </Button>
            <Button
              variant="primary"
              className="w-44"
              onClick={() => setStep(ButtonStep.ACTION)}
            >
              {t("docDetails.button.start")}
            </Button>
          </div>
        ) : (
          <>
            <Button
              variant="primary"
              className="w-full"
              onClick={() => setStep(ButtonStep.ACTION)}
            >
              {t("docDetails.button.start")}
            </Button>
          </>
        );
      case ButtonStep.ACTION:
        return (
          <div className="flex justify-between">
            {isCurrentUserSenderSameAsRecipient ? (
              <Button
                variant="secondary"
                className="w-44"
                onClick={onDiscard}
                type="button"
              >
                {t("dashboard.button.discard")}
              </Button>
            ) : (
              <Button
                variant="secondary"
                className="w-44"
                onClick={onReject}
                type="button"
              >
                {t("docDetails.button.reject")}
              </Button>
            )}

            {children}
          </div>
        );
      case ButtonStep.DONE:
        return isCurrentUserSenderSameAsRecipient ? (
          <div className="flex justify-between">{children}</div>
        ) : (
          <></>
        );
    }
  };

  return (
    <ButtonContainer>
      {isHitMessageDisplay && (
        <StyledHintMessage variant={"warning"} isBackgroundEnable={false}>
          {hintMessage}
        </StyledHintMessage>
      )}
      {isError && (
        <Message
          variant={"warning"}
          isBackgroundEnable={false}
          className="mb-2.5 text-xs"
        >
          {errorMessage}
        </Message>
      )}
      {getButtonGroupByStep()}
    </ButtonContainer>
  );
};

const StyledHintMessage = tw(Message)`mb-3 !text-dark-grey text-xs`;
const ButtonContainer = styled.div(tw`w-full`);
