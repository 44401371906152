import { useModal } from "@liholiho/react-modal-hook";
import {
  DependencyList,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";

type ModalFactory<T> = (data?: T) => FunctionComponent<any>;
type ModalHandlers<T> = [showModal: (data: T) => void, closeModal: () => void];

const useModalWithData = <T>(
  modalFactory: ModalFactory<T>,
  dependencyList?: DependencyList
): ModalHandlers<T> => {
  const [data, setData] = useState<T>();
  const modalComponent = useMemo(
    () => modalFactory(data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data].concat(dependencyList ?? [])
  );
  const [_showModal, closeModal] = useModal(
    modalComponent,
    [data].concat(dependencyList ?? [])
  );

  const showModal = useCallback(
    (data: T) => {
      setData(data);
      _showModal();
    },
    [_showModal]
  );

  return [showModal, closeModal];
};

export default useModalWithData;
