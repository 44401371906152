import { Button } from "common/components/Button";
import { SignatureControlPanelStep } from "features/documents/pages/documentRequest/types";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type SignatureControlPanelNavigationProps = {
  isButtonDisable: boolean;
  onBack: () => void;
  onNext: () => void;
  onSkip: () => void;
  step: SignatureControlPanelStep;
};

export const SignatureControlPanelNavigation = ({
  step,
  isButtonDisable,
  onNext,
  onBack,
  onSkip,
}: SignatureControlPanelNavigationProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="flex">
      <Button type="button" variant="secondary" onClick={onBack}>
        {t("common.back")}
      </Button>
      <div className="ml-auto flex items-center gap-4">
        {step === SignatureControlPanelStep.SENDER_AUTHENTICATE ? (
          <>
            <SkipButton data-cy="skip-btn" onClick={onSkip}>
              {t("docRequest.button.skip")}
            </SkipButton>
            <Button
              variant="primary"
              type="button"
              className="ml-auto"
              onClick={onNext}
              disabled={isButtonDisable}
            >
              {t("common.authenticate")}
            </Button>
          </>
        ) : (
          <Button
            variant="primary"
            type="button"
            className="ml-auto"
            onClick={onNext}
            disabled={isButtonDisable}
          >
            {step === SignatureControlPanelStep.PREVIEW_TO_SEND
              ? t("common.send")
              : t("common.next")}
          </Button>
        )}
      </div>
    </div>
  );
};

const SkipButton = tw.span`text-base font-medium cursor-pointer text-light-blue`;
