import { Button } from "common/components/Button";
import { RecipientListFormValues } from "features/documents/pages/documentRequest/types";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

export type CancelAndNextButtonsProps = {
  onClickCancel: () => void;
  onClickNext: () => void;
};
export const CancelAndNextButtons = ({
  onClickCancel,
  onClickNext,
}: CancelAndNextButtonsProps): JSX.Element => {
  const { submitForm, validateForm } =
    useFormikContext<RecipientListFormValues>();
  const { t } = useTranslation();
  const handleClickNext = async () => {
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length === 0) {
        await submitForm();
        onClickNext();
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <Button variant="secondary" type="button" onClick={onClickCancel}>
        {t("common.cancel")}
      </Button>
      <Button variant="primary" type="button" onMouseDown={handleClickNext}>
        {t("common.next")}
      </Button>
    </>
  );
};
