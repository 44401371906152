import { authAPI } from "api/auth";
import { useAuthError } from "common/hooks/useAuthError";
import { KENTRO_ONE_NETWORK_DOMAIN } from "common/utils/cross-domain/react/config";
import { postTokenHandler } from "common/utils/cross-domain/react/postTokenHandler";
import { useCallback } from "react";
import { useDispatch } from "redux/hooks";
import { clearAuth } from "redux/slices/auth/authSlice";
import { resetParties } from "redux/slices/party/partySlice";
import { useSWRConfig } from "swr";

declare module "swr/dist/types" {
  export interface Cache {
    clear(): void;
  }
}

type LogoutFn = (redirectTo?: string) => Promise<void>;

/**
 * navigate is not stable function that causes undesired re-render
 * see https://github.com/remix-run/react-router/issues/7634
 */

const useLogout = (): LogoutFn => {
  const dispatch = useDispatch();
  const { setAuthError } = useAuthError();
  const { cache } = useSWRConfig();

  return useCallback<LogoutFn>(
    async (redirectTo?: string) => {
      try {
        await authAPI.logout();
      } catch (e) {
        console.error(e);
      }
      setAuthError(undefined);
      cache.clear();
      dispatch(clearAuth());
      dispatch(resetParties());
      (async () => {
        try {
          await postTokenHandler();
        } catch (err) {
          console.error(err);
        }
      })();
      if (KENTRO_ONE_NETWORK_DOMAIN) {
        window.location.replace(KENTRO_ONE_NETWORK_DOMAIN);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, useAuthError]
  );
};

export default useLogout;
