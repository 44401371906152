import { Popover } from "@headlessui/react";
import { Card } from "common/components/Card";
import SvgLogOut from "common/components/svg/LogOut";
import { UserIcon } from "common/components/UserIcon";
import { useCommonPopover } from "common/hooks/useCommonPopover";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { useGenerateColorByString } from "common/hooks/useGenerateColorByString";
import { ItemText, ItemWrapper } from "common/layouts/DashboardLayout";
import { generateFullName } from "common/utils/common";
import { useLogoutPopupHandler } from "features/Logout/hooks/useLogoutPopupHandler";
import { useTranslation } from "react-i18next";

export const UserSetting = (): JSX.Element => {
  const currentUser = useCurrentUserProfile();
  const { color } = useGenerateColorByString(currentUser?.email);
  const { setReferenceElement, setPopperElement, styles, attributes } =
    useCommonPopover();
  const { t } = useTranslation();
  const { handler: showLogoutPopup } = useLogoutPopupHandler();

  return (
    <Popover className="relative" data-cy="user">
      <Popover.Button ref={setReferenceElement}>
        <UserIcon
          variant={"initials"}
          content={generateFullName(
            currentUser?.firstName,
            currentUser?.lastName
          )}
          color={color}
        />
      </Popover.Button>
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="z-popover"
      >
        {({ close }) => (
          <Card className="w-[172px] min-h-fit bg-white mx-7 my-5 p-4 flex flex-col">
            <ItemWrapper onClick={showLogoutPopup}>
              <SvgLogOut className="mr-[10px] text-light-blue" />
              <ItemText>{t("dashboard.button.logout")}</ItemText>
            </ItemWrapper>
          </Card>
        )}
      </Popover.Panel>
    </Popover>
  );
};
