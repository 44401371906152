import * as React from "react";
import { SVGProps } from "react";

const SvgFileJpg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 19.5V7.625L14 2H6.667a2.76 2.76 0 0 0-1.886.732A2.423 2.423 0 0 0 4 4.5v15c0 .663.28 1.299.781 1.768A2.76 2.76 0 0 0 6.667 22h10.666a2.76 2.76 0 0 0 1.886-.732c.5-.47.781-1.105.781-1.768ZM14 5.75c0 .497.21.974.586 1.326.375.351.884.549 1.414.549h2.667V19.5c0 .331-.14.65-.39.884a1.38 1.38 0 0 1-.944.366H6.667a1.38 1.38 0 0 1-.943-.366 1.212 1.212 0 0 1-.39-.884v-15c0-.332.14-.65.39-.884.25-.234.589-.366.943-.366H14v2.5Z"
      fill="#00A3FF"
    />
    <path
      d="M7.684 18.06c.911 0 1.478-.393 1.478-1.52v-2.5h-.936v2.488c0 .616-.245.787-.64.787a1.63 1.63 0 0 1-.482-.07l-.104.72c.184.06.468.095.684.095ZM11.198 16.6c.555 0 1.028-.13 1.35-.417.246-.223.382-.552.382-.94 0-.387-.179-.716-.445-.915-.276-.212-.69-.316-1.268-.316a8.13 8.13 0 0 0-1.27.08v3.902h.93v-1.413c.087.012.198.018.321.018ZM15.613 18.037c.55 0 1.1-.13 1.387-.223V15.73h-1.546v.697h.641v.828c-.075.036-.246.06-.463.06-.77 0-1.307-.482-1.307-1.297 0-.857.593-1.28 1.376-1.28.456 0 .739.077.967.17l.196-.714c-.202-.093-.617-.194-1.152-.194-1.363 0-2.366.75-2.374 2.065-.005.581.205 1.097.586 1.437.383.353.932.535 1.69.535Z"
      fill="#00A3FF"
    />
    <path
      d="M11.262 14.754c-.19 0-.32.018-.387.036v1.167c.079.017.179.022.316.022.497 0 .805-.24.805-.644 0-.364-.264-.58-.734-.58Z"
      fill="#fff"
    />
  </svg>
);

export default SvgFileJpg;
