import SvgHamburger from "common/components/svg/Hamburger";
import { SearchBarWithDropdown } from "features/search/components/SearchBarWithDropdown ";
import { ReactNode } from "react";
import tw, { styled } from "twin.macro";
import { DashboardResponse } from "types/common";

type HeaderProps = {
  children: ReactNode;
  setSearchBarValue: (value: string) => void;
  searchDocuments: DashboardResponse[];
  isOnResetSearchValue: boolean;
  setIsOnResetSearchValue: (isOnResetSearchValue: boolean) => void;
  onMenuBarClick?: () => void;
  className?: string;
};

const Wrapper = styled.div(() => [
  tw`relative py-3 px-8 w-full z-fixed`,
  tw`text-grey `,
  tw`bg-white`,
  tw`border-b-4 border-light-greyscale`,
  tw`grid grid-cols-12 gap-x-5`,
]);

export const SearchArea = styled.div(tw`w-[480px]`);
const LeftContainer = styled.div(tw`col-span-6 flex space-x-8 items-center`);
const RightContainer = styled.div(
  tw`col-end-13 flex space-x-2 items-center justify-end`
);

export const Header = ({
  onMenuBarClick,
  isOnResetSearchValue,
  setIsOnResetSearchValue,
  setSearchBarValue,
  searchDocuments,
  children,
  className,
}: HeaderProps): JSX.Element => {
  return (
    <Wrapper className={className}>
      <LeftContainer>
        <SvgHamburger
          onClick={onMenuBarClick}
          className="cursor-pointer"
          data-cy="icon-hamburger"
        />
        <SearchBarWithDropdown
          className="w-full"
          searchResults={searchDocuments}
          onChange={setSearchBarValue}
          isOnReset={isOnResetSearchValue}
          setIsOnReset={setIsOnResetSearchValue}
        />
      </LeftContainer>
      <RightContainer>{children}</RightContainer>
    </Wrapper>
  );
};
