import { documentAPI, SignatureRecord } from "api/document";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { ToastContainerId } from "common/toast";
import { getDashboardItemViewStatus } from "features/documents/components/StatusCell";
import { RecipientUpdateInfoProps } from "features/documents/pages/documentRequest/types";
import { PreviewDocumentWithSignatures } from "features/documents/pages/postDocumentRequest/components//PreviewDocumentWithSignatures";
import { ButtonStep } from "features/documents/pages/postDocumentRequest/components/ButtonStepGroup";
import { PostDocumentRequestTemplate } from "features/documents/pages/postDocumentRequest/components/PostDocumentRequestTemplate";
import { useIsActionTaken } from "features/documents/pages/postDocumentRequest/hooks/useIsActionTaken";
import useRecipientProfileList from "features/documents/pages/postDocumentRequest/hooks/useRecipientProfileList";
import { DocumentAuthenticateSetting } from "features/documents/pages/postDocumentRequest/pages/authentication/components/DocumentAuthenticateSetting";
import { generateDocumentUpdatePayload } from "features/documents/pages/postDocumentRequest/pages/preview/DocumentPreviewView";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "redux/hooks";
import { currentPartySelector } from "redux/slices/party/selectors";
import { FormSubmissionHandler } from "types/common";
import {
  ActionTaken,
  DocumentStatePayload,
  DocumentStatePayloadMutate,
} from "types/document";
import { MapSchema } from "types/mapSchema";
import * as yup from "yup";

// TODO add schema type with Map
const schema: yup.SchemaOf<any> = yup.object().shape({
  recipientInfo: yup.array().of(
    yup.object().shape({
      email: yup.string().trim().required().email(),
      firstName: yup.string().trim().required(),
      lastName: yup.string().trim().required(),
    })
  ),
  signatures: new MapSchema(yup.string(), yup.string()),
});

export type DocumentAuthenticateForm = {
  signatures: SignatureRecord;
  recipientInfo: RecipientUpdateInfoProps[];
};

type DocumentAuthenticateViewProps = {
  documentStatePayload: DocumentStatePayload;
  documentPath: string | undefined;
  mutate: DocumentStatePayloadMutate;
  signatureRecord: SignatureRecord;
  isSender: boolean;
  senderAsRecipientActionTaken?: ActionTaken;
};
export const DocumentAuthenticateView = ({
  documentStatePayload,
  documentPath,
  mutate,
  signatureRecord,
  isSender,
  senderAsRecipientActionTaken,
}: DocumentAuthenticateViewProps): JSX.Element => {
  const [isRecipientListEditMode, setIsRecipientListEditMode] = useState(false);
  const currentUserProfile = useCurrentUserProfile();
  const currentParty = useSelector(currentPartySelector);
  const listRecipients = documentStatePayload.recipients;
  const { recipientProfileList } = useRecipientProfileList(listRecipients);
  const viewStatus = getDashboardItemViewStatus(
    documentStatePayload.recipients,
    documentStatePayload.sender,
    documentStatePayload.status,
    currentUserProfile?.email
  );
  const isActionTaken = useIsActionTaken(documentStatePayload);
  const [step, setStep] = useState<ButtonStep>(
    isActionTaken ? ButtonStep.DONE : ButtonStep.START
  );

  const handleSubmit: FormSubmissionHandler<DocumentAuthenticateForm> = async (
    values,
    { setSubmitting }
  ) => {
    const ref = documentStatePayload?.ref;
    const payload = generateDocumentUpdatePayload(
      currentParty,
      values.recipientInfo,
      listRecipients
    );
    try {
      setSubmitting(true);
      await documentAPI.update(ref, payload);
      setSubmitting(false);
      setIsRecipientListEditMode(false);
      mutate();
    } catch (e) {
      composeErrorHandlers(() =>
        baseFallbackErrorHandler(ToastContainerId.DocumentDetail)
      )(e as GenericError);
    }
  };

  return (
    <div className="h-full text-dark-blue">
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        initialValues={{
          signatures: signatureRecord,
          recipientInfo: recipientProfileList ?? [],
        }}
        enableReinitialize
      >
        <Form autoComplete="off" noValidate className="h-full">
          <PostDocumentRequestTemplate
            leftComponent={
              <PreviewDocumentWithSignatures
                documentStatePayload={documentStatePayload}
                documentPath={documentPath}
                isPreview={true}
                step={step}
                setStep={setStep}
              />
            }
            rightComponent={
              <DocumentAuthenticateSetting
                documentStatePayload={documentStatePayload}
                documentViewStatus={viewStatus}
                isViewEditMode={isRecipientListEditMode}
                setIsViewEditMode={setIsRecipientListEditMode}
                mutate={mutate}
                isSenderAsRecipientActionTaken={!!senderAsRecipientActionTaken}
              />
            }
          />
        </Form>
      </Formik>
    </div>
  );
};
