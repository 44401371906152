import { PDFSignatureSigningRefProps } from "features/documents/pages/postDocumentRequest/components/PDFSignatureSigning";
import { ActionType } from "features/documents/pages/postDocumentRequest/context/SignatureScrollReducer";

export const setRef = (payload: {
  ref: PDFSignatureSigningRefProps | null | undefined;
}) => {
  return {
    type: ActionType.SET_REF,
    payload,
  };
};
