import { isCurrentUserSenderAsRecipient } from "common/utils/common";
import { DocumentViewStatus } from "features/documents/components/StatusCell";
import {
  DocumentParticipant,
  DocumentProps,
  DocumentStatus,
  ParticipantResponse,
} from "types/document";
import { CurrentUserProfile } from "types/user";

export const isShowRecipientSetting = (
  documentProps: DocumentProps,
  currentUser: CurrentUserProfile | null,
  documentViewStatus: DocumentViewStatus | null
) => {
  const isCurrentUserSenderEqualRecipient = isCurrentUserSenderAsRecipient(
    documentProps,
    currentUser
  );
  const caseSenderAsRecipient =
    isCurrentUserSenderEqualRecipient &&
    (documentViewStatus === DocumentViewStatus.Sent ||
      documentViewStatus === DocumentViewStatus.New);

  const caseNormalSender =
    !isCurrentUserSenderEqualRecipient &&
    documentViewStatus === DocumentViewStatus.Sent;

  if (caseSenderAsRecipient || caseNormalSender) {
    return !documentProps.recipients.some(
      (it) =>
        it.email !== currentUser?.email &&
        it.response !== ParticipantResponse.PENDING &&
        it.response !== ParticipantResponse.VIEWED
    );
  }
  return false;
};

export const isAllowSeeRejectDetail = (
  currentUserProfile: CurrentUserProfile | null,
  documentProps: DocumentProps
) => {
  return (
    currentUserProfile?.email === documentProps.sender.email &&
    documentProps.recipients.some(
      (it) => it.response === ParticipantResponse.REJECTED
    )
  );
};

export const isCompletedNoRejectStatus = (
  documentStatus?: DocumentStatus,
  recipients?: DocumentParticipant[]
) => {
  return (
    (documentStatus === DocumentStatus.COMPLETED ||
      documentStatus === DocumentStatus.DIGITAL_SIGNED) &&
    !recipients?.some((it) => it.response === ParticipantResponse.REJECTED)
  );
};
