import { SvgFolder } from "common/components/svg";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { DashboardResponse, FolderResponse } from "types/common";

export type FolderListProps = {
  folderList: FolderResponse[];
  scrollHegith?: number;
  onFolderClick: (parentFolder: DashboardResponse) => void;
  fetchNext: () => void;
  hasMore: () => boolean;
};

export const FolderList = ({
  folderList,
  onFolderClick,
  fetchNext,
  hasMore,
  scrollHegith,
}: FolderListProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <InfiniteScroll
      next={fetchNext}
      hasMore={hasMore()}
      loader={folderList.length > 0 && <h4>Loading...</h4>}
      dataLength={folderList.length}
      className="mb-12"
      height={scrollHegith ?? 384}
    >
      <div className="flex flex-col gap-1">
        {folderList.length > 0 ? (
          folderList.map((it) => (
            <div className="ml-2.5 my-4" key={it.id}>
              <div
                className="flex items-center gap-1 cursor-pointer w-fit max-w-full"
                onClick={() => onFolderClick(it)}
              >
                <SvgFolder className="text-light-blue" role="svg" />
                <span className="text-sm flex-1 truncate">{it.folderName}</span>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center font-medium text-sm leading-[22px]">
            {t("docRequest.folder.emptyList")}
          </div>
        )}
      </div>
    </InfiniteScroll>
  );
};
