import { Colors, generateColorByString } from "enums/colors";
import { RecipientUpdateInfoProps } from "features/documents/pages/documentRequest/types";
import { useSortedRecipientList } from "features/documents/pages/postDocumentRequest/hooks/useSortedRecipientList";
import { useMemo } from "react";
import { DocumentParticipant } from "types/document";

type RecipientProfileListValues = {
  recipientProfileList: RecipientUpdateInfoProps[];
};

const useRecipientProfileList = (
  listRecipients: DocumentParticipant[]
): RecipientProfileListValues => {
  const sortedRecipientList = useSortedRecipientList(listRecipients);
  const recipientProfileList = useMemo(() => {
    return sortedRecipientList.map((recipient) => {
      const email = recipient.email;
      const { firstName, lastName } = recipient.profile;
      const recipientProfile = {
        firstName,
        lastName,
        email,
        cardColor : email ? generateColorByString(email) : Colors.LIGHT_GREY
      } as RecipientUpdateInfoProps;
      return recipientProfile;
    });
  }, [sortedRecipientList]);
  return { recipientProfileList };
};

export default useRecipientProfileList;
