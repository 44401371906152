import { Action } from "@reduxjs/toolkit";
import {
  Dimension,
  SignaturePercentageProps,
  SignaturePxProps,
} from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { Colors } from "enums/colors";
import { RecipientFormValues } from "features/documents/pages/documentRequest/components/RecipientInputFields";
import { FormikValues } from "formik";
import { Dispatch, SetStateAction } from "react";
import { LinearId } from "types/common";
import { DocumentRole } from "types/document";

export type PDFFile = {
  name: string;
  size: number;
  loaded?: number;
  id?: string;
  URL: string;
  isFromKentro?: boolean;
  linearId?: LinearId;
};

export type RecipientInfoProps = {
  firstName: string;
  lastName: string;
  email: string;
  company?: string;
  title?: string;
  cardColor?: Colors;
};

export type RecipientUpdateInfoProps = {
  firstName: string;
  lastName: string;
  email: string;
  cardColor?: Colors;
};
export type RecipientRolesProps = {
  recipient: RecipientFormValues;
  roles?: DocumentRole[];
  canDownload: boolean;
};
export interface DocumentRequestViewProps {
  step: number;
}

export interface DocumentRequestViewTemplateProps {
  step: number;
  data: FormikValues;
  onSubmit: () => void;
}

export type DocumentRequestStepViewProps = {
  data?: FormikValues;
};

export type UploadDocumentFormValue = {
  filesUpload: PDFFile[];
  fileDimensions: Dimension[][];
};

export type AuthenticateFilesFormValue = {
  authenticatedFiles: PDFFile[];
};

export type CanDownloadProps = { [role: string]: boolean };

export type SettingRolePermissionFormValue = {
  recipientRoles: RecipientRolesProps[];
};
export type RecipientListFormValues = {
  recipientInfo: RecipientInfoProps[];
};

export type RecipientUpdateListFormValues = {
  recipientInfo: RecipientUpdateInfoProps[];
};

export type PDFSignaturesPxFormValue = {
  signatures: SignaturePxProps[][];
  authenticatedFiles: string[];
};

export type PDFSignaturesPercentageFormValue = {
  signatures: SignaturePercentageProps[][];
};

export type DocumentRequestFormValue = {
  filesUpload: PDFFile[];
  recipients: RecipientListFormValues;
  rolePermission: SettingRolePermissionFormValue;
  signatures: SignaturePercentageProps[][];
  dispatch?: (action: Action) => Dispatch<any>;
  setForcePageLeave?: Dispatch<SetStateAction<boolean>>;
  authenticatedFiles: PDFFile[];
  fileDimensions: Dimension[][];
};

export enum DocumentRequestStep {
  UploadDocuments = 1,
  AddRecipients = 2,
  SetRolesPermissions = 3,
  ConfirmDocumentRequestDetails = 4,
  SubmitSuccess = 5,
  SubmitFail = 6,
}

export enum SenderAsRecipientMode {
  "SIGNING" = "signing",
  "AUTHENTICATE" = "authenticate",
  "SIGNANDAUTHENTICATE" = "signingAndAuthenticate",
}

export enum SignatureControlPanelStep {
  SET_PERMISSION = "SET_PERMISSION",
  ADD_SIGNATURE = "ADD_SIGNATURE",
  SENDER_AUTHENTICATE = "SENDER_AUTHENTICATE",
  PREVIEW_TO_SEND = "PREVIEW_TO_SEND",
  SUBMIT_SUCCESS = "SUBMIT_SUCCESS",
  SUBMIT_FAIL = "SUBMIT_FAIL",
}

export type SignatureBoxValuesNotSignedBeforeCreationProps = {
  fileName: string;
  amount: number;
};
