import {
  KentroToastContainer,
  ToastCloseButton,
} from "common/components/KentroToastContainer";
import { ToastContainerId } from "common/toast";
import React from "react";

type PostDocumentRequestTemplateProps = {
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
};

export const PostDocumentRequestTemplate = ({
  leftComponent,
  rightComponent,
}: PostDocumentRequestTemplateProps) => {
  return (
    <div className="grid grid-cols-12 h-full">
      <div className="col-span-7">{leftComponent}</div>
      <div className="relative col-span-5">
        <KentroToastContainer
          containerId={ToastContainerId.DocumentDetail}
          position="top-center"
          enableMultiContainer
          autoClose={false}
          closeButton={ToastCloseButton}
        />
        <div className="bg-white px-5 pt-10 pb-6 h-full overflow-y-auto">
          <div className="relative h-full">{rightComponent}</div>
        </div>
      </div>
    </div>
  );
};
