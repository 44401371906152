import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_KEY } from "common/utils/cross-domain/react/helper";
import { AppThunk } from "redux/store";
import { CurrentUserProfile } from "types/user";

export type AuthState = {
  isLoggedIn: boolean;
  currentUserProfile: CurrentUserProfile | null;
  canCreateRequest: boolean;
};

const initialState: AuthState = {
  isLoggedIn: false,
  currentUserProfile: null,
  canCreateRequest: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn(state) {
      state.isLoggedIn = true;
    },
    setCanCreateRequest(state, action: PayloadAction<boolean>) {
      state.canCreateRequest = action.payload;
    },
    setCurrentUser(state, action: PayloadAction<CurrentUserProfile>) {
      state.currentUserProfile = action.payload;
    },
    resetAuth() {
      return initialState;
    },
  },
});

const { setLoggedIn, setCurrentUser, resetAuth, setCanCreateRequest } =
  authSlice.actions;

export const clearAuth = (): AppThunk => (dispatch) => {
  localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEY.TIMEOUT);
  dispatch(resetAuth());
  return;
};

export { setCurrentUser, setLoggedIn, setCanCreateRequest };

const authReducer = authSlice.reducer;
export default authReducer;
