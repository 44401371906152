import { useMemo, useState } from "react";

export const SPACE_PX = 16;

export type Position = {
  x: number;
  y: number;
};

type RndBehaviorHandlerProps = {
  initialPosVal: Position;
  adjustFactor: number;
  children: (options: {
    isHover: boolean;
    setIsHover: React.Dispatch<React.SetStateAction<boolean>>;
    isDrag: boolean;
    handleMouseDown: (currentPos: Position) => void;
    handleMouseMove: (currentPos: Position) => void;
    resizingSpace: number;
    updateResizeSpace: (space: number) => void;
    isResizing: boolean;
    setIsResizing: (isResizing: boolean) => void;
  }) => React.ReactNode;
};
export const RndBehaviorHandler = ({
  children,
  adjustFactor,
  initialPosVal,
}: RndBehaviorHandlerProps) => {
  const [isHover, setIsHover] = useState(false);
  const [initialPos, setInitialPos] = useState<Position>(initialPosVal);
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const handleMouseDown = (currentPos: Position) => {
    setInitialPos(currentPos);
    setIsDrag(false);
  };
  const handleMouseMove = (currentPos: Position) => {
    if (
      Math.sqrt(
        Math.pow(currentPos.x - initialPos.x, 2) +
          Math.pow(currentPos.y - initialPos.y, 2)
      ) >= 1
    )
      setIsDrag(true);
  };

  const adjustedSpace = useMemo(() => adjustFactor * SPACE_PX, [adjustFactor]);

  const [resizingSpace, setResizingSpace] = useState<number>(SPACE_PX);

  const updateResizeSpace = (currentWidth: number) => {
    setResizingSpace(currentWidth / adjustedSpace);
  };

  const [isResizing, setIsResizing] = useState(false);
  return (
    <>
      {children({
        isHover,
        setIsHover,
        isDrag,
        handleMouseDown,
        handleMouseMove,
        resizingSpace,
        updateResizeSpace,
        isResizing,
        setIsResizing,
      })}
    </>
  );
};
