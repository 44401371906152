import tw, { styled } from "twin.macro";

type ToggleSwitchProps = {
  className?: string;
  isSelected: boolean;
  onSelect: (value: boolean) => void;
};

const ToggleButton = styled.button<{ isSelected: boolean }>(
  ({ isSelected }) => [
    tw`relative flex flex-row justify-start bg-dark-greyscale rounded-[50px] w-11 h-6 cursor-pointer px-1.5 transition`,
    isSelected && tw`bg-light-blue justify-end`,
  ]
);

const Pin = styled.span(() => [
  tw`bg-white absolute rounded-2xl h-4 w-4 top-1 transition`,
]);

export const ToggleSwitch = ({
  isSelected,
  onSelect,
  className,
}: ToggleSwitchProps): JSX.Element => {
  return (
    <ToggleButton
      className={className}
      isSelected={isSelected}
      onClick={() => {
        onSelect(!isSelected);
      }}
      type="button"
      role="button"
      data-cy="switch-button"
    >
      <Pin />
    </ToggleButton>
  );
};
