import { compose } from "@reduxjs/toolkit";
import { ERROR_KEY_CONCURRENT_LOGIN } from "common/hooks/useAuthErrorHandler";
import { toast, ToastContainerId } from "common/toast";
import { t } from "i18next";

type ErrorObject = {
  enMessage: string;
  key: string;
  valuesStr: string[];
};

export type APIErrorResponse = {
  errors: ErrorObject[];
  path: string;
};

export class APIError extends Error {
  errors: ErrorObject[];
  status?: number;

  constructor(errors: ErrorObject[] = [], status?: number) {
    super();
    this.name = "APIError";
    this.errors = errors;
    this.status = status;
  }
}

export type GenericError = APIError | Error;
export type ErrorHandler = (error: GenericError) => void;
export type ErrorHandlerCreator = (handler: ErrorHandler) => ErrorHandler;

export const isAPIError = (error: any): error is APIError => {
  return (error as APIError).errors !== undefined;
};

export const ERROR_KEY_CORDA_NOTFOUND = "Corda.Query.NotFound";

export const baseFallbackErrorHandler =
  (containerId: ToastContainerId): ErrorHandler =>
  (error) => {
    if (isAPIError(error) && error.errors[0]) {
      switch (error.errors[0].key) {
        /* This two first cases will handled in WithRequest, but there are some case stil go here => do nothing */
        case ERROR_KEY_CONCURRENT_LOGIN:
        case ERROR_KEY_CORDA_NOTFOUND:
          return;
        case "exception.internal":
        case "Error.Unknown":
          toast.error(t("common.toast.unknownError"), containerId);
          break;
        default:
          toast.error(error.errors[0].key, containerId);
      }
    } else {
      toast.error(t("common.toast.unknownError"));
    }
  };

const fallbackErrorHandler: ErrorHandler = baseFallbackErrorHandler(
  ToastContainerId.AfterLogin
);

export const onStaleStateError =
  (handler: () => void): ErrorHandlerCreator =>
  (next) =>
  (error) => {
    if (isAPIError(error) && error.errors[0]?.key === "Corda.Query.NotFound") {
      handler();
    } else {
      next(error);
    }
  };

export const composeErrorHandlers = (
  ...handlers: ErrorHandlerCreator[]
): ErrorHandler => {
  return compose<ErrorHandler>(...handlers)(fallbackErrorHandler);
};
