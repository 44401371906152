import { StatusItem } from "common/components/StatusItem";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import {
  assertCurrentUserRole,
  getCurrentParticipant,
} from "common/utils/document";
import { Colors } from "enums/colors";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  DocumentParticipant,
  DocumentRole,
  DocumentStatus,
  ParticipantResponse,
} from "types/document";

export enum DocumentViewStatus {
  Completed = "Completed",
  New = "New",
  Sent = "Sent",
  InProgress = "InProgress",
  Discarded = "Discarded",
  Rejected = "Rejected",
}

const DocumentViewStatusColorMap: { [key in DocumentViewStatus]: Colors } = {
  [DocumentViewStatus.InProgress]: Colors.LIGHT_YELLOW,
  [DocumentViewStatus.Completed]: Colors.LIGHT_GREEN,
  [DocumentViewStatus.New]: Colors.LIGHT_PURPLE,
  [DocumentViewStatus.Sent]: Colors.LIGHT_BLUE,
  [DocumentViewStatus.Rejected]: Colors.LIGHT_RED,
  [DocumentViewStatus.Discarded]: Colors.GREY,
};

export const DocumentViewStatusTextMap: {
  [key in DocumentViewStatus]: string;
} = {
  [DocumentViewStatus.InProgress]: "common.docStatus.inProgress",
  [DocumentViewStatus.Completed]: "common.docStatus.completed",
  [DocumentViewStatus.New]: "common.docStatus.new",
  [DocumentViewStatus.Sent]: "common.docStatus.sent",
  [DocumentViewStatus.Rejected]: "common.docStatus.rejected",
  [DocumentViewStatus.Discarded]: "common.docStatus.discarded",
};

/* Folder Management */
type StatusCellPayloadProps = {
  recipients?: DocumentParticipant[];
  sender?: DocumentParticipant;
  status?: DocumentStatus;
};
type StatusCellProps = {
  value: StatusCellPayloadProps;
  className?: string;
};

export const StatusCell = ({
  value,
  className,
}: StatusCellProps): JSX.Element => {
  const { t } = useTranslation();
  const currentUserProfile = useCurrentUserProfile();
  const viewStatus = getDashboardItemViewStatus(
    value?.recipients,
    value?.sender,
    value?.status,
    currentUserProfile?.email
  );
  return viewStatus ? (
    <StatusItem
      className={`text-${DocumentViewStatusColorMap[viewStatus]} ${className}`}
    >
      {t(DocumentViewStatusTextMap[viewStatus])}
    </StatusItem>
  ) : (
    <></>
  );
};

export const getDashboardItemViewStatus = (
  recipients?: DocumentParticipant[],
  sender?: DocumentParticipant,
  status?: DocumentStatus,
  currentUserEmail?: string
): DocumentViewStatus | null => {
  if (!recipients && !sender && !status) {
    return null;
  }
  const allResponses = recipients ? recipients.map((it) => it.response) : [];
  const ownResponse = getCurrentParticipant(
    recipients,
    sender,
    currentUserEmail
  )?.response;

  const isSender = assertCurrentUserRole(
    DocumentRole.SENDER,
    recipients,
    sender,
    currentUserEmail
  );
  const isSigner = assertCurrentUserRole(
    DocumentRole.SIGNER,
    recipients,
    sender,
    currentUserEmail
  );
  const isAuthenticator = assertCurrentUserRole(
    DocumentRole.AUTHENTICATOR,
    recipients,
    sender,
    currentUserEmail
  );

  switch (status) {
    case DocumentStatus.CREATED:
      return isSender &&
        ownResponse !== ParticipantResponse.PENDING &&
        ownResponse !== ParticipantResponse.VIEWED
        ? DocumentViewStatus.Sent
        : DocumentViewStatus.New;
    case DocumentStatus.IN_PROGRESS:
      if (isSigner || isAuthenticator) {
        return ownResponse === ParticipantResponse.PENDING ||
          ownResponse === ParticipantResponse.VIEWED
          ? DocumentViewStatus.New
          : DocumentViewStatus.InProgress;
      } else {
        return DocumentViewStatus.InProgress;
      }
    case DocumentStatus.COMPLETED:
    case DocumentStatus.DIGITAL_SIGNED:
      return allResponses.some((it) => it === ParticipantResponse.REJECTED)
        ? DocumentViewStatus.Rejected
        : DocumentViewStatus.Completed;
    case DocumentStatus.DISCARDED:
      return DocumentViewStatus.Discarded;
    default:
      return null;
  }
};
