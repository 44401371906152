import { ProgressBar } from "./ProgressBar";

type ProgressBarWithTextProps = {
  currentPage: number;
  pageTitle?: string;
  pageAmount: number;
  progressPercentage: number;
  className?: string;
  reverse?: boolean;
};

export const formateDisplayPageNumber = (pageNumber: number) => {
  if (pageNumber > 0 && pageNumber < 10) {
    return `0${pageNumber}`;
  }
  if (pageNumber >= 10) {
    return pageNumber.toString();
  }
  return "";
};

export const ProgressBarWithText = ({
  currentPage,
  pageTitle,
  pageAmount,
  progressPercentage,
  reverse,
}: ProgressBarWithTextProps): JSX.Element => {
  const displayCurrentPage = formateDisplayPageNumber(currentPage);
  const displayPageAmount = formateDisplayPageNumber(pageAmount);

  return (
    <div
      className={`flex ${reverse ? "flex-col" : "flex-col-reverse"}`}
      data-cy="document-request-bar"
    >
      <ProgressBar progressPercentage={progressPercentage} />
      <div className="text-grey mb-3">
        <span className="text-light-blue inline mr-2 text-h1">
          {displayCurrentPage}
        </span>
        <span className="mr-2">/</span>
        <span className="text-base mr-2">{displayPageAmount} </span>
        <span className="text-sm">{pageTitle}</span>
      </div>
    </div>
  );
};
