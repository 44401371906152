import { useBackListener } from "common/hooks/useBackListener";
import { AuthorizedCheck } from "common/layouts/AuthorizedCheck";
import { DocumentListView } from "features/documents/pages/documentManagement/DocumentListView";
import { DocumentFilterType } from "features/documents/pages/documentManagement/types";
import { DocumentRequestRoutes } from "features/documents/pages/documentRequest/DocumentRequestRoutes";
import { PostDocumentRequestRoutes } from "features/documents/pages/postDocumentRequest/postDocumentRequestRoutes";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

export const BusinessRoutes = (): JSX.Element => {
  useBackListener();
  return (
    <Routes>
      <Route index element={<Navigate to="documents" />} />
      <Route path="documents" element={<Outlet />}>
        <Route index element={<Navigate to="all" />} />
        <Route
          path="all"
          element={<DocumentListView filter={DocumentFilterType.All} />}
        />

        <Route
          path="created-by-me"
          element={<DocumentListView filter={DocumentFilterType.Sender} />}
        />
        <Route
          path="shared-with-me"
          element={<DocumentListView filter={DocumentFilterType.Recipient} />}
        />
        <Route
          path="bin"
          element={<DocumentListView filter={DocumentFilterType.Bin} />}
        />

        <Route
          path="create-request/*"
          element={
            <AuthorizedCheck>
              <DocumentRequestRoutes />
            </AuthorizedCheck>
          }
        />

        <Route
          path="details/:linearId/*"
          element={<PostDocumentRequestRoutes />}
        />

        {/* For Folder Management Features */}
        {/* <Route
          path="all/:parentFolderId/*"
          element={<DocumentListView filter={DocumentFilterType.All} />}
        /> */}
      </Route>
      <Route path="*" element={<Navigate to="/documents" />} />
      {/* // TODO TBD */}
      {/* <Route path="admin-settings" element={<AdminSettingsView />} /> */}
    </Routes>
  );
};
