import {
  SignaturePercentageProps,
  SignaturePxProps,
} from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { useDocumentRequest } from "common/hooks/useDocumentRequest";
import { uploadSignatures } from "features/documents/pages/documentRequest/context/DocumentRequestActions";
import { uploadAuthenticatedFiles } from "features/documents/pages/documentRequest/context/DocumentRequestActions";
import { usePDFSignatureControl } from "features/documents/pages/documentRequest/hooks/usePDFSignatureControl";
import { SignatureControlPanel } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/SignatureControlPanel";
import {
  cleanPureTestValues,
  getInitialStep,
  getNextStep,
  getSenderRole,
  handleSignatureBoxValuesNotSignedBeforeCreation,
  parseFromPxToPercentage,
} from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/utils/PDFSignatureUtils";
import {
  PDFFile,
  PDFSignaturesPxFormValue,
  SignatureControlPanelStep,
} from "features/documents/pages/documentRequest/types";
import { parseFromPercentageToPx } from "features/documents/pages/postDocumentRequest/hooks/useSignaturePositionToPx";
import { Form, Formik } from "formik";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormSubmissionHandler } from "types/common";
import { DocumentRole } from "types/document";
import * as yup from "yup";
const documentSignatureLocationRequiredError =
  "docRequest.message.documentSignatureLocationRequired";
const noAssignRecipientForSignatureBoxError =
  "docRequest.message.noAssignRecipientForSignatureBox";
const signerSignatureRequiredError =
  "docRequest.message.signerSignatureRequired";
const senderAsRecipientSignPartialError =
  "docRequest.message.senderAsRecipientSignPartial";

type ConfirmDocumentDetailProps = {
  onBack: () => void;
  isPreview: boolean;
  setIsPreview: (isPreview: boolean) => void;
  selectedFile: PDFFile;
  setSelectedFile: React.Dispatch<React.SetStateAction<PDFFile>>;
  onSubmitRequest: () => Promise<void>;
};

export const ConfirmDocumentDetail = ({
  onBack,
  isPreview,
  setIsPreview,
  selectedFile,
  setSelectedFile,
  onSubmitRequest,
}: ConfirmDocumentDetailProps): JSX.Element => {
  const { pdfRef, pageWidth, filterIndex, setFilterIndex, setFilterValue } =
    usePDFSignatureControl();
  const currentUserProfile = useCurrentUserProfile();
  const { t } = useTranslation();

  const [invalidFiles, setInvalidFiles] = useState<(string | undefined)[]>([]);
  const [filesNoSignatureLocation, setFileNoSignatureLocation] = useState<
    string[]
  >([]);
  const [filesSenderPartialSigned, setFileSenderPartialSigned] = useState<
    string[]
  >([]);

  // Handle invalid files for 2 type of yup test
  useEffect(() => {
    if (filesSenderPartialSigned.length > 0) {
      setInvalidFiles(filesSenderPartialSigned);
    } else setInvalidFiles(filesNoSignatureLocation);
  }, [filesNoSignatureLocation, filesSenderPartialSigned]);

  // Request Flow Context
  const {
    dispatch,
    filesUpload,
    signatures,
    rolePermission,
    authenticatedFiles,
    fileDimensions,
  } = useDocumentRequest();

  // Jump to confirm detail screen if authen only
  const isAuthenOnly = useMemo(
    () =>
      rolePermission.recipientRoles.every(
        (it) => !it.roles?.includes(DocumentRole.SIGNER)
      ),
    [rolePermission]
  );

  const isSenderSameAsRecipient = useMemo(
    () =>
      rolePermission.recipientRoles.some(
        (it) => it.recipient.email === currentUserProfile?.email
      ),
    [currentUserProfile?.email, rolePermission.recipientRoles]
  );

  const senderRole = useMemo(() => {
    return getSenderRole(
      rolePermission.recipientRoles,
      currentUserProfile?.email ?? ""
    );
  }, [currentUserProfile?.email, rolePermission.recipientRoles]);

  const initialStep = useMemo(() => {
    return getInitialStep(isAuthenOnly, isSenderSameAsRecipient);
  }, [isAuthenOnly, isSenderSameAsRecipient]);

  const [currentStep, setCurrentStep] =
    useState<SignatureControlPanelStep>(initialStep);

  const handleSubmit: FormSubmissionHandler<PDFSignaturesPxFormValue> = async (
    values,
    formikHelper
  ) => {
    switch (currentStep) {
      case SignatureControlPanelStep.PREVIEW_TO_SEND:
        formikHelper.setSubmitting(true);
        await onSubmitRequest();
        formikHelper.setSubmitting(false);
        break;
      case SignatureControlPanelStep.ADD_SIGNATURE:
        if (dispatch) {
          const validSignatures: SignaturePercentageProps[][] =
            values.signatures.map((signature, index) =>
              signature
                .filter((item) => !!item.value)
                .map((pxSignature) =>
                  parseFromPxToPercentage(
                    fileDimensions[index],
                    pxSignature,
                    pageWidth
                  )
                )
            );
          dispatch(uploadSignatures({ signatures: validSignatures }));
          handleNext();
        }
        break;
      case SignatureControlPanelStep.SENDER_AUTHENTICATE:
        if (dispatch) {
          dispatch(
            uploadAuthenticatedFiles({
              authenticatedFiles: values.authenticatedFiles.map(
                (it) => JSON.parse(it) as PDFFile
              ),
            })
          );
          handleNext();
        }
        break;
      default:
        handleNext();
    }
  };

  const handleNext = () => {
    const next = getNextStep(currentStep, isSenderSameAsRecipient, senderRole);
    setCurrentStep(next);
  };

  // PDF Page Control
  const handleScrollToOffset = (offset: number) => {
    pdfRef?.current?.scrollToOffset(offset);
  };

  const handleAddSignature = () => {
    pdfRef?.current?.addSignature();
  };

  const signers = useMemo(
    () =>
      rolePermission.recipientRoles
        .filter((recipientRole) =>
          recipientRole.roles?.includes(DocumentRole.SIGNER)
        )
        .map((recipientRole) => recipientRole.recipient),
    [rolePermission.recipientRoles]
  );

  const signatures_px: SignaturePxProps[][] = useMemo(
    () =>
      signatures.map((signature, index) =>
        signature.map((it) => {
          return {
            ...parseFromPercentageToPx(it, fileDimensions[index], pageWidth),
            value: it.value,
            id: it.id,
            imageSrc: it.imageSrc,
          };
        })
      ),
    [fileDimensions, pageWidth, signatures]
  );

  const schema: yup.SchemaOf<PDFSignaturesPxFormValue, SignaturePxProps> = yup
    .object()
    .shape({
      /* TODO: Define schema type details to avoid any*/
      signatures: yup
        .array<SignaturePxProps[]>()
        .of(yup.array<SignaturePxProps>())
        .test(
          "sender-as-recipient-partial-sign",
          t(senderAsRecipientSignPartialError),
          (values) => {
            if (isSenderSameAsRecipient && values) {
              const notSignedBoxesDetails =
                handleSignatureBoxValuesNotSignedBeforeCreation(
                  values,
                  filesUpload,
                  currentUserProfile
                );
              const invalidIds = notSignedBoxesDetails.map((it) => it.fileName);
              setFileSenderPartialSigned(invalidIds);
              return invalidIds.length === 0;
            }
            return true;
          }
        )
        .test(
          "no-signatures-location",
          t(documentSignatureLocationRequiredError),
          (values) => {
            if (values) {
              const invalidIds: string[] = [];
              const valueCleaned = cleanPureTestValues(values, filesUpload);
              valueCleaned.forEach((it, index) => {
                if (it?.length === 0) {
                  invalidIds.push(filesUpload[index].name);
                }
              });
              setFileNoSignatureLocation(invalidIds);
              return invalidIds.length === 0;
            }
            return false;
          }
        )
        .test(
          "not-assigned-signatures",
          t(noAssignRecipientForSignatureBoxError),
          (values) => {
            const isRecipientsAssigned = values?.every((value) => {
              return value?.every((it) => it.value !== undefined);
            });
            return !!isRecipientsAssigned;
          }
        )
        .test(
          "not-assigned-recipients",
          t(signerSignatureRequiredError),
          (value, context) => {
            const isEnoughSignaturesPerDocument =
              context.parent.signatures?.every((value: SignaturePxProps[]) => {
                const signersSignedSet = new Set<string>();
                value?.forEach((it) => {
                  if (it?.value?.email) {
                    signersSignedSet.add(it.value.email.trim());
                  }
                });
                return signersSignedSet.size === signers.length;
              });
            return !!isEnoughSignaturesPerDocument;
          }
        ),
      authenticatedFiles: yup.array(),
    });

  const initialValues = useMemo(() => {
    return {
      signatures: signatures_px || Array(filesUpload?.length ?? 1).fill([]),
      authenticatedFiles:
        authenticatedFiles.map((it) => JSON.stringify(it)) ?? [],
    };
  }, [authenticatedFiles, filesUpload?.length, signatures_px]);

  return (
    <Formik
      validationSchema={isAuthenOnly ? null : schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <Form autoComplete="off" noValidate className="w-full h-full">
        <SignatureControlPanel
          onBack={onBack}
          recipients={rolePermission.recipientRoles}
          files={filesUpload ?? []}
          filterIndex={filterIndex}
          setFilterIndex={setFilterIndex}
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
          onAddSignature={handleAddSignature}
          afterSelectFilter={handleScrollToOffset}
          onReSubmit={onSubmitRequest}
          invalidFiles={invalidFiles}
          isSenderSameAsRecipient={isSenderSameAsRecipient}
          senderAsRecipientMode={senderRole}
          setIsPreview={setIsPreview}
          isPreview={isPreview}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isAuthenOnly={isAuthenOnly}
          setFilterValue={setFilterValue}
        />
      </Form>
    </Formik>
  );
};
