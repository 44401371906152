import { useModal } from "@liholiho/react-modal-hook";
import { documentAPI } from "api/document";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import { toast, ToastContainerId } from "common/toast";
import { DiscardDocumentPopup } from "features/documents/components/DiscardDocumentPopup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LinearId, SubmissionHandler } from "types/common";

/* Folder Management */
export const useDiscardDocumentHandler = (
  dashboardItemLinearId: LinearId,
  documentName: string,
  toastContainerId: ToastContainerId = ToastContainerId.AfterLogin,
  onSuccess?: () => void
): SubmissionHandler => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { t } = useTranslation();

  const [show, hide] = useModal(
    ({ in: isOpen }) => {
      const handleConfirm = async (ref: string) => {
        setSubmitting(true);
        try {
          await documentAPI.discard(ref);
          hide();
          toast.success(
            t("common.toast.discardDocument", { docName: documentName }),
            toastContainerId
          );

          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          composeErrorHandlers(() =>
            baseFallbackErrorHandler(toastContainerId)
          )(e as GenericError);
        }
        setSubmitting(false);
      };

      return (
        <DiscardDocumentPopup
          dashboardItemLinearId={dashboardItemLinearId}
          documentName={documentName}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
          onConfirm={handleConfirm}
          onCancel={hide}
        />
      );
    },
    [onSuccess, dashboardItemLinearId, isSubmitting]
  );

  return {
    isSubmitting,
    handler: show,
  };
};
