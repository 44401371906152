import { documentAPI, useInfiniteDocumentList } from "api/document";
import { folderAPI } from "api/folder";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import { toast, ToastContainerId } from "common/toast";
import { MenuButton } from "features/documents/pages/documentManagement/components/common";
import { DocumentListMutatorContext } from "features/documents/pages/documentManagement/DocumentListMutatorContext";
import { DocumentActionButtonProps } from "features/documents/pages/documentManagement/types";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryDocumentRole } from "types/document";

/*Folder Management */
export const RetrieveDashboardItemButton = ({
  className,
  dashboardItem,
  afterClick = () => {},
  disabled,
}: DocumentActionButtonProps): JSX.Element => {
  const { mutate: mutateArchiveTab } = useContext(DocumentListMutatorContext);
  /*
   * Use mutateArchiveTab from DocumentListMutatorContext2 => only mutate data in bin tab
   * Use mutateAllTab to also mutate the data from all tab to get latest data when back to all tab
   */
  const { mutate: mutateAllTab } = useInfiniteDocumentList(
    QueryDocumentRole.ALL,
    false,
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const handleClick = async () => {
    try {
      setIsSubmitting(true);
      dashboardItem.isFolder
        ? await folderAPI.retrieve(dashboardItem.id)
        : await documentAPI.retrieve(dashboardItem.id);
      setIsSubmitting(false);
      mutateArchiveTab();
      mutateAllTab();
      toast.success(
        dashboardItem.isFolder
          ? t("common.toast.retrieveFolder", {
              folderName: dashboardItem.folderName,
            })
          : t("common.toast.retrieveDocument", {
              docName: dashboardItem.documentName,
            })
      );
    } catch (e) {
      composeErrorHandlers(() =>
        baseFallbackErrorHandler(ToastContainerId.AfterLogin)
      )(e as GenericError);
    }
    afterClick();
  };

  return (
    <MenuButton
      className={className}
      onClick={handleClick}
      disabled={isSubmitting || disabled}
      data-cy="retrieve-button"
    >
      {t("dashboard.button.retrieve")}
    </MenuButton>
  );
};
