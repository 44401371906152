import { SvgDot } from "common/components/svg/index";
import { FC } from "react";

type StatusItemProps = {
  className?: string;
  iconSize?: string | number;
  spacing?: "md" | "sm";
  isDotIconShow?: boolean;
};

export const StatusItem: FC<StatusItemProps> = ({
  className,
  children,
  iconSize = 8,
  spacing = "md",
  isDotIconShow = true,
}) => {
  return (
    <span className={"flex items-center justify-start " + className}>
      {isDotIconShow && (
        <SvgDot
          className={`${spacing === "sm" ? "mr-1.5" : "mr-3"} shrink-0`}
          width={iconSize}
          height={iconSize}
          role="img"
        />
      )}
      {children}
    </span>
  );
};
