import { TERM_OF_USE_URL } from "constants/common";

import { Trans } from "react-i18next";
import tw from "twin.macro";

type TNCmemoProps = {
  tncLink?: String;
};

export const TNCmemo = ({ tncLink }: TNCmemoProps): JSX.Element => {
  return (
    <StyledText>
      <Trans i18nKey="docDetails.message.TNC">
        <StyledSpan href={TERM_OF_USE_URL} target="_blank" />
      </Trans>
    </StyledText>
  );
};

const StyledText = tw.p`block text-dark-grey text-xs`;
const StyledSpan = tw.a`text-light-blue cursor-pointer whitespace-nowrap`;
