import { Button } from "common/components/Button";
import { Modal } from "common/components/Modal";
import { SvgFileCancel } from "common/components/svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

const StyledPopupContentWrapper = tw.div`flex flex-col items-center justify-between h-44`;
const StyledPopUpTitle = tw.div`font-medium text-base leading-[22px]`;
const StyledPopupDescription = tw.p`font-medium text-dark-grey text-xs`;
const StyledButtonsWrapper = tw.div`flex flex-wrap mt-4`;

type CancelDocumentRequestModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const CancelDocumentRequestModal = ({
  isOpen,
  onCancel,
  onConfirm,
}: CancelDocumentRequestModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [isDisabled, setDisabled] = useState(false);
  return (
    <Modal open={isOpen} onClose={onCancel} size="emd">
      <StyledPopupContentWrapper>
        <SvgFileCancel width={40} height={40} className="text-light-blue" />
        <StyledPopUpTitle>
          {t("dashboard.cancelDocumentRequest.title")}
        </StyledPopUpTitle>
        <StyledPopupDescription>
          {t("dashboard.cancelDocumentRequest.titleHint")}
        </StyledPopupDescription>
      </StyledPopupContentWrapper>
      <StyledButtonsWrapper>
        <Button
          variant="primary"
          className="w-full mb-4"
          disabled={isDisabled}
          onClick={() => {
            setDisabled(true);
            onConfirm();
          }}
        >
          {t("common.confirm")}
        </Button>
        <Button
          variant="secondary"
          className="text-light-blue w-full"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </Button>
      </StyledButtonsWrapper>
    </Modal>
  );
};
