import { useFolderItems } from "api/folder";
import { Button } from "common/components/Button";
import { Loader } from "common/components/Loader";
import { Message } from "common/components/Message";
import { Modal } from "common/components/Modal";
import SvgNewFolder from "common/components/svg/NewFolder";
import { ToastContainerId } from "common/toast";
import { FolderBreadCrumbs } from "features/documents/components/FolderManagement/components/FolderBreadCrumbs";
import { FolderList } from "features/documents/components/FolderManagement/components/FolderList";
import { useCreateNewFolderHandler } from "features/documents/hooks/useCreateNewFolderHandler";
import cloneDeep from "lodash/cloneDeep";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DashboardResponse } from "types/common";
import { QueryDocumentRole } from "types/document";

type MoveDashboardItemPopupProps = {
  dashboardItem: DashboardResponse;
  isOpen: boolean;
  isSubmitting: boolean;
  currentDashboardFolderId: string | null;
  currentInternalFolder?: DashboardResponse;
  onConfirm: (mutate?: () => void, currentPosition?: DashboardResponse) => void;
  onCancel: () => void;
  onMoveToArchiveSection: () => void;
  setCurrentInternalFolder: (value?: DashboardResponse) => void;
  onMutateGlobal?: () => void;
  isGlobalSearch: boolean;
  currentError?: string;
};

// This popup always show data from all tab
export const MoveDashboardItemPopup = ({
  dashboardItem,
  isOpen,
  isSubmitting,
  currentInternalFolder,
  currentError,
  onCancel,
  onConfirm,
  setCurrentInternalFolder,
  onMoveToArchiveSection,
  currentDashboardFolderId,
  isGlobalSearch,
}: MoveDashboardItemPopupProps): JSX.Element => {
  const { t } = useTranslation();
  const initialPathNames = useMemo(
    () => [
      t("dashboard.breadcrumbs.documents"),
      t("dashboard.breadcrumbs.all"),
    ],
    [t]
  );
  const [folderPaths, setFolderPaths] = useState<string[]>(initialPathNames);
  const [previousFolders, setPreviousFolders] = useState<DashboardResponse[]>(
    []
  );

  // Mutate internal
  const {
    data,
    mutate: mutateInternal,
    fetchNext,
    total,
  } = useFolderItems(
    QueryDocumentRole.ALL,
    currentInternalFolder ? currentInternalFolder.id : null,
    false
  );

  const isAbleToMoveHere = useMemo(() => {
    if (isGlobalSearch && dashboardItem.parentFolder.length > 0) {
      const parentFolderId =
        dashboardItem.parentFolder[dashboardItem.parentFolder.length - 1].id;
      return parentFolderId !== currentInternalFolder?.id;
    }
    return currentDashboardFolderId !== (currentInternalFolder?.id ?? null);
  }, [
    currentDashboardFolderId,
    currentInternalFolder?.id,
    dashboardItem.parentFolder,
    isGlobalSearch,
  ]);

  // Only show folders not same as current selected folder
  const showingFolders = useMemo(() => {
    if (data) {
      return data.filter((it) => it.id !== dashboardItem.id);
    }
    return [];
  }, [dashboardItem, data]);

  const { handler: handleCreateNewFolder } = useCreateNewFolderHandler(
    ToastContainerId.AfterLogin,
    currentInternalFolder?.id ?? null,
    onMoveToArchiveSection,
    mutateInternal
  );

  // Back button behavior
  const handleBackToPreviousFolder = useCallback(
    (folderPaths: string[], currentInternalFolder?: DashboardResponse) => {
      const cloneFolderPaths = cloneDeep(folderPaths);
      if (currentInternalFolder) {
        if (currentInternalFolder.id === dashboardItem.id) {
          return;
        }
        setFolderPaths(
          cloneFolderPaths.filter(
            (it) =>
              it !==
              `${currentInternalFolder.folderName}${currentInternalFolder.id}`
          )
        );
        const clonePreviousFolders = cloneDeep(previousFolders);
        setCurrentInternalFolder(
          clonePreviousFolders[clonePreviousFolders.length - 1]
        );
        clonePreviousFolders.pop();
        setPreviousFolders(clonePreviousFolders);
      }
    },
    [previousFolders, setCurrentInternalFolder, dashboardItem.id]
  );

  // Breadcrumbs behavior
  const handleBreadCrumbItemClick = useCallback(
    (index: number) => {
      if (index === 1) {
        setCurrentInternalFolder();
        setFolderPaths(initialPathNames);
      } else return;
    },
    [setCurrentInternalFolder, initialPathNames]
  );

  // Internal Folder behavior
  const handleFolderInternalClick = useCallback(
    (folder: DashboardResponse) => {
      setPreviousFolders((prev) =>
        currentInternalFolder ? [...prev, currentInternalFolder] : [...prev]
      );
      setCurrentInternalFolder(folder);
      setFolderPaths((prevPaths) => {
        return [...prevPaths, `${folder.folderName ?? ""}${folder.id}`];
      });
    },
    [setCurrentInternalFolder, currentInternalFolder]
  );

  return (
    <Modal
      open={isOpen}
      onClose={isSubmitting ? undefined : onCancel}
      size="exl"
    >
      <Loader isLoading={isSubmitting} overlay>
        <div
          className="flex flex-col text-dark-blue"
          data-cy="move-dashboard-item-popup"
        >
          <div className="flex items-center font-medium text-sm leading-[22px] mb-8 gap-1">
            <span className="shrink-0">
              {t("dashboard.moveDashboardItem.headTitle", {
                item: dashboardItem.isFolder ? "folder" : "",
              })}
            </span>
            <span className="text-light-blue w-fit truncate">
              {dashboardItem.isFolder
                ? `${dashboardItem.folderName}`
                : `${dashboardItem.documentName}`}
            </span>
            <span className="shrink-0">{t("dashboard.to")}</span>
          </div>
          <div className="flex items-center justify-between mb-8 max-w-full">
            <FolderBreadCrumbs
              className="w-full"
              folderPaths={folderPaths}
              currentInternalFolder={currentInternalFolder}
              onBack={() =>
                handleBackToPreviousFolder(folderPaths, currentInternalFolder)
              }
              onItemClick={handleBreadCrumbItemClick}
            />
            <SvgNewFolder
              data-cy="add-new-folder"
              className="cursor-pointer"
              onClick={handleCreateNewFolder}
            />
          </div>
          <FolderList
            folderList={showingFolders}
            onFolderClick={handleFolderInternalClick}
            fetchNext={fetchNext}
            hasMore={() => data?.length !== total}
          />
          {currentError && (
            <Message
              variant="warning"
              className="mb-4 text-xs font-medium"
              isBackgroundEnable={false}
            >
              {currentError}
            </Message>
          )}
          <div className="w-full flex items-center justify-between">
            <Button className="w-32" onClick={onCancel} variant="secondary">
              {t("common.cancel")}
            </Button>
            <Button
              className="w-32"
              onClick={() => {
                return onConfirm(mutateInternal, currentInternalFolder);
              }}
              variant="primary"
              disabled={!isAbleToMoveHere}
            >
              {t("dashboard.button.moveHere")}
            </Button>
          </div>
        </div>
      </Loader>
    </Modal>
  );
};
