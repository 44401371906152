import { Children, ReactNode } from "react";
import tw, { styled } from "twin.macro";

type BreadCrumbComponentProps = {
  children: ReactNode;
  containerClassName?: string;
  itemContainerClassName?: string;
  defaultItemStyle?: boolean;
  dividerIcon?: ReactNode;
  dividerSpaceClassName?: string;
  disabledActive?: boolean;
};

type BreadCrumbItemWrapperProps = {
  active: boolean;
  defaultStyle: boolean;
  isLast: boolean;
};

const BreadCrumbItemWrapper = styled.div(
  ({ active, defaultStyle, isLast }: BreadCrumbItemWrapperProps) => [
    tw`cursor-pointer`,
    defaultStyle && tw`text-grey text-sm`,
    active
      ? tw`text-dark-blue hover:text-dark-blue cursor-auto truncate`
      : defaultStyle && tw`text-grey text-sm`,
    isLast && tw`truncate whitespace-pre`,
  ]
);

export const BaseBreadCrumbs = ({
  children,
  containerClassName,
  itemContainerClassName,
  defaultItemStyle = true,
  dividerSpaceClassName = "mx-1 my-0",
  dividerIcon = <>/</>,
  disabledActive = false,
}: BreadCrumbComponentProps) => {
  const allChildren = Children.toArray(children).map((child, index) => {
    const isLast = index === Children.toArray(children).length - 1;
    return (
      <BreadCrumbItemWrapper
        className={`${itemContainerClassName}`}
        defaultStyle={defaultItemStyle}
        active={isLast && !disabledActive}
        isLast={isLast}
        key={index}
      >
        {child}
        {!isLast && (
          <span className={`mx-1 my-0 ${dividerSpaceClassName}`}>
            {dividerIcon}
          </span>
        )}
      </BreadCrumbItemWrapper>
    );
  });

  return (
    <div className={`flex items-center font-medium ${containerClassName}`}>
      {allChildren}
    </div>
  );
};
