import * as React from "react";
import { SVGProps } from "react";

const SvgSignatureSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0979 32.052L29.0982 18.0517L22.4483 11.4018L8.44796 25.4021C8.25522 25.5951 8.11831 25.8367 8.05174 26.1012L6.5 34L14.3973 32.4483C14.6625 32.382 14.905 32.2449 15.0979 32.052ZM33.6178 13.5321C34.1827 12.967 34.5 12.2008 34.5 11.4018C34.5 10.6029 34.1827 9.83661 33.6178 9.27157L31.2284 6.88219C30.6634 6.31732 29.8971 6 29.0982 6C28.2992 6 27.533 6.31732 26.9679 6.88219L24.5785 9.27157L31.2284 15.9215L33.6178 13.5321Z"
      fill="#00A3FF"
    />
    <path
      d="M32.5 42C38.014 42 42.5 37.514 42.5 32C42.5 26.486 38.014 22 32.5 22C26.986 22 22.5 26.486 22.5 32C22.5 37.514 26.986 42 32.5 42ZM32.5 24C36.911 24 40.5 27.589 40.5 32C40.5 36.411 36.911 40 32.5 40C28.089 40 24.5 36.411 24.5 32C24.5 27.589 28.089 24 32.5 24Z"
      fill="#00A3FF"
    />
    <path
      d="M30.8106 33.294L28.5116 30.999L27.0996 32.415L30.8126 36.12L37.5186 29.414L36.1046 28L30.8106 33.294Z"
      fill="#00A3FF"
    />
  </svg>
);

export default SvgSignatureSuccess;
