import { Option } from "common/components/form/CreatableInput/CreatableInput";
import { SvgRemove } from "common/components/svg/";
import {
  components,
  MultiValueRemoveProps,
  GroupBase,
  StylesConfig,
} from "react-select";

export const ValueContainer: React.FC<any> = ({ ...props }) => {
  return (
    <components.ValueContainer
      {...props}
      className={props.hasValue ? "" : "text-sm h-12"}
    >
      {props.children}
    </components.ValueContainer>
  );
};

export const MultiValueRemove = (
  props: MultiValueRemoveProps<Option, boolean, GroupBase<Option>>
) => {
  return (
    <components.MultiValueRemove {...props}>
      <SvgRemove width="12" height="12" className="mx-2" />
    </components.MultiValueRemove>
  );
};

export const creatableSelectConfigComponent = {
  DropdownIndicator: null,
  ClearIndicator: () => null,
  MultiValueRemove,
  ValueContainer,
};

export const creatableInputStyles = (
  hasError: boolean
): StylesConfig<Option, true> => ({
  control: (base, { isFocused }) => {
    const borderColor = isFocused
      ? "#0063AD"
      : hasError
      ? "#F1416C"
      : "#F5F8FA";
    return {
      ...base,
      backgroundColor: "#F5F8FA",
      cursor: "text",
      boxShadow: "none",
      borderRadius: "4px",
      maxHeight: "132px",
      overflowY: "auto",
      borderColor,
      ":hover": {
        borderColor,
      },
    };
  },
  multiValue: (base) => {
    return {
      ...base,
      backgroundColor: "#FFFFFF",
      marginTop: "6px",
      marginBottom: "6px",
      marginRight: "8px",
      borderRadius: "4px",
    };
  },
  multiValueLabel: (base) => {
    return {
      ...base,
      height: 32,
      color: "#3F4254",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      marginLeft: "2px",
      padding: "4px 0px 8px 8px",
    };
  },
  multiValueRemove: (base) => {
    return {
      ...base,
      ":hover": {
        backgroundColor: "#FFFFFF",
      },
    };
  },
});

export const createOption = (label: string) => ({
  label,
  value: label,
});
