import * as React from "react";
import { SVGProps } from "react";

const SvgFileCancel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 4H8a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V16L24 4Zm-2 14V8l10 10H22Z"
      fill="currentColor"
    />
    <path
      d="M44 34c0 5.514-4.486 10-10 10s-10-4.486-10-10 4.486-10 10-10 10 4.486 10 10Z"
      fill="#fff"
    />
    <path
      d="M30.667 39 34 35.667 37.333 39 39 37.333 35.667 34 39 30.667 37.333 29 34 32.333 30.667 29 29 30.667 32.333 34 29 37.333 30.667 39Z"
      fill="currentColor"
    />
    <path
      d="M34 44c5.514 0 10-4.486 10-10s-4.486-10-10-10-10 4.486-10 10 4.486 10 10 10Zm0-18c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFileCancel;
