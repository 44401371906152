import { useWindowResizeEvent } from "common/hooks/useWindowResizeEvent";
import { useState } from "react";

type Size = {
  width: number;
  height: number;
};

// Learn more here: https://usehooks.com/useWindowSize/
export const useWindowSize = (): Size => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useWindowResizeEvent(handleResize);

  return windowSize;
};
