import * as React from "react";
import { SVGProps } from "react";

const SvgGridMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 3H8V6.92308H4V3ZM4 10.0385H8V13.9615H4V10.0385ZM4 17.0769H8V21H4V17.0769Z"
      fill="#00A3FF"
    />
    <path
      d="M10 3H14V6.92308H10V3ZM10 10.0385H14V13.9615H10V10.0385ZM10 17.0769H14V21H10V17.0769Z"
      fill="#00A3FF"
    />
    <path
      d="M16 3H20V6.92308H16V3ZM16 10.0385H20V13.9615H16V10.0385ZM16 17.0769H20V21H16V17.0769Z"
      fill="#00A3FF"
    />
  </svg>
);

export default SvgGridMenu;
