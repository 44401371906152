import {
  Dimension,
  SignaturePxProps,
} from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import {
  DocumentRequestFormValue,
  PDFFile,
  RecipientListFormValues,
  SettingRolePermissionFormValue,
} from "features/documents/pages/documentRequest/types";

type Action =
  | { type: ActionType.UPLOAD_DOCUMENT; payload: PDFFile[] }
  | { type: ActionType.ADD_RECIPIENTS; payload: RecipientListFormValues }
  | {
      type: ActionType.SET_ROLE_PERMISSION;
      payload: SettingRolePermissionFormValue;
    }
  | {
      type: ActionType.HANDLE_SIGNATURES;
      payload: SignaturePxProps[][];
    }
  | {
      type: ActionType.AUTHENTICATE_DOCUMENT;
      payload: PDFFile[];
    }
  | {
      type: ActionType.FILE_DIMENSIONS;
      payload: Dimension[][];
    };

export enum ActionType {
  UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT",
  ADD_RECIPIENTS = "ADD_RECIPIENTS",
  SET_ROLE_PERMISSION = "SET_ROLE_PERMISSION",
  HANDLE_SIGNATURES = "HANDLE_SIGNATURES",
  AUTHENTICATE_DOCUMENT = "AUTHENTICATE_DOCUMENT",
  FILE_DIMENSIONS = "FILE_DIMENSIONS",
}

const DocumentRequestReducer = (
  state: DocumentRequestFormValue,
  action: Action
) => {
  switch (action.type) {
    case ActionType.ADD_RECIPIENTS:
      return { ...state, recipients: action.payload };
    case ActionType.SET_ROLE_PERMISSION:
      return { ...state, rolePermission: action.payload };
    case ActionType.UPLOAD_DOCUMENT:
    case ActionType.HANDLE_SIGNATURES:
    case ActionType.AUTHENTICATE_DOCUMENT:
    case ActionType.FILE_DIMENSIONS:
      return { ...state, ...action.payload };
    default:
      throw new Error("Invalid Action");
  }
};

export default DocumentRequestReducer;
