import { Loader } from "common/components/Loader";
import { LatestDocumentsContextProvider } from "common/context/LatestDocumentsContext";
import { useAuthRehydrate } from "common/hooks";
import { useWindowSize } from "common/hooks/useWindowSize";
import { AuthenticatedCheck } from "common/layouts/AuthenticatedCheck";
import { DashboardLayout } from "common/layouts/DashboardLayout";
import { SessionTimeoutLayout } from "common/layouts/SessionTimeoutLayout";
import { BusinessRoutes } from "features/BusinessRoutes";
import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

const App = (): JSX.Element => {
  const isHydrated = useAuthRehydrate();
  const windowSize = useWindowSize();

  return (
    <div className="h-screen flex flex-col overflow-auto">
      <Loader isLoading={!isHydrated}>
        <Routes>
          <Route
            path="/"
            element={
              <AuthenticatedCheck>
                <SessionTimeoutLayout>
                  <LatestDocumentsContextProvider>
                    <DashboardLayout
                      className={`${
                        windowSize.height > 2560
                          ? "h-maxScreenDashboard"
                          : "min-h-dashboard"
                      }`}
                    >
                      <Outlet />
                    </DashboardLayout>
                  </LatestDocumentsContextProvider>
                </SessionTimeoutLayout>
              </AuthenticatedCheck>
            }
          >
            <Route index element={<Navigate to="documents" />} />
            <Route path="*" element={<BusinessRoutes />} />
          </Route>
        </Routes>
      </Loader>
    </div>
  );
};

export default App;
