import { UploadResponse } from "api/attachment";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import { ToastContainerId } from "common/toast";
import { isFileDuplicate } from "features/documents/pages/documentRequest/pages/UploadDocument/utils/common";
import { PDFFile } from "features/documents/pages/documentRequest/types";
import { cloneDeep } from "lodash";
import { UploadedFile } from "types/common";

export const getNonDuplicatedFiles = (
  uploadingFiles: File[],
  uploadedFiles: PDFFile[]
) => {
  return uploadingFiles.filter(
    (f) =>
      !uploadedFiles?.some(
        (uploadedFile) =>
          uploadedFile.name === f.name && uploadedFile.size === f.size
      )
  );
};

export const uploadAndHandleProgressBar = async (
  uploadingFiles: File[],
  uploadedFiles: PDFFile[],
  api: (
    file: File,
    onUploadProgress: (loaded: number) => void,
    onCancelFile: () => void
  ) => Promise<UploadResponse>,
  updateProgressBar: (value: PDFFile[]) => void
): Promise<UploadedFile[]> => {
  try {
    const result = await Promise.all(
      uploadingFiles.map((file) => {
        let loadedValue = 0;
        const localURL = URL.createObjectURL(file);
        return api(
          file,
          (loaded: number) => {
            loadedValue = loaded;
            const existedFile = uploadedFiles.find((f) => {
              return isFileDuplicate(f, file.name, file.size);
            });
            if (!existedFile) {
              const newFile: PDFFile = {
                name: file.name,
                size: file.size,
                loaded,
                URL: localURL,
              };
              uploadedFiles.push(newFile);
            } else {
              // update progress status
              existedFile.loaded = loaded;
            }
            // change the form to display it
            updateProgressBar(cloneDeep(uploadedFiles));
          },
          () => {}
        ).then((data) => {
          return new Promise<UploadedFile>((resolve) => {
            resolve({
              id: data.attId,
              size: file.size,
              name: file.name,
              loaded: loadedValue,
              URL: localURL,
            });
          });
        });
      })
    );
    return result;
  } catch (e) {
    composeErrorHandlers(() =>
      baseFallbackErrorHandler(ToastContainerId.DocumentRequest)
    )(e as GenericError);
    return [];
  }
};
