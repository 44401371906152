import { UserIcon } from "common/components/UserIcon";
import { generateFullName } from "common/utils/common";
import { Colors } from "enums/colors";
import {
  UserResponseStatus,
  UserResponseStatusLabel,
} from "features/documents/components/UserResponseStatus";
import { StyledCard } from "features/documents/pages/documentRequest/components/RecipientCreateCard";
import { generateCurrentSelectRolesText } from "features/documents/pages/documentRequest/components/RecipientRole";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DocumentParticipant, ParticipantResponse } from "types/document";

interface RecipientStatusCardProps {
  recipientInfo: DocumentParticipant;
  currentColor: Colors;
  isCommentShow?: boolean;
  onCardClick?: () => void;
  isRecipientAsSender: boolean;
}

const RecipientCardStatusMap: {
  [key in ParticipantResponse]?: UserResponseStatus;
} = {
  [ParticipantResponse.ACCEPTED]: UserResponseStatus.COMPLETE,
  [ParticipantResponse.PENDING]: UserResponseStatus.NOT_VIEWED,
  [ParticipantResponse.REJECTED]: UserResponseStatus.REJECTED,
  [ParticipantResponse.VIEWED]: UserResponseStatus.VIEWED,
};

export const RecipientStatusCard = ({
  recipientInfo,
  currentColor,
  isCommentShow,
  onCardClick,
  isRecipientAsSender,
}: RecipientStatusCardProps) => {
  const { t } = useTranslation();
  const defaultCommentMessage = t("docDetails.reject.emptyMessage");

  const handleCardClicked = () => {
    if (recipientInfo.response === ParticipantResponse.REJECTED) {
      if (onCardClick) {
        onCardClick();
      }
    }
  };

  const isHavingComment = useMemo(() => {
    return (
      recipientInfo.comments.length > 0 &&
      recipientInfo.comments[0].comment !== ""
    );
  }, [recipientInfo.comments]);

  const showStatusByParticipantResponse = (
    isRecipientAsSender: boolean,
    recipientInfo: DocumentParticipant
  ) => {
    return isRecipientAsSender &&
      recipientInfo.response === ParticipantResponse.PENDING
      ? RecipientCardStatusMap[ParticipantResponse.VIEWED]
      : RecipientCardStatusMap[recipientInfo.response];
  };

  return (
    <StyledCard isShadow={false} onClick={handleCardClicked}>
      <div className="grow flex justify-between mb-2.5 w-full">
        <div className="flex items-center gap-x-3.5">
          <UserIcon
            variant={"initials"}
            content={generateFullName(
              recipientInfo.profile.firstName,
              recipientInfo.profile.lastName
            )}
            color={currentColor}
          />
        </div>
        <div className="flex flex-col grow justify-center ml-3.5 w-4/5">
          <div className="flex justify-between">
            <label className="font-medium truncate">
              {generateFullName(
                recipientInfo.profile.firstName,
                recipientInfo.profile.lastName
              )}
            </label>
            <span className="font-medium text-dark-grey">
              {generateCurrentSelectRolesText(recipientInfo.roles)
                .map((it) => t(it))
                .join(" & ")}
            </span>
          </div>
          {!isCommentShow && (
            <div className="flex justify-between">
              <span className="text-dark-grey truncate">
                {recipientInfo.email}
              </span>
              <UserResponseStatusLabel
                status={showStatusByParticipantResponse(
                  isRecipientAsSender,
                  recipientInfo
                )}
                isDotIconShow={
                  recipientInfo.comments.length > 0 &&
                  recipientInfo.comments.some(
                    (it) => it.comment !== "" && !it.resolved
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
      {isCommentShow && (
        <div className="w-full">
          <textarea
            className={`${
              isHavingComment ? "text-dark-blue" : "text-grey"
            } w-full p-2 h-20 bg-background-grey rounded resize-none`}
            value={
              isHavingComment
                ? recipientInfo.comments[0].comment
                : defaultCommentMessage
            }
            readOnly
          />
        </div>
      )}
    </StyledCard>
  );
};
