import { Button } from "common/components/Button";
import { Loader } from "common/components/Loader";
import { Modal } from "common/components/Modal";
import SvgSignatureSuccess from "common/components/svg/SignatureSuccess";
import { getDisplayFileNameOnActionPopup } from "common/utils/document";
import { StyledActionPopupText } from "features/documents/components/ActionPopup/AuthenticateDocumentPopUp";
import { DocumentSigningHintMessage } from "features/documents/components/ActionPopup/DocumentSigningHintMessage";
import { ActionPopupTitle } from "features/documents/hooks/useActionPopupHandler";
import { useTranslation } from "react-i18next";

type SignDocumentPopupProps = {
  fileName: string | string[];
  isOpen: boolean;
  isSubmitting: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const SignDocumentPopup = ({
  fileName,
  isOpen,
  isSubmitting,
  onCancel,
  onConfirm,
}: SignDocumentPopupProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={onCancel}>
      <Loader isLoading={isSubmitting} overlay>
        <div
          className="flex items-center flex-col"
          data-cy="authenticate-document-popup"
        >
          <SvgSignatureSuccess
            name="signatureSuccess"
            width={48}
            height={48}
            className="text-light-blue mb-10"
          />

          <StyledActionPopupText className="mb-6">
            <ActionPopupTitle
              titleKey="docDetails.actionPopup.title"
              fileName={getDisplayFileNameOnActionPopup(fileName)}
            />
          </StyledActionPopupText>

          <div className="mb-12 inline-flex space-x-1.5 items-center">
            <StyledActionPopupText className="text-light-blue">
              {t("docDetails.actionPopup.signing.title")}
            </StyledActionPopupText>
            <DocumentSigningHintMessage />
          </div>

          <Button className="w-full mb-4" onClick={onConfirm} variant="primary">
            {t("common.confirm")}
          </Button>

          <Button className="w-full" onClick={onCancel} variant="secondary">
            {t("common.cancel")}
          </Button>
        </div>
      </Loader>
    </Modal>
  );
};
