import * as React from "react";
import { SVGProps } from "react";

const SvgSubmitFail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 338 204"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#submit-fail_svg__a)">
      <path
        d="M5.418 129.218c-16.33 29.211 7.298 86.511 157.116 46.682 75.118-19.973 131.235 25.015 142.794-6.384 26.938-73.13-83.054-157.725-144.214-98.666C105.206 124.84 35.131 76.06 5.418 129.218Z"
        fill="#ECF2FE"
        stroke="#B7C9E4"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeDasharray="32.09 32.09"
      />
      <path
        d="M58.337 184.226 22.063 96.435c-1.649-3.98.45-7.285 4.647-7.285h7.27c2.998 0 5.021-1.652 5.171-4.205l.3-3.23c.225-2.553 2.248-4.205 5.246-4.205h34.7c2.699 0 5.771 1.352 8.02 3.53l2.848 2.703c2.248 2.178 5.321 3.53 8.02 3.53h44.218c4.197 0 8.919 3.229 10.567 7.284l35.975 89.594H58.337v.075Z"
        fill="#3B82F4"
      />
      <path
        opacity={0.3}
        d="M58.337 184.226 22.063 96.435c-1.649-3.98.45-7.285 4.647-7.285h7.27c2.998 0 5.021-1.652 5.171-4.205l.3-3.23c.225-2.553 2.248-4.205 5.246-4.205h34.7c2.699 0 5.771 1.352 8.02 3.53l2.848 2.703c2.248 2.178 5.321 3.53 8.02 3.53h44.218c4.197 0 8.919 3.229 10.567 7.284l35.975 89.594H58.337v.075Z"
        fill="#000"
      />
      <path
        d="m52.343 154.412-13.716-53.096c-.9-3.53 1.35-6.984 4.572-6.984h107.324c2.099 0 3.972 1.577 4.572 3.98l19.936 79.831c.899 3.53-.525 5.633-3.673 5.558H63.36c-2.099 0-3.897-1.653-4.497-3.906l-6.52-25.383Z"
        fill="#F6F5F5"
      />
      <path
        d="m59.76 181.823-5.845-83.586c-.225-2.929 2.174-5.482 5.096-5.482h111.222v89.068H59.761Z"
        fill="#E4E4E4"
      />
      <path
        d="m58.486 183.701 13.266-86.14c.6-2.778 3.148-4.806 6.07-4.806h112.496c4.422 0 7.42 4.506 5.696 8.486l-24.283 78.855-113.245 3.605Z"
        fill="#fff"
      />
      <path
        d="m58.533 184.019 27.28-80.657c1.125-3.38 4.198-5.632 7.57-5.632h116.019c3.747 0 6.37 3.83 5.171 7.509l-25.407 78.78H58.533Z"
        fill="#3B82F4"
      />
      <path
        d="M259.558 71.055c-2.905.237-5.395-2.016-5.514-4.92l-.237-5.633s-4.209-.355-4.565-4.565c-.296-4.209-.178-13.813-.178-13.813 4.684-2.372 10.375-2.016 14.703.89l-.237 14.702v7.944c0 2.668-1.363 5.217-3.972 5.395ZM228.787 79.71s-1.127-3.142-1.719-3.438c-.653-.356-2.075-1.364-2.55-2.253-.296-.653-.83-.415-.83-.415-.178.415-.118.889.119 1.245l.889 1.245-5.098-2.727s-.238.77.355 1.482c.593.711.415.652.415.652s-.593.415-.237.889c.297.534.889 1.126.889 1.126s-.533.712-.296 1.305c0 0-.178.533 0 1.304.178.77 1.601 2.134 1.601 2.134l5.928 3.32 2.965-4.387-2.431-1.482Z"
        fill="#FDC8A9"
      />
      <path
        d="M222.977 75.145c0 .06.652.474 1.482.949.83.474 1.541.83 1.541.77 0-.06-.652-.474-1.482-.948-.83-.475-1.541-.83-1.541-.771ZM220.191 75.204c-.059.06.593.652 1.364 1.305.771.71 1.423 1.245 1.482 1.185.059-.06-.593-.652-1.364-1.304-.77-.712-1.422-1.245-1.482-1.186ZM220.666 77.279c-.06.059.237.415.652.83.415.415.711.711.771.711.059-.06-.238-.415-.653-.83-.355-.415-.711-.711-.77-.711ZM220.605 78.701c-.059.06.119.238.356.534.178.296.356.474.415.474s-.059-.296-.296-.593c-.178-.237-.415-.415-.475-.415Z"
        fill="#F1BA9F"
      />
      <path
        d="m277.283 171.781 1.304 24.544-8.892 2.134-1.779-23.951 9.367-2.727Z"
        fill="#FDC8A9"
      />
      <path
        d="m278.885 196.147 1.482 6.64H270.17l-.475-4.327 9.19-2.313Z"
        fill="#0163AE"
      />
      <path
        d="m243.49 183.696-2.134 16.303-7.885-.593 1.66-14.88 8.359-.83Z"
        fill="#FDC8A9"
      />
      <path
        d="M233.412 197.69s-8.834 2.964-9.012 4.624l16.897.474.178-4.446-.652.118c-2.49.475-5.099.238-7.411-.77Z"
        fill="#0163AE"
      />
      <path
        opacity={0.6}
        d="M239.518 199.111c.297.119.534.475.475.83-.06.356-.475.534-.771.475-.356-.119-.593-.534-.474-.83.118-.297.592-.534.889-.356M241.296 202.789v-1.363l-16.185.059s-.771.296-.711.83l16.896.474Z"
        fill="#fff"
      />
      <path
        d="M270.703 101.351s4.565 7.766 5.335 16.896l2.49 75.767-9.841 2.372-7.589-74.285s-18.2 74.463-18.793 74.463-9.545-1.601-9.545-1.601l13.635-93.257 24.308-.355Z"
        fill="#263238"
      />
      <path
        d="m253.035 64.415 1.067-2.253 9.486-.356 1.482 2.905h-11.916M230.327 80.302l-3.616 5.04 10.375 8.833 3.498-8.24-10.257-5.633ZM276.333 92.576l-9.13-3.616 2.016-7.53 7.411 2.55-.297 8.596Z"
        fill="#0163AE"
      />
      <path
        d="M237.798 94.65c-2.253-1.126-2.845-3.854-1.719-6.106l9.426-20.157c.238-.475.534-.949.949-1.305 3.676-3.201 7.766-2.668 7.766-2.668l9.842-.237s7.707 0 10.612 6.107c.237.474 4.684 9.367 7.648 15.236 1.364 2.727.059 5.988-2.786 7.055-2.49.89-5.277-.237-6.403-2.668l-3.202-7.173-2.016 10.256 2.906 8.478-24.367.356 1.897-11.324.593-8.063-4.921 10.197c-1.185 2.194-3.972 3.143-6.225 2.016Z"
        fill="#0163AE"
      />
      <path
        opacity={0.2}
        d="M267.084 92.695c-1.837 1.364-4.565.949-6.817.534-2.253-.356-4.447-1.482-5.929-3.201-.652-.771-1.186-1.779-1.126-2.787.118-1.008 1.007-2.016 2.015-1.897 1.186.119 1.72 1.482 2.312 2.49 1.779 3.32 5.81 5.217 9.486 4.446 0 0 .356.178.059.415ZM252.026 76.864c-.593 4.625-.296 4.269-1.304 8.834-.474 2.253-1.067 4.565-2.609 6.284l.771-9.663 3.142-5.455Z"
        fill="#000"
      />
      <path
        d="m270.819 101.349.712-2.016-1.838-1.185-22.351-1.838-1.541 2.964.652 2.431 24.366-.356Z"
        fill="#0163AE"
      />
      <path
        d="M269.99 83.625s.178.474.475 1.126c.355.712.83 1.66 1.422 2.668.593 1.008 1.186 1.897 1.601 2.55.415.652.712 1.007.771 1.007 0 0-.237-.415-.652-1.067a92.13 92.13 0 0 1-1.542-2.55 97.863 97.863 0 0 1-1.482-2.608 25.726 25.726 0 0 0-.593-1.126Z"
        fill="#263238"
      />
      <path
        d="M267.915 92.99c.059 0 1.185-4.683 2.43-10.434 1.305-5.75 2.253-10.494 2.194-10.494-.059 0-1.186 4.624-2.431 10.434s-2.253 10.494-2.193 10.494ZM243.785 92.871s.119-.237.356-.592c.237-.415.533-1.008.948-1.72a259.26 259.26 0 0 0 2.905-5.81c1.127-2.253 2.135-4.328 2.905-5.81.356-.711.653-1.304.89-1.719.237-.415.296-.652.296-.652s-.178.237-.415.593c-.237.415-.593 1.008-.948 1.719-.83 1.482-1.838 3.498-2.965 5.75-1.126 2.254-2.134 4.27-2.845 5.81l-.89 1.78c-.178.414-.237.651-.237.651Z"
        fill="#263238"
      />
      <path
        d="M264.298 62.754h-.415c-.356 0-.712 0-1.186-.06a28.887 28.887 0 0 0-3.853.06c-1.483.119-2.846.237-3.795.415-.415.06-.83.119-1.126.178-.119 0-.237.06-.297.06h-.118s.059 0 .118-.06c.119 0 .178-.06.297-.06.296-.059.652-.118 1.126-.236a27.359 27.359 0 0 1 3.795-.415c1.482-.119 2.845-.06 3.853 0 .474 0 .889.059 1.186.059.118 0 .237 0 .296.06h.119Z"
        fill="#FAFAFA"
      />
      <path
        d="M273.31 50.779c.533-2.135-.771-4.625-2.846-5.396-.178-.059-.415-.177-.534-.355-.118-.119-.118-.356-.118-.474-.178-1.364-1.067-2.609-2.372-3.143-.592-.237-1.245-.355-1.897-.237-.356-.652-.771-1.245-1.423-1.66-1.423-.889-3.379-.948-4.802 0-1.008-.474-1.897-1.126-2.905-1.66-1.363-.711-3.023-1.126-4.387-.415-.949.474-1.601 1.482-2.549 2.016-1.364.77-3.202.593-3.854 2.608-.237.712-.178 1.423 0 2.135.949 2.727 4.269 3.794 6.699 2.608.06.475-.415 1.483.06 2.905.178.475.237 1.186.948 1.186.712-.89 1.897-1.245 2.846-.652.949.652 1.364 2.016.83 3.024-.356.711-1.186 1.363-2.075 1.541-.771.178-1.245.83-1.245 1.6-.059.179-.059.416-.059.594-.771-.653-1.897.118-2.372 1.007a5.843 5.843 0 0 0-.652 3.617s1.364.474 2.016.83c2.016 1.067 4.328 1.482 6.64 1.423 2.253-.06 4.565-.475 6.759-.89-.06-.533-.178-1.067-.238-1.6.475.237.712.83 1.186 1.126.652.415 1.423.119 2.134-.119 2.016-.77 4.269-1.778 5.04-3.794-.652-.178-1.067-.889-1.008-1.541.83.237 1.838.474 2.431-.119.237-.237.355-.474.474-.77.533-1.186 1.067-2.372 1.541-3.558-1.067-1.126-2.668-1.778-4.268-1.837Z"
        fill="#263238"
      />
      <path
        d="M225.696 16.85h-53.385a2.195 2.195 0 0 0-2.188 2.188v21.806c0 1.203.985 2.188 2.188 2.188h47.003c.219 0 .438.073.584.255l9.762 2.77c.51.51 1.385.145 1.385-.584l-3.161-11.302V19.038c-.037-1.204-.985-2.188-2.188-2.188Z"
        fill="#3B82F4"
      />
      <path
        d="M182.849 28.774h-1.094a.249.249 0 0 0-.255.256l.182 3.5c0 .146.11.256.256.256h.729c.146 0 .255-.11.255-.256l.182-3.5a.249.249 0 0 0-.255-.256ZM182.339 33.551a.732.732 0 0 0-.73.73c0 .4.329.729.73.729a.731.731 0 0 0 .729-.73c-.036-.4-.365-.729-.729-.729Z"
        fill="#fff"
      />
      <path
        d="m190.361 36.36-7.475-12.91a.651.651 0 0 0-1.131 0l-7.475 12.91c-.255.437.073.984.583.984h14.915c.51 0 .838-.547.583-.985Zm-2.771-.73h-10.539c-.182 0-.291-.183-.182-.328l5.287-9.117c.073-.145.292-.145.402 0l5.287 9.117c.036.145-.073.328-.255.328ZM207.171 26.149h-13.857a.903.903 0 0 1-.912-.912v-.583c0-.51.401-.912.912-.912h13.857c.51 0 .911.401.911.912v.583c0 .51-.437.912-.911.912ZM217.31 26.149h-5.762a.903.903 0 0 1-.911-.912v-.583c0-.51.401-.912.911-.912h5.762c.51 0 .911.401.911.912v.583c0 .51-.437.912-.911.912ZM202.43 31.035h-9.116a.903.903 0 0 1-.912-.912v-.583c0-.51.401-.912.912-.912h9.116c.511 0 .912.401.912.912v.583c0 .51-.401.912-.912.912ZM217.309 31.035h-10.466a.903.903 0 0 1-.911-.912v-.583c0-.51.401-.912.911-.912h10.466c.51 0 .911.401.911.912v.583c0 .51-.437.912-.911.912ZM204.655 35.922h-11.341a.903.903 0 0 1-.912-.912v-.584c0-.51.401-.911.912-.911h11.341c.51 0 .911.401.911.911v.584c0 .51-.401.912-.911.912ZM217.309 35.922h-8.241a.903.903 0 0 1-.912-.912v-.584c0-.51.401-.911.912-.911h8.241c.51 0 .912.401.912.911v.584c0 .51-.438.912-.912.912Z"
        fill="#fff"
      />
      <path
        opacity={0.25}
        d="m53.042 38.069 2.201-2.383c.424-.464.465-1.11.101-1.514l-2.06-2.383a14.659 14.659 0 0 0 1.838-4.968l3.11-.465c.545-.06.929-.585.909-1.211l-.122-3.251c-.02-.626-.464-1.111-1.01-1.131l-3.13-.222a14.65 14.65 0 0 0-2.22-4.806l1.857-2.525c.343-.424.242-1.07-.222-1.494l-2.383-2.201c-.464-.424-1.11-.465-1.514-.101l-2.383 2.06a14.66 14.66 0 0 0-4.968-1.838l-.464-3.11c-.06-.545-.586-.929-1.212-.909l-3.251.122c-.626.02-1.11.464-1.131 1.01l-.222 3.13a14.648 14.648 0 0 0-4.806 2.22l-2.525-1.857c-.424-.343-1.07-.242-1.494.222l-2.201 2.383c-.424.464-.464 1.11-.101 1.514l2.06 2.363a14.66 14.66 0 0 0-1.838 4.968l-3.11.464c-.545.06-.929.586-.908 1.212l.12 3.251c.02.626.465 1.11 1.01 1.13l3.13.223a14.649 14.649 0 0 0 2.222 4.806l-1.858 2.524c-.343.425-.242 1.07.222 1.495l2.383 2.2c.464.425 1.11.465 1.515.102l2.362-2.04a14.659 14.659 0 0 0 4.968 1.838l.464 3.11c.06.545.586.929 1.212.909l3.251-.122c.626-.02 1.11-.464 1.131-1.01l.222-3.13a14.65 14.65 0 0 0 4.806-2.22l2.525 1.857c.444.323 1.09.242 1.514-.222ZM36.988 28.053a5.165 5.165 0 0 1-.283-7.29 5.165 5.165 0 0 1 7.29-.283 5.165 5.165 0 0 1 .283 7.29c-1.939 2.08-5.19 2.201-7.29.283Z"
        fill="#3B82F4"
      />
      <path
        opacity={0.5}
        d="m74.044 48.287 1.394-1.515c.262-.282.283-.706.06-.949l-1.292-1.494c.586-.99.97-2.06 1.151-3.15l1.959-.303c.343-.04.585-.364.585-.768l-.08-2.06c-.02-.383-.303-.706-.626-.726l-1.98-.142a9.905 9.905 0 0 0-1.393-3.049l1.172-1.595c.222-.263.161-.687-.142-.95l-1.514-1.393c-.283-.262-.707-.282-.95-.06l-1.494 1.292a9.136 9.136 0 0 0-3.15-1.151l-.303-1.959c-.04-.343-.363-.585-.767-.585l-2.06.08c-.384.02-.707.303-.727.626l-.141 1.98c-1.07.282-2.1.747-3.05 1.393l-1.595-1.172c-.262-.222-.687-.161-.95.142l-1.392 1.514c-.263.283-.283.707-.061.95l1.292 1.494c-.585.99-.969 2.06-1.15 3.15l-1.96.303c-.343.04-.585.364-.585.767l.08 2.06c.02.384.304.707.627.727l1.979.142c.282 1.07.747 2.1 1.393 3.049l-1.171 1.595c-.222.263-.162.687.141.95l1.515 1.393c.282.262.706.282.949.06l1.494-1.292c.99.585 2.06.97 3.15 1.15l.303 1.96c.04.343.364.585.768.585l2.06-.08c.383-.02.706-.303.726-.627l.142-1.979a9.904 9.904 0 0 0 3.05-1.393l1.594 1.171c.263.222.687.162.95-.141Zm-10.157-6.34a3.24 3.24 0 0 1-.182-4.605 3.24 3.24 0 0 1 4.604-.182 3.24 3.24 0 0 1 .182 4.604 3.238 3.238 0 0 1-4.604.182Z"
        fill="#3B82F4"
      />
      <path
        d="m79.22 34.638-9.41-16.256a.833.833 0 0 0-1.434 0l-9.35 16.277c-.323.545.08 1.231.707 1.231l18.78-.02c.626 0 1.01-.686.707-1.232Z"
        fill="#3B82F4"
      />
      <path
        d="M77.301 33.891 69.385 20.22c-.121-.222-.465-.222-.586 0L60.924 33.91a.344.344 0 0 0 .303.505l15.791-.02a.329.329 0 0 0 .283-.505Z"
        fill="#fff"
      />
      <path
        d="M68.679 30.256h.868a.297.297 0 0 0 .303-.303l.202-5.795a.31.31 0 0 0-.303-.303h-1.272a.31.31 0 0 0-.303.303l.202 5.795a.31.31 0 0 0 .303.303ZM69.102 31.165a.853.853 0 0 0-.848.848c0 .465.384.849.848.849a.853.853 0 0 0 .848-.849.828.828 0 0 0-.848-.848Z"
        fill="#3B82F4"
      />
      <path
        opacity={0.35}
        d="m124.69 33.2-2.403 1.172c-.464.222-.686.727-.525 1.13l.869 2.444a12.768 12.768 0 0 0-2.908 3.272l-2.525-.566c-.424-.12-.888.182-1.07.646l-.868 2.525c-.162.484.02.99.424 1.171l2.342 1.11a11.827 11.827 0 0 0 .263 4.363l-2.201 1.393c-.384.222-.505.747-.283 1.212l1.171 2.403c.222.464.727.686 1.131.525l2.444-.869a12.781 12.781 0 0 0 3.271 2.908l-.565 2.524c-.122.424.181.889.666 1.07l2.524.87c.485.16.99-.021 1.172-.425l1.11-2.343a11.82 11.82 0 0 0 4.362-.262l1.393 2.201c.223.384.748.505 1.212.283l2.403-1.171c.465-.223.687-.727.525-1.131l-.868-2.444a12.804 12.804 0 0 0 2.908-3.271l2.524.565c.424.121.888-.181 1.07-.666l.869-2.524c.161-.485-.021-.99-.425-1.172l-2.342-1.11a11.806 11.806 0 0 0-.263-4.362l2.202-1.393c.383-.223.504-.748.282-1.212l-1.171-2.403c-.222-.465-.727-.687-1.131-.525l-2.443.868a12.79 12.79 0 0 0-3.272-2.908l.566-2.524c.121-.424-.182-.889-.667-1.07l-2.524-.869c-.484-.161-.989.02-1.171.424l-1.111 2.343a11.826 11.826 0 0 0-4.362.262l-1.393-2.2c-.222-.364-.747-.485-1.212-.263Zm9.33 12.541a4.23 4.23 0 0 1-1.979 5.675 4.231 4.231 0 0 1-5.675-1.98 4.231 4.231 0 0 1 1.979-5.674c2.101-1.03 4.645-.141 5.675 1.98Z"
        fill="#3B82F4"
      />
      <path
        opacity={0.5}
        d="m105.304 57.352-1.535 1.313c-.282.242-.363.646-.161.929l1.09 1.615a8.714 8.714 0 0 0-1.474 2.929l-1.938.06c-.344 0-.626.303-.647.687l-.161 1.999c-.02.384.202.727.545.767l1.918.364a9.085 9.085 0 0 0 1.01 3.11l-1.333 1.433c-.242.243-.222.647.021.93l1.312 1.534c.243.283.646.363.929.162l1.616-1.09a8.698 8.698 0 0 0 2.928 1.473l.06 1.939c0 .343.303.626.687.646l1.999.162c.384.02.727-.202.767-.546l.364-1.918a9.08 9.08 0 0 0 3.11-1.01l1.434 1.333c.242.242.646.222.928-.02l1.535-1.313c.283-.242.364-.646.162-.929l-1.091-1.615a8.677 8.677 0 0 0 1.474-2.928l1.939-.06c.343 0 .626-.304.646-.687l.162-2c.02-.383-.202-.727-.545-.767l-1.919-.363a8.692 8.692 0 0 0-1.03-3.11l1.333-1.434c.242-.242.222-.646-.02-.929l-1.313-1.535c-.242-.282-.646-.363-.929-.161l-1.615 1.09a8.707 8.707 0 0 0-2.928-1.474L114.573 56c0-.343-.303-.626-.686-.646l-2-.161c-.383-.02-.727.202-.767.545l-.363 1.918a9.066 9.066 0 0 0-3.11 1.01l-1.434-1.333c-.202-.242-.606-.222-.909.02Zm9.168 7.33c1.151 1.334.99 3.353-.343 4.504-1.333 1.151-3.352.99-4.503-.343-1.151-1.333-.99-3.352.343-4.504a3.179 3.179 0 0 1 4.503.344Z"
        fill="#3B82F4"
      />
      <path
        d="m106.204 45.344 1.413-.06c.263-.02.485-.243.465-.505l-.061-9.471a.495.495 0 0 0-.504-.485l-2.08.081a.484.484 0 0 0-.465.525l.727 9.45c.02.284.242.485.505.465ZM106.972 46.798a1.411 1.411 0 0 0-1.333 1.454 1.412 1.412 0 0 0 1.454 1.333 1.41 1.41 0 0 0 1.333-1.454 1.41 1.41 0 0 0-1.454-1.333Z"
        fill="#3B82F4"
      />
    </g>
    <defs>
      <clipPath id="submit-fail_svg__a">
        <path
          fill="#fff"
          transform="matrix(-1 0 0 1 337.5 0)"
          d="M0 0h337v203.16H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSubmitFail;
