import { useCurrentFolderId } from "common/hooks/useCurrentFolderId";
import { useDashboardBreadcrumbs } from "common/hooks/useDashboardBreadcrumbs";
import { useOriginalRoute } from "common/hooks/useOriginalRoute";
import { useCallback } from "react";

type GoToSpecifiedFolderPathProps = {
  handleBreadCrumbItemClicked: (path: string, index?: number) => void;
};

export const useGoToSpecifiedFolderPath = (): GoToSpecifiedFolderPathProps => {
  const { dashboardBreadcrumbsItems, setDashboardBreadcrumbsItems } =
    useDashboardBreadcrumbs();
  const { setIsOriginalRoute } = useOriginalRoute();
  const { setCurrentFolderId } = useCurrentFolderId();

  const handleBreadCrumbItemClicked = useCallback(
    (targetPath: string, index?: number) => {
      const lastBreadcrumbsItem =
        dashboardBreadcrumbsItems[dashboardBreadcrumbsItems.length - 1];

      // Target path is the last path
      if (!lastBreadcrumbsItem || targetPath === lastBreadcrumbsItem.value) {
        return;
      }

      // Target path is initial path
      if (!targetPath || index === undefined) {
        setIsOriginalRoute(true);
        return;
      }

      // Target path is folder
      const cloneDashboardBreadcrumbsItems = [
        ...dashboardBreadcrumbsItems,
      ].slice(0, index + 1);
      setCurrentFolderId(targetPath);
      setDashboardBreadcrumbsItems(cloneDashboardBreadcrumbsItems);
    },
    [
      dashboardBreadcrumbsItems,
      setIsOriginalRoute,
      setCurrentFolderId,
      setDashboardBreadcrumbsItems,
    ]
  );

  return { handleBreadCrumbItemClicked };
};
