import { useModal } from "@liholiho/react-modal-hook";
import { Button } from "common/components/Button";
import { Modal } from "common/components/Modal";
import { SvgWarning } from "common/components/svg";
import { useTranslation } from "react-i18next";

export const useRefreshPopupHandler = () => {
  const { t } = useTranslation();
  const [show, hide] = useModal(({ in: isOpen }) => {
    return (
      <Modal open={isOpen}>
        <div className="flex items-center flex-col">
          <SvgWarning
            width={48}
            height={48}
            className="text-light-blue mb-10"
          />
          <p className="font-medium text-center">
            {t("dashboard.refresh.title")}
          </p>
          <p className="mt-10 font-medium text-center text-xs mb-4 text-dark-grey">
            {t("dashboard.refresh.titleHint")}
          </p>
          <Button
            className="w-full mb-4"
            onClick={() => {
              window.location.reload();
            }}
            variant="primary"
          >
            {t("dashboard.button.refresh")}
          </Button>
        </div>
      </Modal>
    );
  });

  return {
    show,
    hide,
  };
};
