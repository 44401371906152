import * as React from "react";
import { SVGProps } from "react";

const SvgLeftCircleArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.89 10.105a8.699 8.699 0 0 0-.79-1.456l-1.657 1.119c.478.71.813 1.507.987 2.345a6.658 6.658 0 0 1 0 2.648 6.492 6.492 0 0 1-.384 1.231c-.164.39-.366.762-.603 1.112a6.654 6.654 0 0 1-1.776 1.775 6.606 6.606 0 0 1-2.343.987 6.734 6.734 0 0 1-2.646 0 6.55 6.55 0 0 1-3.317-1.788 6.605 6.605 0 0 1-1.408-2.088 6.612 6.612 0 0 1-.382-1.23 6.627 6.627 0 0 1 .382-3.877c.33-.78.808-1.488 1.407-2.086a6.539 6.539 0 0 1 3.316-1.789c.107-.022.216-.032.324-.049V10l5-4-5-4v2.938a8.804 8.804 0 0 0-.725.111 8.566 8.566 0 0 0-4.33 14.445 8.55 8.55 0 0 0 11.486.563 8.576 8.576 0 0 0 2.958-4.895 8.641 8.641 0 0 0 0-3.45 8.68 8.68 0 0 0-.5-1.607Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLeftCircleArrow;
