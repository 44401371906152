import { useSelector } from "redux/hooks";
import { RootState } from "redux/rootReducer";

type useAuthorizedType = {
  canCreateRequest: boolean;
};

export const useAuthorized = (): useAuthorizedType => {
  const { canCreateRequest } = useSelector((state: RootState) => state.auth);
  return {
    canCreateRequest,
  };
};

