import useAuth from "common/hooks/useAuth";
import { isTopWindow } from "common/utils/cross-domain/base/utils";
import {
  KENTRO_ONE_NETWORK_DOMAIN,
  KENTRO_SIGN_DOMAIN,
} from "common/utils/cross-domain/react/config";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

type AuthenticatedCheckProps = {
  children: React.ReactNode;
};

export const AuthenticatedCheck = ({
  children,
}: AuthenticatedCheckProps): JSX.Element => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  const redirectUrl = useMemo(() => {
    return `${KENTRO_ONE_NETWORK_DOMAIN}/public/login?redirectURL=${KENTRO_SIGN_DOMAIN}${location.pathname}`;
  }, [location.pathname]);

  if (isLoggedIn) {
    return <>{children}</>;
  } else {
    if (isTopWindow()) {
      if (KENTRO_ONE_NETWORK_DOMAIN) {
        window.location.replace(redirectUrl);
      } else {
        console.error("INVALID CONFIG");
      }
    }
    return <></>;
  }
};
