import { CrossButton } from "features/documents/components/SignatureBox/CrossButton";
import { useSignatureBox } from "features/documents/components/SignatureBox/hooks/useSignatureBox";
import { SignatureButtonRecipientsPopover } from "features/documents/components/SignatureBox/SignatureBoxRecipientsPopoverProps";
import {
  SignatureButtonContainer,
  SignatureButtonContentContainer,
  SignaturePencil,
} from "features/documents/components/SignatureBox/SignatureButtonBased";
import { SignatureContent } from "features/documents/components/SignatureBox/SignatureCreateButton";
import { useContentRatio } from "features/documents/hooks/useContentRatio";
import { RecipientInfoProps } from "features/documents/pages/documentRequest/types";
import { useTranslation } from "react-i18next";
import { CommonMouseEvents } from "types/common";

type SignatureBoxBeforeAssignActionProps = {
  onSelectRecipient: (recipient: RecipientInfoProps) => void;
  isPopoverShow: boolean;
  onRemove: () => void;
} & CommonMouseEvents;

export const SignatureBoxBeforeAssignAction = ({
  onSelectRecipient,
  isPopoverShow,
  onMouseEnter,
  onMouseLeave,
  onRemove,
}: SignatureBoxBeforeAssignActionProps) => {
  const { color, width, recipients, scale } = useSignatureBox();
  const { contentRef, scaleRatio } = useContentRatio(width * scale, 0.7);
  const { t } = useTranslation();

  return (
    <SignatureButtonRecipientsPopover
      recipients={recipients}
      onSelectRecipient={onSelectRecipient}
      isPopoverShow={isPopoverShow}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {({ setReferenceElement }) => {
        return (
          <div tabIndex={0} className="relative w-full h-full">
            <SignatureButtonContainer
              ref={setReferenceElement}
              data-cy="signature-btn"
              color={color}
              isBackgroundShow={true}
              isBorderShow={true}
            >
              <div className="w-full h-full">
                <SignatureButtonContentContainer scaleRatio={scaleRatio}>
                  <SignaturePencil
                    className={`text-${color}`}
                    scaleRatio={scaleRatio}
                    data-cy="signature-box-pencil"
                  />
                  <SignatureContent
                    ref={contentRef}
                    className="signature-content"
                  >
                    {t("docRequest.button.signature")}
                  </SignatureContent>
                  <div>
                    <CrossButton scaleRatio={scaleRatio} onClick={onRemove} />
                  </div>
                </SignatureButtonContentContainer>
              </div>
            </SignatureButtonContainer>
          </div>
        );
      }}
    </SignatureButtonRecipientsPopover>
  );
};
