import { SvgLeftArrow } from "common/components/svg";
import { useTranslation } from "react-i18next";
type FileManagementTitleProps = {
  onClickBackButton: () => void;
  title?: string;
};
export const FileManagementTitle = ({
  onClickBackButton,
  title,
}: FileManagementTitleProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <SvgLeftArrow
        className="cursor-pointer"
        width={32}
        height={32}
        onClick={onClickBackButton}
      />
      <h1 className="text-dark-blue ml-3">
        {title ? title : t("docDetails.title")}
      </h1>
    </div>
  );
};
