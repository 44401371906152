import classNames from "classnames";
import {
  SvgCheckCircle,
  SvgCrossCircle,
  SvgWarning,
} from "common/components/svg";
import React, { SVGProps } from "react";

type MessageProps = {
  variant: MessageType;
  children: React.ReactNode;
  className?: string;
  isBackgroundEnable?: boolean;
};

type MessageType = "warning" | "success" | "error";
const MessageIconMap: Record<
  MessageType,
  (props: SVGProps<SVGSVGElement>) => JSX.Element
> = {
  warning: SvgWarning,
  success: SvgCheckCircle,
  error: SvgCrossCircle,
};

const MessageColorMap: Record<MessageType, string> = {
  warning: "text-light-red",
  success: "text-light-green",
  error: "text-light-red",
};

const BackgroundColorMap: Record<MessageType, string> = {
  warning: "bg-error-red/10",
  success: "bg-green/10",
  error: "bg-error-red/10",
};

export const Message = ({
  children,
  variant,
  className,
  isBackgroundEnable = true,
}: MessageProps): JSX.Element => {
  const backgroundColor = isBackgroundEnable
    ? `${BackgroundColorMap[variant]} h-[48px] px-6`
    : "bg-transparent";

  const Icon = MessageIconMap[variant];

  const classStr = classNames(
    `flex items-center space-x-2`,
    MessageColorMap[variant],
    backgroundColor,
    className
  );

  return (
    <div className={classStr}>
      <div className="shrink-0">
        <Icon width={20} height={20} />
      </div>
      <div className="font-medium">{children}</div>
    </div>
  );
};
