import {
  InfiniteDocumentListResult,
  useInfiniteDocumentList,
} from "api/document";
import { useDocumentRequest } from "common/hooks/useDocumentRequest";
import { DocumentRequestTemplate } from "features/documents/pages/documentRequest/components/DocumentRequestTemplate";
import { uploadDocuments } from "features/documents/pages/documentRequest/context/DocumentRequestActions";
import { UploadDocumentArea } from "features/documents/pages/documentRequest/pages/UploadDocument/components/UploadDocumentArea";
import { UploadedFileArea } from "features/documents/pages/documentRequest/pages/UploadDocument/components/UploadedFileArea";
import { UploadDocumentsProvider } from "features/documents/pages/documentRequest/pages/UploadDocument/context/PureUploadDocumentsCreateContext";
import {
  FILE_NOT_UPLOAD_COMPLETED_ERROR,
  NO_FILE_UPLOADED_ERROR,
} from "features/documents/pages/documentRequest/pages/UploadDocument/utils/common";
import {
  DocumentRequestStep,
  PDFFile,
  UploadDocumentFormValue,
} from "features/documents/pages/documentRequest/types";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormSubmissionHandler } from "types/common";
import { QueryDocumentRole } from "types/document";
import * as yup from "yup";

export const UploadDocuments = (): JSX.Element => {
  /** 
   * This hot fix is used to temporarily solve the problem of too few filter items and not enough height (300px), 
  which makes it impossible to fetch all the data.
  TODO: research on KT-1177 
  */

  const pageSize = 50;
  const [searchValue, setSearchValue] = useState<string>("");
  const documentsFetchResult = useInfiniteDocumentList(
    QueryDocumentRole.ALL,
    false,
    null,
    pageSize,
    true,
    searchValue,
    false
  );
  return (
    <PureUploadDocuments
      documentsFetchResult={documentsFetchResult}
      onSearchChange={setSearchValue}
    />
  );
};

type PureUploadDocumentsProps = {
  documentsFetchResult: InfiniteDocumentListResult;
  onSearchChange: (searchValue: string) => void;
};

export const PureUploadDocuments = ({
  documentsFetchResult,
  onSearchChange,
}: PureUploadDocumentsProps) => {
  const { t } = useTranslation();
  const schema: yup.SchemaOf<UploadDocumentFormValue, PDFFile> = yup
    .object()
    .shape({
      filesUpload: yup
        .array()
        .min(1, t(NO_FILE_UPLOADED_ERROR))
        .test(
          "is-complete",
          t(FILE_NOT_UPLOAD_COMPLETED_ERROR),
          (value: PDFFile[] | undefined) => {
            return (
              value?.every(
                (file: PDFFile) =>
                  file && file.id && file.loaded && file.loaded >= file.size
              ) || false
            );
          }
        ),
      fileDimensions: yup.array(),
    });
  const { dispatch, filesUpload, fileDimensions } = useDocumentRequest();
  const navigate = useNavigate();
  const handleSubmitForm: FormSubmissionHandler<
    UploadDocumentFormValue
  > = async (values) => {
    if (dispatch) {
      dispatch(uploadDocuments(values));
      navigate("../document-request");
    }
  };

  return (
    <Formik
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmitForm}
      initialValues={{
        filesUpload: filesUpload ?? [],
        fileDimensions: fileDimensions ?? [],
      }}
    >
      <Form autoComplete="off" noValidate className="w-full h-full">
        <UploadDocumentsProvider fieldName="filesUpload">
          <DocumentRequestTemplate
            leftComponent={
              <UploadDocumentArea
                documentsFetchResult={documentsFetchResult}
                onSearchChange={onSearchChange}
              />
            }
            rightComponent={<UploadedFileArea />}
            step={DocumentRequestStep.UploadDocuments}
          />
        </UploadDocumentsProvider>
      </Form>
    </Formik>
  );
};
