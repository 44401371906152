import { useModal } from "@liholiho/react-modal-hook";
import { useCurrentFolderId } from "common/hooks/useCurrentFolderId";
import { useDashboardBreadcrumbs } from "common/hooks/useDashboardBreadcrumbs";
import { FolderExistPopup } from "features/documents/components/FolderManagement/components/FolderExistPopup";

export const useFolderExistErrorPopup = (
  folderNameExisted: string,
  onCancel: () => void,
  onSuccess: () => void,
  isBackHintButtonShow: boolean = true
) => {
  const { setCurrentFolderId } = useCurrentFolderId();
  const { setDashboardBreadcrumbsItems } = useDashboardBreadcrumbs();
  const handleConfirm = () => {
    hide();

    // Reset to original paths when go to bin tab
    setCurrentFolderId(null);
    setDashboardBreadcrumbsItems([]);
    onSuccess();
  };
  const handleCancel = () => {
    hide();
    onCancel();
  };
  const [show, hide] = useModal(
    ({ in: isOpen }) => {
      return (
        <FolderExistPopup
          isOpen={isOpen}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          folderNameExisted={folderNameExisted}
          isBackHintShow={isBackHintButtonShow}
        />
      );
    },
    [folderNameExisted]
  );

  return {
    handler: show,
  };
};
