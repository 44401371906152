import { documentAPI, SignatureRecord } from "api/document";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { toast, ToastContainerId } from "common/toast";
import { getDashboardItemViewStatus } from "features/documents/components/StatusCell";
import { RecipientUpdateInfoProps } from "features/documents/pages/documentRequest/types";
import { PreviewDocumentWithSignatures } from "features/documents/pages/postDocumentRequest/components//PreviewDocumentWithSignatures";
import { ButtonStep } from "features/documents/pages/postDocumentRequest/components/ButtonStepGroup";
import { PostDocumentRequestTemplate } from "features/documents/pages/postDocumentRequest/components/PostDocumentRequestTemplate";
import { useIsActionTaken } from "features/documents/pages/postDocumentRequest/hooks/useIsActionTaken";
import useRecipientProfileList from "features/documents/pages/postDocumentRequest/hooks/useRecipientProfileList";
import { generateDocumentUpdatePayload } from "features/documents/pages/postDocumentRequest/pages/preview/DocumentPreviewView";
import { DocumentSigningSetting } from "features/documents/pages/postDocumentRequest/pages/signing/components/DocumentSigningSetting";
import { useCurrentUserSignatures } from "features/documents/pages/postDocumentRequest/pages/signing/hooks/useCurrentUserSignatures";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "redux/hooks";
import { currentPartySelector } from "redux/slices/party/selectors";
import { FormSubmissionHandler } from "types/common";
import {
  ActionTaken,
  DocumentRole,
  DocumentStatePayload,
  DocumentStatePayloadMutate,
} from "types/document";
import { MapSchema } from "types/mapSchema";
import * as yup from "yup";

// TODO add schema type with Map
const schema: yup.SchemaOf<any> = yup.object().shape({
  // https://github.com/jquense/yup/issues/524#issuecomment-715114967
  recipientInfo: yup.array().of(
    yup.object().shape({
      email: yup.string().trim().required().email(),
      firstName: yup.string().trim().required(),
      lastName: yup.string().trim().required(),
    })
  ),
  signatures: new MapSchema(yup.string().required(), yup.string().required()),
});

export type DocumentSigningViewForm = {
  signatures: SignatureRecord;
  recipientInfo: RecipientUpdateInfoProps[];
};

type DocumentSigningViewProps = {
  documentStatePayload: DocumentStatePayload;
  documentPath: string | undefined;
  currentRoles?: DocumentRole[];
  mutate: DocumentStatePayloadMutate;
  signatureRecord: SignatureRecord;
  isSender: boolean;
  senderAsRecipientActionTaken?: ActionTaken;
};
export const DocumentSigningView = ({
  documentStatePayload,
  documentPath,
  signatureRecord,
  currentRoles,
  isSender,
  senderAsRecipientActionTaken,
  mutate,
}: DocumentSigningViewProps): JSX.Element => {
  const { t } = useTranslation();
  const [isRecipientListEditMode, setIsRecipientListEditMode] = useState(false);
  const listRecipients = documentStatePayload.recipients;
  const { recipientProfileList } = useRecipientProfileList(listRecipients);
  const currentUserProfile = useCurrentUserProfile();
  const currentParty = useSelector(currentPartySelector);
  const viewStatus = getDashboardItemViewStatus(
    documentStatePayload.recipients,
    documentStatePayload.sender,
    documentStatePayload.status,
    currentUserProfile?.email
  );
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const isActionTaken = useIsActionTaken(documentStatePayload);
  const [currentActiveBoxId, setCurrentActiveBoxId] = useState<
    string | undefined
  >(() => {
    return documentStatePayload.signatureSchemas.length > 0
      ? documentStatePayload.signatureSchemas.filter(
          (it) => it.email === currentUserProfile?.email
        )[0]?.id
      : undefined;
  });

  const [step, setStep] = useState<ButtonStep>(
    isActionTaken ? ButtonStep.DONE : ButtonStep.START
  );

  const { getCurrentUserSignedSignatureRecord, isAllSignaturedSigned } =
    useCurrentUserSignatures(documentStatePayload);

  const handleSubmit: FormSubmissionHandler<DocumentSigningViewForm> = async (
    values,
    { setSubmitting }
  ) => {
    const signedValue = getCurrentUserSignedSignatureRecord(values.signatures);
    if (Object.keys(signedValue).length === 0) {
      const ref = documentStatePayload?.ref;
      const payload = generateDocumentUpdatePayload(
        currentParty,
        values.recipientInfo,
        listRecipients
      );
      try {
        setSubmitting(true);
        await documentAPI.update(ref, payload);
        setSubmitting(false);
        setIsRecipientListEditMode(false);
        mutate();
      } catch (e) {
        composeErrorHandlers(() =>
          baseFallbackErrorHandler(ToastContainerId.DocumentDetail)
        )(e as GenericError);
      }
      return;
    }

    if (isAllSignaturedSigned(signedValue)) {
      try {
        await documentAPI.accept(documentStatePayload.ref, {
          signature: {
            signatures: signedValue,
          },
          authenticate:
            senderAsRecipientActionTaken === ActionTaken.AUTHENTICATED,
        });
        toast.success(
          t("docDetails.message.signingSuccess"),
          ToastContainerId.DocumentDetail
        );
        mutate();
      } catch (e) {
        composeErrorHandlers(() =>
          baseFallbackErrorHandler(ToastContainerId.DocumentDetail)
        )(e as GenericError);
      }
    } else {
      // TODO error handling
      console.error(
        "Please sign in all required signature locations before proceeding. "
      );
    }
  };

  return (
    <div className="h-full text-dark-blue ">
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        initialValues={{
          signatures: signatureRecord,
          recipientInfo: recipientProfileList ?? [],
        }}
        enableReinitialize
      >
        <Form autoComplete="off" noValidate className="h-full">
          <PostDocumentRequestTemplate
            leftComponent={
              <PreviewDocumentWithSignatures
                documentViewStatus={viewStatus}
                documentStatePayload={documentStatePayload}
                documentPath={documentPath}
                isPreview={step === ButtonStep.START || isPreview}
                step={step}
                setStep={setStep}
                currentActiveBoxId={currentActiveBoxId}
              />
            }
            rightComponent={
              <DocumentSigningSetting
                setStep={setStep}
                step={step}
                setIsPreview={setIsPreview}
                isPreview={isPreview}
                documentStatePayload={documentStatePayload}
                currentRoles={currentRoles}
                documentViewStatus={viewStatus}
                isViewEditMode={isRecipientListEditMode}
                setIsViewEditMode={setIsRecipientListEditMode}
                mutate={mutate}
                isSenderAsRecipientActionTaken={!!senderAsRecipientActionTaken}
                setCurrentActiveBoxId={setCurrentActiveBoxId}
              />
            }
          />
        </Form>
      </Formik>
    </div>
  );
};
