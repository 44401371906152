import * as React from "react";
import { SVGProps } from "react";

const SvgHamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.4.8a.8.8 0 1 0 0 1.6h19.2a.8.8 0 0 0 0-1.6H1.4ZM.6 8a.8.8 0 0 1 .8-.8h19.2a.8.8 0 0 1 0 1.6H1.4A.8.8 0 0 1 .6 8Zm0 6.4a.8.8 0 0 1 .8-.8h19.2a.8.8 0 0 1 0 1.6H1.4a.8.8 0 0 1-.8-.8Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHamburger;
