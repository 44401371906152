import { CrossButton } from "features/documents/components/SignatureBox/CrossButton";
import { useSignatureBox } from "features/documents/components/SignatureBox/hooks/useSignatureBox";
import {
  SignatureButtonContainer,
  SignatureButtonContentContainer,
  SignaturePencil,
} from "features/documents/components/SignatureBox/SignatureButtonBased";
import { SignatureContent } from "features/documents/components/SignatureBox/SignatureCreateButton";
import { useContentRatio } from "features/documents/hooks/useContentRatio";
import { forwardRef, MouseEventHandler, useEffect } from "react";
import { CommonMouseEvents } from "types/common";

type SignatureBoxNotSignedPreviewProps = {
  isCrossShow: boolean;
  onCrossClick?: MouseEventHandler<HTMLDivElement>;
  displayMsg: string;
  isGradientBorderShow?: boolean;
  isGradientEnable?: boolean;
} & CommonMouseEvents;

export const SignatureBoxNotSignedPreview = forwardRef<
  HTMLDivElement,
  SignatureBoxNotSignedPreviewProps
>(
  (
    {
      onMouseEnter,
      onMouseLeave,
      onClick,
      onCrossClick,
      isCrossShow,
      displayMsg,
      isGradientBorderShow,
      isGradientEnable,
    },
    ref
  ) => {
    const { color, width, scale } = useSignatureBox();
    const { scaleRatio, contentRef, mutate } = useContentRatio(
      width * scale,
      0.7
    );
    useEffect(() => {
      mutate();
      // Must disable to prevent infinite update
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayMsg, width, scale]);
    return (
      <SignatureButtonContainer
        ref={ref}
        color={color}
        data-cy="signature-btn"
        isBackgroundShow={true}
        isBorderShow={true}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        isGradientBorderShow={isGradientBorderShow}
        isGradientEnable={isGradientEnable}
      >
        <SignatureButtonContentContainer scaleRatio={scaleRatio}>
          <SignaturePencil
            className={`text-${color}`}
            scaleRatio={scaleRatio}
            data-cy="signature-box-pencil"
          />
          <SignatureContent ref={contentRef} className="grow">
            {displayMsg}
          </SignatureContent>
          {isCrossShow && (
            <CrossButton scaleRatio={scaleRatio} onClick={onCrossClick} />
          )}
        </SignatureButtonContentContainer>
      </SignatureButtonContainer>
    );
  }
);
