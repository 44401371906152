import * as React from "react";
import { SVGProps } from "react";

const SvgDownArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m13.707 6.707-1.414-1.414L8 9.586V0H6v9.586L1.707 5.293.293 6.707 7 13.414l6.707-6.707Z"
      fill="#7E8299"
    />
  </svg>
);

export default SvgDownArrow;
