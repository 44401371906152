import * as React from "react";
import { SVGProps } from "react";

const SvgUpArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 4.414V14h2V4.414l4.293 4.293 1.414-1.414L7 .586.293 7.293l1.414 1.414L6 4.414Z"
      fill="#7E8299"
    />
  </svg>
);

export default SvgUpArrow;
