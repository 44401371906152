import { Button } from "common/components/Button";
import { RecipientListFormValues } from "features/documents/pages/documentRequest/types";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

const StyleAction = tw.div`mt-10 flex w-full justify-between absolute bottom-0`;

export type DocumentUpdateSaveButtonProps = {
  isDisabled: boolean;
  onClickSave: () => void;
};
export const DocumentUpdateSaveButton = ({
  onClickSave,
  isDisabled,
}: DocumentUpdateSaveButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const { submitForm, validateForm } =
    useFormikContext<RecipientListFormValues>();
  const handleSave = async () => {
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length === 0) {
        await submitForm();
        onClickSave();
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <StyleAction>
      <Button
        type="button"
        variant="primary"
        className="w-full"
        onClick={handleSave}
        disabled={isDisabled}
      >
        {t("docRequest.button.save")}
      </Button>
    </StyleAction>
  );
};
