import { SelectOption } from "common/components/form/SelectField";
import { StylesConfig } from "react-select";

export const relatedRecipientsStyles: StylesConfig<SelectOption> = {
  menu: (base) => ({
    ...base,
    padding: 16,
    width: 190,
    maxWidth: 190,
  }),
  menuList: (base) => ({
    ...base,
    paddingRight: 16,
    "::-webkit-scrollbar": {
      width: 4,
    },
    "::-webkit-scrollbar-thumb": {
      background: "#CACCD7",
      borderRadius: 2,
    },
  }),
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    backgroundColor: "#F5F8FA",
    color: "3F4254",
    paddingLeft: 8,
    position: "relative",
  }),
  dropdownIndicator: () => ({
    padding: "6px 0",
  }),
  indicatorsContainer: () => ({
    color: "#7E8299",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  option: (base) => ({
    ...base,
    color: "#3F4254",
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#F5F8FA",
    },
    "&:nth-of-type(1)": {
      paddingBottom: 8,
      marginBottom: 8,
      borderBottom: "2px solid #EFF2F5",
    },
  }),
};
