import { Popover } from "@headlessui/react";
import { oneNetworkAPI, App, ApplicationName } from "api/oneNetwork";
import { Button } from "common/components/Button";
import { Card } from "common/components/Card";
import {
  GridMenuTitle,
  MyApplications,
  OtherApplications,
} from "common/components/Header/GridMenuSection";
import SvgGridMenu from "common/components/svg/GridMenu";
import { useCommonPopover } from "common/hooks/useCommonPopover";
import { useErrorMessagePopupHandler } from "common/hooks/useErrorMessagePopupHandler";
import { useLoadingModal } from "common/hooks/useLoadingModal";
import { useOpenWindow } from "common/hooks/useOpenWindow";
import {
  ATLAS_ELITE_DOMAIN,
  KENTRO_ONE_NETWORK_DOMAIN,
  KENTRO_SIGN_DOMAIN,
  MIDAS_DOMAIN,
  TOPAZ_DOMAIN,
} from "common/utils/cross-domain/react/config";
import { postTokenHandler } from "common/utils/cross-domain/react/postTokenHandler";
import { useEffect, useMemo, useState } from "react";

const getDomainByAppName = (appName: ApplicationName): string | undefined => {
  switch (appName) {
    case ApplicationName.AtlasElite:
      return ATLAS_ELITE_DOMAIN;
    case ApplicationName.Midas:
      return MIDAS_DOMAIN;
    case ApplicationName.Topaz:
      return TOPAZ_DOMAIN;
    case ApplicationName.KentroSign:
      return KENTRO_SIGN_DOMAIN;
    default:
      return KENTRO_ONE_NETWORK_DOMAIN;
  }
};

export const GridMenuPopup = (): JSX.Element => {
  const { setReferenceElement, setPopperElement, styles, attributes } =
    useCommonPopover();

  const [apps, setApps] = useState<App>([]);
  const { openWindow } = useOpenWindow();

  const [showLoadingModal, hideLoadingModal] = useLoadingModal();
  const { show: showErrorMessage } = useErrorMessagePopupHandler();

  const tryOpenDomainWindow = async (
    domain: string | undefined,
    windowConfig = {
      path: "",
      isForceRedirect: false,
    }
  ) => {
    showLoadingModal();
    try {
      if (domain === undefined) throw new Error("Domain is undefined.");
      // broadcast again to prevent token missing in children domains
      await postTokenHandler();
      openWindow(domain, windowConfig);
    } catch (error) {
      showErrorMessage();
      console.error(error);
    }
    hideLoadingModal();
  };

  const handleKentro = () => {
    tryOpenDomainWindow(KENTRO_ONE_NETWORK_DOMAIN, {
      path: "",
      isForceRedirect: true,
    });
  };

  const handleMyApplications = (app: ApplicationName, id: string) => {
    const domain = getDomainByAppName(app);
    tryOpenDomainWindow(domain);
  };

  const handleOtherApplications = (app: ApplicationName, id: string) => {
    tryOpenDomainWindow(KENTRO_ONE_NETWORK_DOMAIN, {
      path: `/dashboard/details/${id}/${app
        .replaceAll(" ", "-")
        .toLowerCase()}`,
      isForceRedirect: true,
    });
  };

  const myApps = useMemo(() => apps.filter((it) => it.enabled), [apps]);
  const otherApps = useMemo(() => apps.filter((it) => !it.enabled), [apps]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await oneNetworkAPI.appList();
        setApps(resp.content);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  return (
    <Popover className="relative mr-2">
      <Popover.Button
        ref={setReferenceElement}
        className="flex items-center justify-center"
      >
        <SvgGridMenu />
      </Popover.Button>
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="z-popover"
      >
        {({ close }) => (
          <Card className="absolute -right-5 w-80 min-h-[120px] bg-white my-7 p-6 flex flex-col">
            <MyApplications
              applicationList={myApps}
              onClick={(app: ApplicationName, id: string) => {
                handleMyApplications(app, id);
                close();
              }}
              className="mb-14"
            />
            <OtherApplications
              applicationList={otherApps}
              onClick={(app: ApplicationName, id: string) => {
                handleOtherApplications(app, id);
                close();
              }}
              className="mb-8"
            />
            <Button
              onClick={() => {
                handleKentro();
                close();
              }}
            >
              <GridMenuTitle>Go To Kentro</GridMenuTitle>
            </Button>
          </Card>
        )}
      </Popover.Panel>
    </Popover>
  );
};
