import { LatestDocumentsContext } from "common/context/LatestDocumentsContext";
import { Dispatch, SetStateAction, useContext } from "react";

export const useOriginalRoute = (): {
  isOriginalRoute: boolean;
  setIsOriginalRoute: Dispatch<SetStateAction<boolean>>;
} => {
  const { isOriginalRoute, setIsOriginalRoute } = useContext(
    LatestDocumentsContext
  );

  return { isOriginalRoute, setIsOriginalRoute };
};
