import classNames from "classnames";
import { DataTable } from "common/components/DataTable/DataTable";
import { getDocumentRecipientRoleSet } from "common/utils/document";
import {
  CreatedAtCell,
  TitleCell,
  UsageCell,
} from "features/documents/components/Cells";
import { DocumentMenuCell } from "features/documents/components/DocumentMenuCell";
import { InvolvedParticipantsCell } from "features/documents/components/InvolvedParticipantsCell";
import { RoleCell } from "features/documents/components/RoleCell";
import { StatusCell } from "features/documents/components/StatusCell";
import { TitleWithBreadCrumbsCell } from "features/documents/components/TitleWithBreadCrumbsCell";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Cell, Column, Row } from "react-table";
import { DashboardResponse } from "types/common";
import { DocumentStatus } from "types/document";

type DocumentTableProps = {
  documents: DashboardResponse[];
  isArchived: boolean;
  isGlobalSearch: boolean;
  isPending: boolean;
  fetchNext: () => void;
  hasMore: () => boolean;
  hasMenu?: boolean;
  scrollHeight?: number;
  selectedRows?: Row<DashboardResponse>[];
  onRowSelected?: (selected: Row<DashboardResponse>) => void;
  currentFolderId?: string | null;
  isOnSearchResultPage?: boolean;
};

export const DocumentTable = ({
  documents,
  isArchived,
  isGlobalSearch,
  isPending,
  fetchNext,
  hasMore,
  hasMenu,
  scrollHeight,
  selectedRows,
  onRowSelected,
  currentFolderId = null,
  isOnSearchResultPage = false,
}: DocumentTableProps): JSX.Element => {
  const { t } = useTranslation();
  const columns = useMemo<Column<DashboardResponse>[]>(() => {
    const baseColumns: Column<DashboardResponse>[] = [
      {
        id: "title",
        Header: t("dashboard.table.titleCol"),
        accessor: (data) => data,
        Cell: isOnSearchResultPage ? TitleWithBreadCrumbsCell : TitleCell,
      },
      {
        id: "usage",
        Header: t("dashboard.table.usageCol"),
        accessor: (data) =>
          data.isFolder
            ? undefined
            : getDocumentRecipientRoleSet(data.recipients),
        Cell: UsageCell,
      },
      {
        id: "role",
        Header: t("dashboard.table.roleCol"),
        accessor: (data) => (data.isFolder ? undefined : data),
        Cell: RoleCell,
      },
      {
        id: "status",
        Header: t("dashboard.table.statusCol"),
        accessor: (data) =>
          data.isFolder
            ? undefined
            : {
                recipients: data.recipients,
                sender: data.sender,
                status: data.status,
              },
        Cell: StatusCell,
      },
      {
        id: "participants",
        Header: t("dashboard.table.partiesCol"),
        accessor: (data) => data,
        Cell: InvolvedParticipantsCell,
      },
      {
        id: "createdAt",
        Header: t("dashboard.table.createdDateCol"),
        accessor: (data) => data.createdAt,
        Cell: CreatedAtCell,
      },
    ];

    return hasMenu
      ? baseColumns.concat({
          id: "menu",
          Header: "",
          maxWidth: 24,
          accessor: (data) => data,
          Cell: ({ value }: { value: DashboardResponse }) => (
            <DocumentMenuCell
              dashboardItem={value}
              isArchived={isArchived}
              currentDashboardFolderId={currentFolderId}
              rootDashboardItems={documents}
              isGlobalSearch={isGlobalSearch}
            />
          ),
        })
      : baseColumns;
  }, [
    t,
    isOnSearchResultPage,
    hasMenu,
    isArchived,
    currentFolderId,
    documents,
    isGlobalSearch,
  ]);

  const isDiscarded = (cell: Cell<DashboardResponse>): boolean => {
    return cell.row.values.status?.status === DocumentStatus.DISCARDED;
  };
  useEffect(() => {
    // work around to fix height not enough to have scroll event
    // https://github.com/ankeetmaini/react-infinite-scroll-component/issues/47
    fetchNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataTable
      className="flex flex-col h-full mt-3"
      columns={columns}
      data={documents}
      fetchNext={fetchNext}
      hasMore={hasMore}
      scrollHeight={scrollHeight}
      selectedRows={selectedRows}
      onRowSelected={onRowSelected}
      getCustomCellWrapperProps={(cell) => {
        return {
          className: classNames({
            "!text-grey": isDiscarded(cell),
          }),
        };
      }}
      getCustomCellInnerProps={(cell) => {
        return {
          isDiscarded: isDiscarded(cell),
        };
      }}
      isPending={isPending}
    />
  );
};
