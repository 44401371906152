import KentroSignFullLogo from "assets/images/kentro-sign-full-logo.png";
import KentroSignLogo from "assets/images/kentro-sign-logo.png";
import { NavItem } from "common/components/Sidebar/NavItem";
import { SvgFile } from "common/components/svg";
import { useOriginalRoute } from "common/hooks/useOriginalRoute";
import { useWindowSize } from "common/hooks/useWindowSize";
import {
  DocumentFilterType,
  getDocumentFilterTypeText,
} from "features/documents/pages/documentManagement/types";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export type ObjectRoute = {
  path: string;
  name: string;
  icon?: JSX.Element;
  children?: ObjectRoute[];
};

const ROUTES: ObjectRoute[] = [
  {
    path: "documents",
    name: "dashboard.documents",
    icon: <SvgFile />,
    children: [
      {
        path: "documents/all",
        name: getDocumentFilterTypeText(DocumentFilterType.All),
      },
      {
        path: "documents/created-by-me",
        name: getDocumentFilterTypeText(DocumentFilterType.Sender),
      },
      {
        path: "documents/shared-with-me",
        name: getDocumentFilterTypeText(DocumentFilterType.Recipient),
      },
      {
        path: "documents/bin",
        name: getDocumentFilterTypeText(DocumentFilterType.Bin),
      },
    ],
  },
  // { path: "/admin-settings", name: "Admin Settings", icon: <SvgUserAccount /> }, // TODO TBD
];

const SidebarTemplate = React.memo(
  ({ expanded }: { expanded: boolean }): JSX.Element => {
    const navigate = useNavigate();
    const { setIsOriginalRoute } = useOriginalRoute();
    const handleClickLogo = () => {
      navigate("/documents");
      setIsOriginalRoute(true);
    };
    return (
      <div
        className={`px-6 py-8 bg-white shadow-xl min-h-full ${
          expanded ? "w-[220px]" : "w-20"
        }`}
        data-cy="sidebar"
      >
        {/* Logo */}
        <div
          className="flex items-center mb-20 cursor-pointer"
          onClick={handleClickLogo}
          data-cy="kentro-logo"
        >
          {expanded ? (
            <img src={KentroSignFullLogo} alt="Kentro" />
          ) : (
            <img src={KentroSignLogo} alt="Kentro" />
          )}
        </div>

        {/* Navbar */}
        {ROUTES.map((route, index) => (
          <NavItem
            route={route}
            expanded={expanded}
            className={`${index ? "mt-6" : ""}`}
            key={index}
          />
        ))}
      </div>
    );
  }
);

export const Sidebar = (): JSX.Element => {
  const { width } = useWindowSize();
  /* Check width === 0 to avoid logo glitch when reload */
  const expanded = useMemo(() => width === 0 || width >= 1440, [width]);
  return <SidebarTemplate expanded={expanded} />;
};
