import { SignaturePxProps } from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { generateFullName } from "common/utils/common";
import { PanelState } from "features/documents/pages/documentRequest/context/PDFSignatureControlContext";
import { usePDFSignatureControl } from "features/documents/pages/documentRequest/hooks/usePDFSignatureControl";
import { PanelAddSignatureSection } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/PanelAddSignatureSection";
import { SELECT_ALL } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/SignatureControlPanel";
import { SignatureLocationsControl } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/SignatureLocationsControl";
import {
  PDFFile,
  RecipientRolesProps,
} from "features/documents/pages/documentRequest/types";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { DocumentRole } from "types/document";
import * as yup from "yup";

type SignatureControlPanelFormValue = {
  filterValue: string;
};
const schema: yup.SchemaOf<SignatureControlPanelFormValue> = yup
  .object()
  .shape({
    filterValue: yup.string().required(),
  });

type SignatureControlDetailPanelProps = {
  currSignedSignatureNum: number;
  selectedFile: PDFFile;
  onAddSignature: () => void;
  handleScrollToIndex: (
    index: number,
    filterValue: string,
    upperLimitation?: number
  ) => void;
  currSignature: SignaturePxProps[];
  filterIndex: number;
  recipients: RecipientRolesProps[];
};

const StyledSignatureControlDetailPanelWrapper = tw.div`absolute top-[55%] h-[45%] flex flex-col space-y-5 w-full`;

export const SignatureControlDetailPanel = ({
  currSignedSignatureNum,
  selectedFile,
  onAddSignature,
  handleScrollToIndex,
  currSignature,
  filterIndex,
  recipients,
}: SignatureControlDetailPanelProps) => {
  const { t } = useTranslation();
  const { panelState, setPanelState } = usePDFSignatureControl();
  const handlePanelClick = () => {
    setPanelState((preState) =>
      preState === PanelState.ADD_SIGNATURE
        ? PanelState.SIGNATURE_FILTER
        : PanelState.ADD_SIGNATURE
    );
  };

  const recipientOptions = React.useMemo(
    () => [
      { label: t("docRequest.confirmDetail.selectAll"), value: SELECT_ALL },
      ...recipients
        .filter((recipientRole) =>
          recipientRole.roles?.includes(DocumentRole.SIGNER)
        )
        .map((recipient, index) => ({
          label: generateFullName(
            recipient.recipient.firstName,
            recipient.recipient.lastName
          ),
          value: recipient.recipient.email,
          index: index,
        })),
    ],
    [recipients, t]
  );
  return (
    <StyledSignatureControlDetailPanelWrapper>
      {panelState === PanelState.ADD_SIGNATURE ? (
        <PanelAddSignatureSection
          currSignedSignatureNum={currSignedSignatureNum}
          selectedFile={selectedFile}
          onAddSignature={onAddSignature}
        />
      ) : (
        <Formik
          validationSchema={schema}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{ filterValue: SELECT_ALL }}
          onSubmit={({ filterValue }) => {
            handleScrollToIndex(0, filterValue);
          }}
        >
          {({ values, handleSubmit }) => (
            <SignatureLocationsControl
              handleSubmit={handleSubmit}
              recipientOptions={recipientOptions}
              filterValue={values.filterValue}
              currSignature={currSignature}
              filterIndex={filterIndex}
              onClickScrollToIndex={handleScrollToIndex}
            />
          )}
        </Formik>
      )}
      <div className="text-sm font-medium text-light-blue cursor-pointer">
        <div onClick={handlePanelClick}>
          <div>
            {panelState === PanelState.ADD_SIGNATURE
              ? t("docRequest.confirmDetail.signatureLocationsPreviewHint")
              : t("docRequest.confirmDetail.signatureLocationsSpecifyHint")}
          </div>
        </div>
      </div>
    </StyledSignatureControlDetailPanelWrapper>
  );
};
