import { StatusItem } from "common/components/StatusItem";
import { Colors, ColorsWithOpacity } from "enums/colors";
import React from "react";
import { useTranslation } from "react-i18next";

type UserResponseStatusProps = {
  status?: UserResponseStatus;
  isDotIconShow?: boolean;
};

export enum UserResponseStatus {
  NOT_VIEWED = "Not Viewed",
  VIEWED = "Viewed",
  REJECTED = "Rejected",
  COMPLETE = "Completed",
}

const UserResponseStatusColorMap: { [key in UserResponseStatus]: Colors } = {
  [UserResponseStatus.NOT_VIEWED]: Colors.LIGHT_BLUE,
  [UserResponseStatus.VIEWED]: Colors.LIGHT_YELLOW,
  [UserResponseStatus.REJECTED]: Colors.LIGHT_RED,
  [UserResponseStatus.COMPLETE]: Colors.LIGHT_GREEN,
};

const UserResponseStatusBackgroundColorMap: {
  [key in UserResponseStatus]: ColorsWithOpacity;
} = {
  [UserResponseStatus.NOT_VIEWED]: ColorsWithOpacity.LIGHT_BLUE,
  [UserResponseStatus.VIEWED]: ColorsWithOpacity.LIGHT_YELLOW,
  [UserResponseStatus.REJECTED]: ColorsWithOpacity.LIGHT_RED,
  [UserResponseStatus.COMPLETE]: ColorsWithOpacity.LIGHT_GREEN,
};

const UserResponseStatusTextMap: { [key in UserResponseStatus]: string } = {
  [UserResponseStatus.NOT_VIEWED]: "docDetails.responseStatus.notViewed",
  [UserResponseStatus.VIEWED]: "docDetails.responseStatus.viewed",
  [UserResponseStatus.REJECTED]: "docDetails.responseStatus.rejected",
  [UserResponseStatus.COMPLETE]: "docDetails.responseStatus.complete",
};

export const UserResponseStatusLabel = ({
  status,
  isDotIconShow,
}: UserResponseStatusProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      {status && (
        <div
          className={`px-1.5 py-px w-fit bg-${UserResponseStatusBackgroundColorMap[status]}`}
        >
          <StatusItem
            isDotIconShow={
              status !== UserResponseStatus.REJECTED ? false : isDotIconShow
            }
            iconSize={6}
            className={`text-${UserResponseStatusColorMap[status]}`}
          >
            <span
              className={`text-xs font-medium text-${UserResponseStatusColorMap[status]}`}
            >
              {t(UserResponseStatusTextMap[status])}
            </span>
          </StatusItem>
        </div>
      )}
    </>
  );
};
