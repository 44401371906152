import {
  Dimension,
  PageDimensions,
  SignaturePercentageSizeWithPosition,
  SignaturePxSizeWithPosition,
} from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import {
  getDisplayDimensions,
  parseToDimensionList,
} from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/utils/PDFSignatureUtils";
import { useMemo } from "react";
import { SignatureSchemas } from "types/document";

export const useSignaturePositionToPx = (
  pageDimensions: PageDimensions | undefined,
  userPageWidth: number,
  signatureSchemas: SignatureSchemas[]
): {
  signatures_px: SignatureSchemas[];
} => {
  const dimensionList = useMemo(
    () => parseToDimensionList(pageDimensions),
    [pageDimensions]
  );
  const signatures_px: SignatureSchemas[] = useMemo(() => {
    return signatureSchemas.map((it) => {
      const pxValue = parseFromPercentageToPx(
        {
          width_percentage: it.width,
          height_percentage: it.height,
          x_percentage: it.x,
          y_percentage: it.y,
          pageNumber: it.pageNumber ?? 0,
        },
        dimensionList,
        userPageWidth
      );

      return {
        height: pxValue.height_px,
        width: pxValue.width_px,
        x: pxValue.x_px,
        y: pxValue.y_px,
        party: it.party,
        email: it.email,
        id: it.id,
      };
    });
  }, [dimensionList, signatureSchemas, userPageWidth]);

  return {
    signatures_px,
  };
};

export const parseFromPercentageToPx = (
  percentageSignature: SignaturePercentageSizeWithPosition,
  dimensions: Dimension[],
  userPageWidth: number
): SignaturePxSizeWithPosition => {
  if (!dimensions || !dimensions[percentageSignature.pageNumber]) {
    return { x_px: 0, y_px: 0, width_px: 0, height_px: 0 };
  }

  const displayDimension = getDisplayDimensions(dimensions, userPageWidth);
  const currDisplayDimension = displayDimension[percentageSignature.pageNumber];

  return {
    x_px: currDisplayDimension.width * percentageSignature.x_percentage,
    y_px:
      currDisplayDimension.totalOffset +
      currDisplayDimension.height * percentageSignature.y_percentage,
    width_px: currDisplayDimension.width * percentageSignature.width_percentage,
    height_px:
      currDisplayDimension.height * percentageSignature.height_percentage,
  };
};
