import { useModal } from "@liholiho/react-modal-hook";
import { documentAPI } from "api/document";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import { ToastContainerId } from "common/toast";
import { ForwardDocumentPopup } from "features/documents/components/ForwardDocumentPopup/ForwardDocumentPopup";
import { useState } from "react";
import { CreatableInputFormProps } from "stories/designSystem/CreatableInput.stories";
import {
  FormSubmissionHandler,
  LinearId,
  SubmissionHandler,
} from "types/common";
import { DocumentStatePayload } from "types/document";
export const useForwardDocumentHandler = <T,>(
  dashboardItemLinearId: LinearId,
  documentName: string,
  toastContainerId: ToastContainerId = ToastContainerId.AfterLogin,
  getLatestRef: () => Promise<string | undefined>,
  callback?: () => Promise<T>
): SubmissionHandler => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [forwardedRecipients, setForwardedRecipients] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [latestDocument, setLatestDocument] = useState<DocumentStatePayload>();

  const handleSubmit: FormSubmissionHandler<CreatableInputFormProps> = async (
    value,
    { setValues }
  ) => {
    setSubmitting(true);
    try {
      if (latestDocument) {
        const forwardRequestPayload = value.input.map((opt) => {
          return {
            email: opt.value.trim(),
            party: latestDocument.sender.party,
          };
        });
        const latestRef = (await getLatestRef()) ?? latestDocument.ref;
        await documentAPI.forward(latestRef, {
          newForwardRecipients: forwardRequestPayload,
        });
        setForwardedRecipients((recipients) => {
          return [...recipients, ...value.input.map((it) => it.value)];
        });
        setIsSuccess(true);
        setValues({ input: [] }); // clear input after submitted
      }
    } catch (e) {
      composeErrorHandlers(() => baseFallbackErrorHandler(toastContainerId))(
        e as GenericError
      );
    }
    setSubmitting(false);
  };

  const handleCancel = () => {
    setIsSuccess(false);
    if (callback) {
      callback();
    }
    hide();
  };

  const [show, hide] = useModal(
    ({ in: isOpen }) => {
      return (
        <ForwardDocumentPopup
          isOpen={isOpen}
          dashboardItemLinearId={dashboardItemLinearId}
          documentName={documentName}
          isSubmitting={isSubmitting}
          isSuccess={isSuccess}
          latestDocument={latestDocument}
          setLatestDocument={setLatestDocument}
          forwardedRecipients={forwardedRecipients}
          setForwardedRecipients={setForwardedRecipients}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      );
    },
    [isSubmitting, isSuccess, forwardedRecipients, handleSubmit, handleCancel]
  );

  return {
    isSubmitting,
    handler: show,
  };
};
