import {
  EMAIL_DUPLICATE_ERROR,
  EMAIL_FORMAT_INCORRECT_ERROR,
} from "constants/commonValidatorError";
import { REG_EMAIL_VALIDATOR } from "constants/emailValidatorKey";

import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import {
  isCurrentUserSenderAsRecipient,
  isEqualIgnoreCase,
} from "common/utils/common";
import { Colors } from "enums/colors";
import {
  getEmptyRecipientListIndex,
  RecipientListBased,
} from "features/documents/components/RecipientListBased";
import { RecipientInputFields } from "features/documents/pages/documentRequest/components/RecipientInputFields";
import {
  RecipientUpdateInfoProps,
  RecipientUpdateListFormValues,
} from "features/documents/pages/documentRequest/types";
import { RecipientUpdateCard } from "features/documents/pages/postDocumentRequest/components/RecipientUpdateCard";
import { Formik, useFormikContext } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentProps } from "types/document";
import * as yup from "yup";

export type RecipientsFormProps = {
  documentProps: DocumentProps;
  children: React.ReactNode;
};
const defaultUpdateRecipientInfo: RecipientUpdateInfoProps = {
  firstName: "",
  lastName: "",
  email: "",
};

export const RecipientUpdateForm = ({
  documentProps,
  children,
}: RecipientsFormProps): JSX.Element => {
  const { t } = useTranslation();
  const currentUserProfile = useCurrentUserProfile();
  const isCurrentUserSenderSameAsRecipient = useMemo(() => {
    return isCurrentUserSenderAsRecipient(documentProps, currentUserProfile);
  }, [currentUserProfile, documentProps]);
  const schema = yup.object().shape({
    recipientInfo: yup
      .array()
      .of(
        yup.object().shape({
          email: yup
            .string()
            .trim()
            .required()
            .test(
              "email-duplicate",
              t(EMAIL_DUPLICATE_ERROR),
              (value, context: any) => {
                const currentEmails: RecipientUpdateInfoProps[] =
                  context.from[context.from.length - 1].value.recipientInfo;
                const isDuplicateEmail =
                  currentEmails.filter((it) =>
                    isEqualIgnoreCase(it.email?.trim(), value?.trim())
                  ).length > 1;
                return !isDuplicateEmail;
              }
            )
            .test(
              "email-validate",
              t(EMAIL_FORMAT_INCORRECT_ERROR),
              (value) => {
                return REG_EMAIL_VALIDATOR.test(String(value));
              }
            )
            .test(
              "duplicate-sender-email",
              t(EMAIL_DUPLICATE_ERROR),
              (value) => {
                if (!isCurrentUserSenderSameAsRecipient) {
                  return !isEqualIgnoreCase(value, currentUserProfile?.email);
                }
                return true;
              }
            ),
          firstName: yup.string().trim().required(),
          lastName: yup.string().trim().required(),
        })
      )
      .required(),
  });
  const { values, setFieldValue } =
    useFormikContext<RecipientUpdateListFormValues>();
  const initialIsEditArray = useMemo(() => {
    const initialArray: boolean[] = Array(
      values.recipientInfo?.length ?? 1
    ).fill(false);
    const initialIsEditArray = [
      ...initialArray.map((item, index) => {
        return getEmptyRecipientListIndex(values).includes(index);
      }),
    ];
    return initialIsEditArray;
  }, [values]);
  const [isEditArray, setIsEditArray] = useState<boolean[]>(initialIsEditArray);
  const initialIsNewAddedRecipients = useMemo(() => {
    const initialArray: boolean[] = Array(
      values.recipientInfo?.length ?? 1
    ).fill(false);
    return initialArray;
  }, [values]);
  const [isNewAddedRecipients, setIsNewAddedRecipients] = useState<boolean[]>(
    initialIsNewAddedRecipients
  );

  const handleRecipientsFormSubmit = (
    values: RecipientUpdateListFormValues
  ) => {
    const emptyRecipient = getEmptyRecipientListIndex(values);
    setIsEditArray((preArray) => {
      return [
        ...preArray.map((item, i) => {
          return emptyRecipient.includes(i);
        }),
      ];
    });
    setFieldValue("recipientInfo", values.recipientInfo);
  };

  return (
    <Formik
      initialValues={{
        recipientInfo: values.recipientInfo ?? [defaultUpdateRecipientInfo],
      }}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleRecipientsFormSubmit}
    >
      {({ values }) => (
        <>
          <RecipientListBased
            value={values}
            isEditArray={isEditArray}
            setIsEditArray={setIsEditArray}
            afterClickAdd={() => {
              setIsNewAddedRecipients((preArray) => {
                return [...preArray.slice(), true];
              });
            }}
            customizedCard={(
              form,
              recipient,
              index,
              remove,
              handleClickEdit,
              handleClickRemove
            ) => {
              return (
                <RecipientUpdateCard
                  key={index}
                  currentColor={
                    values.recipientInfo[index].cardColor || Colors.LIGHT_GREY
                  }
                  recipientInfo={recipient}
                  isEdit={isEditArray[index]}
                  isDisplayRemoveButton={isNewAddedRecipients[index]}
                  onClickEdit={() => handleClickEdit(index, form)}
                  onClickRemove={() => handleClickRemove(index, remove)}
                >
                  <RecipientInputFields
                    name="recipientInfo"
                    index={index}
                    isEmailDisabled={!isNewAddedRecipients[index]}
                  />
                </RecipientUpdateCard>
              );
            }}
          >
            {children}
          </RecipientListBased>
        </>
      )}
    </Formik>
  );
};
