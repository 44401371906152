import { UserIcon, UserIconSize } from "common/components/UserIcon";
import { generateColorByString } from "enums/colors";
import { StyledPreviousUserIcon } from "features/documents/components/ForwardDocumentPopup/ForwardDocumentPopup";
import { useCallback, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { styled } from "twin.macro";

const StyledReactTooltip = styled(ReactTooltip)`
  border-radius: 8px !important;
`;

export const ForwardRecipientTooltip = ({ email }: { email: string }) => {
  useEffect(() => {
    // Some cases that the ReactTooltip not show when hover
    // rebuild it when component mounted to make sure it will show correctly
    ReactTooltip.rebuild();
  }, []);
  const FORWARD_RECIPIENT_EMAIL_ID = `forwardRecipientTip-${email}`;

  const generateColorByEmail = useCallback(
    (email: string) => generateColorByString(email),
    []
  );
  return (
    <div>
      <StyledPreviousUserIcon
        data-cy="forwarded-section"
        data-tip
        data-for={FORWARD_RECIPIENT_EMAIL_ID}
      >
        <UserIcon
          variant="initials"
          content={`${email.trim().substring(0, 1)} ${email
            .trim()
            .substring(1, 2)}`}
          className="mr-4"
          color={generateColorByEmail(email)}
          size={UserIconSize.SMALL}
        />
        <span className="truncate">{email}</span>
      </StyledPreviousUserIcon>
      <StyledReactTooltip
        id={FORWARD_RECIPIENT_EMAIL_ID}
        effect="solid"
        place="top"
        offset={{ left: 80 }}
      >
        <div className="w-[288px] break-words text-sm text-center">{email}</div>
      </StyledReactTooltip>
    </div>
  );
};
