import { SignaturePxProps } from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import _ from "lodash";

export type Rect = {
  x_px: number;
  y_px: number;
  width: number;
  height: number;
};

/**
 * With skipIndex, it will skip the check on specific index, eg: when skipping previous location
 */
export const isOverlaps = (
  signatures: SignaturePxProps[],
  rect: Rect,
  skipIndex?: number
): boolean => {
  return signatures.some((schema, index) => {
    if (skipIndex === index) {
      return false;
    }

    const isInHorizontalBounds =
      rect.x_px < schema.x_px + schema.width_px &&
      rect.x_px + rect.width > schema.x_px;
    const isInVerticalBounds =
      rect.y_px < schema.y_px + schema.height_px &&
      rect.y_px + rect.height > schema.y_px;

    return isInHorizontalBounds && isInVerticalBounds;
  });
};

export const isLastSignatureAssigned = (signatures: SignaturePxProps[]) => {
  return (
    signatures.length && signatures[signatures.length - 1].value !== undefined
  );
};

export const generateValidPosition = (
  rect: Rect,
  signatures: SignaturePxProps[],
  pageWidth: number,
  skipIndex?: number
): {
  x_px: number;
  y_px: number;
} => {
  let currX = rect.x_px;
  let currY = rect.y_px;
  while (
    isOverlaps(signatures, { ...rect, x_px: currX, y_px: currY }, skipIndex)
  ) {
    const plusOrMinus = Math.random() < 0.5 ? -1 : 1;
    currX += 50 * plusOrMinus;
    currY += 100 * plusOrMinus;

    // fix x and y left and right bounds
    if (currX < 0) {
      currX = 0;
    }
    if (pageWidth < currX + rect.width) {
      currX = pageWidth - rect.width;
    }
    if (currY < 0) {
      currY = 0;
    }
  }

  return { x_px: currX, y_px: currY };
};

export const handleSignatureSigningApply = (
  inputRecord: Record<string, string>,
  signaturePxValue: SignaturePxProps[],
  currentUserEmail: string,
  targetSchemaId: string,
  imageData: string,
  inputValue: string,
  isApplyToAll: boolean
) => {
  const newSignatures = _.cloneDeep(signaturePxValue);
  const newInputValue = _.cloneDeep(inputRecord);
  if (isApplyToAll) {
    newSignatures.forEach((currSignature) => {
      if (currSignature.value?.email === currentUserEmail) {
        currSignature.imageSrc = imageData;
        newInputValue[currSignature.id ?? ""] = inputValue;
      }
    });
  } else {
    const currentIndex = newSignatures.findIndex(
      (signature) => signature.id === targetSchemaId
    );
    if (currentIndex >= 0 && currentIndex < signaturePxValue.length) {
      newSignatures[currentIndex].imageSrc = imageData;
      newInputValue[targetSchemaId] = inputValue;
    }
  }

  return {
    newSignatures,
    newInputValue,
  };
};
