import {
  A4_PAGE_WIDTH,
  PageDimensions,
  PDFSignatureBased,
  PDFSignatureBasedRefBased,
  SignaturePxProps,
} from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import {
  generateRandomInteger,
  removeElementWithinArray,
  updateElementWithinArray,
} from "common/utils/common";
import { SignatureCreateRndHandler } from "features/documents/pages/documentRequest/components/SignatureCreateRndHandler";
import { usePDFSignatureControl } from "features/documents/pages/documentRequest/hooks/usePDFSignatureControl";
import {
  isLastSignatureAssigned,
  generateValidPosition,
  isOverlaps,
  handleSignatureSigningApply,
} from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/PDFSignatureCreateUtils";
import {
  PDFFile,
  RecipientInfoProps,
} from "features/documents/pages/documentRequest/types";
import { useGenerateSignatureImageModalHandler } from "features/documents/pages/postDocumentRequest/pages/signing/hooks/useGenerateSignatureImageModalHandler";
import _ from "lodash";
import React, {
  createContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { SignatureSchemas } from "types/document";
import { v4 as uuidv4 } from "uuid";

// https://github.com/bvaughn/react-window/issues/404

type PDFSignatureCreateContextProps = {
  handleSelectRecipient: (index: number, recipient: RecipientInfoProps) => void;
  handleRemove: (index: number) => void;
  handleSize: (index: number, width: number, height: number) => void;
  handlePosition: (index: number, x: number, y: number) => void;
  signatures: SignaturePxProps[];
  scale: number;
  recipients: RecipientInfoProps[];
  isPreview: boolean;
  isSignatureDisplay: boolean;
  pageWidth: number;
  onSignatureClick: (schema: SignatureSchemas) => void;
};
export const PDFSignatureCreateContext =
  createContext<PDFSignatureCreateContextProps>({
    handleSelectRecipient: () => {},
    handleRemove: () => {},
    handleSize: () => {},
    handlePosition: () => {},
    signatures: [],
    scale: 1,
    recipients: [],
    isPreview: false,
    isSignatureDisplay: false,
    pageWidth: 1,
    onSignatureClick: () => {},
  });

export type PDFSignatureCreateProps = {
  className?: string;
  selectedFile: PDFFile;
  recipients: RecipientInfoProps[];
  isPreview: boolean;
  isSignatureDisplay: boolean;
};
export type PDFSignatureCreateRefProps = {
  addSignature: () => void;
  scrollToOffset: (offset: number) => void;
};

export const PDFSignatureCreate = React.forwardRef<
  PDFSignatureCreateRefProps,
  PDFSignatureCreateProps
>(
  (
    { className, selectedFile, recipients, isPreview, isSignatureDisplay },
    ref
  ): JSX.Element => {
    const currentUserProfile = useCurrentUserProfile();
    const [inputRecord, setInputValues] = useState<Record<string, string>>({});
    const [pageDimensions, setPageDimensions] = useState<PageDimensions>();
    const { currSignature, pageWidth, setPageWidth, updateFormValue } =
      usePDFSignatureControl();
    // PDF preview
    const [scale, setScale] = useState<number>(1);
    const pdfRef = useRef<PDFSignatureBasedRefBased | null>();

    const setCurrSignaturesValue = (newValue: SignaturePxProps[]): void => {
      updateFormValue(newValue);
    };

    const pageRatio = pageWidth / A4_PAGE_WIDTH;

    const handleAddSignature = () => {
      const lastSignatureIndex = currSignature.length - 1;
      const x_px = generateRandomInteger();
      const y_px =
        (pdfRef?.current?.targetOffset ?? 0) / scale + generateRandomInteger();
      if (
        currSignature.length === 0 ||
        isLastSignatureAssigned(currSignature)
      ) {
        addSignatureBox(x_px, y_px);
      } else {
        handlePosition(lastSignatureIndex, x_px, y_px);
      }
    };

    const addSignatureBox = (x_px: number, y_px: number) => {
      const adjustFactor = pageRatio * scale;

      const width = 255 * adjustFactor;
      const height = 60 * adjustFactor;
      const validPos = generateValidPosition(
        {
          x_px,
          y_px,
          width,
          height,
        },
        currSignature,
        pageWidth
      );

      setCurrSignaturesValue([
        ...currSignature,
        {
          value: undefined,
          width_px: width,
          height_px: height,
          x_px: validPos.x_px,
          y_px: validPos.y_px,
          id: uuidv4(),
        },
      ]);
    };

    useImperativeHandle(ref, () => ({
      addSignature: () => {
        handleAddSignature();
      },
      scrollToOffset: (offset: number) => {
        pdfRef.current?.scrollToOffset(offset);
      },
    }));

    const handlePosition = (index: number, x_px: number, y_px: number) => {
      const validPos = generateValidPosition(
        {
          x_px,
          y_px,
          width: currSignature[index].width_px,
          height: currSignature[index].height_px,
        },
        currSignature,
        pageWidth,
        index
      );

      const newValue = updateElementWithinArray(
        currSignature,
        {
          ...currSignature[index],
          x_px: validPos.x_px,
          y_px: validPos.y_px,
        },
        index
      );

      setCurrSignaturesValue(newValue);
    };

    const handleSize = (index: number, width: number, height: number) => {
      const isOverlap = isOverlaps(
        currSignature,
        {
          x_px: currSignature[index].x_px,
          y_px: currSignature[index].y_px,
          height,
          width,
        },
        index
      );

      if (!isOverlap) {
        const newValue = updateElementWithinArray(
          currSignature,
          {
            ...currSignature[index],
            width_px: width,
            height_px: height,
          },
          index
        );

        setCurrSignaturesValue(newValue);
      }
    };

    const handleRemove = (index: number) => {
      const newValue = removeElementWithinArray(currSignature, index);
      setCurrSignaturesValue(newValue);
    };

    const handleSelectRecipient = (
      index: number,
      recipient: RecipientInfoProps
    ) => {
      const newValue = updateElementWithinArray(
        currSignature,
        {
          ...currSignature[index],
          value: recipient,
          imageSrc: undefined, // clean image when select recipient
        },
        index
      );
      setCurrSignaturesValue(newValue);

      // clean input
      const newInputValue = _.cloneDeep(inputRecord);
      delete newInputValue[currSignature[index].id ?? ""];
      setInputValues(newInputValue);
    };

    const { showModal } = useGenerateSignatureImageModalHandler({
      fileName: selectedFile.name,
      onSignatureApply: (schema, imageData, isApplyToAll, inputValue) => {
        const { newInputValue, newSignatures } = handleSignatureSigningApply(
          inputRecord,
          currSignature,
          currentUserProfile?.email ?? "",
          schema.id,
          imageData,
          inputValue,
          isApplyToAll
        );
        setCurrSignaturesValue(newSignatures);
        setInputValues(newInputValue);
      },
    });

    const handleSignatureClick = (schema: SignatureSchemas) => {
      const isCurrSignaturesEmpty = currSignature.length === 0;
      const inputValue = isCurrSignaturesEmpty
        ? ""
        : inputRecord[schema.id ?? ""] ?? "";
      showModal({ schema, inputValue });
    };

    //  inspired from https://github.com/michaeldzjap/react-pdf-sample/blob/master/src/Viewer.js
    return (
      <div className={className}>
        <PDFSignatureCreateContext.Provider
          value={{
            signatures: currSignature,
            handlePosition,
            handleRemove,
            handleSize,
            scale,
            recipients,
            handleSelectRecipient,
            isPreview,
            isSignatureDisplay,
            pageWidth,
            onSignatureClick: handleSignatureClick,
          }}
        >
          <PDFSignatureBased
            ref={(el) => (pdfRef.current = el)}
            selectedFile={selectedFile}
            rndHandler={SignatureCreateRndHandler}
            scale={scale}
            setScale={setScale}
            pageDimensions={pageDimensions}
            setPageDimensions={setPageDimensions}
            setPageWidth={setPageWidth}
            isLoaderShowByDefault={false}
          />
        </PDFSignatureCreateContext.Provider>
      </div>
    );
  }
);
