import { LatestDocumentsContext } from "common/context/LatestDocumentsContext";
import { BreadCrumbItemProps } from "features/documents/components/FolderManagement/DashboardBreadcrumbs";
import { Dispatch, SetStateAction, useContext } from "react";

export const useDashboardBreadcrumbs = (): {
  dashboardBreadcrumbsItems: BreadCrumbItemProps[];
  setDashboardBreadcrumbsItems: Dispatch<SetStateAction<BreadCrumbItemProps[]>>;
} => {
  const { dashboardBreadcrumbsItems, setDashboardBreadcrumbsItems } =
    useContext(LatestDocumentsContext);
  return { dashboardBreadcrumbsItems, setDashboardBreadcrumbsItems };
};
