import { Card } from "common/components/Card";
import { UserIcon } from "common/components/UserIcon";
import { generateFullName } from "common/utils/common";
import { generateColorByString } from "enums/colors";
import { RecipientInfoProps } from "features/documents/pages/documentRequest/types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";

type RecipientPopoverProps = {
  recipients: RecipientInfoProps[];
  onSelectRecipient: (recipient: RecipientInfoProps) => void;
};

export const RecipientPopover = ({
  recipients,
  onSelectRecipient,
}: RecipientPopoverProps) => {
  const { t } = useTranslation();
  const generateColorByEmail = useCallback(
    (email: string) => generateColorByString(email),
    []
  );
  return (
    <StyledCard>
      <div className="w-full text-dark-grey text-xs font-medium">
        {t("docRequest.assignRecipients")}
      </div>
      <div className="overflow-y-auto w-full">
        {recipients.map((recipient, index) => {
          const fullName = generateFullName(
            recipient.firstName,
            recipient.lastName
          );
          return (
            <ItemWrapper
              key={`recipient_${index}`}
              onClick={() => {
                onSelectRecipient(recipient);
              }}
              data-cy={`recipient-popup${index}`}
            >
              <div className="w-8 mr-3">
                <UserIcon
                  variant={"initials"}
                  content={fullName}
                  color={generateColorByEmail(recipient.email)}
                />
              </div>
              <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                {fullName}
              </div>
            </ItemWrapper>
          );
        })}
      </div>
    </StyledCard>
  );
};

const ItemWrapper = styled.div(() => [
  tw`flex items-center cursor-pointer rounded p-1`,
  tw`text-sm font-medium text-dark-blue`,
  tw`hover:bg-pale-blue`,
]);

const StyledCard = tw(
  Card
)`relative flex flex-col flex-nowrap space-y-3 max-h-56 bg-white p-4 `;
