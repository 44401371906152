import { Button } from "common/components/Button";
import { Loader } from "common/components/Loader";
import { Modal } from "common/components/Modal";
import { SvgFileCancel } from "common/components/svg";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

const StyledPopupContentWrapper = tw.div`flex flex-col items-center justify-between h-44`;
const StyledPopUpTitle = tw.div`font-medium text-base leading-[22px] text-center`;
const StyledPopupDescription = tw.p`font-medium text-dark-grey text-xs`;
const StyledButtonsWrapper = tw.div`flex flex-wrap mt-4`;

type CancelDocumentRequestModalProps = {
  isOpen: boolean;
  isSubmitting: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const CancelDocumentActionsModal = ({
  isOpen,
  isSubmitting,
  onCancel,
  onConfirm,
}: CancelDocumentRequestModalProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen} onClose={onCancel} size="emd">
      <Loader isLoading={isSubmitting} overlay>
        <StyledPopupContentWrapper>
          <SvgFileCancel width={40} height={40} className="text-light-blue" />
          <StyledPopUpTitle>
            {t("dashboard.cancelDocumentAction.title")}
          </StyledPopUpTitle>
          <StyledPopupDescription>
            {t("dashboard.cancelDocumentAction.titleHint")}
          </StyledPopupDescription>
        </StyledPopupContentWrapper>
        <StyledButtonsWrapper>
          <Button
            variant="primary"
            className="w-full mb-4"
            disabled={isSubmitting}
            onClick={onConfirm}
          >
            {t("common.confirm")}
          </Button>
          <Button
            variant="secondary"
            className="text-light-blue w-full"
            onClick={onCancel}
          >
            {t("common.cancel")}
          </Button>
        </StyledButtonsWrapper>
      </Loader>
    </Modal>
  );
};
