import { RecipientFormValues } from "features/documents/pages/documentRequest/components/RecipientInputFields";
import { useFormikContext } from "formik";
import { useEffect } from "react";

export const useSubmitOnRecipientBlur = (
  isBlur: boolean,
  setIsBlur: (value: boolean) => void,
  submitOnBlur?: object
): void => {
  const { submitForm, isValid } = useFormikContext<RecipientFormValues>();

  useEffect(() => {
    if (isBlur) {
      submitForm();
    }
  }, [submitForm, isBlur, submitOnBlur]);

  useEffect(() => {
    if (!isValid) {
      setIsBlur(false);
    }
  }, [isValid, setIsBlur]);
};
