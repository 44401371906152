import { useModal } from "@liholiho/react-modal-hook";
import { composeErrorHandlers, GenericError } from "common/errorHandling";
import { CancelDocumentActionsModal } from "features/documents/pages/postDocumentRequest/components/CancelDocumentActions";
import { useState } from "react";
import { SubmissionHandler } from "types/common";

export const useCancelDocumentActionsHandler = (
  onSuccess?: () => void,
  onCancel?: () => void
): SubmissionHandler => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [show, hide] = useModal(
    ({ in: isOpen }) => {
      const handleConfirm = async () => {
        setSubmitting(true);
        try {
          hide();
          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          composeErrorHandlers()(e as GenericError);
        }
        setSubmitting(false);
      };

      return (
        <CancelDocumentActionsModal
          isOpen={isOpen}
          isSubmitting={isSubmitting}
          onConfirm={handleConfirm}
          onCancel={() => {
            if (onCancel) {
              onCancel();
            }
            hide();
          }}
        />
      );
    },
    [onSuccess, document, isSubmitting]
  );

  return {
    isSubmitting,
    handler: show,
  };
};
