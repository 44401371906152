import { Card } from "common/components/Card";
import { SvgX } from "common/components/svg";
import SvgEdit from "common/components/svg/Edit";
import { Colors } from "enums/colors";
import { RecipientCardInformation } from "features/documents/pages/documentRequest/components/RecipientInformation";
import { useSubmitOnRecipientBlur } from "features/documents/pages/documentRequest/hooks/useSubmitOnRecipientBlur";
import { AddRecipientId } from "features/documents/pages/documentRequest/pages/AddRecipients/components/AddRecipientButton";
import { RecipientInfoProps } from "features/documents/pages/documentRequest/types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type RecipientUpdateCardProps = {
  currentColor: Colors;
  recipientInfo?: RecipientInfoProps;
  children?: React.ReactNode;
  className?: string;
  isEdit?: boolean;
  isDisplayRemoveButton?: boolean;
  onClickEdit?: () => void;
  onClickRemove?: () => void;
};

export const StyledCard = tw(
  Card
)`px-3 py-3 mb-2 items-center w-full border border-solid border-light-greyscale`;
const StyledPreviewCard = tw.div`flex flex-wrap items-center w-full`;
const LeftBorder = tw.div`absolute h-full w-1 rounded-lg`;
const StyleCardContent = tw.div`ml-3 font-medium`;

export const RecipientUpdateCard = ({
  className,
  recipientInfo = {
    firstName: "",
    lastName: "",
    email: "",
  },
  currentColor,
  children,
  isEdit,
  isDisplayRemoveButton,
  onClickEdit,
  onClickRemove,
}: RecipientUpdateCardProps) => {
  const { t } = useTranslation();
  const [isOnBlur, setIsOnBlur] = useState<boolean>(false);
  const [submitOnBlur, setSubmitOnBlur] = useState({});
  useSubmitOnRecipientBlur(isOnBlur, setIsOnBlur, submitOnBlur);

  // Reference: https://stackoverflow.com/questions/12092261/prevent-firing-the-blur-event-if-any-one-of-its-children-receives-focus
  const handleRecipientCardBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (
      !(
        event.currentTarget.contains(event.relatedTarget) ||
        event.relatedTarget?.id === AddRecipientId
      )
    ) {
      setIsOnBlur(true);
      setSubmitOnBlur({});
    }
  };

  return (
    <StyledCard
      onBlur={(event) => handleRecipientCardBlur(event)}
      isShadow={false}
    >
      {isEdit ? (
        <div className="flex relative">
          <LeftBorder className={`bg-${currentColor} `} />
          <StyleCardContent>
            <div className="flex justify-between items-center text-xs">
              <p>{t("docRequest.addRecipient.cardTitle")}</p>
              {isDisplayRemoveButton && (
                <SvgX
                  className="ml-5 text-grey cursor-pointer"
                  onClick={onClickRemove}
                />
              )}
            </div>
            {children}
          </StyleCardContent>
        </div>
      ) : (
        <StyledPreviewCard>
          <RecipientCardInformation
            className={"basis-3/4 min-w-0"}
            recipientInfo={recipientInfo}
            currentColor={currentColor}
          />
          {/* TODO: handle auto focus on other ticket*/}

          <div className="flex-1 flex justify-end">
            <SvgEdit
              className=" text-grey cursor-pointer"
              onClick={() => {
                setIsOnBlur(false);
                onClickEdit && onClickEdit();
              }}
            />
            {isDisplayRemoveButton && (
              <SvgX
                className="ml-5 text-grey cursor-pointer"
                onMouseDown={onClickRemove}
              />
            )}
          </div>
        </StyledPreviewCard>
      )}
    </StyledCard>
  );
};
