import { useModal } from "@liholiho/react-modal-hook";
import { documentAPI } from "api/document";
import { folderAPI } from "api/folder";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import { toast, ToastContainerId } from "common/toast";
import { DeleteDashboardItem } from "features/documents/pages/documentManagement/components/DeleteDocumentPopup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DashboardResponse, SubmissionHandler } from "types/common";

/* Folder Management */
export const useDeleteDashboardItemHandler = (
  dashboardItem: DashboardResponse,
  onSuccess?: () => void
): SubmissionHandler => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { t } = useTranslation();

  const [show, hide] = useModal(
    ({ in: isOpen }) => {
      const handleConfirm = async () => {
        setSubmitting(true);
        try {
          dashboardItem.isFolder
            ? await folderAPI.deleteArchive(dashboardItem.id)
            : await documentAPI.deleteArchive(dashboardItem.id);
          hide();
          toast.success(
            dashboardItem.isFolder
              ? t("common.toast.moveFolderToBin", {
                  folderName: dashboardItem.folderName,
                })
              : t("common.toast.moveDocumentToBin", {
                  docName: dashboardItem.documentName,
                })
          );
          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          composeErrorHandlers(() =>
            baseFallbackErrorHandler(ToastContainerId.AfterLogin)
          )(e as GenericError);
        }
        setSubmitting(false);
      };

      return (
        <DeleteDashboardItem
          dashboardItem={dashboardItem}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
          onConfirm={handleConfirm}
          onCancel={hide}
        />
      );
    },
    [onSuccess, document, isSubmitting]
  );

  return {
    isSubmitting,
    handler: show,
  };
};
