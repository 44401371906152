import * as React from "react";
import { SVGProps } from "react";

const SvgRejectDetail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.47196 9.13798L8.00063 7.60931L9.5293 9.13798L10.472 8.19531L8.9433 6.66665L10.472 5.13798L9.5293 4.19531L8.00063 5.72398L6.47196 4.19531L5.5293 5.13798L7.05796 6.66665L5.5293 8.19531L6.47196 9.13798Z"
      fill="#00A3FF"
    />
    <path
      d="M13.334 1.33331H2.66732C1.93198 1.33331 1.33398 1.93131 1.33398 2.66665V14.6666L4.88932 12H13.334C14.0693 12 14.6673 11.402 14.6673 10.6666V2.66665C14.6673 1.93131 14.0693 1.33331 13.334 1.33331ZM13.334 10.6666H4.44532L2.66732 12V2.66665H13.334V10.6666Z"
      fill="#00A3FF"
    />
  </svg>
);

export default SvgRejectDetail;
