import { AxiosRequestConfig, Method } from "axios";
import { request, TokenResponse } from "common/HOC/WithRequest";

export type App = {
  app: { id: string; appName: ApplicationName };
  enabled: boolean;
}[];

export enum ApplicationName {
  AtlasElite = "ATLAS ELITE",
  KentroSign = "Kentro Sign",
  Midas = "MIDAS",
  Topaz = "TOPAZ",
}

const ONE_NETWORK_REQUEST_BASE_URL =
  process.env.REACT_APP_ONE_NETWORK_DEV_REQUEST_BASE_URL ??
  process.env.REACT_APP_KENTRO_ONE_NETWORK_URL;

const oneNetworkRequest = <RES, REQ = undefined>(
  url: string,
  method: Method = "GET",
  data?: REQ,
  config?: AxiosRequestConfig<REQ>
): Promise<RES> => {
  return request(url, method, data, {
    ...config,
    baseURL: `${ONE_NETWORK_REQUEST_BASE_URL}/api/v1/`,
  });
};
export const oneNetworkAPI = {
  refreshToken: async (refreshToken: string) =>
    oneNetworkRequest<TokenResponse>("auth/token/refresh", "POST", undefined, {
      headers: {
        "refresh-token": refreshToken,
      },
    }),
  // TODO Need pagination in the future
  appList: async () =>
    oneNetworkRequest<{ content: App }>(
      "dashboard/apps?pageNum=0&pageSize=10&pageNum=0",
      "GET",
      undefined
    ),
};
