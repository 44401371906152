import { FILE_TYPE, MAX_FILE_SIZE } from "constants/file";

import classNames from "classnames";
import { Message } from "common/components/Message";
import { transformFileTypeToMIMEType } from "common/utils/common";
import {
  FILES_CAN_NOT_UPLOAD,
  FILE_SIZE_EXCEED_ERROR,
  FILE_TYPE_INVALID_ERROR,
  TOO_MANY_FILES_ERROR,
} from "features/documents/pages/documentRequest/pages/UploadDocument/utils/common";
import { t } from "i18next";
import { useCallback, useState } from "react";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

const DropzoneContainer = tw.div`h-full border-2 border-dashed flex flex-col items-center justify-center`;

type DropzoneProps = {
  className?: string;
  onChange: (files: File[]) => void;
  children?: (open: () => void) => React.ReactNode;
  acceptFileType?: FILE_TYPE | FILE_TYPE[];
  errorMsgPosition?: "under" | "inner";
  fieldError?: string;
  setFieldError: (error?: string) => void;
  isDisabled?: boolean;
};

export const Dropzone = ({
  className,
  onChange,
  fieldError,
  setFieldError,
  children,
  isDisabled = false,
  acceptFileType = FILE_TYPE.PDF,
  errorMsgPosition = "inner",
}: DropzoneProps): JSX.Element => {
  const [error, setError] = useState<string | null>();
  const [typeError, setTypeError] = useState<string | null>();
  const { t } = useTranslation();

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setError(null);
      setTypeError(null);
      setFieldError();
      if (rejectedFiles.length > 0) {
        const rejectedErrors = rejectedFiles.map((it) => it.errors[0].code);
        let errorInfo;
        switch (true) {
          case rejectedErrors.includes(ErrorCode.TooManyFiles):
            setError(t(TOO_MANY_FILES_ERROR));
            break;

          case rejectedErrors.includes(ErrorCode.FileInvalidType):
            errorInfo = getErrorInfoByErrorCode(
              ErrorCode.FileInvalidType,
              rejectedFiles,
              acceptFileType
            );
            setError(errorInfo.errorMessage);
            setTypeError(errorInfo.errorTypeMessage);
            break;

          case rejectedErrors.includes(ErrorCode.FileTooLarge):
            errorInfo = getErrorInfoByErrorCode(
              ErrorCode.FileTooLarge,
              rejectedFiles,
              acceptFileType
            );
            setError(errorInfo.errorMessage);
            setTypeError(errorInfo.errorTypeMessage);
            break;
        }
      }
      if (acceptedFiles.length > 0) {
        onChange(acceptedFiles);
      }
    },
    [setFieldError, acceptFileType, onChange, t]
  );
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: transformFileTypeToMIMEType(acceptFileType),
    maxSize: MAX_FILE_SIZE,
    onDrop,
    noClick: true,
    noKeyboard: true,
    disabled: isDisabled,
  });

  return (
    <>
      <DropzoneContainer
        {...getRootProps({
          role: "div",
        })}
        className={classNames(className, {
          "bg-error-red bg-opacity-10 border-light-red": !!error,
          "bg-white border-grey": !error,
        })}
      >
        <input
          disabled={isDisabled}
          {...getInputProps()}
          data-cy="input-file"
        />
        {children && children(open)}
        {error && errorMsgPosition !== "under" && (
          <div className="text-xs font-medium">
            <Message
              variant="warning"
              className="mt-8"
              isBackgroundEnable={false}
            >
              {error}
            </Message>
            <div className="text-center">
              <span className="text-light-red">
                {t("common.error")}:{typeError}
              </span>
            </div>
          </div>
        )}
      </DropzoneContainer>
      {error && errorMsgPosition === "under" && (
        <div className="text-error-red text-xs font-medium mt-1">
          {typeError}
        </div>
      )}
    </>
  );
};

export const getErrorInfoByErrorCode = (
  errorCode: ErrorCode,
  rejectedFiles: FileRejection[],
  acceptFileType: FILE_TYPE | FILE_TYPE[]
) => {
  const rejectedFilesNames = rejectedFiles
    .filter((it) => it.errors[0].code === errorCode)
    .map((it) => it.file.name)
    .join(" , ");

  const acceptFileTypeText =
    typeof acceptFileType === "string" ? acceptFileType : acceptFileType.join();

  const errorTypeMessage =
    errorCode === ErrorCode.FileInvalidType
      ? t(FILE_TYPE_INVALID_ERROR, { fileType: acceptFileTypeText })
      : t(FILE_SIZE_EXCEED_ERROR);
  const errorMessage = t(FILES_CAN_NOT_UPLOAD, {
    filesName: rejectedFilesNames,
  });
  return {
    errorMessage,
    errorTypeMessage,
  };
};
