import { Colors } from "enums/colors";
import { PanelState } from "features/documents/pages/documentRequest/context/PDFSignatureControlContext";
import { RecipientInfoProps } from "features/documents/pages/documentRequest/types";
import { createContext } from "react";

type SignatureBoxContextProps = {
  color: Colors;
  recipients: RecipientInfoProps[];
  width: number;
  fullName: string;
  email: string;
  imageSrc: string | undefined;
  isGradientBorderShow?: boolean;
  panelState?: PanelState;
  scale: number;
};

export const SignatureBoxContext = createContext<SignatureBoxContextProps>({
  color: Colors.LIGHT_BLUE,
  recipients: [],
  width: 0,
  fullName: "",
  email: "",
  imageSrc: undefined,
  isGradientBorderShow: false,
  panelState: PanelState.ADD_SIGNATURE,
  scale: 1,
});
