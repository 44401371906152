import { Button } from "common/components/Button";
import {
  Dimension,
  parseToPageDimensions,
} from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { Modal } from "common/components/Modal";
import { SvgCrossCircle } from "common/components/svg";
import { parseToDimensionList } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/utils/PDFSignatureUtils";
import { PDFFile } from "features/documents/pages/documentRequest/types";
import { cloneDeep } from "lodash";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf";
import tw from "twin.macro";

type PDFConfirmModalProps = {
  isOpen: boolean;
  hideModal: () => void;
  files: PDFFile[];
  onAllLoadSuccess: (dimensions: Dimension[][]) => void;
};
export const PDFConfirmModal = ({
  isOpen,
  hideModal,
  files,
  onAllLoadSuccess,
}: PDFConfirmModalProps): JSX.Element => {
  const [invalidFileIndex, setInvalidFileIndex] = useState<number>();
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const handleAnyLoadError = useCallback((error: Error, fileIndex: number) => {
    console.error(error);
    setInvalidFileIndex(fileIndex);
    setIsInvalid(true);
  }, []);
  const { t } = useTranslation();

  const handleResetAndClose = () => {
    hideModal();
    setInvalidFileIndex(undefined);
    setIsInvalid(false);
  };

  // wrap in useCallback to prevent issue in PDFMockPreview
  const handleOnAllLoadSuccess = useCallback(
    (dimensions: Dimension[][]) => {
      hideModal();
      onAllLoadSuccess(dimensions);
    },
    [hideModal, onAllLoadSuccess]
  );
  return (
    <Modal open={isOpen}>
      <div className="flex justify-center">
        {isInvalid && invalidFileIndex !== undefined ? (
          <StyledErrorModal>
            <SvgCrossCircle
              className="text-light-blue"
              width={40}
              height={40}
            />
            <StyledErrorMessage>
              <div className="w-full text-dark-blue text-base">
                <span>{t("docRequest.uploadConfirm.title")}</span>
                <div className="flex justify-center">
                  <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                    "{files?.[invalidFileIndex]?.name}
                  </div>
                  <div className="min-w-[5px]">"</div>
                </div>
              </div>
              <div className="text-dark-grey text-sm text-center">
                {t("docRequest.uploadConfirm.titleHint")}
              </div>
            </StyledErrorMessage>
            <Button
              onClick={handleResetAndClose}
              type="button"
              variant="primary"
              className="w-full"
            >
              {t("common.confirm")}
            </Button>
          </StyledErrorModal>
        ) : (
          <LoadingSpinner />
        )}

        <PDFMockPreview
          files={files}
          onAnyLoadError={handleAnyLoadError}
          onAllLoadSuccess={handleOnAllLoadSuccess}
        />
      </div>
    </Modal>
  );
};

const StyledErrorModal = tw.div`flex flex-col space-y-10 items-center justify-center w-full`;
const StyledErrorMessage = tw.div`flex flex-col items-center justify-center text-center space-y-3 font-medium w-full`;
type PDFMockPreviewProps = {
  files: PDFFile[];
  onAnyLoadError: (error: Error, fileIndex: number) => void;
  onAllLoadSuccess: (dimensions: Dimension[][]) => void;
};

const PDFMockPreview = ({
  files,
  onAnyLoadError,
  onAllLoadSuccess,
}: PDFMockPreviewProps) => {
  const [fileIndex, setFileIndex] = useState<number>(0);
  const [dimensions, setDimensions] = useState<Dimension[][]>([]);
  const handlePageDimensions = async (pdf: PDFDocumentProxy) => {
    const pageDimensions = await parseToPageDimensions(pdf);
    const dimensionList = parseToDimensionList(pageDimensions);
    setDimensions((prev) => {
      const newVal = cloneDeep(prev);
      newVal.push(dimensionList);
      return newVal;
    });
  };

  const handleLoadSuccess = async (pdf: PDFDocumentProxy) => {
    if (fileIndex < files.length) {
      await handlePageDimensions(pdf);
      setFileIndex((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (fileIndex === files.length && dimensions.length === files.length) {
      onAllLoadSuccess(dimensions);
    }
  }, [dimensions, fileIndex, files.length, onAllLoadSuccess]);

  const currFile = files?.[fileIndex]?.URL;

  return (
    <div className={"hidden"}>
      {currFile && (
        <Document
          file={currFile}
          onLoadSuccess={handleLoadSuccess}
          onLoadError={(error) => onAnyLoadError(error, fileIndex)}
        >
          <Page height={0} pageNumber={1} renderAnnotationLayer={false} />
        </Document>
      )}
    </div>
  );
};
