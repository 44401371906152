import * as React from "react";
import { SVGProps } from "react";

const SvgInformation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.688 2.5a7.188 7.188 0 1 0 0 14.376 7.188 7.188 0 0 0 0-14.376Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path d="M8.594 8.594h1.25v4.531" fill="#fff" />
    <path
      d="M8.594 8.594h1.25v4.531"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.125 13.281h3.438"
      stroke="#fff"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M9.54 5.873a.266.266 0 1 1 .295.442.266.266 0 0 1-.295-.442Z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgInformation;
