import { SignaturePercentageProps } from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { useDocumentRequest } from "common/hooks/useDocumentRequest";
import {
  setRolePermission,
  uploadSignatures,
} from "features/documents/pages/documentRequest/context/DocumentRequestActions";
import { ChooseRecipientsRole } from "features/documents/pages/documentRequest/pages/AddRolePermission/components/ChooseRecipientsRole";
import {
  RecipientInfoProps,
  RecipientRolesProps,
  SettingRolePermissionFormValue,
} from "features/documents/pages/documentRequest/types";
import { Form, Formik } from "formik";
import { isEqual } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormSubmissionHandler } from "types/common";
import { DocumentRole } from "types/document";
import * as yup from "yup";

const noAssignedRolePerRecipientError =
  "docRequest.message.noAssignedRolePerRecipient";
const invalidRolesAssignedError = "docRequest.message.invalidRolesAssigned";
export type AddRolePermissionProps = {
  onNext: () => void;
  onBack: () => void;
};

export const AddRolePermission = ({
  onNext,
  onBack,
}: AddRolePermissionProps) => {
  const { dispatch, recipients, rolePermission, signatures } =
    useDocumentRequest();
  const { t } = useTranslation();

  const [invalidRecipients, setInvalidRecipients] = useState<string[]>([]);
  const handleSubmitForm: FormSubmissionHandler<
    SettingRolePermissionFormValue
  > = async (rolePermission) => {
    // Submit when all recipient were set role
    if (
      dispatch &&
      recipients.recipientInfo.length === rolePermission.recipientRoles.length
    ) {
      dispatch(setRolePermission(rolePermission));
      // remove signatures without signer role
      const validSignatures = filterSignaturesByRecipientWithSignerRole(
        rolePermission,
        signatures
      );
      dispatch(uploadSignatures({ signatures: validSignatures }));
      onNext();
    }
  };

  /** If the page Set Roles & Permissions has been submitted once,
  the user click the back button again to return to the page Add Recipients for editing
  (it may change the original card information),
  and click next to go back to the page Set Roles & Permissions again,
  it must be determined whether the old value stored before(rolePermission.recipientRoles) is equal to the latest value now(recipients.recipientInfo)
  If not equal, the old value cannot be used**/
  const initialRecipientRoles: RecipientRolesProps[] = useMemo(() => {
    return generateInitialRecipientRole(
      recipients.recipientInfo,
      rolePermission.recipientRoles
    );
  }, [recipients.recipientInfo, rolePermission.recipientRoles]);

  const initialValues = {
    recipientRoles: initialRecipientRoles,
  };

  const schema: yup.SchemaOf<SettingRolePermissionFormValue> = yup
    .object()
    .shape({
      recipientRoles: yup
        .array()
        .required()
        .of(
          yup.object().shape({
            recipient: yup.object().shape({
              firstName: yup.string().required(),
              lastName: yup.string().required(),
              email: yup.string().required(),
              company: yup.string(),
              title: yup.string(),
            }),
            roles: yup.array(),
            canDownload: yup.bool().required(),
          })
        )
        .test(
          "no-roles-assigned",
          t(noAssignedRolePerRecipientError),
          (values) => {
            const newInvalidRecipients: string[] = [];
            values?.forEach((it) => {
              const isNoRoles =
                it.roles === undefined || it?.roles?.length === 0;
              if (it.recipient.email && isNoRoles) {
                newInvalidRecipients.push(it.recipient.email);
              }
            });
            setInvalidRecipients(newInvalidRecipients);

            return newInvalidRecipients.length === 0;
          }
        )
        .test(
          "invalid-roles-assigned",
          t(invalidRolesAssignedError),
          (values) => {
            const allCurrentRoles = values?.map((it) => it.roles).flat();
            const validRolesAssigned = allCurrentRoles?.some(
              (it) => it !== DocumentRole.VIEWER
            );
            return !!validRolesAssigned;
          }
        ),
    });

  return (
    <Formik
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmitForm}
      initialValues={initialValues}
    >
      <Form autoComplete="off" noValidate className="w-full h-full">
        <ChooseRecipientsRole
          onBack={onBack}
          recipients={recipients.recipientInfo}
          invalidRecipients={invalidRecipients}
        />
      </Form>
    </Formik>
  );
};

export const filterSignaturesByRecipientWithSignerRole = (
  rolePermission: SettingRolePermissionFormValue,
  signatures: SignaturePercentageProps[][]
) => {
  const recipientRoleMap = rolePermission.recipientRoles.reduce(
    (acc, infoWithRoles) => {
      acc.set(infoWithRoles.recipient.email, infoWithRoles.roles ?? []);
      return acc;
    },
    new Map<string, DocumentRole[]>()
  );
  return signatures.reduce((acc, signature) => {
    const valid = signature.filter((it) => {
      const currRecipientRole =
        recipientRoleMap.get(it.value?.email ?? "") ?? [];
      return currRecipientRole.some((role) => role === DocumentRole.SIGNER);
    });
    acc.push(valid);
    return acc;
  }, [] as SignaturePercentageProps[][]);
};

export const generateInitialRecipientRole = (
  newRecipientInfos: RecipientInfoProps[],
  submittedRoles: RecipientRolesProps[]
) => {
  const submittedRecipientInfo: RecipientInfoProps[] = submittedRoles.map(
    (recipient) => ({
      ...recipient.recipient,
    })
  );
  if (
    submittedRoles.length > 0 &&
    isEqual(submittedRecipientInfo, newRecipientInfos)
  ) {
    // same, return prev value
    return submittedRoles;
  } else {
    // not the same, update role based on email
    const emailRoleMap = submittedRoles.reduce((acc, it) => {
      acc.set(it.recipient.email.trim().toLowerCase(), it.roles);
      return acc;
    }, new Map<string, DocumentRole[] | undefined>());
    return newRecipientInfos.map((recipient) => ({
      recipient,
      roles: emailRoleMap.get(recipient.email.trim().toLowerCase()),
      canDownload: true,
    }));
  }
};
