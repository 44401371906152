import { LOCAL_STORAGE_KEY } from "common/utils/cross-domain/react/helper";
import { useEffect } from "react";

export const useLocalStorageListener = (
  localStorageKeys: LOCAL_STORAGE_KEY[],
  onRefreshTokenChange: () => void
) => {
  useEffect(() => {
    const storageListener = (e: StorageEvent) => {
      if (e.key && localStorageKeys.includes(e.key as LOCAL_STORAGE_KEY)) {
        switch (e.key) {
          case LOCAL_STORAGE_KEY.ACCESS_TOKEN:
          case LOCAL_STORAGE_KEY.REFRESH_TOKEN:
            if (e.newValue === null) {
              window.location.reload(); // Reload to make sure localstorage of other iframe connected
            } else {
              onRefreshTokenChange();
            }
            break;
          default:
            return;
        }
      }
    };
    window.addEventListener("storage", storageListener);
    return () => window.removeEventListener("storage", storageListener);
  }, [localStorageKeys, onRefreshTokenChange]);
};
