import { Button } from "common/components/Button";
import SvgLogOut from "common/components/svg/LogOut";
import { useTranslation } from "react-i18next";
export const LogoutConfirmPopup = ({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="px-16">
      <div className="flex items-center flex-col">
        <SvgLogOut
          className="mb-11 rotate-180 text-light-blue"
          width={48}
          height={48}
        />
        <div className="w-full mb-10 text-center font-medium leading-[22px] px-12">
          {t("settings.logout.title")}
        </div>

        <p className="mb-4 text-center text-xs text-dark-grey">
          {t("settings.logout.titleHint")}
        </p>

        <Button className="w-full mb-4" onClick={onConfirm} variant="primary">
          {t("common.confirm")}
        </Button>

        <Button className="w-full" onClick={onCancel} variant="secondary">
          {t("common.cancel")}
        </Button>
      </div>
    </div>
  );
};
