import { Button } from "common/components/Button";
import { Loader } from "common/components/Loader";
import { Modal } from "common/components/Modal";
import SvgBin from "common/components/svg/Bin";
import { useTranslation } from "react-i18next";
import { DashboardResponse } from "types/common";

/* Folder Management */
type DeleteDocumentPopupProps = {
  dashboardItem: DashboardResponse;
  isOpen: boolean;
  isSubmitting: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const DeleteDashboardItem = ({
  dashboardItem,
  isOpen,
  isSubmitting,
  onCancel,
  onConfirm,
}: DeleteDocumentPopupProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen} onClose={onCancel}>
      <Loader isLoading={isSubmitting} overlay>
        <div className="flex items-center flex-col">
          <SvgBin
            name="bin"
            width="48"
            height="48"
            className="text-light-blue mb-10"
          />
          <p className="mb-10 text-center font-medium leading-[22px] px-12">
            {dashboardItem.isFolder ? (
              <>
                {t("dashboard.deleteFolder.title")}
                <br />"{dashboardItem.folderName}"
              </>
            ) : (
              <>
                {t("dashboard.deleteDocument.title")}
                <br />"{dashboardItem.documentName}"
              </>
            )}
          </p>

          <p className="mb-4 text-center text-xs text-dark-grey">
            {dashboardItem.isFolder
              ? t("dashboard.deleteFolder.titleHint")
              : t("dashboard.deleteDocument.titleHint")}
          </p>

          <Button className="w-full mb-4" onClick={onConfirm} variant="primary">
            {t("common.confirm")}
          </Button>

          <Button className="w-full" onClick={onCancel} variant="secondary">
            {t("common.cancel")}
          </Button>
        </div>
      </Loader>
    </Modal>
  );
};
