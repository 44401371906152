import { Button } from "common/components/Button";
import { SvgAdd } from "common/components/svg";
import SvgNewFolder from "common/components/svg/NewFolder";
import { useOriginalRoute } from "common/hooks/useOriginalRoute";
import { ToastContainerId } from "common/toast";
import { useCreateNewFolderHandler } from "features/documents/hooks/useCreateNewFolderHandler";
import { DocumentListMutatorContext } from "features/documents/pages/documentManagement/DocumentListMutatorContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type DocumentTableButtonActionGroupProps = {
  isButtonDisplay: boolean;
  canCreateFolder: boolean;
  canCreateRequest: boolean;
  currentFolderId?: string | null;
};
export const DocumentTableButtonActionGroup = ({
  isButtonDisplay,
  canCreateFolder,
  canCreateRequest,
  currentFolderId = null,
}: DocumentTableButtonActionGroupProps) => {
  const navigate = useNavigate();
  const { setIsOriginalRoute } = useOriginalRoute();
  const { mutate } = useContext(DocumentListMutatorContext);

  const handleClickNewRequest = () => {
    setIsOriginalRoute(true);
    navigate("/documents/create-request");
  };

  const handleMoveToArchiveSection = () => {
    navigate("../bin");
  };

  const { handler: handleCreateNewFolder } = useCreateNewFolderHandler(
    ToastContainerId.AfterLogin,
    currentFolderId,
    handleMoveToArchiveSection,
    mutate
  );

  const { t } = useTranslation();

  return isButtonDisplay ? (
    <div className="flex items-center justify-between gap-5">
      {canCreateFolder && (
        <Button
          className="w-44"
          variant="white"
          onClick={handleCreateNewFolder}
          data-cy="new-folder"
        >
          <SvgNewFolder className="mr-4" />
          {t("dashboard.button.newFolder")}
        </Button>
      )}

      {canCreateRequest && (
        <Button
          variant="primary"
          className="w-44"
          onClick={handleClickNewRequest}
          data-cy="new-request"
        >
          <SvgAdd className="mr-4" />
          {t("dashboard.button.newRequest")}
        </Button>
      )}
    </div>
  ) : (
    <></>
  );
};
