import tw from "twin.macro";

type ControlPanelProps = {
  contentSection: JSX.Element;
  buttonSection: JSX.Element;
};
export const ControlPanel = ({
  contentSection,
  buttonSection,
}: ControlPanelProps) => {
  return (
    <div className="w-full h-full">
      {/* min-h px TBD */}
      <div className="h-[85%] w-full overflow-y-auto relative min-h-[300px]">
        {contentSection}
      </div>
      <div className="h-[15%] w-full relative">
        <ButtonGroup>{buttonSection}</ButtonGroup>
      </div>
    </div>
  );
};

const ButtonGroup = tw.div`mt-10 flex w-full justify-between absolute bottom-0`;
