import { INPUT_MAX_LENGTH } from "constants/common";

import { FieldContainer } from "common/components/form";
import {
  creatableSelectConfigComponent,
  creatableInputStyles,
  createOption,
} from "common/components/form/CreatableInput/CreatableInputConfig";
import { useField } from "formik";
import { FocusEventHandler, KeyboardEventHandler } from "react";
import { OnChangeValue, InputActionMeta } from "react-select";
import CreatableSelect from "react-select/creatable";

export type Option = {
  readonly label: string;
  readonly value: string;
};

export type InvalidOptionHandler = {
  errorMessage: string;
  shouldClearInput: boolean;
};

type CreatableInputProps = {
  name: string;
  className?: string;
  label?: string;
  placeholder?: string;
  validateNewOption?: {
    predicate: (value: Option[]) => boolean;
    handler: InvalidOptionHandler;
  }[];
  inputValue: string;
  setInputValue: (input: string) => void;
};
export const CreatableInput = ({
  name,
  className,
  label,
  placeholder,
  inputValue,
  setInputValue,
  validateNewOption = [],
}: CreatableInputProps) => {
  const [field, meta, helpers] = useField<readonly Option[]>(name);
  const { setValue, setError } = helpers;
  const { error, value } = meta;

  const handleOnChange = (newValue: OnChangeValue<Option, true>) => {
    setValue(newValue);
  };
  const handleInputChange = (
    newInputValue: string,
    actionMeta: InputActionMeta
  ) => {
    if (actionMeta.action === "input-change") {
      if (newInputValue.length > INPUT_MAX_LENGTH) {
        setInputValue(newInputValue.substring(0, INPUT_MAX_LENGTH));
      } else {
        setInputValue(newInputValue);
      }
    }
  };

  const createNewOption = () => {
    setError(undefined);
    const newOption = [...value, createOption(inputValue.toLowerCase().trim())];
    const validateResult = validateNewOption.find((validate) => {
      return !validate.predicate(newOption);
    });

    if (!!validateResult) {
      setError(validateResult.handler.errorMessage);
      if (validateResult.handler.shouldClearInput) {
        setInputValue("");
      }
    } else {
      setInputValue("");
      handleOnChange(newOption);
    }
  };
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
        if (inputValue) createNewOption();
        event.preventDefault();
        break;
      case "Tab":
        createNewOption();
        event.preventDefault();
    }
  };

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if (!inputValue) return;
    createNewOption();
    event.preventDefault();
  };

  return (
    <FieldContainer
      className={className}
      name={name}
      label={label}
      fieldError={error}
    >
      <CreatableSelect
        {...field}
        components={creatableSelectConfigComponent}
        inputValue={inputValue}
        isMulti
        menuIsOpen={false}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        styles={creatableInputStyles(!!meta.error)}
      />
    </FieldContainer>
  );
};
