import { useRef, useState, useEffect } from "react";

/**
 * This hook is use for calculating the scale ratio, to make sure the content can scale to correct size.
 * @containerWidth is the container's width in px
 * @contentWidthRatioInContainer is the percentage of the target content inside container.
 *
 * For example, container contains two children, SVG and text (our target content),
 * If SVG should have 30% and text should be 70%, then the "contentWidthRatioInContainer" should be 0.7
 * or
 * A container contains one child, text(our target)
 * since the text should be 100%, then the "contentWidthRatioInContainer" should be 1.0
 *
 */
export const useContentRatio = (
  containerWidth: number,
  contentWidthRatioInContainer = 1
) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [scaleRatio, setRatio] = useState<number>(1);
  const [refresh, setRefresh] = useState(true);
  const mutate = () => {
    setRefresh((prev) => !prev);
  };
  const offsetWidth = contentRef?.current?.offsetWidth;
  useEffect(() => {
    if (contentWidthRatioInContainer > 1 || contentWidthRatioInContainer <= 0) {
      console.error("Invalid contentWidthRatioInContainer");
      return;
    }
    if (offsetWidth && containerWidth) {
      if (offsetWidth > containerWidth * contentWidthRatioInContainer) {
        setRatio((containerWidth * contentWidthRatioInContainer) / offsetWidth);
      } else {
        setRatio(1);
      }
    }
  }, [offsetWidth, containerWidth, contentWidthRatioInContainer, refresh]);

  return {
    contentRef,
    scaleRatio,
    mutate,
  };
};
