import { Button } from "common/components/Button";
import { Loader } from "common/components/Loader";
import { Modal } from "common/components/Modal";
import SvgSignatureFail from "common/components/svg/SignatureFail";
import { ChangeEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentRole, DocumentStatePayload } from "types/document";

type CommentRejectDocumentPopupProps = {
  document: DocumentStatePayload;
  isOpen: boolean;
  isSubmitting: boolean;
  onConfirm: (comment: string) => void;
  onCancel: () => void;
  roles?: DocumentRole[];
};

export const CommentRejectDocumentPopup = ({
  document,
  isOpen,
  isSubmitting,
  onCancel,
  onConfirm,
  roles,
}: CommentRejectDocumentPopupProps): JSX.Element => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const generateTitleMessageByRole = useCallback(
    (role: DocumentRole[] | undefined) => {
      if (role && role.length > 0) {
        if (role.length > 1) {
          return t("docDetails.reject.signAndAuthenticateTitle");
        }

        return role[0] === DocumentRole.SIGNER
          ? t("docDetails.reject.signingTitle")
          : t("docDetails.reject.authenticateTitle");
      }
    },
    [t]
  );

  return (
    <Modal open={isOpen} onClose={onCancel}>
      <Loader isLoading={isSubmitting} overlay>
        <div
          className="flex items-center flex-col text-dark-blue"
          data-cy="comment-reject-document-modal"
        >
          <SvgSignatureFail
            name="signatureFail"
            width={48}
            height={48}
            className="text-light-blue mb-10"
          />
          <p className="mb-10 text-center font-medium leading-[22px] px-12">
            <span>{generateTitleMessageByRole(roles)}</span>
            <br />
            <span className="opacity-50">
              {t("docDetails.reject.optional")}
            </span>
          </p>
          <textarea
            className="bg-background-grey resize-none w-full h-24 mb-10 p-2"
            name="comment"
            value={comment}
            onChange={handleCommentChange}
          />

          <Button
            className="w-full mb-4"
            onClick={() => onConfirm(comment)}
            variant="primary"
          >
            {t("common.confirm")}
          </Button>

          <Button className="w-full" onClick={onCancel} variant="secondary">
            {t("common.cancel")}
          </Button>
        </div>
      </Loader>
    </Modal>
  );
};
