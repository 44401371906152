import { LatestDocumentsContext } from "common/context/LatestDocumentsContext";
import _ from "lodash";
import { useContext, useEffect } from "react";
import { DashboardResponse } from "types/common";

export const useUpdateDocuments = (
  currentDocuments: DashboardResponse[] | undefined
): {
  documents: DashboardResponse[] | undefined;
} => {
  const { documents, setDocuments } = useContext(LatestDocumentsContext);

  /** Notice: the newly getting documents needs to be different from the old one before setDocuments on LatestDocumentContext, 
  otherwise it will cause maximum update depth exceeded error **/

  useEffect(() => {
    const isNewDocument = !_.isEqual(currentDocuments, documents);
    if (isNewDocument) {
      setDocuments(currentDocuments);
    }
  }, [currentDocuments, documents, setDocuments]);

  return { documents };
};
