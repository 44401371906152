import { documentAPI } from "api/document";
import { Button } from "common/components/Button";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { ToastContainerId } from "common/toast";
import { isCurrentUserSenderAsRecipient } from "common/utils/common";
import { DocumentViewStatus } from "features/documents/components/StatusCell";
import { useDiscardDocumentHandler } from "features/documents/hooks/useDiscardDocumentHandler";
import { useCommentComponentHandler } from "features/documents/pages/documentManagement/hooks/useCommentComponentHandler";
import { SenderAsRecipientMode } from "features/documents/pages/documentRequest/types";
import {
  ButtonStep,
  ButtonStepGroup,
} from "features/documents/pages/postDocumentRequest/components/ButtonStepGroup";
import { DocumentSenderInfo } from "features/documents/pages/postDocumentRequest/components/DocumentSenderInfo";
import { FileManagementTitle } from "features/documents/pages/postDocumentRequest/components/FileManagementTitle";
import { RecipientListEditView } from "features/documents/pages/postDocumentRequest/components/RecipientListEditView";
import { RecipientListView } from "features/documents/pages/postDocumentRequest/components/RecipientListView";
import { RelatedDocumentArea } from "features/documents/pages/postDocumentRequest/components/RelatedDocumentArea";
import { useIsActionTaken } from "features/documents/pages/postDocumentRequest/hooks/useIsActionTaken";
import useNotViewRejectDetails from "features/documents/pages/postDocumentRequest/hooks/useNotViewRejectDetails";
import { usePostDocumentActionPopupHandler } from "features/documents/pages/postDocumentRequest/hooks/usePostDocumentActionPopupHandler";
import useRecipientsRejectedFilter from "features/documents/pages/postDocumentRequest/hooks/useRecipientsRejectedFilter";
import { DocumentAuthenticateForm } from "features/documents/pages/postDocumentRequest/pages/authentication/DocumentAuthenticateView";
import { StyledFlexContainer } from "features/documents/pages/postDocumentRequest/pages/signing/components/DocumentSigningSetting";
import {
  isAllowSeeRejectDetail,
  isShowRecipientSetting,
} from "features/documents/pages/postDocumentRequest/utils/common";
import { useFormikContext } from "formik";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw, { styled } from "twin.macro";
import {
  DocumentStatePayload,
  DocumentStatePayloadMutate,
} from "types/document";

interface DocumentAuthenticateSettingProps {
  documentStatePayload: DocumentStatePayload;
  mutate: DocumentStatePayloadMutate;
  documentViewStatus: DocumentViewStatus | null;
  isViewEditMode: boolean;
  setIsViewEditMode: (isViewEditMode: boolean) => void;
  isSenderAsRecipientActionTaken: boolean;
}

export const DocumentAuthenticateSetting = ({
  documentStatePayload,
  mutate,
  documentViewStatus,
  isViewEditMode,
  setIsViewEditMode,
  isSenderAsRecipientActionTaken,
}: DocumentAuthenticateSettingProps) => {
  const { t } = useTranslation();
  const [isRejectSectionEnable, setRejectSectionEnable] = useState(false);
  const hasBeenDiscarded = useMemo(
    () => documentViewStatus === DocumentViewStatus.Discarded,
    [documentViewStatus]
  );
  const currentUserProfile = useCurrentUserProfile();
  const isCurrentUserSenderSameAsRecipient = useMemo(() => {
    return isCurrentUserSenderAsRecipient(
      documentStatePayload,
      currentUserProfile
    );
  }, [currentUserProfile, documentStatePayload]);

  const isActionTaken = useIsActionTaken(documentStatePayload);
  const { values } = useFormikContext<DocumentAuthenticateForm>();

  const [step, setStep] = useState<ButtonStep>(
    isActionTaken ? ButtonStep.DONE : ButtonStep.ACTION
  );

  // Filter recipients that reject document
  const { listRecipients } = useRecipientsRejectedFilter(
    isRejectSectionEnable,
    documentStatePayload.recipients
  );

  const isAddMoreRecipients = useMemo(
    () =>
      isShowRecipientSetting(
        documentStatePayload,
        currentUserProfile,
        documentViewStatus
      ),
    [documentStatePayload, currentUserProfile, documentViewStatus]
  );

  const mutateAndActionDone = useCallback(() => {
    mutate();
    setStep(ButtonStep.DONE);
  }, [mutate, setStep]);

  const { handler: handleRejectDocument } = useCommentComponentHandler(
    documentStatePayload,
    ToastContainerId.DocumentDetail,
    mutateAndActionDone
  );

  const { handler: handleAuthenticateDocument } =
    usePostDocumentActionPopupHandler(
      SenderAsRecipientMode.AUTHENTICATE,
      documentStatePayload,
      ToastContainerId.DocumentDetail,
      isSenderAsRecipientActionTaken ? values.signatures : undefined,
      mutateAndActionDone
    );

  const { handler: handleDiscardDocument } = useDiscardDocumentHandler(
    documentStatePayload.linearId,
    documentStatePayload.file.name,
    ToastContainerId.DocumentDetail,
    () => {
      mutate();
    }
  );

  const handleShowRejectDetails = async () => {
    setRejectSectionEnable(true);
    if (isNotViewRecipientsComment) {
      await documentAPI.resolve_all_comments(documentStatePayload.ref);
      mutate();
    }
  };

  const navigate = useNavigate();
  const handleBack = () => {
    // reference: https://exerror.com/how-to-go-back-to-previous-route-in-react-router-dom-v6/
    if (isRejectSectionEnable) {
      setRejectSectionEnable(false);
    } else {
      navigate("/documents");
    }
  };

  const isAllowViewRejectDetail = useMemo(
    () => isAllowSeeRejectDetail(currentUserProfile, documentStatePayload),
    [currentUserProfile, documentStatePayload]
  );

  const isNotViewRecipientsComment = useNotViewRejectDetails(
    documentStatePayload.recipients
  );

  return (
    <StyledFlexContainer>
      <FileManagementTitle
        title={
          isRejectSectionEnable
            ? t("docDetails.reject.title")
            : t("docDetails.title")
        }
        onClickBackButton={handleBack}
      />
      <RelatedDocumentArea
        mutate={mutate}
        documentStatePayload={documentStatePayload}
        isEnableForward={isCurrentUserSenderSameAsRecipient}
      />

      {!isCurrentUserSenderSameAsRecipient && (
        <DocumentSenderInfo documentStatePayload={documentStatePayload} />
      )}
      {isViewEditMode ? (
        <StyledRecipientEditListContainer>
          <RecipientListEditView documentStatePayload={documentStatePayload} />
        </StyledRecipientEditListContainer>
      ) : (
        <div className="flex-1">
          <RecipientListView
            listRecipients={listRecipients}
            sender={documentStatePayload.sender}
            isAddMoreRecipients={isAddMoreRecipients}
            isRejectSectionEnable={isRejectSectionEnable}
            isShowButtonRejectDetail={isAllowViewRejectDetail}
            onRejectDetails={
              isAllowViewRejectDetail ? handleShowRejectDetails : undefined
            }
            onClickRecipientSettings={() => setIsViewEditMode(true)}
            isDotIconShow={isNotViewRecipientsComment}
            height={isCurrentUserSenderSameAsRecipient ? "middle" : "short"}
          />
        </div>
      )}

      <div>
        {!isViewEditMode && !hasBeenDiscarded && !isRejectSectionEnable && (
          <ButtonStepGroup
            step={step}
            setStep={setStep}
            hintMessage={t("docDetails.message.authenticateRequest")}
            isHitMessageDisplay={step === ButtonStep.ACTION}
            isCurrentUserSenderSameAsRecipient={
              isCurrentUserSenderSameAsRecipient
            }
            onReject={handleRejectDocument}
            onDiscard={handleDiscardDocument}
          >
            {isCurrentUserSenderSameAsRecipient && step === ButtonStep.DONE ? (
              <Button
                className="w-full"
                variant="secondary"
                onClick={handleDiscardDocument}
                type="button"
              >
                {t("dashboard.button.discard")}
              </Button>
            ) : (
              <Button
                className="w-44"
                variant="primary"
                onClick={handleAuthenticateDocument}
                type="button"
              >
                {t("common.authenticate")}
              </Button>
            )}
          </ButtonStepGroup>
        )}
      </div>
    </StyledFlexContainer>
  );
};

const StyledRecipientEditListContainer = styled.div(
  tw`max-h-[480px] h-auto flex-1 overflow-y-auto`
);
