import { DashboardResponse } from "types/common";

export enum DocumentFilterType {
  All = "dashboard.tab.all",
  Bin = "dashboard.tab.bin",
  Sender = "dashboard.tab.createByMe",
  Recipient = "dashboard.tab.shareWithMe",
}

export const getDocumentFilterTypeText = (type: DocumentFilterType): string => {
  switch (type) {
    case DocumentFilterType.All:
      return "dashboard.tab.all";
    case DocumentFilterType.Bin:
      return "dashboard.tab.bin";
    case DocumentFilterType.Sender:
      return "dashboard.tab.createByMe";
    case DocumentFilterType.Recipient:
      return "dashboard.tab.shareWithMe";
  }
};

/* Folder Management */
export type DocumentActionButtonProps = {
  className?: string;
  dashboardItem: DashboardResponse;
  afterClick?: () => void;
  disabled?: boolean;
};
