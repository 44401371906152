import { SelectOption } from "common/components/form/SelectField";
import { UserIcon, UserIconSize } from "common/components/UserIcon";
import { Colors } from "enums/colors";
import { useTranslation } from "react-i18next";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";

type OptionLabelWithIconProps = {
  color: Colors;
  option: SelectOption;
  formatOptionLabelMeta: FormatOptionLabelMeta<SelectOption>;
};

export const OptionLabelWithIcon = ({
  color,
  option,
  formatOptionLabelMeta,
}: OptionLabelWithIconProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-x-3.5 w-full">
      {option.index !== undefined &&
      option.label !== t("docRequest.confirmDetail.selectAll") ? (
        <>
          <div className="w-8">
            <UserIcon
              variant={"initials"}
              content={option.label}
              size={UserIconSize.SMALL}
              color={color}
            />
          </div>
          <span className="w-full text-ellipsis overflow-hidden whitespace-nowrap">
            {option.label}
          </span>
        </>
      ) : (
        <span>
          {formatOptionLabelMeta.context === "value"
            ? t("docRequest.confirmDetail.allRecipients")
            : option.label}
        </span>
      )}
    </div>
  );
};
