import { useAuth } from "common/hooks";
import { getCurrentParticipant } from "common/utils/document";
import React from "react";
import { useTranslation } from "react-i18next";
import { DashboardResponse } from "types/common";
import { DocumentRole } from "types/document";

export const getRoleName = (role: DocumentRole) => {
  switch (role) {
    case DocumentRole.SENDER:
      return "common.role.sender";
    case DocumentRole.VIEWER:
      return "common.role.viewer";
    case DocumentRole.SIGNER:
      return "common.role.signer";
    case DocumentRole.AUTHENTICATOR:
      return "common.role.authenticator";
  }
};

/* Folder Management */

type RoleCellProps = {
  value: DashboardResponse;
};

export const RoleCell = ({ value }: RoleCellProps): JSX.Element => {
  const { currentUserProfile } = useAuth();
  const { t } = useTranslation();

  // For Folder
  if (!value || !!value.isFolder) {
    return <></>;
  }

  // For Document
  if (
    !value.recipients?.some((it) => it.email === currentUserProfile?.email) &&
    value.sender?.email !== currentUserProfile?.email
  ) {
    return <>{t("common.role.forwardedViewer")}</>;
  } else {
    const roles =
      getCurrentParticipant(
        value.recipients,
        value.sender,
        currentUserProfile?.email
      )?.roles ?? [];
    if (
      value.recipients &&
      value.recipients.some(
        (it) =>
          it.email === value.sender?.email &&
          it.email === currentUserProfile?.email
      )
    ) {
      return (
        <div className="truncate max-w-[144px]">
          {roles.length < 2
            ? `${t(getRoleName(DocumentRole.SENDER))} & ${roles
                .map((it) => t(getRoleName(it)))
                .join(" & ")}`
            : `${t(getRoleName(DocumentRole.SENDER))}, ${roles
                .map((it) => t(getRoleName(it)))
                .join(" & ")}`}
        </div>
      );
    }
    return (
      <div className="truncate max-w-[144px]">
        {roles.map((it) => t(getRoleName(it))).join(" & ")}
      </div>
    );
  }
};
