import { Button } from "common/components/Button";
import { Dimension } from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { Message } from "common/components/Message";
import { ControlPanel } from "features/documents/pages/documentRequest/components/ControlPanel";
import { DocumentRequestUploadedFiles } from "features/documents/pages/documentRequest/pages/UploadDocument/components/DocumentRequestUploadedFiles";
import { usePDFConfirmModal } from "features/documents/pages/documentRequest/pages/UploadDocument/hooks/usePDFConfirmModal";
import {
  DocumentRequestStepViewProps,
  UploadDocumentFormValue,
} from "features/documents/pages/documentRequest/types";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

export const UploadedFileArea = ({
  data,
}: DocumentRequestStepViewProps): JSX.Element => {
  const formik = useFormikContext<UploadDocumentFormValue>();
  const { errors, values, setFieldValue } = formik;
  const { t } = useTranslation();
  const handleFormUploadSubmit = async (dimensions: Dimension[][]) => {
    setFieldValue("fileDimensions", dimensions);
    formik.submitForm();
  };

  const [showModal] = usePDFConfirmModal(
    values.filesUpload,
    handleFormUploadSubmit
  );

  const handleUpload = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      showModal();
    }
  };

  return (
    <ControlPanel
      contentSection={<DocumentRequestUploadedFiles />}
      buttonSection={
        <div className="mt-auto w-full">
          {errors.filesUpload && (
            <Message
              className="mb-[10px] text-xs"
              isBackgroundEnable={false}
              variant="warning"
            >
              {errors.filesUpload}
            </Message>
          )}
          <Button
            variant="primary"
            type="button"
            onClick={handleUpload}
            className="w-full"
            data-cy="btn-upload"
          >
            {t("docRequest.button.upload")}
          </Button>
        </div>
      }
    />
  );
};
