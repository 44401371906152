import { useEffect } from "react";
import ReactTooltip from "react-tooltip";

export const useReactTooltipRebuild = () => {
  // Some cases that the ReactTooltip not show when hover
  // rebuild it when component mounted to make sure it will show correctly
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
};
