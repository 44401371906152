// https://repl.it/@jquense/Map-schema -- adds support for dynamic keys for Yup
const { BaseSchema, string, mixed } = require("yup");
const runValidations = require("yup/lib/util/runTests").default;

// const { inherits } = require("util");

class MapSchema extends BaseSchema {
  constructor(keySchema, valueSchema) {
    super({ type: "object" });
    this.key = keySchema || string();
    this.value = valueSchema || mixed();
  }
}

Object.assign(MapSchema.prototype, {
  _typeCheck(value) {
    return value && typeof value === "object";
  },

  _cast(_value, options) {
    let value = BaseSchema.prototype._cast.call(this, _value, options);

    const result = {};
    Object.entries(value).forEach(([key, value]) => {
      result[this.key.cast(key)] = this.value.cast(value);
    });
    return result;
  },

  _validate(_value, options = {}, cb) {
    let errors = [];
    let { abortEarly, sync, path = "" } = options;

    let originalValue =
      options.originalValue != null ? options.originalValue : _value;

    BaseSchema.prototype._validate.call(this, _value, options, (err, value) => {
      if (!abortEarly && err) {
        throw err;
      }
      if (!this._typeCheck(value)) {
        if (err) throw err;
        return value;
      }
      originalValue = originalValue || value;
      let tests = [];
      Object.entries(value).forEach(([field, fieldValue]) => {
        let innerOptions = {
          ...options,
          parent: value,
          path: path ? `${path}.${field}` : field,
          originalValue: originalValue[field],
        };
        // validate is no longer promise, so bind
        tests.push(
          this.key.validateSync.bind(this.key, field, innerOptions),
          this.value.validateSync.bind(this.value, fieldValue, innerOptions)
        );
      });
      cb(null);
      return runValidations({
        sync,
        path,
        value,
        errors,
        tests,
        endEarly: abortEarly,
      });
    });
  },
});

export { MapSchema };
