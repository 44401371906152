import {
  CancelConfirmType,
  useCancelConfirmPopup,
} from "common/hooks/useCancelConfirmPopup";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { ReactNode, useMemo } from "react";
import {
  DocumentStatePayload,
  DocumentStatus,
  ParticipantResponse,
} from "types/document";

export const DocumentSigningCancelLayout = ({
  documentStatePayload,
  children,
}: {
  documentStatePayload: DocumentStatePayload;
  children: ReactNode;
}) => {
  const currentUserProfile = useCurrentUserProfile();
  const userResponse = useMemo(() => {
    return documentStatePayload.recipients.find(
      (it) => it.email === currentUserProfile?.email
    )?.response;
  }, [currentUserProfile?.email, documentStatePayload.recipients]);

  const isActionTaken = useMemo(() => {
    return (
      documentStatePayload.status === DocumentStatus.DISCARDED ||
      (userResponse !== ParticipantResponse.PENDING &&
        userResponse !== ParticipantResponse.VIEWED)
    );
  }, [userResponse, documentStatePayload.status]);

  useCancelConfirmPopup(CancelConfirmType.DOCUMENT_ACTIONS, [], isActionTaken);

  return <>{children}</>;
};
