import { documentAPI } from "api/document";
import { Button } from "common/components/Button";
import { Loader } from "common/components/Loader";
import { Modal } from "common/components/Modal";
import { SvgFileCancel } from "common/components/svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LinearId } from "types/common";

/* Folder Management */
type DiscardDocumentPopupProps = {
  dashboardItemLinearId: LinearId;
  documentName: string;
  isOpen: boolean;
  isSubmitting: boolean;
  onConfirm: (ref: string) => void;
  onCancel: () => void;
};

export const DiscardDocumentPopup = ({
  dashboardItemLinearId,
  documentName,
  isOpen,
  isSubmitting,
  onCancel,
  onConfirm,
}: DiscardDocumentPopupProps): JSX.Element => {
  const [latestRef, setLatestRef] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    const getLatestInfo = async () => {
      try {
        const refData = await documentAPI.getLatestRef(dashboardItemLinearId);
        setLatestRef(refData);
      } catch (e) {
        console.error(e);
      }
    };
    getLatestInfo();
  }, [dashboardItemLinearId]);

  const handleConfirmDiscard = () => {
    if (latestRef) {
      onConfirm(latestRef);
    }
  };

  return (
    <Modal open={isOpen} onClose={onCancel}>
      <Loader isLoading={isSubmitting} overlay>
        <div
          className="flex items-center flex-col"
          data-cy="discard-document-modal"
        >
          <SvgFileCancel
            name="cancel"
            width="48"
            height="48"
            className="text-light-blue mb-10"
          />
          <div className="w-full mb-10 text-center font-medium leading-[22px] px-12">
            {t("dashboard.discardDocument.title")}
            <div className="flex justify-center">
              <span>"</span>
              <div className="max-w-[270px] text-ellipsis overflow-hidden whitespace-nowrap">
                {documentName}
              </div>
              <span>&nbsp;"&nbsp;?</span>
            </div>
          </div>

          <p className="mb-4 text-center text-xs text-dark-grey">
            {t("dashboard.discardDocument.titleHint")}
          </p>

          <Button
            className="w-full mb-4"
            onClick={handleConfirmDiscard}
            variant="primary"
          >
            {t("dashboard.button.discard")}
          </Button>

          <Button className="w-full" onClick={onCancel} variant="secondary">
            {t("common.cancel")}
          </Button>
        </div>
      </Loader>
    </Modal>
  );
};
