import * as React from "react";
import { SVGProps } from "react";

const SvgFilePng = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 19.5V7.625L14 2H6.667a2.76 2.76 0 0 0-1.886.732A2.423 2.423 0 0 0 4 4.5v15c0 .663.28 1.299.781 1.768A2.76 2.76 0 0 0 6.667 22h10.666a2.76 2.76 0 0 0 1.886-.732c.5-.47.781-1.105.781-1.768ZM14 5.75c0 .497.21.974.586 1.326.375.351.884.549 1.414.549h2.667V19.5c0 .331-.14.65-.39.884a1.38 1.38 0 0 1-.944.366H6.667a1.38 1.38 0 0 1-.943-.366 1.212 1.212 0 0 1-.39-.884v-15c0-.332.14-.65.39-.884.25-.234.589-.366.943-.366H14v2.5Z"
      fill="#00A3FF"
    />
    <path
      d="M8.107 16.614c.49 0 .909-.13 1.193-.419.219-.224.338-.554.338-.943 0-.39-.158-.72-.393-.921-.244-.213-.61-.318-1.122-.318A6.303 6.303 0 0 0 7 14.095v3.923h.823v-1.422c.076.012.175.018.284.018ZM12.338 18.019h.873V14.04h-.764v1.163c-.005.554.018 1.108.071 1.659h-.017c-.164-.448-.381-.944-.6-1.363l-.763-1.458h-.97v3.978h.764V16.82c0-.648-.01-1.21-.033-1.735l.018-.006c.179.467.419.98.637 1.405l.784 1.535ZM15.774 18.06c.417-.007.83-.082 1.226-.224V15.74h-1.368v.701h.567v.833c-.065.036-.218.06-.409.06-.68 0-1.155-.484-1.155-1.304 0-.862.524-1.287 1.216-1.287.403 0 .654.077.855.171l.174-.72c-.179-.093-.545-.194-1.019-.194-1.205 0-2.093.755-2.099 2.077-.005.585.18 1.103.518 1.446.339.354.824.537 1.494.537Z"
      fill="#00A3FF"
    />
    <path
      d="M8.166 14.758c-.169 0-.283.018-.342.036v1.174c.07.018.158.023.278.023.44 0 .713-.242.713-.649 0-.365-.234-.584-.649-.584Z"
      fill="#fff"
    />
  </svg>
);

export default SvgFilePng;
