import * as React from "react";
import { SVGProps } from "react";

const SvgDot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill="none"
    {...props}
  >
    <path
      d="M512 256c0 141.4-114.6 256-256 256S0 397.4 0 256 114.6 0 256 0s256 114.6 256 256z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDot;
