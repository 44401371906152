import { SvgLoading } from "common/components/svg/index";
import { FC } from "react";

type LoadingSpinnerProps = {
  className?: string;
  iconSize?: string | number;
};

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  className,
  iconSize = 129,
}) => {
  return (
    <span className={"animate-loading-spinner " + className}>
      <SvgLoading width={iconSize} height={iconSize} />
    </span>
  );
};
