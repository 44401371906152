import { useInfiniteDocumentList } from "api/document";
import { LatestDocumentsContext } from "common/context/LatestDocumentsContext";
import { Dispatch, SetStateAction, useContext, useMemo } from "react";
import { DashboardResponse } from "types/common";
import { QueryDocumentRole } from "types/document";

export const useGlobalSearchValue = (): {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  searchResults: DashboardResponse[];
} => {
  const { searchValue, setSearchValue, currentFolderId } = useContext(
    LatestDocumentsContext
  );

  const { documents } = useInfiniteDocumentList(
    QueryDocumentRole.ALL,
    false,
    currentFolderId,
    4,
    true,
    searchValue
  );
  const searchResults = useMemo(() => {
    if (searchValue === "") {
      return [];
    } else {
      return documents ?? [];
    }
  }, [documents, searchValue]);

  return { searchValue, setSearchValue, searchResults };
};
