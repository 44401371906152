import { CancelLeaveSitePromptLayout } from "features/documents/pages/documentRequest/components/CancelLeaveSitePromptLayout";
import { DocumentRequestContextProvider } from "features/documents/pages/documentRequest/context/DocumentRequestContext";
import { DocumentRequest } from "features/documents/pages/documentRequest/pages/DocumentRequest";
import { UploadDocuments } from "features/documents/pages/documentRequest/pages/UploadDocument/UploadDocuments";
import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

export const DocumentRequestRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route
        element={
          <DocumentRequestContextProvider>
            <CancelLeaveSitePromptLayout>
              <Outlet />
            </CancelLeaveSitePromptLayout>
          </DocumentRequestContextProvider>
        }
      >
        <Route index element={<Navigate to="upload-documents" />} />
        <Route path="upload-documents" element={<UploadDocuments />} />
        <Route path="document-request" element={<DocumentRequest />} />
      </Route>
      <Route path="*" element={<Navigate to="/documents" />} />
    </Routes>
  );
};
