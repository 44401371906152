import { PDFSignatureSigningRefProps } from "features/documents/pages/postDocumentRequest/components/PDFSignatureSigning";
import { SignatureScrollStateProps } from "features/documents/pages/postDocumentRequest/context//SignatureScrollContext";

type Action = {
  type: ActionType.SET_REF;
  payload: { ref: PDFSignatureSigningRefProps | null | undefined };
};

export enum ActionType {
  SET_REF = "SET_REF",
}

const SignatureScrollReducer = (
  state: SignatureScrollStateProps,
  action: Action
) => {
  switch (action.type) {
    case ActionType.SET_REF:
      return {
        ...state,
        ...action.payload,
      };

    default:
      throw new Error("Invalid Action");
  }
};

export default SignatureScrollReducer;
