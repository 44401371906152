import { SvgPencil } from "common/components/svg";
import {
  SignatureButton,
  StyledGreySubtitle,
} from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/SignatureControlPanel";
import { PDFFile } from "features/documents/pages/documentRequest/types";
import { useTranslation } from "react-i18next";

type PanelAddSignatureSectionProps = {
  currSignedSignatureNum: number;
  selectedFile: PDFFile;
  onAddSignature: () => void;
};

export const PanelAddSignatureSection = ({
  currSignedSignatureNum,
  selectedFile,
  onAddSignature,
}: PanelAddSignatureSectionProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <h3 className="text-dark-blue">
        {t("docRequest.confirmDetail.signatureLocationsTitle")}
      </h3>
      <StyledGreySubtitle className="max-w-fit">
        {t("docRequest.confirmDetail.signatureLocationsTitleHint")}
      </StyledGreySubtitle>

      <SignatureButton
        className="w-[172px]"
        onClick={onAddSignature}
        type="button"
        data-cy="add-signature-btn"
      >
        <SvgPencil className="text-light-blue mr-3" />
        {t("docRequest.button.signature")}
      </SignatureButton>
      <div className="text-base font-regular text-dark-blue text-ellipsis overflow-hidden whitespace-nowrap">
        <span className="text-h1 font-medium text-light-blue">
          {currSignedSignatureNum}
        </span>
        &nbsp;
        <span className="">
          {t("docRequest.confirmDetail.signatureMarked", {
            fileName: selectedFile.name,
          })}
        </span>
      </div>
    </>
  );
};
