import { documentAPI, SignatureRecord } from "api/document";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { toast, ToastContainerId } from "common/toast";
import { isEqualIgnoreCase } from "common/utils/common";
import { useActionPopupHandler } from "features/documents/hooks/useActionPopupHandler";
import { SenderAsRecipientMode } from "features/documents/pages/documentRequest/types";
import { useCurrentUserSignatures } from "features/documents/pages/postDocumentRequest/pages/signing/hooks/useCurrentUserSignatures";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubmissionHandler } from "types/common";
import { ActionTaken, DocumentStatePayload } from "types/document";

export const usePostDocumentActionPopupHandler = (
  variant: SenderAsRecipientMode,
  documentStatePayload: DocumentStatePayload,
  toastContainerId: ToastContainerId = ToastContainerId.DocumentDetail,
  signatureRecord?: SignatureRecord,
  onSuccess?: () => void,
  onCancel?: () => void
): SubmissionHandler => {
  const [isSubmitting] = useState(false);

  const { t } = useTranslation();
  const currentUserProfile = useCurrentUserProfile();
  const { getCurrentUserSignedSignatureRecord, isAllSignaturedSigned } =
    useCurrentUserSignatures(documentStatePayload);

  const currentAcctionTaken = useMemo(() => {
    return documentStatePayload.recipients
      .find((it) => isEqualIgnoreCase(it.email, currentUserProfile?.email))
      ?.actionTaken.map((it) => it.action);
  }, [currentUserProfile?.email, documentStatePayload.recipients]);

  const currSignatureRecord = useMemo(
    () => getCurrentUserSignedSignatureRecord(signatureRecord ?? {}),
    [getCurrentUserSignedSignatureRecord, signatureRecord]
  );

  const submitRequest = async () => {
    try {
      await documentAPI.accept(documentStatePayload.ref, {
        signature: !_.isEmpty(currSignatureRecord)
          ? {
              signatures: currSignatureRecord,
            }
          : undefined,
        authenticate:
          variant === SenderAsRecipientMode.SIGNANDAUTHENTICATE ||
          variant === SenderAsRecipientMode.AUTHENTICATE ||
          !!currentAcctionTaken?.includes(ActionTaken.AUTHENTICATED),
      });
      const toastMessage = t(getToastSuccessMessage(variant));
      toast.success(toastMessage, toastContainerId);
      onSuccess && onSuccess();
    } catch (e) {
      composeErrorHandlers(() =>
        baseFallbackErrorHandler(ToastContainerId.DocumentDetail)
      )(e as GenericError);
    }
  };

  const handleConfirm = async () => {
    if (variant === SenderAsRecipientMode.AUTHENTICATE) {
      submitRequest();
    } else {
      if (isAllSignaturedSigned(currSignatureRecord)) {
        submitRequest();
      } else {
        console.error(
          "Please sign in all required signature locations before proceeding. "
        );
      }
    }
  };

  const { handler } = useActionPopupHandler(
    variant,
    documentStatePayload.file.name,
    handleConfirm,
    onCancel
  );

  return {
    isSubmitting,
    handler: handler,
  };
};

const getToastSuccessMessage = (variant: SenderAsRecipientMode) => {
  switch (variant) {
    case SenderAsRecipientMode.SIGNING:
      return "docDetails.sign.successMessage";
    case SenderAsRecipientMode.SIGNANDAUTHENTICATE:
      return "docDetails.signAndAuthenticate.successMessage";
    case SenderAsRecipientMode.AUTHENTICATE:
      return "docDetails.authenticate.successMessage";
  }
};
