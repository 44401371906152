import { SvgChevronDown } from "common/components/svg";
import { getRoleName } from "features/documents/components/RoleCell";
import { t } from "i18next";
import { DocumentRole } from "types/document";

type RecipientRoleProps = {
  defaultText?: string;
  role?: DocumentRole[];
  isCanEdit: boolean;
};

export const generateCurrentSelectRolesText = (
  recipientRoles: DocumentRole[]
) => {
  const formattedRoles = recipientRoles.map((item: DocumentRole) =>
    getRoleName(item)
  );
  return formattedRoles;
};

export const RecipientRole = ({
  defaultText,
  role,
  isCanEdit,
}: RecipientRoleProps): JSX.Element => {
  return (
    <div className="flex">
      <span className="font-medium text-dark-grey">
        {role && role.length !== 0
          ? generateCurrentSelectRolesText(role)
              .map((it) => t(it))
              .join(" & ")
          : defaultText ?? ""}
      </span>
      {isCanEdit && <SvgChevronDown className="ml-3 text-grey" role="svg" />}
    </div>
  );
};
