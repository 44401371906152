import * as React from "react";
import { SVGProps } from "react";

const SvgPencil = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m8.913 18.887 8-8-3.8-3.8-8 8a.863.863 0 0 0-.226.4L4 20l4.513-.887a.862.862 0 0 0 .4-.226ZM19.496 8.304a1.722 1.722 0 0 0 0-2.435L18.13 4.504a1.722 1.722 0 0 0-2.435 0L14.331 5.87l3.8 3.8 1.365-1.365Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPencil;
