import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { useGenerateColorByString } from "common/hooks/useGenerateColorByString";
import { generateFullName, isEqualIgnoreCase } from "common/utils/common";
import { SignatureBoxContext } from "features/documents/components/SignatureBox/context/SignatureBoxContext";
import { SignatureBoxBeforeAssignAction } from "features/documents/components/SignatureBox/SignatureBoxBeforeAssignAction";
import { SignatureBoxCreateAssignAction } from "features/documents/components/SignatureBox/SignatureBoxCreateAssignAction";
import { SignatureBoxCreateSignAction } from "features/documents/components/SignatureBox/SignatureBoxCreateSignAction";
import { SignatureBoxNotSignedPreview } from "features/documents/components/SignatureBox/SignatureBoxNotSignedPreview";
import { SignatureBoxSignAction } from "features/documents/components/SignatureBox/SignatureBoxSignAction";
import { SignatureBoxSignedPreview } from "features/documents/components/SignatureBox/SignatureBoxSignedPreview";
import { SignatureBoxStep } from "features/documents/pages/documentRequest/components/SignatureCreateRndHandler";
import { PanelState } from "features/documents/pages/documentRequest/context/PDFSignatureControlContext";
import { RecipientInfoProps } from "features/documents/pages/documentRequest/types";
import { useEffect, useMemo, useState } from "react";
import tw, { styled } from "twin.macro";

export const SignatureContent = styled.div(() => [
  tw`font-regular text-dark-blue whitespace-nowrap text-center`,
]);

export type SignatureCreateButtonProps = {
  space: number;
  width: number;
  onRemove: () => void;
  recipients: RecipientInfoProps[];
  onSelectRecipient: (recipient: RecipientInfoProps) => void;
  recipientInfo: RecipientInfoProps | undefined;
  isPreview: boolean;
  onSignatureClick: () => void;
  imageSrc?: string;
  isDrag: boolean;
  isResizing: boolean;
  isHover: boolean;
  setIsHover: React.Dispatch<React.SetStateAction<boolean>>;
  isGradientBorderShow: boolean;
  panelState: PanelState;
  scale: number;
};

export const SignatureCreateButton = ({
  width,
  onSelectRecipient,
  onRemove,
  recipients,
  recipientInfo,
  isPreview,
  isHover,
  setIsHover,
  onSignatureClick,
  imageSrc,
  isDrag,
  isResizing,
  isGradientBorderShow,
  panelState,
  scale,
}: SignatureCreateButtonProps) => {
  const [signatureBoxStep, setSignatureBoxStep] = useState<SignatureBoxStep>(
    SignatureBoxStep.INIT
  );

  const currentUserProfile = useCurrentUserProfile();

  const [selectedRecipient, setSelectedValue] = useState<
    RecipientInfoProps | undefined
  >(recipientInfo);

  const [isPopoverShow, setIsPopoverShow] = useState(!selectedRecipient);

  const { color } = useGenerateColorByString(recipientInfo?.email);

  const handleMouseEnter = () => {
    setIsHover(true);
    setIsPopoverShow(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  useEffect(() => {
    setSelectedValue(recipientInfo);
  }, [recipientInfo]);

  // Handle SignatureBoxStep state when Next/Back
  useEffect(() => {
    if (isPreview) {
      setSignatureBoxStep(SignatureBoxStep.DONE);
    } else {
      if (selectedRecipient) {
        setSignatureBoxStep(SignatureBoxStep.ASSIGN_RECIPIENT);
      }
      if (imageSrc) {
        setSignatureBoxStep(SignatureBoxStep.SENDER_SIGNED);
      }
    }
  }, [isPreview, imageSrc, selectedRecipient]);

  const handleSelectRecipient = (recipient: RecipientInfoProps) => {
    setIsPopoverShow(false);
    setSelectedValue(recipient);
    onSelectRecipient(recipient);
    setSignatureBoxStep(SignatureBoxStep.ASSIGN_RECIPIENT);
  };

  const isSenderEqualsToRecipient = useMemo(
    () =>
      isEqualIgnoreCase(selectedRecipient?.email, currentUserProfile?.email),
    [currentUserProfile?.email, selectedRecipient?.email]
  );

  const handleSignatureBoxClick = async (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    if (selectedRecipient) {
      switch (signatureBoxStep) {
        case SignatureBoxStep.SENDER_SIGNED:
          e.stopPropagation();
          onSignatureClick();
          break;
        case SignatureBoxStep.ASSIGN_RECIPIENT:
          if (isSenderEqualsToRecipient && !isPreview) {
            e.stopPropagation();
            onSignatureClick();
          }
          break;
        default:
          return;
      }
    }
  };

  const fullName = generateFullName(
    selectedRecipient?.firstName,
    selectedRecipient?.lastName
  );

  const email = selectedRecipient?.email ?? "";

  return (
    <SignatureBoxContext.Provider
      value={{
        color,
        recipients,
        width,
        fullName,
        email,
        imageSrc,
        isGradientBorderShow,
        panelState,
        scale,
      }}
    >
      {(() => {
        switch (true) {
          case signatureBoxStep === SignatureBoxStep.INIT:
            return (
              <SignatureBoxBeforeAssignAction
                onSelectRecipient={handleSelectRecipient}
                isPopoverShow={isResizing ? false : isPopoverShow}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onRemove={onRemove}
              />
            );
          case signatureBoxStep === SignatureBoxStep.ASSIGN_RECIPIENT:
            return (
              <SignatureBoxCreateAssignAction
                onSelectRecipient={handleSelectRecipient}
                isPopoverShow={isPopoverShow}
                isSenderEqualsToRecipient={isSenderEqualsToRecipient}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={() => {
                  handleMouseLeave();
                  setIsPopoverShow(false);
                }}
                onClick={(e) => !isDrag && handleSignatureBoxClick(e)}
                onCrossClick={onRemove}
              />
            );

          case signatureBoxStep === SignatureBoxStep.SENDER_SIGNED:
            return isHover ? (
              <SignatureBoxCreateSignAction
                onCrossClick={(e) => {
                  e.stopPropagation();
                  onRemove();
                }}
                onClick={(e) => !isDrag && handleSignatureBoxClick(e)}
                onSelectRecipient={onSelectRecipient}
                isPopoverShow={isPopoverShow}
                onMouseLeave={() => {
                  handleMouseLeave();
                  setIsPopoverShow(false);
                }}
              />
            ) : (
              <SignatureBoxSignAction onMouseEnter={handleMouseEnter} />
            );
          case signatureBoxStep === SignatureBoxStep.DONE:
            return !!imageSrc ? (
              <SignatureBoxSignedPreview
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            ) : (
              <SignatureBoxNotSignedPreview
                isCrossShow={false}
                displayMsg={fullName}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            );

          default:
            return <></>;
        }
      })()}
    </SignatureBoxContext.Provider>
  );
};
