import { FieldContainer } from "common/components/form/FieldContainer";
import { useField } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue, StylesConfig } from "react-select";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";

export type SelectOption = {
  value: string;
  label: string;
  index?: number;
};

type SelectFieldProps = {
  className?: string;
  name: string;
  label: string;
  options: SelectOption[];
  isMulti?: boolean;
  onAfterChange?: () => void;
  maxHeight?: number;
  isSearchable?: boolean;
  selectStyles?: StylesConfig<SelectOption>;
  formatOptionLabel?: (
    option: SelectOption,
    formatOptionLabelMeta: FormatOptionLabelMeta<SelectOption>
  ) => JSX.Element;
};

export const SelectField = ({
  className,
  name,
  label,
  options,
  isMulti,
  onAfterChange,
  isSearchable,
  maxHeight,
  selectStyles,
  formatOptionLabel,
}: SelectFieldProps): JSX.Element => {
  const { t } = useTranslation();
  const [field, , helpers] = useField(name);
  const sortedOptions = useMemo<SelectOption[]>(
    () =>
      [...options].sort((a, b) => {
        if (b.label === t("docRequest.confirmDetail.selectAll")) {
          return 1;
        }
        return a.label.localeCompare(b.label);
      }),
    [options, t]
  );

  const value = isMulti
    ? options.filter((it) => field.value.includes(it.value))
    : options.find((it) => it.value === field.value);

  const handleChange: (
    value: MultiValue<SelectOption> | SingleValue<SelectOption>
  ) => void = isMulti
    ? (options) =>
        options &&
        helpers.setValue(
          (options as MultiValue<SelectOption>).map((it) => it.value)
        )
    : (option) =>
        option &&
        helpers.setValue((option as SingleValue<SelectOption>)?.value);

  return (
    <FieldContainer className={className} name={name} label={label}>
      <Select
        defaultValue={sortedOptions[0]}
        id={name}
        options={sortedOptions}
        value={value}
        onChange={(event) => {
          handleChange(event);
          if (onAfterChange) onAfterChange();
        }}
        isMulti={isMulti}
        styles={selectStyles}
        formatOptionLabel={formatOptionLabel}
        isSearchable={isSearchable}
        maxMenuHeight={maxHeight}
        menuPlacement="auto"
      />
    </FieldContainer>
  );
};
