import { Card } from "common/components/Card";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { isEqualIgnoreCase } from "common/utils/common";
import { Colors } from "enums/colors";
import { ChooseRecipientRolePopup } from "features/documents/pages/documentRequest/components/ChooseRecipientRolePopup";
import { StyledPreviewCard } from "features/documents/pages/documentRequest/components/RecipientCreateCard";
import { RecipientCardInformation } from "features/documents/pages/documentRequest/components/RecipientInformation";
import { RecipientRole } from "features/documents/pages/documentRequest/components/RecipientRole";
import { RecipientDownloadPermission } from "features/documents/pages/documentRequest/pages/AddRolePermission/components/RecipientDownloadPermission";
import {
  RecipientInfoProps,
  SettingRolePermissionFormValue,
} from "features/documents/pages/documentRequest/types";
import { useFormikContext } from "formik";
import cloneDeep from "lodash/cloneDeep";
import { useCallback, useMemo, useState } from "react";
import tw from "twin.macro";
import { DocumentRole } from "types/document";

export enum RecipientDisplayMode {
  CHOOSE_ROLE = "ChooseRole",
  PREVIEW = "Preview",
}

type RecipientWithRoleCardProps = {
  mode: RecipientDisplayMode;
  currentColor: Colors;
  recipientInfo?: RecipientInfoProps;
  recipientRole?: DocumentRole[];
  recipientDownloadPermission: boolean;
  className?: string;
};

export const StyledCard = tw(
  Card
)`px-3 py-3 mb-2 items-center w-full border border-solid border-light-greyscale`;

export const RecipientWithRoleCard = ({
  className,
  mode = RecipientDisplayMode.PREVIEW,
  recipientInfo = {
    firstName: "",
    lastName: "",
    email: "",
  },
  recipientRole,
  recipientDownloadPermission,
  currentColor,
}: RecipientWithRoleCardProps) => {
  const { setFieldValue, values } =
    useFormikContext<SettingRolePermissionFormValue>();
  const [isAllowDownload, setIsAllowDownload] = useState(
    recipientDownloadPermission
  );
  const currentUserProfile = useCurrentUserProfile();
  const isSenderSameAsRecipient = useMemo(
    () => currentUserProfile?.email === recipientInfo.email,
    [currentUserProfile?.email, recipientInfo.email]
  );

  const handleDownloadPermissionToggle = useCallback(() => {
    if (!isSenderSameAsRecipient) {
      setIsAllowDownload((prev) => {
        return !prev;
      });
      const recipientRolesWithPermission = cloneDeep(values.recipientRoles).map(
        (it) => {
          if (isEqualIgnoreCase(it.recipient.email, recipientInfo.email)) {
            return {
              recipient: it.recipient,
              roles: it.roles,
              canDownload: !isAllowDownload,
            };
          } else return it;
        }
      );
      setFieldValue("recipientRoles", recipientRolesWithPermission);
    }
  }, [
    isSenderSameAsRecipient,
    isAllowDownload,
    recipientInfo.email,
    values.recipientRoles,
    setFieldValue,
  ]);

  return (
    <StyledCard isShadow={false} className={className}>
      <StyledPreviewCard>
        <RecipientCardInformation
          className={`w-1/2 grow`}
          recipientInfo={recipientInfo}
          currentColor={currentColor}
        />
        {mode === RecipientDisplayMode.CHOOSE_ROLE && (
          <ChooseRecipientRolePopup
            recipientInfo={recipientInfo}
            recipientRole={recipientRole ? recipientRole : []}
            isSenderSameAsRecipient={isSenderSameAsRecipient}
            isAllowDownload={isAllowDownload}
          />
        )}
        {mode === RecipientDisplayMode.PREVIEW && (
          <RecipientRole role={recipientRole} isCanEdit={false} />
        )}
      </StyledPreviewCard>
      {mode === RecipientDisplayMode.CHOOSE_ROLE ? (
        <RecipientDownloadPermission
          recipientEmail={recipientInfo.email}
          allowDownload={isAllowDownload}
          handleSelect={handleDownloadPermissionToggle}
        />
      ) : null}
    </StyledCard>
  );
};
