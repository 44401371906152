import { StyledReactTooltip } from "common/components/StyledReactTooltip";
import { SvgInformation } from "common/components/svg";
import { ToggleSwitch } from "common/components/ToggleSwitch";
import { useReactTooltipRebuild } from "common/hooks/useReactTooltipRebuild";
import { useTranslation } from "react-i18next";

type RecipientDownloadPermissionProps = {
  recipientEmail: string;
  allowDownload: boolean;
  handleSelect: () => void;
};

const RECIPIENT_DOWNLOAD_PERMISSION_TOOLTIP_ID = "downloadPermissionTooltip";

export const RecipientDownloadPermission = ({
  recipientEmail,
  allowDownload = true,
  handleSelect,
}: RecipientDownloadPermissionProps) => {
  const { t } = useTranslation();
  useReactTooltipRebuild();

  return (
    <div
      className="w-full flex items-center pt-4 ml-11 text-dark-blue text-xs font-medium"
      key={recipientEmail}
      data-cy="donwload-switch"
    >
      <div className="flex items-center gap-3 basis-4/6">
        <ToggleSwitch
          isSelected={allowDownload}
          onSelect={() => handleSelect()}
        />
        <span className="text-sm font-normal">
          {t("docRequest.documentDownload")}
        </span>
        <SvgInformation
          width={20}
          height={20}
          className="text-grey cursor-pointer border-0"
          data-tip
          data-for={RECIPIENT_DOWNLOAD_PERMISSION_TOOLTIP_ID}
        />
        <StyledReactTooltip
          id={RECIPIENT_DOWNLOAD_PERMISSION_TOOLTIP_ID}
          effect="solid"
          place="top"
          offset={{ right: 130, top: 0 }}
        >
          <div className="text-sm">{t("docRequest.permission.tooltip")}</div>
        </StyledReactTooltip>
      </div>
    </div>
  );
};
