import { attachmentAPI } from "api/attachment";
import { Button } from "common/components/Button";
import { StyledReactTooltip } from "common/components/StyledReactTooltip";
import { SvgFilePdf } from "common/components/svg";
import SvgDownload from "common/components/svg/Download";
import SvgForward from "common/components/svg/Forward";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { ToastContainerId } from "common/toast";
import { downloadFile } from "common/utils/common";
import {
  DocumentViewStatus,
  getDashboardItemViewStatus,
  StatusCell,
} from "features/documents/components/StatusCell";
import { useForwardDocumentHandler } from "features/documents/hooks/useForwardDocumentHandler";
import { ButtonStep } from "features/documents/pages/postDocumentRequest/components/ButtonStepGroup";
import { isCompletedNoRejectStatus } from "features/documents/pages/postDocumentRequest/utils/common";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import tw, { styled } from "twin.macro";
import {
  DocumentParticipant,
  DocumentStatePayload,
  DocumentStatePayloadMutate,
  DocumentStatus,
} from "types/document";

type RelatedDocumentAreaProps = {
  documentStatePayload: DocumentStatePayload;
  isEnableForward?: boolean;
  mutate: DocumentStatePayloadMutate;
  step?: ButtonStep;
};

export const RelatedDocumentArea = ({
  mutate,
  documentStatePayload,
  isEnableForward,
  step,
}: RelatedDocumentAreaProps) => {
  const currentUserProfile = useCurrentUserProfile();
  const currentUserEmail = currentUserProfile?.email ?? "";
  const { t } = useTranslation();

  const documentViewStatus = useMemo(() => {
    return getDashboardItemViewStatus(
      documentStatePayload.recipients,
      documentStatePayload.sender,
      documentStatePayload.status,
      currentUserProfile?.email
    );
  }, [
    currentUserProfile?.email,
    documentStatePayload.recipients,
    documentStatePayload.sender,
    documentStatePayload.status,
  ]);

  const currentRecipient = useMemo(() => {
    return (
      documentStatePayload.recipients.find(
        (recipient) => recipient.email === currentUserEmail
      ) ?? documentStatePayload.sender
    );
  }, [documentStatePayload, currentUserEmail]);

  const isForwardedViewer = useMemo(() => {
    return documentStatePayload.forwardedRecipients.some(
      (it) => it.email === currentUserEmail
    );
  }, [documentStatePayload, currentUserEmail]);

  const isAllowDownload = useMemo(() => {
    const senderEmail = documentStatePayload.sender.email;
    const documentStatus = documentStatePayload.status;
    return isAllowToDownload(
      senderEmail,
      documentStatus,
      isForwardedViewer,
      currentRecipient
    );
  }, [documentStatePayload, currentRecipient, isForwardedViewer]);

  const signaturesRequired = useMemo(() => {
    return documentStatePayload.signatureSchemas.filter(
      (it) => it.email === currentUserEmail
    );
  }, [currentUserEmail, documentStatePayload.signatureSchemas]);

  const isPdfReady = documentStatePayload.completedFile;
  const isCompletedWithNoRejected = useMemo(
    () =>
      isCompletedNoRejectStatus(
        documentStatePayload.status,
        documentStatePayload.recipients
      ),
    [documentStatePayload]
  );

  const targetFileId = () => {
    if (isCompletedWithNoRejected) {
      const completedFile = documentStatePayload.completedFile;
      if (completedFile) {
        return completedFile.id;
      } else {
        console.error("Invalid Status");
        return "";
      }
    } else {
      return documentStatePayload.file.id;
    }
  };

  const handleDownloadDocument = async () => {
    try {
      const response = await attachmentAPI.download(
        targetFileId(),
        documentStatePayload.linearId
      );
      const blob = new Blob([response], { type: "application/pdf" });
      const href = URL.createObjectURL(blob);
      downloadFile(href, documentStatePayload.file.name);
    } catch (e) {
      composeErrorHandlers(() =>
        baseFallbackErrorHandler(ToastContainerId.DocumentDetail)
      )(e as GenericError);
    }
  };
  const { handler: handleForwardDocument } = useForwardDocumentHandler(
    documentStatePayload.linearId,
    documentStatePayload.file.name,
    ToastContainerId.DocumentDetail,
    () => mutate().then((resp) => resp?.ref),
    mutate
  );

  useEffect(() => {
    if (isAllowDownload && isCompletedWithNoRejected) {
      if (isPdfReady) {
        return;
      }
      const timer = setInterval(mutate, 30000);
      return () => clearInterval(timer);
    }
  }, [isPdfReady, isAllowDownload, isCompletedWithNoRejected, mutate]);

  return (
    <div>
      <div className="text-h3 font-medium mb-3 h-md:mb-6">
        {t("docDetails.relatedDocument.title")}
      </div>
      <StyledRelatedDocumentContainer>
        <div className="flex items-center gap-4 w-1/2 grow">
          <div className="flex items-center gap-4 truncate">
            <SvgFilePdf className="text-light-blue" />
            <StyledFileName>{documentStatePayload.file.name}</StyledFileName>
          </div>
          {step !== ButtonStep.ACTION ? (
            <StatusCell
              value={{
                recipients: documentStatePayload.recipients,
                sender: documentStatePayload.sender,
                status: documentStatePayload.status,
              }}
              className="text-sm leading-[22px] min-w-[50px] shrink-0 status-cell"
            />
          ) : (
            <span className="inline-block min-w-[154px] shrink-0 text-base text-dark-grey">
              {t("docDetails.relatedDocument.signaturesRequired", {
                signaturesNum: signaturesRequired.length,
              })}
            </span>
          )}
        </div>
        <div className="flex items-center gap-2">
          {isEnableForward && (
            <div data-cy="forward-btn" data-tip data-for="forwardTooltip">
              <StyledRelatedDocumentButton
                type="button"
                disabled={documentViewStatus !== DocumentViewStatus.Completed}
                onClick={handleForwardDocument}
              >
                <SvgForward className="mr-2" />
                {t("dashboard.button.forward")}
              </StyledRelatedDocumentButton>
            </div>
          )}
          <StyledReactTooltip
            id="forwardTooltip"
            effect="solid"
            className="w-56"
            offset={{ right: 50, top: 0 }}
            disable={documentViewStatus === DocumentViewStatus.Completed}
            $arrowPosition="left"
          >
            {t("dashboard.tooltip.forward")}
          </StyledReactTooltip>

          {isAllowDownload && (
            <div data-cy="download-btn" data-tip data-for="downloadTooltip">
              <StyledRelatedDocumentButton
                type="button"
                disabled={isCompletedWithNoRejected && !isPdfReady}
                onClick={handleDownloadDocument}
              >
                <SvgDownload className="mr-2" />
                {t("docDetails.button.download")}
              </StyledRelatedDocumentButton>
            </div>
          )}
          <ReactTooltip
            id="downloadTooltip"
            className="w-72"
            effect="solid"
            disable={!(isCompletedWithNoRejected && !isPdfReady)}
          >
            <div className="w-[267px] text-sm">
              {t("docDetails.relatedDocument.tooltip")}
            </div>
          </ReactTooltip>
        </div>
      </StyledRelatedDocumentContainer>
    </div>
  );
};

export const isAllowToDownload = (
  senderEmail: string,
  documentStatus: DocumentStatus,
  isForwardedViewer: boolean,
  currentRecipient?: DocumentParticipant
): boolean => {
  const isDiscard = documentStatus === DocumentStatus.DISCARDED;

  if (isDiscard) {
    return false;
  }

  if (isForwardedViewer) {
    return true;
  }

  if (currentRecipient) {
    if (currentRecipient.email === senderEmail) {
      return true;
    } else {
      return !!currentRecipient.canDownload;
    }
  }

  return false;
};

const StyledRelatedDocumentContainer = styled.span(
  tw`flex items-center justify-between h-md:mb-10 mb-5 w-full gap-2`
);
const StyledFileName = styled.span(tw`basis-1/2 grow text-base truncate`);

const StyledRelatedDocumentButton = styled(Button)(
  tw`cursor-pointer text-sm font-medium`,
  tw`!bg-background-grey !text-light-blue`,
  tw`disabled:!text-grey`
);
