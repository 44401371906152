import * as React from "react";
import { SVGProps } from "react";

const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 5.6a6.4 6.4 0 1 0 0 12.8 6.4 6.4 0 0 0 0-12.8ZM4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z"
      fill="currentColor"
    />
    <path
      d="M12 13.6a.8.8 0 0 1-.8-.8V8a.8.8 0 1 1 1.6 0v4.8a.8.8 0 0 1-.8.8Zm-1.2 2a1.2 1.2 0 1 1 2.4 0 1.2 1.2 0 0 1-2.4 0Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgWarning;
