import { LatestDocumentsContext } from "common/context/LatestDocumentsContext";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

export enum DocumentFilterTabType {
  All = "all",
  CreatedByMe = "created-by-me",
  SharedWithMe = "shared-with-me",
  Bin = "bin",
}

export const useLastDocumentFilterType = () => {
  const location = useLocation();
  const { docFilterType, setDocFilterType } = useContext(
    LatestDocumentsContext
  );

  useEffect(() => {
    const documentPageFilterType = location.pathname.match(
      /^\/documents\/([^/]+)$/
    );

    if (documentPageFilterType) {
      const isInDocumentFilterTabType = (
        Object.values(DocumentFilterTabType) as string[]
      ).includes(documentPageFilterType[1]);
      if (isInDocumentFilterTabType) {
        setDocFilterType(documentPageFilterType[1]);
      }
    }
  }, [location.pathname, setDocFilterType]);

  return docFilterType;
};
