import {
  EMAIL_FORMAT_INCORRECT_ERROR,
  EMAIL_DUPLICATE_ERROR,
} from "constants/commonValidatorError";
import { REG_EMAIL_VALIDATOR_WITHOUT_TRIM } from "constants/emailValidatorKey";

import {
  CreatableInput,
  Option,
} from "common/components/form/CreatableInput/CreatableInput";
import { isEqualIgnoreCase } from "common/utils/common";
import { useTranslation } from "react-i18next";

type ForwardCreatableInputProps = {
  forwardedEmails: string[];
  name: string;
  inputValue: string;
  setInputValue: (input: string) => void;
};
export const ForwardCreatableInput = ({
  forwardedEmails,
  name,
  inputValue,
  setInputValue,
}: ForwardCreatableInputProps) => {
  const { t } = useTranslation();
  return (
    <CreatableInput
      inputValue={inputValue}
      setInputValue={setInputValue}
      name={name}
      placeholder={t("dashboard.forwardDocument.placeholder")}
      validateNewOption={[
        // Those validation need to be done before generate new options, can not put into schema validation
        {
          predicate: emailFormatPredicate,
          handler: {
            errorMessage: t(EMAIL_FORMAT_INCORRECT_ERROR),
            shouldClearInput: false,
          },
        },
        {
          predicate: (options) => duplicatePredicate(options, forwardedEmails),
          handler: {
            errorMessage: t(EMAIL_DUPLICATE_ERROR),
            shouldClearInput: true,
          },
        },
      ]}
    />
  );
};

export const emailFormatPredicate = (newOption: Option[]) =>
  newOption.every((op) => op.value.match(REG_EMAIL_VALIDATOR_WITHOUT_TRIM));

export const duplicatePredicate = (
  newOption: Option[],
  forwardedEmails: string[]
) => {
  const isDuplicateOptions =
    new Set(newOption?.map((it) => it.value)).size !== newOption?.length;
  const isOptionDuplicateWithForwarded = newOption.some((op) =>
    forwardedEmails.some((it) => isEqualIgnoreCase(it, op.label))
  );
  return !isDuplicateOptions && !isOptionDuplicateWithForwarded;
};
