import { SearchBar } from "common/components/DataTable/SearchBar";
import tw, { styled } from "twin.macro";

type DocumentTableHeaderProps = {
  onSearchChange: (searchValue: string) => void;
};
export const DocumentTableHeader = ({
  onSearchChange,
}: DocumentTableHeaderProps) => {
  return (
    <div className="flex items-center justify-between mb-11">
      <SearchArea>
        <SearchBar onSearchChange={onSearchChange} />
      </SearchArea>
    </div>
  );
};
const SearchArea = styled.div(() => [tw`w-1/4`]);
