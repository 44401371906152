import classNames from "classnames";
import { BaseBreadCrumbs } from "common/components/Breadcrumbs/BaseBreadcrumbs";
import { useCurrentFolderId } from "common/hooks/useCurrentFolderId";
import { useDashboardBreadcrumbs } from "common/hooks/useDashboardBreadcrumbs";
import { useOriginalRoute } from "common/hooks/useOriginalRoute";
import { BreadCrumbItemProps } from "features/documents/components/FolderManagement/DashboardBreadcrumbs";
import { useNavigate } from "react-router-dom";

export type SearchBreadcrumbsProps = {
  className?: string;
  data: BreadCrumbItemProps[];
  onClick?: () => void;
};
const containerClassStr = classNames(
  "w-fit max-w-full gap-1 transition font-medium text-dark-grey",
  "border-white border-b",
  "hover:cursor-pointer hover:border hover:border-current hover:border-x-0 hover:border-b hover:border-t-0 "
);

export const SearchBreadcrumbs = ({
  data,
  onClick,
  className,
}: SearchBreadcrumbsProps): JSX.Element => {
  // Integration get pathsName from router
  const mockPathsName = ["documents", "all"];
  const { setDashboardBreadcrumbsItems } = useDashboardBreadcrumbs();
  const { setCurrentFolderId } = useCurrentFolderId();
  const { setIsOriginalRoute } = useOriginalRoute();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    navigate(`/documents/all`);
    setIsOriginalRoute(false);
    setDashboardBreadcrumbsItems([...data]);
    setCurrentFolderId(data[data.length - 1]?.value);
    onClick && onClick();
  };
  const displayPaths =
    data.length > 1
      ? ["...", data[data.length - 1]?.label]
      : [data[data.length - 1]?.label];

  return (
    <div className={className && className} onMouseDown={handleClick}>
      <BaseBreadCrumbs
        dividerIcon={<>{">"}</>}
        containerClassName={containerClassStr}
        itemContainerClassName="text-dark-grey text-xs"
        defaultItemStyle={false}
        disabledActive={true}
      >
        {mockPathsName.map((it) => (
          <span key={it} className="capitalize">
            {it}
          </span>
        ))}
        {data.length > 0 &&
          displayPaths.map((it) => <span key={it}>{it}</span>)}
      </BaseBreadCrumbs>
    </div>
  );
};
