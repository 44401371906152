import { generateColorByString } from "enums/colors";
import { RecipientStatusCard } from "features/documents/pages/documentRequest/components/RecipientStatusCard";
import { AddRecipientButton } from "features/documents/pages/documentRequest/pages/AddRecipients/components/AddRecipientButton";
import { RejectDetailButton } from "features/documents/pages/documentRequest/pages/AddRecipients/components/RejectDetailButton";
import { useSortedRecipientList } from "features/documents/pages/postDocumentRequest/hooks/useSortedRecipientList";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";
import { DocumentParticipant } from "types/document";

type RecipientListViewProps = {
  isAddMoreRecipients?: boolean;
  isRejectSectionEnable?: boolean;
  listRecipients: DocumentParticipant[];
  onRejectDetails?: () => void;
  onClickRecipientSettings?: () => void;
  sender: DocumentParticipant;
  isShowButtonRejectDetail?: boolean;
  isDotIconShow?: boolean;
  height?: "short" | "middle" | "long";
};

const RecipientListViewWrapper = styled.div(
  ({ height }: Pick<RecipientListViewProps, "height">) => [
    tw`flex flex-col w-full flex-1 max-h-[264px]`,
    height === "short" && tw`h-md:max-h-[340px]`,
    height === "middle" && tw`h-md:max-h-[460px]`,
    height === "long" && tw`h-md:max-h-[550px]`,
  ]
);

export const RecipientListView = ({
  isAddMoreRecipients,
  isRejectSectionEnable,
  listRecipients,
  onRejectDetails,
  onClickRecipientSettings,
  sender,
  isShowButtonRejectDetail,
  isDotIconShow,
  height,
}: RecipientListViewProps) => {
  const { t } = useTranslation();
  const sortedRecipientList = useSortedRecipientList(listRecipients);
  const generateColorByEmail = useCallback(
    (email: string) => generateColorByString(email),
    []
  );

  return (
    <RecipientListViewWrapper height={height ?? "short"}>
      <div className="flex items-center justify-between mb-3 h-md:mb-6">
        <div className="text-h3 font-medium">
          {isRejectSectionEnable
            ? t("docDetails.reject.subtitle")
            : t("docRequest.addRecipient.title")}
        </div>

        {isShowButtonRejectDetail && !isAddMoreRecipients && (
          <RejectDetailButton
            onClick={onRejectDetails}
            isDotIconShow={isDotIconShow}
          />
        )}
        {isAddMoreRecipients && !isRejectSectionEnable && (
          <AddRecipientButton
            text={t("docDetails.button.recipientSettings")}
            onClick={() =>
              onClickRecipientSettings && onClickRecipientSettings()
            }
          />
        )}
      </div>
      <div className="overflow-auto h-auto max-h-[80%]">
        {sortedRecipientList.length > 0 &&
          sortedRecipientList.map((recipient, index) => (
            <RecipientStatusCard
              key={recipient.email}
              recipientInfo={recipient}
              currentColor={generateColorByEmail(recipient.email)}
              isCommentShow={isRejectSectionEnable}
              onCardClick={onRejectDetails}
              isRecipientAsSender={sender.email === recipient.email}
            />
          ))}
      </div>
    </RecipientListViewWrapper>
  );
};
