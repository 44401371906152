import React, { useEffect, useMemo, useRef } from "react";
import { Page } from "react-pdf";
type PDFPageRendererProps = {
  index: number;
  style: React.CSSProperties;
  data: dataProps;
};

type dataProps = {
  pageRef: React.MutableRefObject<(HTMLDivElement | null)[]>;
  scale: number;
};

export const PAGE_GAP = 10;
/** Inspired from https://github.com/michaeldzjap/react-pdf-sample/blob/master/src/PageRenderer.js */
export const PDFPageRenderer = ({
  index,
  data,
  style,
}: PDFPageRendererProps) => {
  const { pageRef, scale } = data;
  const pageWrapperRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null);
  const currPageNumber = index + 1;

  const draggableAreaWidth = useMemo(() => {
    return (
      Number(style.width?.toString().split("%")[0]) +
      (scale - 1) * 100
    ).toFixed();
  }, [scale, style.width]);

  useEffect(() => {
    /**
     * When scale change, the draggable area still keep the same bound width.
     * Re-calculated the draggable area width when scale change.
     * This element rendered by react-window (parent of pageWrapperRef)
     */
    const draggableBoundingAreaElement = pageWrapperRef.current?.parentElement;
    if (draggableBoundingAreaElement) {
      draggableBoundingAreaElement.style.width = `${draggableAreaWidth}%`;
    }
  }, [draggableAreaWidth]);

  return (
    <div ref={pageWrapperRef}>
      <div
        key={`page_${currPageNumber}`}
        style={style}
        className={`flex justify-start items-start p-0`}
        ref={(el) => (pageRef.current[index] = el)}
      >
        <Page
          // TODO make sure border width is same as PAGE_GAP,
          // tailwind doesn't support dynamic value, and Page doesn't support style
          className={`border-b-[10px] border-background-grey`}
          height={(style.height as number) - PAGE_GAP}
          pageNumber={currPageNumber}
          renderAnnotationLayer={false}
        />
      </div>
    </div>
  );
};
