import { INPUT_MAX_LENGTH } from "constants/common";

import { formatSearchValue } from "common/components/DataTable/SearchBar";
import { FieldContainer } from "common/components/form/FieldContainer";
import { StyledField } from "common/components/form/StyleField";
import { useField } from "formik";
import React, { ChangeEvent, RefObject } from "react";

type InputFieldProps = {
  className?: string;
  errorMsgClassName?: string;
  name: string;
  label?: string;
  type: string;
  disabled?: boolean;
  placeholder?: string;
  hideErrorMsg?: boolean;
  hideBorderError?: boolean;
  maxLength?: number;
  fieldRef?: RefObject<HTMLInputElement>;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  isTrimmed?: boolean;
};

export const InputField = ({
  className,
  errorMsgClassName,
  name,
  label,
  type,
  placeholder,
  disabled = false,
  hideErrorMsg,
  hideBorderError = true,
  maxLength = INPUT_MAX_LENGTH,
  fieldRef,
  onBlur,
  onFocus,
  isTrimmed = true,
}: InputFieldProps): JSX.Element => {
  const [, meta, helper] = useField(name);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isTrimmed) {
      const formattedValue = formatSearchValue(event.target.value);
      helper.setValue(formattedValue);
    }
  };
  return (
    <FieldContainer
      className={className}
      name={name}
      label={label}
      hideErrorMsg={hideErrorMsg}
      errorMsgClassName={errorMsgClassName}
      fieldError={meta.error}
    >
      <StyledField
        id={name}
        type={type}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        $hasError={!!meta.error}
        $hideErrorMsg={hideErrorMsg}
        $hideBorderError={hideBorderError}
        onBlur={onBlur}
        onFocus={onFocus}
        maxLength={maxLength}
        onChange={handleChange}
        innerRef={fieldRef}
      />
    </FieldContainer>
  );
};
