import { SvgEdit } from "common/components/svg";
import { CrossButton } from "features/documents/components/SignatureBox/CrossButton";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

type SignatureBoxEditOverlayProps = {
  onCrossClick?: MouseEventHandler<HTMLDivElement>;
  scaleRatio: number;
  isCrossShow: boolean;
};
export const SignatureBoxEditOverlay = ({
  onCrossClick,
  scaleRatio,
  isCrossShow,
}: SignatureBoxEditOverlayProps) => {
  const { t } = useTranslation();
  return (
    <div className="absolute hover:bg-light-black-opacity w-full h-full flex items-center justify-center rounded-md">
      <div>
        <SvgEdit className="text-white" />
      </div>
      <div
        className="ml-2.5 font-medium text-white text-base whitespace-nowrap flex justify-center items-center"
        data-cy="edit-signature"
      >
        {t("docRequest.signatureBox.editSignature")}
        {isCrossShow && (
          <CrossButton scaleRatio={scaleRatio} onClick={onCrossClick} />
        )}
      </div>
    </div>
  );
};
