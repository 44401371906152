import { UserIcon } from "common/components/UserIcon";
import { generateFullName } from "common/utils/common";
import { Colors } from "enums/colors";
import { RecipientInfoProps } from "features/documents/pages/documentRequest/types";

export type RecipientCardInformationProps = {
  currentColor: Colors;
  recipientInfo: RecipientInfoProps;
  className?: string;
};
export const RecipientCardInformation = ({
  recipientInfo,
  currentColor,
  className,
}: RecipientCardInformationProps) => {
  return (
    <div className={`flex items-center ${className}`}>
      <UserIcon
        className="min-w-[32px]"
        variant={"initials"}
        content={generateFullName(
          recipientInfo.firstName,
          recipientInfo.lastName
        )}
        color={currentColor}
      />
      <div className="flex flex-col w-[85%] ml-3">
        <span className="font-medium text-ellipsis overflow-hidden whitespace-nowrap">
          {generateFullName(recipientInfo.firstName, recipientInfo.lastName)}
        </span>
        <span className="text-dark-grey text-ellipsis overflow-hidden whitespace-nowrap">
          {recipientInfo.email}
        </span>
      </div>
    </div>
  );
};
