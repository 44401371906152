import { request } from "common/HOC/WithRequest";
import { CurrentUserInfoPayload } from "types/user";

export const userAPI = {
  getCurrentUserInfo: (): Promise<CurrentUserInfoPayload> => {
    return request<CurrentUserInfoPayload>("/user/current/info", "GET");
  },
  refreshSession: () => {
    return request("user/current/refresh-session", "GET");
  },
};
