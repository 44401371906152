import { Button } from "common/components/Button";
import { SearchBar } from "common/components/DataTable/SearchBar";
import { useGoToRowSelectedPage } from "common/hooks/useGoToRowSelectedPage";
import { DocumentFilterTabType } from "common/hooks/useLastDocumentFilterType";
import { CreatedAtCell } from "features/documents/components/Cells";
import { TitleWithBreadCrumbsCell } from "features/documents/components/TitleWithBreadCrumbsCell";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { DashboardResponse } from "types/common";

const SearchDropdownContainer = tw.div`absolute mt-2 p-4 max-h-[332px] w-full bg-white rounded-lg shadow-md`;

type SearchBarWithDropdownProps = {
  onChange: (value: string) => void;
  searchResults: DashboardResponse[];
  isOnReset: boolean;
  setIsOnReset: (isOnReset: boolean) => void;
  className?: string;
};

export const SearchBarWithDropdown = ({
  className,
  searchResults = [],
  onChange,
  isOnReset,
  setIsOnReset,
}: SearchBarWithDropdownProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleGoToRowSelectedPage } = useGoToRowSelectedPage();
  const [searchBarValue, setSearchBarValue] = useState<string>("");
  const [isResultsShow, setIsResultsShow] = useState(false);
  const [results, setResults] = useState<DashboardResponse[]>([]);

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      setResults(searchResults.slice(0, 4));
    } else {
      setResults([]);
    }
  }, [searchResults]);

  useEffect(() => {
    if (searchBarValue === "") {
      setIsResultsShow(false);
    } else {
      setIsResultsShow(true);
    }
  }, [searchBarValue]);

  const resetSearchBar = useCallback(() => {
    setIsOnReset && setIsOnReset(true);
  }, [setIsOnReset]);

  const hideDropdown = useCallback(() => {
    setIsResultsShow(false);
  }, []);

  const handleOnFocus = () => {
    if (searchBarValue !== "") setIsResultsShow(true);
  };

  const handleSearchBarChange = (value: string) => {
    onChange(value);
    setSearchBarValue(value);
  };

  const handleSelection = (selectedIndex: number) => {
    const selectedItem = results[selectedIndex];
    resetSearchBar();
    handleGoToRowSelectedPage(selectedItem, DocumentFilterTabType.All);
  };

  const handleClickSearchBreadcrumbs = () => {
    resetSearchBar();
  };

  const handleClickViewAllResults = () => {
    // reference: https://stackoverflow.com/questions/2828211/how-can-i-include-special-characters-in-query-strings
    navigate(`/documents/all?name=${encodeURIComponent(searchBarValue)}`);
    resetSearchBar();
  };

  return (
    <div className={className && className}>
      <div
        tabIndex={1}
        onBlur={hideDropdown}
        onFocus={handleOnFocus}
        className="relative"
      >
        <SearchBar
          onSearchChange={handleSearchBarChange}
          onEnter={handleClickViewAllResults}
          isOnReset={isOnReset}
          setIsOnReset={setIsOnReset}
        />

        {/* Search Results Container */}
        {isResultsShow && (
          <SearchDropdownContainer data-cy="search-results-dropdown">
            {results.length > 0 ? (
              <>
                {results.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onMouseDown={() => handleSelection(index)}
                      className="cursor-pointer mb-4"
                    >
                      <div className="w-full inline-flex justify-between space-x-4 text-dark-blue text-sm">
                        <TitleWithBreadCrumbsCell
                          value={item}
                          isDiscarded={false}
                          breadCrumbAlignPlace="title-text"
                          onClickSearchBreadcrumbs={
                            handleClickSearchBreadcrumbs
                          }
                        />
                        <CreatedAtCell value={item.createdAt} />
                      </div>
                    </div>
                  );
                })}
                <Button
                  data-cy="view-all-results-btn"
                  className="w-full tracking-wider"
                  variant="primary"
                  onMouseDown={handleClickViewAllResults}
                >
                  {t("dashboard.button.viewAllResults")}
                </Button>
              </>
            ) : (
              <div className="flex items-center justify-center py-3 text-dark-blue text-sm">
                {t("common.noResultsFound")}
              </div>
            )}
          </SearchDropdownContainer>
        )}
      </div>
    </div>
  );
};
