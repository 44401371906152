import { SvgUserPlus } from "common/components/svg";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

export const AddRecipientId = "add-recipient";
export type AddRecipientButtonProps = {
  onClick: () => void;
  className?: string;
  text?: string;
};
const StyleAddRecipientButton = tw.button`text-light-blue text-sm flex mt-7 font-medium`;
export const AddRecipientButton = ({
  onClick,
  text,
  className,
}: AddRecipientButtonProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyleAddRecipientButton
      id={AddRecipientId}
      type="button"
      onMouseDown={onClick}
      className={className}
      data-cy="add-recipient-btn"
    >
      <SvgUserPlus width={20} height={20} />
      <span className="ml-2.5">{text || t("docRequest.addRecipients")}</span>
    </StyleAddRecipientButton>
  );
};
