import * as React from "react";
import { SVGProps } from "react";

const SvgLoading = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={129}
    height={129}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 129 129"
    fill="none"
    {...props}
  >
    <path
      d="M64.0181 121.036C95.5083 121.036 121.036 95.5083 121.036 64.0181C121.036 32.5279 95.5083 7 64.0181 7C32.5279 7 7 32.5279 7 64.0181C7 95.5083 32.5279 121.036 64.0181 121.036Z"
      stroke="#80C6FF"
      strokeOpacity="0.3"
      strokeWidth="14"
    />
    <path
      d="M15.3171 98.2064C10.6347 89.8878 7.99932 80.5099 7.63037 70.8526C7.26142 61.1953 9.16949 51.5377 13.1959 42.6827C17.2223 33.8279 23.2506 26.0317 30.7797 19.9425C38.3087 13.8534 47.1208 9.6472 56.4831 7.67383"
      stroke="url(#paint0_linear_1123_26989)"
      strokeWidth="14"
      strokeLinecap="round"
      strokeDasharray="4 4"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1123_26989"
        x1="68.708"
        y1="6.39622"
        x2="68.708"
        y2="127.947"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A3FF" />
        <stop offset="0.755208" stopColor="#00A3FF" stopOpacity="0.01" />
        <stop offset="1" stopColor="#00A3FF" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgLoading;
