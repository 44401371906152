import { SvgFilePdf, SvgFolder } from "common/components/svg";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { DashboardResponse, ISODate } from "types/common";
import { DocumentRole } from "types/document";

type CreatedAtCellProps = {
  value: ISODate;
};

export const CreatedAtCell = ({ value }: CreatedAtCellProps): JSX.Element => (
  // TODO: keep this letter-spacing until dataTable max-width is readjusted
  <div className="-tracking-1">{format(new Date(value), "dd-MMM-yyyy")}</div>
);

/* Folder Management */
type TitleCellProps = {
  value: DashboardResponse;
  isDiscarded: boolean;
};

export const TitleCell = ({
  value,
  isDiscarded,
}: TitleCellProps): JSX.Element => {
  return (
    <div className="flex gap-1.5 max-w-full" data-cy="row-title">
      <div>
        {value.isFolder ? (
          <SvgFolder
            className={isDiscarded ? "text-grey" : "text-light-blue"}
          />
        ) : (
          <SvgFilePdf
            className={isDiscarded ? "text-grey" : "text-light-blue"}
          />
        )}
      </div>
      <p className="truncate whitespace-pre">
        {value.isFolder ? value.folderName : value.documentName}
      </p>
    </div>
  );
};

type UsageCellProps = {
  value?: Set<DocumentRole>;
};

export const UsageCell = ({ value }: UsageCellProps): string => {
  const { t } = useTranslation();
  if (value) {
    switch (true) {
      case value.has(DocumentRole.SIGNER):
        if (value.has(DocumentRole.AUTHENTICATOR)) {
          return t("common.usage.signAndAuthenticate");
        } else {
          return t("common.usage.sign");
        }
      case value.has(DocumentRole.AUTHENTICATOR):
        return t("common.usage.authenticate");
      default:
        console.error("Invalid Roles", value);
        return "";
    }
  }
  return "";
};
