import { Popover, Transition } from "@headlessui/react";
import { UserIcon, UserIconSize } from "common/components/UserIcon";
import { useCommonPopover } from "common/hooks/useCommonPopover";
import { useGenerateColorByString } from "common/hooks/useGenerateColorByString";
import { StyledUserIconCard } from "features/documents/components/SignatureBox/SignatureButtonBased";
import { Dispatch, SetStateAction, useState } from "react";
import tw, { styled } from "twin.macro";

type SignatureButtonRecipientInfoLabelPopoverProps = {
  children: (props: {
    setReferenceElement: Dispatch<
      SetStateAction<HTMLElement | null | undefined>
    >;
    isPopoverShow: boolean;
    setIsPopoverShow: Dispatch<SetStateAction<boolean>>;
  }) => React.ReactNode;
  isPopoverShowByDefault?: boolean;
  fullName: string;
  email: string;
};
export const SignatureButtonRecipientInfoLabelPopover = ({
  children,
  fullName,
  email,
  isPopoverShowByDefault = false,
}: SignatureButtonRecipientInfoLabelPopoverProps) => {
  const [isPopoverShow, setIsPopoverShow] = useState(isPopoverShowByDefault);

  const { setReferenceElement, setPopperElement, styles, attributes, update } =
    useCommonPopover({
      placement: "top-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      ],
    });

  const { color } = useGenerateColorByString(email);
  return (
    <Popover
      className="relative w-full h-full"
      onMouseLeave={() => {
        update && update();
      }}
    >
      {children({ setReferenceElement, isPopoverShow, setIsPopoverShow })}
      <Transition
        unmount={false} // fix initial wrong position https://github.com/tailwindlabs/headlessui/issues/985#issuecomment-1031922396
        show={isPopoverShow}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="w-[171px]"
          static
          tabIndex={-1}
        >
          <StyledUserIconCard>
            <div className="flex text-base">
              <UserIcon
                size={UserIconSize.SMALL}
                variant={"initials"}
                content={fullName}
                className="mr-3"
                color={color}
              />
              <p className="w-[120px] truncate">{fullName}</p>
            </div>
          </StyledUserIconCard>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export const ImageCenterContainer = styled.div(() => [
  tw`flex justify-center items-center w-full h-full`,
]);
