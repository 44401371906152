import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { isCurrentUserSenderAsRecipient } from "common/utils/common";
import { DocumentSenderInfo } from "features/documents/pages/postDocumentRequest/components/DocumentSenderInfo";
import { FileManagementTitle } from "features/documents/pages/postDocumentRequest/components/FileManagementTitle";
import { RecipientListView } from "features/documents/pages/postDocumentRequest/components/RecipientListView";
import { RelatedDocumentArea } from "features/documents/pages/postDocumentRequest/components/RelatedDocumentArea";
import { StyledFlexContainer } from "features/documents/pages/postDocumentRequest/pages/signing/components/DocumentSigningSetting";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  DocumentStatePayload,
  DocumentStatePayloadMutate,
} from "types/document";
interface DocumentViewerSettingProps {
  documentStatePayload: DocumentStatePayload;
  mutate: DocumentStatePayloadMutate;
}

export const DocumentViewerSetting = ({
  documentStatePayload,
  mutate,
}: DocumentViewerSettingProps) => {
  const currentUserProfile = useCurrentUserProfile();
  const navigate = useNavigate();
  const handleBack = () => {
    // reference: https://exerror.com/how-to-go-back-to-previous-route-in-react-router-dom-v6/
    navigate("/documents");
  };
  const isCurrentUserSenderSameAsRecipient = useMemo(() => {
    return isCurrentUserSenderAsRecipient(
      documentStatePayload,
      currentUserProfile
    );
  }, [currentUserProfile, documentStatePayload]);

  return (
    <StyledFlexContainer>
      <FileManagementTitle onClickBackButton={handleBack} />
      <RelatedDocumentArea
        mutate={mutate}
        documentStatePayload={documentStatePayload}
      />
      {!isCurrentUserSenderSameAsRecipient && (
        <DocumentSenderInfo documentStatePayload={documentStatePayload} />
      )}
      <RecipientListView
        listRecipients={documentStatePayload.recipients}
        sender={documentStatePayload.sender}
        height="middle"
      />
    </StyledFlexContainer>
  );
};
