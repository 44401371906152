import { Disclosure, Transition } from "@headlessui/react";
import classNames from "classnames";
import { StatusItem } from "common/components/StatusItem";
import { SvgChevronDown } from "common/components/svg";
import { useOriginalRoute } from "common/hooks/useOriginalRoute";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";

import { ObjectRoute } from "./Sidebar";
import { StyledNavLink } from "./StyledNavLink";

type NavItemProps = {
  route: ObjectRoute;
  expanded: boolean;
  className?: string;
};

export const NavItem = ({
  route,
  expanded,
  className,
}: NavItemProps): JSX.Element => {
  const isMatch = useMatch({ path: route.path, end: false });
  const { setIsOriginalRoute } = useOriginalRoute();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(expanded);

  useEffect(() => {
    setIsOpen(expanded);
  }, [expanded]);

  const childrenHeight = useMemo(() => {
    let height = "h-0";
    if (route.children?.length) {
      const bufferHeight = expanded ? 16 : 0;
      height = `h-[${bufferHeight + 37 * route.children?.length}px]`;
    }
    return height;
  }, [expanded, route.children?.length]);

  return (
    <Disclosure
      as="div"
      className={`relative text-grey ${className}`}
      defaultOpen
    >
      {({ close }) => {
        return (
          <>
            <Disclosure.Button
              className={`flex w-full cursor-pointer hover:text-light-blue ${
                expanded ? "justify-between" : "justify-center"
              } ${isMatch && "text-light-blue"}`}
              as="div"
              data-cy="sidebar-dropdown"
              onClick={() => {
                setIsOpen((prev) => !prev);
              }}
            >
              {expanded ? (
                <>
                  <div className="flex justify-start">
                    {route.icon}
                    <span className="font-medium text-sm ml-2">
                      {t(route.name)}
                    </span>
                  </div>
                  <SvgChevronDown
                    className={`transform ${
                      isOpen ? "rotate-0" : "rotate-[270deg]"
                    } w-5 h-5 text-purple-500`}
                    data-cy="sidebar-chevrondown"
                  />
                </>
              ) : (
                route.icon
              )}
            </Disclosure.Button>

            <Transition
              static
              show={isOpen}
              enter="transition-[height] duration-300 overflow-hidden ease-in-out"
              enterFrom="h-0"
              enterTo={childrenHeight}
              leave="transition-[height] duration-300 overflow-hidden ease-in-out"
              leaveFrom={childrenHeight}
              leaveTo="h-0"
              as="div"
              className={classNames({
                "absolute top-0 left-[4.25rem] bg-white w-[172px] rounded-lg shadow-xl":
                  !expanded,
              })}
            >
              <Disclosure.Panel
                static
                className={classNames({
                  "pt-4": expanded,
                })}
              >
                {route.children?.map((child: ObjectRoute, index: number) => (
                  <StyledNavLink
                    to={child.path}
                    key={index + child.name}
                    onClick={() => {
                      setIsOriginalRoute(true);
                      if (!expanded) {
                        close();
                        setIsOpen(false);
                      }
                    }}
                  >
                    <StatusItem key={child.name} iconSize={6}>
                      {t(child.name)}
                    </StatusItem>
                  </StyledNavLink>
                ))}
              </Disclosure.Panel>
            </Transition>
          </>
        );
      }}
    </Disclosure>
  );
};
