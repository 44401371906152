import { useModal } from "@liholiho/react-modal-hook";
import { folderAPI } from "api/folder";
import {
  composeErrorHandlers,
  GenericError,
  isAPIError,
} from "common/errorHandling";
import { toast, ToastContainerId } from "common/toast";
import {
  CreateNewFolderFormProps,
  CreateNewFolderPopup,
} from "features/documents/components/FolderManagement/components/CreateNewFolderPopup";
import { useFolderExistErrorPopup } from "features/documents/hooks/useFolderExistErrorPopup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormSubmissionHandler, SubmissionHandler } from "types/common";

export const FOLDER_NAME_EXIST_ERROR = "dashboard.message.folderExist";

export const useCreateNewFolderHandler = (
  toastContainerId: ToastContainerId = ToastContainerId.AfterLogin,
  currentFolderId: string | null = null,
  onMoveToArchiveSection: () => void,
  onSuccess?: () => void
): SubmissionHandler => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [folderNameExisted, setFolderNameExisted] = useState<string>("");
  const { t } = useTranslation();

  const [show, hide] = useModal(
    ({ in: isOpen }) => {
      return (
        <CreateNewFolderPopup
          isOpen={isOpen}
          onCancel={hide}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      );
    },
    [onSuccess, isSubmitting, currentFolderId]
  );

  const { handler: handleShowFolderExistError } = useFolderExistErrorPopup(
    folderNameExisted,
    show,
    onMoveToArchiveSection
  );

  const handleSubmit: FormSubmissionHandler<CreateNewFolderFormProps> = async (
    value,
    { setFieldError }
  ) => {
    setSubmitting(true);
    const trimFolderName = value.folderName.trim();
    try {
      await folderAPI.createNewFolder(trimFolderName, currentFolderId);
      setSubmitting(false);
      toast.success(
        t("common.toast.createFolder", { folderName: trimFolderName }),
        toastContainerId
      );
      if (onSuccess) {
        onSuccess();
      }
      hide();
    } catch (e) {
      setSubmitting(false);
      composeErrorHandlers((next) => (e) => {
        if (isAPIError(e)) {
          switch (e.errors[0]?.key) {
            case "Corda.FileSystem.InvalidFolderName":
              setFieldError("folderName", t(FOLDER_NAME_EXIST_ERROR));
              break;
            case "Corda.FileSystem.InvalidFolderName.Archived":
              setFolderNameExisted(trimFolderName);
              hide();
              handleShowFolderExistError();
              break;
            default:
              next(e);
          }
        } else {
          next(e);
        }
      })(e as GenericError);
    }
  };

  return {
    isSubmitting,
    handler: show,
  };
};
