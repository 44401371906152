import { SvgInformation } from "common/components/svg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { styled } from "twin.macro";
const FORWARD_TOOLTIP_ID = "forwardTooltip";

const StyledReactTooltip = styled(ReactTooltip)`
  border-radius: 8px !important;
`;

export const ForwardHintMessage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    // Some cases that the ReactTooltip not show when hover
    // rebuild it when component mounted to make sure it will show correctly
    ReactTooltip.rebuild();
  }, []);
  return (
    <>
      <SvgInformation
        width="20"
        height="20"
        className="ml-[10.5px] text-grey cursor-pointer"
        data-tip
        data-for={FORWARD_TOOLTIP_ID}
      />
      <StyledReactTooltip
        id={FORWARD_TOOLTIP_ID}
        effect="solid"
        place="top"
        offset={{ left: 133, top: 10 }}
      >
        <div className="w-[288px] text-sm">
          {t("dashboard.forwardDocument.hint1")}
          <br />
          <br />
          {t("dashboard.forwardDocument.hint2")}
        </div>
      </StyledReactTooltip>
    </>
  );
};
