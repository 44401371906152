import { useCallbackPrompt } from "common/hooks/useCallbackPrompt";
import { useCancelDocumentRequestHandler } from "features/documents/pages/documentRequest/hooks/useCancelDocumentRequestHandler";
import { useCancelDocumentActionsHandler } from "features/documents/pages/postDocumentRequest/hooks/useCancelDocumentActionsHandler";
import { useEffect } from "react";

export enum CancelConfirmType {
  DOCUMENT_REQUEST = "DOCUMENT_REQUEST",
  DOCUMENT_ACTIONS = "DOCUMENT_ACTIONS",
}

export const useCancelConfirmPopup = (
  popupType: CancelConfirmType,
  allowPaths: string[] = [],
  forceLeavePage: boolean = false
): void => {
  const isConfirmationShow = true;
  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    isConfirmationShow,
    allowPaths,
    forceLeavePage
  );

  const { handler: handleCancelDocumentActions } =
    useCancelDocumentActionsHandler(
      () => {
        confirmNavigation();
      },
      () => {
        cancelNavigation();
      }
    );

  const { handler: handleCancelDocumentRequest } =
    useCancelDocumentRequestHandler(
      () => {
        confirmNavigation();
      },
      () => {
        cancelNavigation();
      }
    );

  useEffect(() => {
    if (showPrompt) {
      if (popupType === CancelConfirmType.DOCUMENT_REQUEST) {
        handleCancelDocumentRequest();
      } else handleCancelDocumentActions();
    }
    /* This is for block infinite re-render */
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPrompt]);
};
