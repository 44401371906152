import { Button } from "common/components/Button";
import { Message } from "common/components/Message";
import { generateColorByString } from "enums/colors";
import { ControlPanel } from "features/documents/pages/documentRequest/components/ControlPanel";
import {
  RecipientDisplayMode,
  RecipientWithRoleCard,
} from "features/documents/pages/documentRequest/components/RecipientWithRoleCard";
import {
  RecipientInfoProps,
  SettingRolePermissionFormValue,
} from "features/documents/pages/documentRequest/types";
import { useFormikContext } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type ChooseRecipientsRoleProps = {
  recipients: RecipientInfoProps[];
  invalidRecipients: string[];
  onBack: () => void;
};

export const ChooseRecipientsRole = ({
  recipients,
  invalidRecipients,
  onBack,
}: ChooseRecipientsRoleProps): JSX.Element => {
  const { values, errors } = useFormikContext<SettingRolePermissionFormValue>();
  const generateColorByEmail = useCallback(
    (email: string) => generateColorByString(email),
    []
  );
  const { t } = useTranslation();

  return (
    <div className="relative h-full">
      <ControlPanel
        contentSection={
          <>
            <div className="flex items-center mb-7 justify-between">
              <h3>{t("docRequest.addRecipient.title")}</h3>
            </div>
            <div className="absolute w-full h-4/6 overflow-y-auto">
              {values.recipientRoles.map((recipient, index) => (
                <RecipientWithRoleCard
                  key={recipient.recipient.email ?? recipients[index].email}
                  mode={RecipientDisplayMode.CHOOSE_ROLE}
                  currentColor={generateColorByEmail(recipient.recipient.email)}
                  recipientInfo={recipient.recipient}
                  recipientRole={recipient?.roles}
                  recipientDownloadPermission={recipient.canDownload}
                  className={`${
                    invalidRecipients.includes(recipient.recipient.email)
                      ? "bg-light-red-opacity"
                      : ""
                  }`}
                />
              ))}
            </div>
          </>
        }
        buttonSection={
          <div className="absolute w-full bottom-12">
            {errors.recipientRoles && (
              <Message
                className="mb-2.5 text-xs"
                isBackgroundEnable={false}
                variant="warning"
              >
                {errors.recipientRoles}
              </Message>
            )}
            <div>
              <Button
                variant="secondary"
                type="button"
                onClick={onBack}
                className="absolute left-0"
              >
                {t("common.back")}
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="absolute right-0"
              >
                {t("common.next")}
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};
