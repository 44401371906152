import { useModal } from "@liholiho/react-modal-hook";
import { AuthenticateDocumentPopup } from "features/documents/components/ActionPopup/AuthenticateDocumentPopUp";
import { SignAndAuthenticateDocumentPopup } from "features/documents/components/ActionPopup/SignAndAuthenticateDocumentPopUp";
import { SignDocumentPopup } from "features/documents/components/ActionPopup/SignDocumentPopup";
import { SenderAsRecipientMode } from "features/documents/pages/documentRequest/types";
import { useState } from "react";
import { Trans } from "react-i18next";
import { SubmissionHandler } from "types/common";

export const useActionPopupHandler = (
  variant: SenderAsRecipientMode,
  fileNames: string[] | string,
  onConfirm?: () => Promise<any>,
  onCancel?: () => void
): SubmissionHandler => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [show, hide] = useModal(
    ({ in: isOpen }) => {
      const handleConfirm = async () => {
        setSubmitting(true);
        onConfirm && (await onConfirm());
        hide();
        setSubmitting(false);
      };
      const handleCancel = () => {
        onCancel && onCancel();
        hide();
      };

      const popup = () => {
        switch (variant) {
          case SenderAsRecipientMode.AUTHENTICATE:
            return (
              <AuthenticateDocumentPopup
                fileName={fileNames}
                isOpen={isOpen}
                isSubmitting={isSubmitting}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
              />
            );
          case SenderAsRecipientMode.SIGNING:
            return (
              <SignDocumentPopup
                fileName={fileNames}
                isOpen={isOpen}
                isSubmitting={isSubmitting}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
              />
            );
          case SenderAsRecipientMode.SIGNANDAUTHENTICATE:
            return (
              <SignAndAuthenticateDocumentPopup
                fileName={fileNames}
                isOpen={isOpen}
                isSubmitting={isSubmitting}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
              />
            );
          default:
            return null;
        }
      };

      return popup();
    },
    [onConfirm, isSubmitting, variant]
  );

  return {
    isSubmitting,
    handler: show,
  };
};

export const ActionPopupTitle = ({
  titleKey,
  fileName,
}: {
  titleKey: string;
  fileName: string;
}): JSX.Element => {
  return (
    <Trans i18nKey={titleKey} values={{ fileName: fileName }}>
      <span />
      <span className="text-light-blue font-medium leading-5.5 align-bottom inline-block max-w-[70%] truncate" />
    </Trans>
  );
};
