import { attachmentAPI } from "api/attachment";
import { documentAPI, InfiniteDocumentListResult } from "api/document";
import { Button } from "common/components/Button";
import { Modal } from "common/components/Modal";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { isEqualIgnoreCase } from "common/utils/common";
import { isCurrentUserWithDownloadPermission } from "common/utils/document";
import { DocumentTable } from "features/documents/components/DocumentTable";
import { DocumentTableHeader } from "features/documents/components/DocumentTableHeader";
import { DocumentTableWrapper } from "features/documents/components/DocumentTableWrapper";
import {
  DocumentViewStatus,
  getDashboardItemViewStatus,
} from "features/documents/components/StatusCell";
import { DUPLICATE_FILE_UPLOADED_ERROR } from "features/documents/pages/documentRequest/pages/UploadDocument/utils/common";
import { PDFFile } from "features/documents/pages/documentRequest/types";
import { useField } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "react-table";
import tw, { styled } from "twin.macro";
import { DashboardResponse } from "types/common";

const PopUpTitle = styled.div(() => [
  tw`p-8 pb-0 font-medium text-[20px] leading-7`,
]);

type KentroDocumentPopupProps = {
  name: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  documentsFetchResult: InfiniteDocumentListResult;
  onSearchChange: (searchValue: string) => void;
};

export const KentroDocumentPopup = ({
  documentsFetchResult,
  name,
  isOpen,
  setIsOpen,
  onSearchChange,
}: KentroDocumentPopupProps): JSX.Element => {
  const [field, , helpers] = useField<PDFFile[]>(name);
  const [selectedFiles, setSelectedFiles] = useState<Row<DashboardResponse>[]>(
    []
  );
  const { documents, total, fetchNext } = documentsFetchResult;
  const currentUserProfile = useCurrentUserProfile();
  const documentsFromKentro = useMemo(() => {
    return isOpen
      ? documents?.filter((it) => {
          // TODO extract to utils and add tests
          const isForwardedUser =
            !it.recipients?.some((it) =>
              isEqualIgnoreCase(it.email, currentUserProfile?.email)
            ) &&
            !isEqualIgnoreCase(it.sender?.email, currentUserProfile?.email);
          const isDocumentViewStatusCompleted =
            getDashboardItemViewStatus(
              it.recipients,
              it.sender,
              it.status,
              currentUserProfile?.email
            ) === DocumentViewStatus.Completed;

          return (
            (isDocumentViewStatusCompleted &&
              isCurrentUserWithDownloadPermission(
                it.recipients,
                it.sender,
                currentUserProfile?.email
              )) ||
            isForwardedUser
          );
        })
      : [];
  }, [documents, currentUserProfile?.email, isOpen]);

  const { t } = useTranslation();

  const hasMore = () => documents?.length !== total;

  const handleClosePopUp = () => {
    setSelectedFiles([]);
    setIsOpen(false);
    onSearchChange("");
  };

  const handleRowSelected = (row: Row<DashboardResponse>) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.find((file) => file.original.id === row.original.id)
        ? prevFiles.filter((file) => file.original.id !== row.original.id)
        : [...prevFiles, row]
    );
  };

  // Submit file to document request
  const handleUploadKentroFiles = async () => {
    // Convert list file to list PDF files format
    try {
      helpers.setError(undefined);
      const selectedFilesPDFFormat: PDFFile[] = await Promise.all(
        selectedFiles.map(async (item): Promise<PDFFile> => {
          const documentLinearId = item.original.id;
          const fileLatest = await documentAPI.getLatestDocument(
            documentLinearId
          );
          const completedFileId = fileLatest.completedFile?.id ?? "";
          const fileName = item.original.documentName ?? "";

          return attachmentAPI
            .download(completedFileId, item.original.id)
            .then((data: Blob) => {
              return new Promise<PDFFile>((resolve) => {
                const file = new File([data], fileName, {
                  type: data.type,
                });
                resolve({
                  id: completedFileId,
                  name: fileName,
                  size: file.size,
                  loaded: file.size,
                  URL: URL.createObjectURL(file),
                  isFromKentro: true,
                  linearId: documentLinearId,
                });
              });
            });
        })
      );
      // Remove duplicate files by name and size
      const selectedCleanFiles = selectedFilesPDFFormat.filter(
        (fileSelected) => {
          const existedFile = field.value.find(
            (fileUpload) =>
              fileUpload.id === fileSelected.id ||
              (fileUpload.name === fileSelected.name &&
                fileUpload.size === fileSelected.size)
          );
          if (existedFile) {
            helpers.setError(t(DUPLICATE_FILE_UPLOADED_ERROR));
            return false;
          }
          return true;
        }
      );
      // Set value for formik
      helpers.setValue([...field.value, ...selectedCleanFiles]);
    } catch (error) {
      console.error("error", error);
    }
    // Clear selected files active in datatable
    setSelectedFiles([]);
    // Close popup
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen} onClose={handleClosePopUp} size="elg">
      <PopUpTitle data-cy="kentro-popup-title">
        {t("docRequest.kentroPopup.title")}
      </PopUpTitle>
      <DocumentTableWrapper>
        <DocumentTableHeader onSearchChange={onSearchChange} />
        <DocumentTable
          documents={documentsFromKentro ?? []}
          isArchived={false}
          fetchNext={fetchNext}
          hasMore={hasMore}
          scrollHeight={300}
          onRowSelected={handleRowSelected}
          selectedRows={selectedFiles}
          isGlobalSearch={true}
          isPending={!documentsFromKentro}
        />
      </DocumentTableWrapper>

      <div className="flex justify-between p-8">
        <Button
          variant="secondary"
          className="text-light-blue w-3/12"
          onClick={handleClosePopUp}
          data-cy="kentro-popup-close"
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant="primary"
          className="w-3/12"
          onClick={handleUploadKentroFiles}
          disabled={selectedFiles.length <= 0}
          data-cy="kentro-popup-select"
        >
          {t("docRequest.button.select")}
        </Button>
      </div>
    </Modal>
  );
};
