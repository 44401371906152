import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { useMemo } from "react";
import {
  DocumentStatePayload,
  DocumentStatus,
  ParticipantResponse,
} from "types/document";

export const useIsActionTaken = (
  documentStatePayload: DocumentStatePayload | undefined
): boolean => {
  const currentUserProfile = useCurrentUserProfile();
  const isActionTaken = useMemo(() => {
    const recipientResponse = documentStatePayload?.recipients.find(
      (recipient) => recipient.email === currentUserProfile?.email
    )?.response;

    return isActionTakenByStatus(
      documentStatePayload?.status,
      recipientResponse
    );
  }, [documentStatePayload, currentUserProfile]);

  return isActionTaken;
};

export const isActionTakenByStatus = (
  documentStatus: DocumentStatus | undefined,
  participantResponse: ParticipantResponse | undefined
): boolean => {
  if (
    documentStatus === DocumentStatus.COMPLETED ||
    documentStatus === DocumentStatus.DIGITAL_SIGNED
  ) {
    return true;
  } else if (participantResponse === undefined) {
    // when sender views the document, but the file is not completed
    return false;
  } else {
    return (
      participantResponse !== ParticipantResponse.PENDING &&
      participantResponse !== ParticipantResponse.VIEWED
    );
  }
};
