import { A4_PAGE_WIDTH } from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { RndBehaviorHandler } from "common/components/form/PDF/SignatureRndHandler/RndBehaviorHandler";
import React, { useMemo } from "react";
import { Rnd, RndDragEvent, DraggableData } from "react-rnd";
import { SignatureSchemas } from "types/document";

type SignatureHandlerBasedProps = {
  style: React.CSSProperties;
  buttonComponent: (
    signatureSchema: SignatureSchemas,
    index: number,
    isHover: boolean,
    setIsHover: React.Dispatch<React.SetStateAction<boolean>>,
    resizingSpace: number,
    isDrag: boolean,
    isResizing: boolean
  ) => JSX.Element;
  resizeConfig: {
    bottom: boolean;
    bottomLeft: boolean;
    bottomRight: boolean;
    left: boolean;
    right: boolean;
    top: boolean;
    topLeft: boolean;
    topRight: boolean;
  };
  isSignatureDisplay: boolean;
  signatureSchemas: SignatureSchemas[];
  isDraggable: boolean;
  onDragStop?: (
    event: RndDragEvent,
    data: DraggableData,
    index: number
  ) => void;
  onResizeStop?: (
    event: RndDragEvent,
    direction: any,
    ref: HTMLElement,
    index: number
  ) => void;
  scale: number;
  children: React.ReactNode;
  pageWidth: number;
};
export const SPACE_PX = 16;
export const SignatureRndHandlerBased = React.forwardRef(
  (
    {
      style,
      resizeConfig,
      isSignatureDisplay,
      signatureSchemas,
      isDraggable,
      onDragStop = () => {},
      onResizeStop = () => {},
      scale,
      buttonComponent,
      children,
      pageWidth,
      ...rest
    }: SignatureHandlerBasedProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const adjustFactor = useMemo(
      () => (pageWidth / A4_PAGE_WIDTH) * scale,
      [pageWidth, scale]
    );

    return (
      <div ref={ref} style={style} {...rest}>
        {children}
        {isSignatureDisplay &&
          signatureSchemas.map((item, index) => {
            return (
              <RndBehaviorHandler
                key={`rnd_${index}`}
                initialPosVal={{ x: item.x, y: item.y }}
                adjustFactor={adjustFactor}
              >
                {({
                  isHover,
                  setIsHover,
                  isDrag,
                  handleMouseDown,
                  handleMouseMove,
                  updateResizeSpace,
                  resizingSpace,
                  isResizing,
                  setIsResizing,
                }) => (
                  <Rnd
                    bounds="parent"
                    onDragStart={(event, data) => {
                      handleMouseDown({ x: data.x, y: data.y });
                    }}
                    disableDragging={!isDraggable}
                    onDragStop={(event, data) => {
                      handleMouseMove({ x: data.x, y: data.y });
                      onDragStop(event, data, index);
                    }}
                    onResizeStop={(e, direction, ref) => {
                      onResizeStop(e, direction, ref, index);
                      updateResizeSpace(ref.offsetWidth);
                      setIsResizing(false);
                    }}
                    onResizeStart={() => {
                      setIsResizing(true);
                    }}
                    className={`${isHover ? `z-tooltip` : `z-popover`}`}
                    size={{
                      width: item.width * scale,
                      height: item.height * scale,
                    }}
                    position={{
                      x: item.x * scale,
                      y: item.y * scale,
                    }}
                    minHeight={40 * adjustFactor}
                    minWidth={170 * adjustFactor}
                    maxHeight={80 * adjustFactor}
                    maxWidth={340 * adjustFactor}
                    resizeHandleComponent={{
                      bottomRight: React.createElement("div", {
                        className:
                          "bg-white w-2 h-2 rounded-full shadow shadow-black/25 translate-x-1 translate-y-1",
                      }),
                    }}
                    enableResizing={resizeConfig}
                    lockAspectRatio={true}
                  >
                    {buttonComponent(
                      item,
                      index,
                      isHover,
                      setIsHover,
                      resizingSpace,
                      isDrag,
                      isResizing
                    )}
                  </Rnd>
                )}
              </RndBehaviorHandler>
            );
          })}
      </div>
    );
  }
);
