import { INPUT_MAX_LENGTH } from "constants/common";
import {
  REG_SEARCH_HEAD_SPACE,
  REG_SEARCH_MIDDLE_SPACE,
  REG_SEARCH_TAIL_SPACE,
} from "constants/searchBarValidator";

import { SvgSearch } from "common/components/svg";
import { debounce } from "lodash";
import { FormEvent, useEffect, useState } from "react";
import { ChangeEvent, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type SearchBarComponentProps = {
  onSearchChange: (searchValue: string) => void;
  onEnter?: () => void;
  isOnReset?: boolean;
  setIsOnReset?: (isOnReset: boolean) => void;
};

const SearchField = tw.input`block bg-background-grey text-dark-blue rounded px-2 py-3 border border-background-grey focus:border-navy w-full h-10 font-medium pl-12`;

export const formatSearchValue = (searchValue: string): string => {
  if (REG_SEARCH_HEAD_SPACE.test(searchValue)) {
    searchValue = searchValue.trimStart();
  }

  if (REG_SEARCH_MIDDLE_SPACE.test(searchValue)) {
    searchValue = searchValue.replace(/\s+/g, " ");
  }

  if (REG_SEARCH_TAIL_SPACE.test(searchValue)) {
    searchValue = searchValue.trimEnd();
  }

  return searchValue;
};

export const SearchBar = ({
  onSearchChange,
  onEnter,
  isOnReset = false,
  setIsOnReset,
}: SearchBarComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    if (isOnReset) {
      setSearchValue("");
      if (onSearchChange) {
        onSearchChange("");
      }
    }
  }, [isOnReset, onSearchChange]);

  // reference: https://stackoverflow.com/questions/31272207/to-call-onchange-event-after-pressing-enter-key
  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onEnter && onEnter();
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValueTrimmed = formatSearchValue(e.target.value);
    setSearchValue(searchValueTrimmed);
    if (setIsOnReset) {
      setIsOnReset(false);
    }
    if (onSearchChange) {
      debounce(
        (): void => {
          onSearchChange(formatSearchValue(e.target.value));
        },
        500,
        { maxWait: 1500 }
      )();
    }
  };

  const handleSubmitSearch = (e: FormEvent) => {
    e.preventDefault();
    const searchValueTrimmed = searchValue.trim();
    setSearchValue(searchValueTrimmed);
    if (setIsOnReset) {
      setIsOnReset(false);
    }
    if (onSearchChange) {
      onSearchChange(searchValueTrimmed);
    }
  };

  return (
    <div>
      <form
        autoComplete="off"
        noValidate
        className="flex items-center justify-start bg-background-grey rounded relative"
        onSubmit={handleSubmitSearch}
        onBlur={handleSubmitSearch}
      >
        <div className="absolute left-4">
          <SvgSearch className="text-dark-grey" />
        </div>
        <SearchField
          name="searchValue"
          type="text"
          placeholder={t("common.search")}
          maxLength={INPUT_MAX_LENGTH}
          value={searchValue}
          onChange={handleSearchChange}
          onKeyUp={handleKeyUp}
        />
      </form>
    </div>
  );
};
