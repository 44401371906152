import { CancelAndNextButtons } from "features/documents/pages/documentRequest/pages/AddRecipients/components/CancelAndNextButtons";
import { RecipientCreateForm } from "features/documents/pages/documentRequest/pages/AddRecipients/components/RecipientCreateForm";
import { RecipientListFormValues } from "features/documents/pages/documentRequest/types";
import { useFormikContext } from "formik";
type RecipientsControlPanelProps = {
  onBack: () => void;
};
export const RecipientsControlPanel = ({
  onBack,
}: RecipientsControlPanelProps): JSX.Element => {
  const { submitForm, resetForm } = useFormikContext<RecipientListFormValues>();
  const handleClickCancel = () => {
    // TODO: when click cancel button ,need to clear form
    resetForm({ values: { recipientInfo: [] } });
    onBack();
  };
  const handleClickNext = () => {
    submitForm();
  };

  return (
    <RecipientCreateForm>
      <CancelAndNextButtons
        onClickCancel={handleClickCancel}
        onClickNext={handleClickNext}
      />
    </RecipientCreateForm>
  );
};
