import {
  KentroToastContainer,
  ToastCloseButton,
} from "common/components/KentroToastContainer";
import { ProgressBarWithText } from "common/components/ProgressBarWithText";
import { SvgX } from "common/components/svg";
import { ToastContainerId } from "common/toast";
import { DocumentRequestStep } from "features/documents/pages/documentRequest/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type DocumentRequestTemplateProps = {
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
  step: DocumentRequestStep;
};

export const DocumentRequestTemplate = ({
  leftComponent,
  rightComponent,
  step,
}: DocumentRequestTemplateProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const STEP_TITLE = [
    t("docRequest.uploadDocuments"),
    t("docRequest.addRecipients"),
    t("docRequest.roleAndPermissions"),
    t("docRequest.confirmDetails"),
  ];

  const isSubmitted =
    step === DocumentRequestStep.SubmitSuccess ||
    step === DocumentRequestStep.SubmitFail;

  return (
    <div className="grid grid-cols-12 h-full">
      <div className="col-span-7">{leftComponent}</div>
      <div className="relative col-span-5">
        <KentroToastContainer
          containerId={ToastContainerId.DocumentRequest}
          position="top-center"
          enableMultiContainer
          autoClose={false}
          closeButton={ToastCloseButton}
        />
        <div className="flex flex-col bg-white px-5 py-6 h-full ">
          {!isSubmitted && (
            <>
              <div className="flex items-center justify-between my-5">
                <h1 className="text-dark-blue">{t("docRequest.title")}</h1>
                <SvgX
                  className="bg-light-greyscale text-dark-grey cursor-pointer rounded-[50%]"
                  onClick={() => {
                    navigate("/documents");
                  }}
                />
              </div>
              <ProgressBarWithText
                currentPage={step}
                pageTitle={STEP_TITLE[step - 1]}
                pageAmount={4}
                progressPercentage={(step / 4) * 100}
                reverse={true}
              />
            </>
          )}
          {rightComponent}
        </div>
      </div>
    </div>
  );
};
