import { useModal } from "@liholiho/react-modal-hook";
import { documentAPI } from "api/document";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { toast, ToastContainerId } from "common/toast";
import { CommentRejectDocumentPopup } from "features/documents/pages/documentManagement/components/CommentRejectDocumentPopUp";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubmissionHandler } from "types/common";
import { DocumentRole, DocumentStatePayload } from "types/document";

export const useCommentComponentHandler = (
  documentStatePayload: DocumentStatePayload,
  toastContainerId: ToastContainerId = ToastContainerId.DocumentDetail,
  onSuccess?: () => void
): SubmissionHandler => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const currentUserProfile = useCurrentUserProfile();
  const currentRoles = useMemo(() => {
    return documentStatePayload.recipients.find(
      (recipient) => recipient.email === currentUserProfile?.email
    )?.roles;
  }, [documentStatePayload, currentUserProfile]);

  const generateToastMessageByRole = useCallback(
    (role: DocumentRole[] | undefined) => {
      if (role && role.length > 0) {
        if (role.length > 1) {
          return t("docDetails.message.rejectSignAndAuthenticate");
        }

        return role[0] === DocumentRole.SIGNER
          ? t("docDetails.message.rejectSigning")
          : t("docDetails.message.rejectAuthenticate");
      }
    },
    [t]
  );

  const [show, hide] = useModal(
    ({ in: isOpen }) => {
      const handleConfirm = async (comment: string) => {
        setSubmitting(true);
        try {
          await documentAPI.reject(documentStatePayload.ref, comment.trim());
          /* TODO: correct style for toast message in file management */
          toast.success(
            generateToastMessageByRole(currentRoles),
            toastContainerId
          );
          hide();
          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          composeErrorHandlers(() =>
            baseFallbackErrorHandler(toastContainerId)
          )(e as GenericError);
        }
        setSubmitting(false);
      };

      return (
        <CommentRejectDocumentPopup
          document={documentStatePayload}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
          onConfirm={handleConfirm}
          onCancel={hide}
          roles={currentRoles}
        />
      );
    },
    [onSuccess, documentStatePayload, isSubmitting]
  );

  return {
    isSubmitting,
    handler: show,
  };
};
