import { attachmentAPI } from "api/attachment";
import { Card } from "common/components/Card";
import { Dropzone } from "common/components/form";
import { SvgKentroLogo, SvgUpload } from "common/components/svg";
import { uploadAndHandleProgressBar } from "common/utils/upload";
import { PureUploadDocumentsCreateContext } from "features/documents/pages/documentRequest/pages/UploadDocument/context/PureUploadDocumentsCreateContext";
import {
  DUPLICATE_FILE_UPLOADED_ERROR,
  isFileDuplicate,
} from "features/documents/pages/documentRequest/pages/UploadDocument/utils/common";
import { PDFFile } from "features/documents/pages/documentRequest/types";
import { useField } from "formik";
import { cloneDeep } from "lodash";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type FileUploadFieldProps = {
  name: string;
  setIsOpenKentroPopUp: (isOpenKentroPopUp: boolean) => void;
};

const StyledContentWrapper = tw.div`flex flex-col items-center justify-center`;
const StyledCard = tw(
  Card
)`px-4 py-6 ml-8 flex-col justify-center items-center cursor-pointer bg-white box-border w-[130px] h-[130px] flex-nowrap text-center`;

export const FileUploadField = ({
  name,
  setIsOpenKentroPopUp,
}: FileUploadFieldProps) => {
  const [field, meta, helpers] = useField<PDFFile[]>(name);
  const { setNewUploadedFiles, isUploading, setIsUploading } = useContext(
    PureUploadDocumentsCreateContext
  );
  const { t } = useTranslation();

  const handleChangeFile = useCallback(
    async (uploadingFiles: File[]) => {
      setIsUploading(true);
      helpers.setError(undefined);
      const uploadedFiles = cloneDeep(field.value);

      const nonDuplicatedUploadingFiles = getNonDuplicatedFiles(
        uploadingFiles,
        uploadedFiles
      );

      if (nonDuplicatedUploadingFiles.length < uploadingFiles.length) {
        helpers.setError(t(DUPLICATE_FILE_UPLOADED_ERROR));
      }
      // only handle if contains valid file
      if (nonDuplicatedUploadingFiles.length) {
        try {
          const newUploadedFilesResponse = await uploadAndHandleProgressBar(
            nonDuplicatedUploadingFiles,
            uploadedFiles,
            attachmentAPI.upload,
            helpers.setValue
          );
          //  prepare to write back to form
          if (uploadedFiles[0].id) {
            for (let item of uploadedFiles) {
              const newUploadedFile = newUploadedFilesResponse.find((fileRes) =>
                isFileDuplicate(fileRes, item.name, item.size)
              );
              if (newUploadedFile) {
                uploadedFiles[uploadedFiles.indexOf(item)] = newUploadedFile;
                setNewUploadedFiles(uploadedFiles);
              }
            }
          } else {
            // KT-503's bug is from here, because user is cloning field.value at different moments
            setNewUploadedFiles(newUploadedFilesResponse);
          }
        } catch (error) {
          console.error("error", error);
        }
      }
      setIsUploading(false);
    },
    [field.value, helpers, setIsUploading, setNewUploadedFiles, t]
  );

  return (
    <Dropzone
      className="py-12"
      onChange={handleChangeFile}
      fieldError={meta.error}
      setFieldError={helpers.setError}
      isDisabled={isUploading}
    >
      {(open) => (
        <StyledContentWrapper>
          <h3 className="text-dark-blue mb-2">
            {t("docRequest.dropZone.title")}
          </h3>
          <p className="text-grey text-xs font-medium mb-8">
            {t("docRequest.dropZone.titleHint")}
          </p>
          <p className="text-grey font-medium mb-8">{t("docRequest.or")}</p>
          <div className="flex flex-row">
            <StyledCard dataCy="local-upload" onClick={open}>
              <SvgUpload width={48} height={48} className="text-pale-blue" />
              <p className="font-medium text-sm text-dark-blue">
                {t("docRequest.dropZone.localUpload")}
              </p>
            </StyledCard>
            <StyledCard
              dataCy="kentro-upload"
              onClick={() => {
                !isUploading && setIsOpenKentroPopUp(true);
              }}
            >
              <div className="mb-5">
                <SvgKentroLogo
                  width={48}
                  height={48}
                  className="text-pale-blue"
                />
              </div>
              <p className="font-medium text-sm px-1 text-dark-blue">
                {t("docRequest.dropZone.filesOnKentro")}
              </p>
            </StyledCard>
          </div>
        </StyledContentWrapper>
      )}
    </Dropzone>
  );
};

export const getNonDuplicatedFiles = (
  uploadingFiles: File[],
  uploadedFiles: PDFFile[]
) => {
  return uploadingFiles.filter(
    (f) =>
      !uploadedFiles?.some(
        (uploadedFile) =>
          uploadedFile.name === f.name && uploadedFile.size === f.size
      )
  );
};
