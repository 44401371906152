import {
  Dimension,
  SignaturePercentageProps,
} from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import {
  CancelConfirmType,
  useCancelConfirmPopup,
} from "common/hooks/useCancelConfirmPopup";
import DocumentRequestReducer from "features/documents/pages/documentRequest/context/DocumentRequestReducer";
import {
  DocumentRequestFormValue,
  PDFFile,
  RecipientListFormValues,
  SettingRolePermissionFormValue,
} from "features/documents/pages/documentRequest/types";
import React, { useReducer, useState } from "react";

interface DocumentRequestContextProps {
  children: React.ReactNode;
}

const initialState: DocumentRequestFormValue = {
  filesUpload: [],
  recipients: {
    recipientInfo: [],
  },
  rolePermission: {
    recipientRoles: [],
  },
  signatures: [[]],
  authenticatedFiles: [],
  fileDimensions: [[]],
};

// TODO refactor KT-1067
type DocumentContextValues = {
  filesUpload: PDFFile[];
  recipients: RecipientListFormValues;
  rolePermission: SettingRolePermissionFormValue;
  signatures: SignaturePercentageProps[][];
  authenticatedFiles: PDFFile[];
  fileDimensions: Dimension[][];
};

export const DocumentRequestContext = React.createContext(initialState);

const documentRequestPathname = "/documents/create-request/document-request";
const documentUploadPathname = "/documents/create-request/upload-documents";

export const DocumentRequestContextProvider = ({
  children,
}: DocumentRequestContextProps) => {
  const [state, dispatch] = useReducer(DocumentRequestReducer, initialState);
  const [isForcePageLeave, setForcePageLeave] = useState(false);
  useCancelConfirmPopup(
    CancelConfirmType.DOCUMENT_REQUEST,
    [documentUploadPathname, documentRequestPathname],
    isForcePageLeave
  );

  const contextValues: DocumentContextValues = {
    setForcePageLeave,
    dispatch,
    ...state,
  };
  return (
    <DocumentRequestContext.Provider value={{ ...contextValues }}>
      {children}
    </DocumentRequestContext.Provider>
  );
};
