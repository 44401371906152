import classNames from "classnames";
import React from "react";
import { FocusEvent } from "react";

type CardProps = {
  id?: string;
  className?: string;
  isShadow?: boolean;
  onClick?: () => void;
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void;
  onFocus?: (event: FocusEvent<HTMLDivElement>) => void;
  children: React.ReactNode;
  dataCy?: string;
};

export const Card = ({
  id,
  className,
  onClick,
  onBlur = () => {},
  onFocus = () => {},
  children,
  isShadow = true,
  dataCy,
}: CardProps) => {
  const classStr = classNames(
    "flex flex-wrap rounded-[10px] overflow-hidden",
    isShadow && "shadow-[0px_4px_16px_rgba(160,160,160,0.25)]",
    className
  );
  // Add the prop of tabIndex to make the element of div focus
  return (
    <div
      id={id}
      onClick={onClick}
      className={classStr}
      onBlur={(event) => onBlur(event)}
      onFocus={(event) => onFocus(event)}
      tabIndex={0}
      data-cy={dataCy}
    >
      {children}
    </div>
  );
};
