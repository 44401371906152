import * as React from "react";
import { SVGProps } from "react";

const SvgFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 5H10.414L8.707 3.293C8.61426 3.2 8.50406 3.12624 8.38273 3.07596C8.2614 3.02568 8.13134 2.99986 8 3H4C2.897 3 2 3.897 2 5V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V7C22 5.897 21.103 5 20 5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFolder;
