import { SignaturePxProps } from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { Message } from "common/components/Message";
import { ControlPanel } from "features/documents/pages/documentRequest/components/ControlPanel";
import { FileAuthenWithSignatureList } from "features/documents/pages/documentRequest/components/FileAuthenWithSignatureList";
import { FileWithSignatureList } from "features/documents/pages/documentRequest/components/FileWithSignatureList";
import { usePDFSignatureControl } from "features/documents/pages/documentRequest/hooks/usePDFSignatureControl";
import { AuthHintSection } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/AuthHintSection";
import { RecipientList } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/RecipientList";
import { SignatureControlDetailPanel } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/SignatureControlDetailPanel";
import { SignatureControlPanelNavigation } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/SignatureControlPanelNavigation";
import {
  SubmitSuccessView,
  SubmitFailView,
} from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/SubmitView";
import { getBackStep } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/utils/PDFSignatureUtils";
import {
  PDFFile,
  PDFSignaturesPxFormValue,
  RecipientRolesProps,
  SenderAsRecipientMode,
} from "features/documents/pages/documentRequest/types";
import { SignatureControlPanelStep } from "features/documents/pages/documentRequest/types";
import { getNewIndex } from "features/documents/pages/postDocumentRequest/components/SignatureScrollControl";
import { useFormikContext } from "formik";
import { t } from "i18next";
import React, { useCallback, useEffect, useMemo } from "react";
import { Dispatch, SetStateAction } from "react";
import tw, { styled } from "twin.macro";

export const SELECT_ALL = "SELECT_ALL";

type SignatureControlPanelProps = {
  filterIndex: number;
  setFilterIndex: Dispatch<SetStateAction<number>>;
  onAddSignature: () => void;
  afterSelectFilter: (offset: number) => void;
  selectedFile: PDFFile;
  setSelectedFile: Dispatch<SetStateAction<PDFFile>>;
  files: PDFFile[];
  recipients: RecipientRolesProps[];
  isPreview: boolean;
  invalidFiles: (string | undefined)[];
  onReSubmit: () => void;
  isSenderSameAsRecipient: boolean;
  senderAsRecipientMode?: SenderAsRecipientMode;
  setIsPreview: (value: boolean) => void;
  currentStep: SignatureControlPanelStep;
  setCurrentStep: (step: SignatureControlPanelStep) => void;
  isAuthenOnly: boolean;
  onBack: () => void;
  setFilterValue: Dispatch<SetStateAction<string>>;
};

export const SignatureControlPanel = ({
  filterIndex,
  setFilterIndex,
  onAddSignature,
  afterSelectFilter,
  selectedFile,
  setSelectedFile,
  files,
  recipients,
  isPreview,
  invalidFiles,
  onReSubmit,
  isSenderSameAsRecipient,
  senderAsRecipientMode,
  setIsPreview,
  currentStep,
  setCurrentStep,
  isAuthenOnly,
  onBack,
  setFilterValue,
}: SignatureControlPanelProps): JSX.Element => {
  const {
    values,
    errors,
    submitForm,
    isSubmitting,
    validateForm,
    setFieldValue,
  } = useFormikContext<PDFSignaturesPxFormValue>();

  const { signatures } = values;
  const { currSignature, setCurrSignature, setUpdateFormValue } =
    usePDFSignatureControl();
  const currentFileIndex = useMemo(
    () => files.findIndex((file) => file === selectedFile),
    [files, selectedFile]
  );

  const updateSignature = useCallback(
    (newSignature: SignaturePxProps[]) => {
      setFieldValue(`signatures[${currentFileIndex}]`, newSignature);
    },
    [currentFileIndex, setFieldValue]
  );

  useEffect(() => {
    setUpdateFormValue(() => updateSignature);
  }, [setUpdateFormValue, updateSignature]);

  useEffect(() => {
    setCurrSignature(signatures[currentFileIndex] ?? []);
  }, [currentFileIndex, setCurrSignature, signatures]);

  const handleScrollToIndex = (
    index: number,
    filterValue: string,
    upperLimitation = index // prevent scroll to unexpected index, if not provided then will ignore the check
  ) => {
    const newIndex = getNewIndex(0, upperLimitation + 1, index);
    setFilterIndex(newIndex);
    setFilterValue(filterValue);

    const target = currSignature.filter((signature) =>
      filterValue === SELECT_ALL ? true : signature.value?.email === filterValue
    );
    const offset = target.sort((a, b) => a.y_px - b.y_px)[newIndex].y_px;
    afterSelectFilter(offset);
  };

  const currSignedSignatureNum = useMemo(() => {
    const index = files.findIndex((file) => file === selectedFile);
    const signedNum = signatures?.[index]?.filter(
      (signature) => !!signature.value
    ).length;
    return signedNum ?? 0;
  }, [selectedFile, files, signatures]);

  const handleNextClicked = async () => {
    const error = await validateForm();
    if (!error.signatures) {
      submitForm();
    }
  };

  const handleSkipAuthBeforeCreateDoc = () => {
    setFieldValue("authenticatedFiles", []);
    handleNextClicked();
  };

  useEffect(() => {
    if (currentStep === SignatureControlPanelStep.ADD_SIGNATURE) {
      setIsPreview(false);
    } else {
      setIsPreview(true);
    }
  }, [currentStep, setIsPreview]);

  const handleOnBack = () => {
    const newStep = getBackStep(
      currentStep,
      isAuthenOnly,
      isSenderSameAsRecipient,
      senderAsRecipientMode
    );
    setCurrentStep(newStep);
    if (newStep === SignatureControlPanelStep.SET_PERMISSION) {
      onBack();
    }
  };

  return (
    <ControlPanel
      contentSection={
        <>
          {(() => {
            switch (currentStep) {
              case SignatureControlPanelStep.ADD_SIGNATURE:
                return (
                  <>
                    {getHeaderComponent(currentStep)}
                    <FileWithSignatureList
                      signatures={signatures}
                      afterFileChange={() => setFilterIndex(0)}
                      files={files}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      isPreview={isPreview}
                      invalidFiles={invalidFiles}
                    />
                    <SignatureControlDetailPanel
                      currSignedSignatureNum={currSignedSignatureNum}
                      selectedFile={selectedFile}
                      onAddSignature={onAddSignature}
                      handleScrollToIndex={handleScrollToIndex}
                      currSignature={currSignature}
                      filterIndex={filterIndex}
                      recipients={recipients}
                    />
                  </>
                );
              case SignatureControlPanelStep.SENDER_AUTHENTICATE:
                return (
                  <>
                    {getHeaderComponent(currentStep)}
                    <FileAuthenWithSignatureList
                      signatures={signatures}
                      files={files}
                      isPreview={false}
                    />
                    <AuthHintSection />
                  </>
                );
              case SignatureControlPanelStep.PREVIEW_TO_SEND:
                return (
                  <>
                    {getHeaderComponent(currentStep)}
                    <FileWithSignatureList
                      signatures={signatures}
                      afterFileChange={() => setFilterIndex(0)}
                      files={files}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      isPreview={isPreview}
                      invalidFiles={invalidFiles}
                    />
                    <RecipientList recipients={recipients} className="" />
                  </>
                );
              case SignatureControlPanelStep.SUBMIT_SUCCESS:
                return <SubmitSuccessView />;
              case SignatureControlPanelStep.SUBMIT_FAIL:
                return <SubmitFailView onReSubmit={onReSubmit} />;
              default:
                return null;
            }
          })()}
        </>
      }
      buttonSection={
        <div className="flex flex-col w-full h-full">
          <div className="mt-auto">
            {errors.signatures && (
              <Message
                className="mb-2.5 text-xs"
                isBackgroundEnable={false}
                variant="warning"
              >
                {Array.isArray(errors.signatures)
                  ? errors.signatures[errors.signatures.length - 1]
                  : errors.signatures}
              </Message>
            )}
          </div>
          <SignatureControlPanelNavigation
            step={currentStep}
            onSkip={handleSkipAuthBeforeCreateDoc}
            onBack={handleOnBack}
            onNext={handleNextClicked}
            isButtonDisable={isSubmitting}
          />
        </div>
      }
    />
  );
};

export const StyledHeader = tw.h3`text-dark-blue mb-6`;
export const StyledGreySubtitle = tw.p`text-sm font-medium text-grey`;
export const SignatureButton = styled.button(() => [
  tw`rounded-lg px-5 py-3 flex justify-center items-center`,
  tw`text-dark-grey font-bold leading-[1.125rem]`,
  tw`bg-background-grey hover:bg-greyscale`,
]);

export const getHeaderComponent = (currentStep: SignatureControlPanelStep) => {
  switch (currentStep) {
    case SignatureControlPanelStep.PREVIEW_TO_SEND:
      return (
        <StyledHeader>
          {t("docRequest.confirmDetail.relatedDocuments")}
        </StyledHeader>
      );
    default:
      return (
        <div className="h-[10%] mb-4">
          <h3 className="text-dark-blue">{t("dashboard.documents")}</h3>
          <StyledGreySubtitle>
            {t("docRequest.confirmDetail.selectFileHint")}
          </StyledGreySubtitle>
        </div>
      );
  }
};
