import { sortRecipientList } from "common/utils/document";
import { useMemo } from "react";
import { DocumentParticipant } from "types/document";

/* Folder Management */
export const useSortedRecipientList = (
  recipientList?: DocumentParticipant[]
): DocumentParticipant[] => {
  /*
    1: Sort by role: Signer&Authen -> Signer -> Authen
    2: Sort by name
  */
  const sortedList = useMemo(() => {
    return recipientList ? sortRecipientList(recipientList) : [];
  }, [recipientList]);
  return sortedList;
};
