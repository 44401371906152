import { documentAPI } from "api/document";
import { Button } from "common/components/Button";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { ToastContainerId } from "common/toast";
import { isCurrentUserSenderAsRecipient } from "common/utils/common";
import { DocumentViewStatus } from "features/documents/components/StatusCell";
import { TNCmemo } from "features/documents/components/TNCmemo";
import { useDiscardDocumentHandler } from "features/documents/hooks/useDiscardDocumentHandler";
import { useCommentComponentHandler } from "features/documents/pages/documentManagement/hooks/useCommentComponentHandler";
import { SenderAsRecipientMode } from "features/documents/pages/documentRequest/types";
import {
  ButtonStep,
  ButtonStepGroup,
} from "features/documents/pages/postDocumentRequest/components/ButtonStepGroup";
import { DocumentSenderInfo } from "features/documents/pages/postDocumentRequest/components/DocumentSenderInfo";
import { FileManagementTitle } from "features/documents/pages/postDocumentRequest/components/FileManagementTitle";
import { RecipientListEditView } from "features/documents/pages/postDocumentRequest/components/RecipientListEditView";
import { RecipientListView } from "features/documents/pages/postDocumentRequest/components/RecipientListView";
import { RelatedDocumentArea } from "features/documents/pages/postDocumentRequest/components/RelatedDocumentArea";
import { SignatureScrollControl } from "features/documents/pages/postDocumentRequest/components/SignatureScrollControl";
import useNotViewRejectDetails from "features/documents/pages/postDocumentRequest/hooks/useNotViewRejectDetails";
import { usePostDocumentActionPopupHandler } from "features/documents/pages/postDocumentRequest/hooks/usePostDocumentActionPopupHandler";
import useRecipientsRejectedFilter from "features/documents/pages/postDocumentRequest/hooks/useRecipientsRejectedFilter";
import { useSignatureScroll } from "features/documents/pages/postDocumentRequest/hooks/useSignatureScroll";
import { DocumentSigningViewForm } from "features/documents/pages/postDocumentRequest/pages/signing/DocumentSigningView";
import { useCurrentUserSignatures } from "features/documents/pages/postDocumentRequest/pages/signing/hooks/useCurrentUserSignatures";
import {
  isAllowSeeRejectDetail,
  isShowRecipientSetting,
} from "features/documents/pages/postDocumentRequest/utils/common";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw, { styled } from "twin.macro";
import {
  DocumentRole,
  DocumentStatePayload,
  DocumentStatePayloadMutate,
} from "types/document";

interface DocumentSigningSettingProps {
  documentStatePayload: DocumentStatePayload;
  mutate: DocumentStatePayloadMutate;
  setIsPreview: React.Dispatch<React.SetStateAction<boolean>>;
  currentRoles?: DocumentRole[];
  isPreview: boolean;
  step: ButtonStep;
  setStep: React.Dispatch<React.SetStateAction<ButtonStep>>;
  documentViewStatus: DocumentViewStatus | null;
  isViewEditMode: boolean;
  setIsViewEditMode: (isViewEditMode: boolean) => void;
  isSenderAsRecipientActionTaken: boolean;
  setCurrentActiveBoxId: (value: string) => void;
}

export const DocumentSigningSetting = ({
  documentStatePayload,
  mutate,
  setIsPreview,
  isPreview,
  currentRoles,
  setStep,
  step,
  documentViewStatus,
  isViewEditMode,
  setIsViewEditMode,
  isSenderAsRecipientActionTaken,
  setCurrentActiveBoxId,
}: DocumentSigningSettingProps) => {
  const { t } = useTranslation();
  const [isRejectSectionEnable, setRejectSectionEnable] = useState(false);
  const hasBeenDiscarded = documentViewStatus === DocumentViewStatus.Discarded;
  const { getCurrentUserSignedSignatureRecord, isAllSignaturedSigned } =
    useCurrentUserSignatures(documentStatePayload);
  const [isSignatureRequiredError, setIsSignatureRequiredError] =
    useState(false);
  const { ref } = useSignatureScroll();

  // Filter recipients that reject document
  const { listRecipients } = useRecipientsRejectedFilter(
    isRejectSectionEnable,
    documentStatePayload.recipients
  );

  const { values, setFieldValue, initialValues } =
    useFormikContext<DocumentSigningViewForm>();

  useEffect(() => {
    if (step === ButtonStep.START) {
      setFieldValue("signatures", initialValues.signatures);
    }
    if (step !== ButtonStep.ACTION) {
      setIsSignatureRequiredError(false);
    }
  }, [step, setFieldValue, initialValues.signatures]);

  const isSigningAndAuthenticate = useMemo(() => {
    return (
      currentRoles &&
      currentRoles.includes(DocumentRole.AUTHENTICATOR) &&
      currentRoles.includes(DocumentRole.SIGNER)
    );
  }, [currentRoles]);
  const currentUserProfile = useCurrentUserProfile();
  const hintMessage =
    isSigningAndAuthenticate && !isSenderAsRecipientActionTaken
      ? t("docDetails.message.signingAndAuthenRequest")
      : t("docDetails.message.signingRequest");

  const isAddMoreRecipients = useMemo(
    () =>
      isShowRecipientSetting(
        documentStatePayload,
        currentUserProfile,
        documentViewStatus
      ),
    [documentStatePayload, currentUserProfile, documentViewStatus]
  );

  const mutateAndActionDone = useCallback(() => {
    mutate();
    setStep(ButtonStep.DONE);
  }, [mutate, setStep]);

  const signatureBackEditMode = useCallback(() => {
    setIsPreview(false);
  }, [setIsPreview]);

  const { handler: handleRejectDocument } = useCommentComponentHandler(
    documentStatePayload,
    ToastContainerId.DocumentDetail,
    () => {
      setFieldValue(`signatures`, initialValues.signatures);
      mutateAndActionDone();
    }
  );

  const { handler: handleSignAndAuthenticateDocument } =
    usePostDocumentActionPopupHandler(
      SenderAsRecipientMode.SIGNANDAUTHENTICATE,
      documentStatePayload,
      ToastContainerId.DocumentDetail,
      values.signatures,
      mutateAndActionDone,
      signatureBackEditMode
    );
  const { handler: handleSignDocument } = usePostDocumentActionPopupHandler(
    SenderAsRecipientMode.SIGNING,
    documentStatePayload,
    ToastContainerId.DocumentDetail,
    values.signatures,
    mutateAndActionDone,
    signatureBackEditMode
  );

  const handleShowRejectDetails = async () => {
    setRejectSectionEnable(true);
    if (isNotViewRecipientsComment) {
      await documentAPI.resolve_all_comments(documentStatePayload.ref);
      mutate();
    }
  };

  const { handler: handleDiscardDocument } = useDiscardDocumentHandler(
    documentStatePayload.linearId,
    documentStatePayload.file.name,
    ToastContainerId.DocumentDetail,
    () => {
      mutate();
    }
  );

  const handleNext = () => {
    if (!isPreview) {
      const signedValue = getCurrentUserSignedSignatureRecord(
        values.signatures
      );
      if (isAllSignaturedSigned(signedValue)) {
        setIsSignatureRequiredError(false);
        setIsPreview(true);
        if (isSigningAndAuthenticate && !isSenderAsRecipientActionTaken) {
          handleSignAndAuthenticateDocument();
        } else {
          handleSignDocument();
        }
      } else {
        setIsSignatureRequiredError(true);
      }
    }
  };

  const handleScrollToSignatureId = useCallback(
    (id: string) => {
      ref?.scrollToSignatureId(id);
      setCurrentActiveBoxId(id);
    },
    [ref, setCurrentActiveBoxId]
  );

  const navigate = useNavigate();
  const handleBack = () => {
    if (hasBeenDiscarded) {
      navigate("/documents");
      return;
    }
    if (step === ButtonStep.ACTION) {
      setRejectSectionEnable(false);
      if (isPreview) {
        setIsPreview(false);
      } else {
        setFieldValue(`signatures`, {});
        setStep(ButtonStep.START);
      }
    } else if (isRejectSectionEnable) {
      setRejectSectionEnable(false);
    } else {
      navigate("/documents");
    }
  };

  const isCurrentUserSenderSameAsRecipient = useMemo(() => {
    return isCurrentUserSenderAsRecipient(
      documentStatePayload,
      currentUserProfile
    );
  }, [currentUserProfile, documentStatePayload]);

  const isAllowViewRejectDetail = useMemo(
    () => isAllowSeeRejectDetail(currentUserProfile, documentStatePayload),
    [currentUserProfile, documentStatePayload]
  );
  const isNotViewRecipientsComment = useNotViewRejectDetails(
    documentStatePayload.recipients
  );

  const isTncShow = shouldTncShow(
    isPreview,
    !!isSigningAndAuthenticate,
    isSenderAsRecipientActionTaken,
    step
  );

  return (
    <StyledFlexContainer>
      <FileManagementTitle
        title={
          isRejectSectionEnable
            ? t("docDetails.reject.title")
            : t("docDetails.title")
        }
        onClickBackButton={handleBack}
      />
      <RelatedDocumentArea
        step={step}
        mutate={mutate}
        documentStatePayload={documentStatePayload}
        isEnableForward={isCurrentUserSenderSameAsRecipient}
      />
      {step === ButtonStep.ACTION ? (
        <div className="flex-1">
          <SignatureScrollControl
            documentStatePayload={documentStatePayload}
            onScrollToSignatureId={handleScrollToSignatureId}
            isError={isSignatureRequiredError}
          />
        </div>
      ) : (
        <>
          {!isCurrentUserSenderSameAsRecipient && (
            <DocumentSenderInfo documentStatePayload={documentStatePayload} />
          )}
          {isViewEditMode ? (
            <StyledRecipientEditListContainer>
              <RecipientListEditView
                documentStatePayload={documentStatePayload}
              />
            </StyledRecipientEditListContainer>
          ) : (
            <div className="flex-1">
              <RecipientListView
                listRecipients={listRecipients}
                sender={documentStatePayload.sender}
                isAddMoreRecipients={isAddMoreRecipients}
                isRejectSectionEnable={isRejectSectionEnable}
                isShowButtonRejectDetail={isAllowViewRejectDetail}
                onRejectDetails={
                  isAllowViewRejectDetail ? handleShowRejectDetails : undefined
                }
                onClickRecipientSettings={() => setIsViewEditMode(true)}
                isDotIconShow={isNotViewRecipientsComment}
                height={isCurrentUserSenderSameAsRecipient ? "middle" : "short"}
              />
            </div>
          )}
        </>
      )}

      <div>
        {isTncShow && (
          <StyledTncContainer>
            <TNCmemo />
          </StyledTncContainer>
        )}
        {!hasBeenDiscarded && !isViewEditMode && !isRejectSectionEnable && (
          <ButtonStepGroup
            step={step}
            setStep={setStep}
            hintMessage={hintMessage}
            isHitMessageDisplay={step === ButtonStep.START}
            isError={isSignatureRequiredError}
            errorMessage={
              isSignatureRequiredError
                ? t("docDetails.message.signingRequiredError")
                : ""
            }
            isCurrentUserSenderSameAsRecipient={
              isCurrentUserSenderSameAsRecipient
            }
            onReject={handleRejectDocument}
            onDiscard={handleDiscardDocument}
          >
            {isCurrentUserSenderSameAsRecipient && step === ButtonStep.DONE ? (
              <Button
                className="w-full"
                variant="secondary"
                onClick={handleDiscardDocument}
                type="button"
              >
                {t("dashboard.button.discard")}
              </Button>
            ) : (
              <Button
                className="p-0 w-44"
                variant="primary"
                onClick={handleNext}
                type="button"
              >
                {isSigningAndAuthenticate && !isSenderAsRecipientActionTaken
                  ? t("docDetails.button.signAndAuthenticate")
                  : t("docDetails.button.sign")}
              </Button>
            )}
          </ButtonStepGroup>
        )}
      </div>
    </StyledFlexContainer>
  );
};

export const StyledFlexContainer = styled.div(tw`flex flex-col h-full gap-4 h-md:gap-8`);
const StyledRecipientEditListContainer = styled.div(
  tw`max-h-[480px] h-auto flex-1 overflow-y-auto`
);
const StyledTncContainer = styled.div(tw`pb-4`);
export const shouldTncShow = (
  isPreview: boolean,
  isSigningAndAuthenticate: boolean,
  isSenderAsRecipientActionTaken: boolean,
  step: ButtonStep
) => {
  return (
    isPreview &&
    !(isSigningAndAuthenticate && !isSenderAsRecipientActionTaken) &&
    step !== ButtonStep.DONE
  );
};
