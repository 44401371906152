import classNames from "classnames";
import { Card } from "common/components/Card";
import { SvgPencil } from "common/components/svg";
import { Colors } from "enums/colors";
import { forwardRef, ReactNode, useMemo } from "react";
import tw, { styled } from "twin.macro";
import { CommonMouseEvents } from "types/common";

type SignatureButtonContentContainerProps = {
  scaleRatio: number;
  children: ReactNode;
};
export const SignatureButtonContentContainer = ({
  scaleRatio,
  children,
}: SignatureButtonContentContainerProps) => {
  return (
    <div
      className={`flex items-center justify-center h-full signature-box-content pointer-events-auto`}
      style={{
        transform: `scale(${scaleRatio})`,
      }}
    >
      {children}
    </div>
  );
};

type SignatureButtonContainerProps = {
  children: ReactNode;
  color: Colors;
  isBackgroundShow: boolean;
  isBorderShow: boolean;
  isGradientBorderShow?: boolean;
  isGradientEnable?: boolean;
  disabled?: boolean;
  className?: string;
} & CommonMouseEvents;
export const SignatureButtonContainer = forwardRef<
  HTMLDivElement,
  SignatureButtonContainerProps
>(
  (
    {
      children,
      color,
      isBackgroundShow,
      isBorderShow,
      isGradientBorderShow,
      isGradientEnable,
      disabled,
      onMouseEnter,
      onMouseLeave,
      onClick,
      className,
    },
    ref
  ) => {
    const isGradientShow = useMemo(() => {
      if (isGradientEnable === undefined) {
        return isBorderShow && isGradientBorderShow;
      }
      return isBorderShow && isGradientEnable && isGradientBorderShow;
    }, [isBorderShow, isGradientEnable, isGradientBorderShow]);

    return (
      <div
        ref={ref}
        className={classNames(
          "rounded-lg flex justify-center items-center leading-[1.125rem] h-full w-full overflow-hidden",
          isBorderShow ? `border-2 border-${color}` : null,
          isGradientShow ? `border-2 border-${color} shadow-${color}` : null,
          { "cursor-pointer": !disabled },
          { "bg-background-grey hover:bg-greyscale": isBackgroundShow },
          className
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }
);

type SignaturePencilProps = {
  scaleRatio: number;
  onClick?: () => void;
  handleHover?: () => void;
  isDisabled?: boolean;
  className?: string;
};
export const SignaturePencil = ({
  scaleRatio,
  onClick,
  handleHover,
  isDisabled,
  className,
}: SignaturePencilProps) => {
  return (
    <div>
      <SvgPencil
        className={`${className}`}
        width={24 / scaleRatio}
        height={24 / scaleRatio}
        onClick={onClick}
        onMouseEnter={handleHover}
      />
    </div>
  );
};

type SignatureImageProps = {
  src: string;
};
export const SignatureImage = ({ src }: SignatureImageProps) => {
  return (
    <img
      className="object-contain"
      src={src}
      alt="signed_img"
      draggable={false}
      data-cy="signature-img"
    />
  );
};

export const StyledUserIconCard = styled(Card)`
  ${tw`bg-background-grey p-2 flex flex-col space-y-3 h-10`}
`;
