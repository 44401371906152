import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useHistoryPaths = () => {
  const location = useLocation();
  const pathNames = useMemo(() => {
    return location.pathname.split("/").filter((i) => i);
  }, [location.pathname]);

  return pathNames;
};
