import { TABLE_MIN_SIZE_HEIGHT } from "constants/common";

import { LoadingSpinner } from "common/components/LoadingSpinner";
import { DocumentListMutatorContext } from "features/documents/pages/documentManagement/DocumentListMutatorContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Cell, Column, Row, useFlexLayout, useTable } from "react-table";
import tw, { styled } from "twin.macro";

type DataTableProps<T extends object> = {
  className?: string;
  columns: Array<Column<T>>;
  data: T[];
  scrollHeight?: number;
  fetchNext: () => void;
  hasMore: () => boolean;
  selectedRows?: Row<T>[];
  onRowSelected?: (selectedRow: Row<T>) => void;
  getCustomCellWrapperProps?: (row: Cell<T>) => {};
  getCustomCellInnerProps?: (row: Cell<T>) => {};
  isPending: boolean;
};
/**
 * For more detail please view https://react-table.tanstack.com/
 ** getCustomCellWrapperProps: to control or overwrite library's cell wrapper (mainly for position or pure string)
 ** getCustomCellInnerProps: to control or styled the cell content (customized cell)
 */
export const DataTable = <T extends object>({
  className,
  columns,
  data,
  scrollHeight,
  fetchNext,
  hasMore,
  selectedRows,
  onRowSelected,
  getCustomCellWrapperProps = () => ({}),
  getCustomCellInnerProps = () => ({}),
  isPending,
}: DataTableProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useFlexLayout);
  const tableRef = useRef<HTMLDivElement>(null);
  const rowRef = useRef<HTMLDivElement>(null);
  const { setPageSize } = useContext(DocumentListMutatorContext);
  const scrollHeightThreshHold =
    tableRef.current?.clientHeight || scrollHeight || TABLE_MIN_SIZE_HEIGHT;
  const [tableHeight, setTableHeight] = useState<number>(
    scrollHeightThreshHold
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (tableRef.current) setTableHeight(tableRef.current.clientHeight);
  }, [tableRef.current?.clientHeight]);

  useEffect(() => {
    if (tableRef.current && rowRef.current && setPageSize) {
      const tableHeight = tableRef.current.clientHeight;
      const rowHeight = rowRef.current.clientHeight;
      const pageSize = Math.ceil(tableHeight / rowHeight);
      setPageSize(pageSize);
    }
  }, [
    tableRef.current?.clientHeight,
    rowRef.current?.clientHeight,
    setPageSize,
  ]);

  return (
    <div className={className} data-cy="document-table">
      <div {...getTableProps()} className="h-full flex flex-col">
        {
          // Loop over the header rows
          headerGroups.map((headerGroup, index) => (
            <div key={index}>
              <HeaderWrapper {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    <HeaderCell {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </HeaderCell>
                  ))
                }
              </HeaderWrapper>
            </div>
          ))
        }
        {/* Apply the table body props */}
        {/* min-h-0 must add. It tells the browser that this element has no right to take up any minimum height, and taking up only as much height as is available by flex calculating*/}
        {isPending ? (
          <div className="h-full w-full flex items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : data && data.length > 0 ? (
          <div ref={tableRef} className="flex-1 min-h-0">
            <div {...getTableBodyProps()}>
              <InfiniteScroll
                next={fetchNext}
                hasMore={hasMore()}
                loader={rows.length > 0 && <h4>Loading...</h4>} // TODO Waiting for design
                dataLength={rows.length}
                height={tableHeight}
              >
                {/* // Loop over the cells in each row */}

                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <div ref={rowRef} key={row.id}>
                      <RowWrapper
                        {...row.getRowProps()}
                        onClick={() => onRowSelected && onRowSelected(row)}
                        className={
                          selectedRows
                            ? selectedRows.find((item) => item.id === row.id)
                              ? "bg-grey/10"
                              : ""
                            : ""
                        }
                        data-cy="document-dashboard-row"
                      >
                        {row.cells.map((cell) => {
                          return (
                            <TableCell
                              {...cell.getCellProps(
                                getCustomCellWrapperProps(cell)
                              )}
                            >
                              {cell.render("Cell", {
                                ...getCustomCellInnerProps(cell),
                              })}
                            </TableCell>
                          );
                        })}
                      </RowWrapper>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </div>
        ) : (
          <div className="text-center mt-4">
            {t("docRequest.table.emptyList")}
          </div>
        )}
      </div>
    </div>
  );
};

const HeaderCell = styled.div(() => [
  tw`text-xs font-medium text-dark-grey text-left h-10`,
]);
const TableCell = styled.div(() => [
  tw`text-sm font-regular text-dark-blue text-left h-16 `,
  tw`flex items-center`,
]);

const HeaderWrapper = styled.div(() => [tw`border-solid border-b border-grey`]);

const RowWrapper = styled.div(() => [
  tw`hover:bg-grey/10 ease-in-out duration-300 h-full`,
]);
