import { InputField } from "common/components/form";
import { Message } from "common/components/Message";
import { Colors, generateColorByString } from "enums/colors";
import {
  RecipientListFormValues,
  RecipientUpdateListFormValues,
} from "features/documents/pages/documentRequest/types";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

export type RecipientFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  company?: string;
  title?: string;
};

export type RecipientInputFieldsProps = {
  index: number;
  name: string;
  isExpanded?: boolean;
  isEmailDisabled?: boolean;
};

export const RecipientInputFields = ({
  index,
  name,
  isExpanded,
  isEmailDisabled,
}: RecipientInputFieldsProps): JSX.Element => {
  const formik = useFormikContext<
    RecipientListFormValues | RecipientUpdateListFormValues
  >();
  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) =>
    formik.setFieldValue(
      `${name}[${index}].cardColor`,
      event.target.value
        ? generateColorByString(event.target.value)
        : Colors.LIGHT_GREY
    );

  const { t } = useTranslation();
  return (
    <>
      <div className="flex mt-2">
        <InputField
          disabled={isEmailDisabled}
          name={`${name}[${index}].email`}
          placeholder={`${t("common.email")}*`}
          type="text"
          className="mr-3 basis-1/2"
          errorMsgClassName={"whitespace-nowrap"}
          hideErrorMsg={true}
          hideBorderError={false}
          onBlur={handleOnBlur}
        />
        <InputField
          name={`${name}[${index}].firstName`}
          placeholder={`${t("common.firstName")}*`}
          type="text"
          className="mr-4"
          hideErrorMsg={true}
          hideBorderError={false}
        />
        <InputField
          name={`${name}[${index}].lastName`}
          placeholder={`${t("common.lastName")}*`}
          type="text"
          hideErrorMsg={true}
          hideBorderError={false}
        />
      </div>
      {formik.errors.recipientInfo && formik.errors.recipientInfo[index] && (
        <Message
          variant="warning"
          className="text-xs mt-2"
          isBackgroundEnable={false}
        >
          {Object.values(formik.errors.recipientInfo[index])[0]}
        </Message>
      )}
      {isExpanded && (
        <div className="flex mt-2">
          <InputField
            name={`${name}[${index}].company`}
            placeholder={t("common.company")}
            type="text"
            className="flex-1 mr-4"
          />
          <InputField
            name={`${name}[${index}].title`}
            placeholder={t("common.title")}
            type="text"
            className="flex-1"
          />
        </div>
      )}
    </>
  );
};
