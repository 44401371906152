import { ReactComponent as SvgWarning } from "assets/svg/warning.svg";
import { Button } from "common/components/Button";
import { Trans, useTranslation } from "react-i18next";

type ErrorPopupProps = {
  onClose: () => void;
  onLogout: () => void;
};

export const ErrorPopup = ({
  onClose: close,
  onLogout: logout,
}: ErrorPopupProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="text-center px-16">
      <SvgWarning className="text-light-blue m-auto w-10 h-10" />
      <h3 className="p-10">{t("dashboard.refresh.title")}</h3>

      <Trans i18nKey="common.message.openApplicationError" here="here">
        <a
          href="https://kentro.network/help-centre"
          className="text-light-blue"
        >
          here
        </a>
      </Trans>

      <div className="inline-block w-full mt-5">
        <Button
          type="submit"
          variant="primary"
          className="w-full mb-3"
          onClick={logout}
        >
          {t("dashboard.button.logout")}
        </Button>
        <Button
          type="submit"
          variant="secondary"
          className="w-full mb-3"
          onClick={close}
        >
          {t("common.cancel")}
        </Button>
      </div>
    </div>
  );
};
