import axios from "axios";
import { APIError } from "common/errorHandling";
import { request } from "common/HOC/WithRequest";
import { useAuthErrorHandler } from "common/hooks";
import useSWR, { SWRResponse } from "swr";
import { UUID } from "types/common";

const CancelToken = axios.CancelToken;
export const attachmentAPI = {
  upload: (
    file: File,
    onUploadProgress: (loaded: number) => void,
    onCancelFile: () => void
  ): Promise<UploadResponse> => {
    const formData = new FormData();
    formData.append("file", file);

    return request<UploadResponse, FormData>(
      "attachment/upload",
      "POST",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded } = progressEvent;
          onUploadProgress(loaded);
        },
        cancelToken: new CancelToken((cancel) => (onCancelFile = cancel)),
        withCredentials: false,
      }
    );
  },
  remove: (hash: string): Promise<unknown> => {
    return request<unknown>(`attachment/delete/${hash}`, "DELETE");
  },
  download: (hash: string, documentLinearId: UUID): Promise<Blob> => {
    return request<Blob, null>(
      `document/download/${documentLinearId}/${hash}`,
      "GET",
      null,
      {
        responseType: "blob",
      }
    );
  },
};

export const useAttachment = (
  documentLinearId?: string,
  hash?: string
): SWRResponse<Blob, APIError> => {
  const onError = useAuthErrorHandler();
  return useSWR<Blob, APIError>(
    hash && documentLinearId
      ? `document/download/${documentLinearId}/${hash}`
      : hash,
    (url: string) =>
      request(url, "GET", undefined, {
        responseType: "blob",
      }),
    { onError }
  );
};

export type UploadResponse = {
  attId: string;
  size: number;
};
