import { useDashboardBreadcrumbs } from "common/hooks/useDashboardBreadcrumbs";
import { useGoToSpecifiedFolderPath } from "common/hooks/useGoToSpecifiedFolderPath";
import { useHistoryPaths } from "common/hooks/useHistoryPaths";
import { useLastDocumentFilterType } from "common/hooks/useLastDocumentFilterType";
import { isDashboardPage, isSigningPage } from "common/utils/document";
import { isDetailPage } from "common/utils/document";
import type { History, Update } from "history";
import { useEffect, useContext, useState } from "react";
import {
  NavigationType,
  UNSAFE_NavigationContext,
  useLocation,
  useNavigate,
} from "react-router-dom";

// reference: https://stackoverflow.com/questions/71369320/how-to-controling-browser-back-button-with-react-router-dom-v6
export const useBackListener = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;
  const prevDocumentFilterType = useLastDocumentFilterType();
  const pathNames = useHistoryPaths();
  const { dashboardBreadcrumbsItems } = useDashboardBreadcrumbs();
  const { handleBreadCrumbItemClicked: handleBackPrevPage } =
    useGoToSpecifiedFolderPath();
  const [prevPagePath, setPrevPagePath] = useState<string>();

  useEffect(() => {
    const listener = ({ location, action }: Update) => {
      const isValidDashboardBreadcrumbs = dashboardBreadcrumbsItems.length > 0;
      const isOnDashboardPage = isDashboardPage(currentLocation.pathname);
      const isOnDetailPage = isDetailPage(currentLocation.pathname);
      const isInFolderSigningPageGoToDashboard =
        isSigningPage(currentLocation.pathname) &&
        isValidDashboardBreadcrumbs &&
        isDashboardPage(location.pathname);

      if (action === NavigationType.Pop) {
        if (isValidDashboardBreadcrumbs) {
          if (isOnDashboardPage) {
            const newdashboardBreadcrumbsItem = [...dashboardBreadcrumbsItems];
            newdashboardBreadcrumbsItem.pop();
            const prevPath =
              newdashboardBreadcrumbsItem[
                newdashboardBreadcrumbsItem.length - 1
              ];
            handleBackPrevPage(
              prevPath?.value,
              newdashboardBreadcrumbsItem.length - 1
            );
          } else {
            // Back to correct folder when view document details inside a folder
            const prevFolder =
              dashboardBreadcrumbsItems[dashboardBreadcrumbsItems.length - 1];
            handleBackPrevPage(
              prevFolder.value,
              dashboardBreadcrumbsItems.length - 1
            );
          }
          setPrevPagePath(`/documents/${prevDocumentFilterType}`);
        } else {
          if (isOnDetailPage) {
            setPrevPagePath(`/documents/${prevDocumentFilterType}`);
          } else {
            setPrevPagePath(location.pathname);
          }
        }
      }

      /** It is used to prevent the error that occurs :
       * When you want to continue to back to the upper level of the folder
       * after clicking on the signing page in the folder
       */
      if (action === NavigationType.Push) {
        if (isInFolderSigningPageGoToDashboard) {
          setPrevPagePath(location.pathname);
        }
      }
    };
    const unListen = navigator.listen(listener);
    return unListen;
  }, [
    dashboardBreadcrumbsItems,
    currentLocation.pathname,
    handleBackPrevPage,
    navigator,
    pathNames,
    prevDocumentFilterType,
  ]);

  useEffect(() => {
    if (prevPagePath) {
      navigate(prevPagePath);
      setPrevPagePath(undefined);
    }
  }, [currentLocation.pathname, navigate, prevPagePath]);
};
