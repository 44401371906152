import { KeyedMutator } from "swr";
import {
  AttachedFile,
  ISODate,
  X500Name,
  Comment,
  GenericState,
  UUID,
} from "types/common";

export enum DocumentStatus {
  /**
   * initial status of document
   */
  CREATED = "CREATED",

  /**
   * when any signer or authenticator accepts / rejects document,
   * it will change to IN_PROGRESS
   */
  IN_PROGRESS = "IN_PROGRESS",

  /**
   * when any signer or authenticator accepts / rejects document,
   * it will change to COMPLETED
   * COMPLETED BE wil also generate the kentro certificate into the PDF file
   */
  COMPLETED = "COMPLETED",

  /**
   * Once the status is completed, then BE will start to generate digital signature
   * DIGITAL_SIGN means digital signature is ready (final state for now)
   */
  DIGITAL_SIGNED = "DIGITAL_SIGNED",

  /**
   * when sender discards document, it will change to DISCARDED
   */
  DISCARDED = "DISCARDED",
}

export enum ParticipantResponse {
  /**
   * Sender only, always set to SENT at creation
   */
  SENT = "SENT",

  /**
   * Recipient only, before it is viewed
   */
  PENDING = "PENDING",

  /**
   * Recipient only, after it is viewed and before it is accepted or rejected
   */
  VIEWED = "VIEWED",

  /**
   * Recipient only, equivalent to signed for signer, authenticated for authenticator
   */
  ACCEPTED = "ACCEPTED",

  /**
   * Recipient only, rejected
   */
  REJECTED = "REJECTED",
}

export enum DocumentRole {
  SENDER = "SENDER",
  VIEWER = "VIEWER",
  SIGNER = "SIGNER",
  AUTHENTICATOR = "AUTHENTICATOR",
}

export type DocumentForwardRecipient = {
  party: X500Name;
  email: string;
  forwarderParty: X500Name;
  forwarderEmail: string;
};

export type ParticipantProfile = {
  firstName: string;
  lastName: string;
  companyName: string | null;
  companyTitle: string | null;
  fullName: string;
};

export enum ActionTaken {
  SIGNED = "SIGNED",
  AUTHENTICATED = "AUTHENTICATED",
  SENT = "SENT",
}

export type ParticipantActionTaken = {
  action: ActionTaken;
  respondedAt: ISODate;
};

export type DocumentParticipant = {
  party: X500Name;
  email: string;
  roles: DocumentRole[];
  profile: ParticipantProfile;
  response: ParticipantResponse;
  comments: Comment[];
  createdAt: ISODate;
  respondedAt: ISODate | null;
  actionTaken: ParticipantActionTaken[];
  canDownload: boolean | null;
};

export type RolePermissionMap = {
  viewer: boolean;
  signer: boolean;
  authenticator: boolean;
};

export type LegacyDocumentLinearId = {
  externalId: string | null;
  id: UUID;
};

export type DocumentProps = {
  completedFile: AttachedFile | null;
  file: AttachedFile;
  sender: DocumentParticipant;
  recipients: DocumentParticipant[];
  forwardedRecipients: DocumentForwardRecipient[];
  signatureSchemas: SignatureSchemas[];
  signatureLinearId: UUID;
  isFileFromKentro: boolean;
  legacyDocumentsLinearId?: LegacyDocumentLinearId[];
};

export type DocumentStatePayload = GenericState<DocumentStatus> & DocumentProps;
export enum QueryDocumentRole {
  ALL = "ALL",
  SENDER = "SENDER",
  RECIPIENT = "RECIPIENT",
}

export type SignatureSchemas = {
  email: string;
  height: number;
  id: string;
  pageNumber?: number;
  party: X500Name;
  width: number;
  x: number;
  y: number;
};

export type DocumentStatePayloadMutate = KeyedMutator<DocumentStatePayload>;
