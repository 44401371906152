import { APIError } from "common/errorHandling";
import { request } from "common/HOC/WithRequest";
import useAuthErrorHandler from "common/hooks/useAuthErrorHandler";
import useSWR, { SWRResponse } from "swr";
import useSWRInfinite from "swr/infinite";
import {
  AttachedFile,
  DashboardResponse,
  LinearId,
  Page,
  UUID,
  X500Name,
} from "types/common";
import {
  DocumentRole,
  DocumentStatePayload,
  QueryDocumentRole,
  SignatureSchemas,
} from "types/document";

export const documentAPI = {
  discard: (ref: string) => request(`document/discard/${ref}`, "POST"),
  reject: (ref: string, comment: string) =>
    request(`document/reject/${ref}`, "POST", {
      comment,
    }),
  archive: (linearId: UUID) =>
    request(`dashboard/document/archive/linearId/${linearId}`, "POST"),
  retrieve: (linearId: UUID) =>
    request(`dashboard/document/archive/linearId/${linearId}`, "DELETE"),
  deleteArchive: (linearId: UUID) =>
    request(`dashboard/document/archive/hide/linearId/${linearId}`, "POST"),
  create: (payload: DocumentCreateRequestPayload) =>
    request(`document/create`, "POST", payload),
  forward: (ref: string, payload: DocumentForwardRequestPayload) =>
    request(`document/forward/${ref}`, "POST", payload),
  accept: (
    ref: string,
    payload: AcceptDocumentPayload = {
      signature: undefined,
      authenticate: true,
    }
  ) => request(`document/accept/${ref}`, "POST", payload),
  view: (ref: string) => request(`document/view/${ref}`, "POST"),
  resolve_all_comments: (ref: string) =>
    request(`document/resolve-all-comments/${ref}`, "POST"),
  update: (ref: string, payload: DocumentUpdateRequestPayload) =>
    request(`document/update/${ref}`, "PUT", payload),
  getLatestRef: (linearId: UUID) =>
    request<DocumentStatePayload>(
      `document/linearId/${linearId}/latest`,
      "GET"
    ).then((resp) => resp.ref),
  getLatestDocument: (linearId: UUID) =>
    request<DocumentStatePayload>(
      `document/linearId/${linearId}/latest`,
      "GET"
    ),
  moveDocument: (documentLinearId: UUID, newParentFolderId: UUID | null) =>
    request(`dashboard/document/linearId/${documentLinearId}`, "PUT", {
      newParentFolderId,
    }),
};

export const useDocument = (
  linearId?: UUID
): SWRResponse<DocumentStatePayload, APIError> => {
  const onError = useAuthErrorHandler();
  return useSWR<DocumentStatePayload, APIError>(
    linearId ? `document/linearId/${linearId}/latest` : linearId,
    request,
    { onError }
  );
};

export const useSignatureListByDocumentLinearId = (
  documentLinearId?: UUID
): SWRResponse<SignatureState[], APIError> => {
  const onError = useAuthErrorHandler();
  return useSWR<SignatureState[], APIError>(
    documentLinearId
      ? `document/linearId/${documentLinearId}/signature`
      : documentLinearId,
    request,
    { onError }
  );
};

/* Folder Management */
export type InfiniteDocumentListResult = {
  documents?: DashboardResponse[];
  total?: number;
  isLoading: boolean;
  error: unknown;
  fetchNext: () => void;
  mutate: () => Promise<Page<DashboardResponse>[] | undefined>;
};

export const useInfiniteDocumentList = (
  role: QueryDocumentRole,
  archived: boolean,
  parentFolderId: string | null,
  pageSize?: number,
  globalSearch?: boolean,
  filename?: string,
  showFolder?: boolean,
  showDocument?: boolean
): InfiniteDocumentListResult => {
  const onError = useAuthErrorHandler();
  const urlParams = new URLSearchParams();
  urlParams.set("role", role);
  const pageSizeParams = pageSize || 10;

  if (filename) {
    urlParams.set("filename", filename);
  }
  if (archived) {
    urlParams.set("archived", archived.toString());
  }
  if (parentFolderId) {
    urlParams.set("parentFolderId", parentFolderId.toString());
  }
  if (globalSearch) {
    urlParams.set("globalSearch", globalSearch.toString());
  }
  if (showFolder === false) {
    urlParams.set("showFolder", showFolder.toString());
  }
  if (showDocument === false) {
    urlParams.set("showDocument", showDocument.toString());
  }
  urlParams.set("pageSize", pageSizeParams.toString());

  const {
    data: documentPageList,
    error,
    size,
    setSize,
    mutate,
  } = useSWRInfinite<Page<DashboardResponse>>(
    getDocumentListPath(urlParams),
    request,
    {
      onError,
      revalidateAll: true,
    }
  );

  const isLoading: boolean =
    (!error && !documentPageList) ||
    (size > 0 &&
      !!documentPageList &&
      documentPageList[size - 1] === undefined);
  const total = documentPageList
    ? documentPageList[documentPageList.length - 1].totalNumOfItems
    : 0;
  const cumulativeDocumentList: DashboardResponse[] | undefined =
    documentPageList?.map((page) => page.data).flat();
  return {
    documents: cumulativeDocumentList,
    total,
    isLoading,
    error,
    fetchNext: () => setSize(size + 1),
    mutate,
  };
};

const getDocumentListPath =
  (params: URLSearchParams) =>
  (
    pageIndex: number,
    previousPageData: Page<DashboardResponse> | null
  ): string | null => {
    if (previousPageData && previousPageData.totalNumOfItems === 0) {
      return null;
    }
    params.set("pageNum", pageIndex.toString());

    return `dashboard?${params.toString()}`;
  };

type SenderSignOrAuthenticate = {
  signature: { signatures: SignatureRecord } | null;
  authenticate: boolean;
};

export type DocumentCreateRequestPayload = {
  file: AttachedFile;
  recipients: {
    email: string;
    party: X500Name;
    profile: ParticipantProfile;
    roles: DocumentRole[];
    canDownload: boolean;
  }[];
  sender: {
    profile: ParticipantProfile;
  };
  signatureSchemas: SignatureSchemas[];
  senderSignOrAuthenticate?: SenderSignOrAuthenticate;
  isFileFromKentro: boolean;
  linearId?: LinearId;
};

export type ParticipantProfile = {
  companyName?: string | null;
  companyTitle?: string | null;
  firstName: string;
  lastName: string;
};

export type DocumentForwardRequestPayload = {
  newForwardRecipients: {
    email: string;
    party: X500Name;
  }[];
};

export type AcceptDocumentPayload = {
  signature?: {
    signatures: SignatureRecord;
  };
  authenticate: boolean;
};

export type SignatureState = {
  email: string;
  party: X500Name;
  signatures: SignatureRecord;
};

export type SignatureRecord = Record<string, string>;

export type DocumentUpdateRecipient = {
  email: string;
  party: X500Name;
  profile: ParticipantProfile;
  roles: DocumentRole[];
};
export type DocumentUpdateRequestPayload = {
  recipients: DocumentUpdateRecipient[];
};
