import { App, ApplicationName } from "api/oneNetwork";
import AtlasEliteApplicationIcon from "assets/images/atlas-application-icon.png";
import KentroApplicationLogo from "assets/images/kentro-application-icon.png";
import KentroSignApplicationLogo from "assets/images/kentro-sign-application-icon.png";
import MidasApplicationIcon from "assets/images/midas-application-icon.png";
import TopazApplicationIcon from "assets/images/topaz-application-icon.png";
import tw from "twin.macro";

const ApplicationListWrapper = tw.div`flex flex-wrap items-center gap-4 mt-4`;
const ApplicationIconWrapper = tw.div`p-2 w-20 h-20 border border-light-greyscale rounded-lg cursor-pointer`;
export const GridMenuTitle = tw.div`font-bold text-base leading-[22px] text-light-blue`;

type GridMenuSectionProps = {
  applicationList: App;
  onClick: (app: ApplicationName, id: string) => void;
  className?: string;
};

const getApplicationIconByAppName = (appName: ApplicationName) => {
  switch (appName) {
    case ApplicationName.AtlasElite:
      return AtlasEliteApplicationIcon;
    case ApplicationName.Midas:
      return MidasApplicationIcon;
    case ApplicationName.Topaz:
      return TopazApplicationIcon;
    case ApplicationName.KentroSign:
      return KentroSignApplicationLogo;
    default:
      return KentroApplicationLogo;
  }
};

export const MyApplications = ({
  applicationList,
  onClick,
  className,
}: GridMenuSectionProps): JSX.Element => {
  return (
    <div className={className}>
      <GridMenuTitle>My Applications</GridMenuTitle>
      <ApplicationListWrapper>
        {applicationList.map((it) => (
          <ApplicationIconWrapper
            key={it.app.appName}
            onClick={() => onClick(it.app.appName, it.app.id)}
          >
            <img
              src={getApplicationIconByAppName(it.app.appName)}
              alt={it.app.appName}
            />
          </ApplicationIconWrapper>
        ))}
      </ApplicationListWrapper>
    </div>
  );
};

export const OtherApplications = ({
  applicationList,
  onClick,
  className,
}: GridMenuSectionProps): JSX.Element => {
  return (
    <div className={className}>
      <GridMenuTitle>Explore Other Applications</GridMenuTitle>
      <ApplicationListWrapper>
        {applicationList.map((it) => (
          <ApplicationIconWrapper
            key={it.app.appName}
            onClick={() => onClick(it.app.appName, it.app.id)}
          >
            <img
              src={getApplicationIconByAppName(it.app.appName)}
              alt={it.app.appName}
            />
          </ApplicationIconWrapper>
        ))}
      </ApplicationListWrapper>
    </div>
  );
};
