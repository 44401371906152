import { SignatureRecord } from "api/document";
import {
  PageDimensions,
  PDFSignatureBased,
  PDFSignatureBasedRefBased,
} from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { PDFFile } from "features/documents/pages/documentRequest/types";
import { ButtonStep } from "features/documents/pages/postDocumentRequest/components/ButtonStepGroup";
import { PostDocumentRequestRndHandler } from "features/documents/pages/postDocumentRequest/components/PostDocumentRequestRndHandler";
import { useSignaturePositionToPx } from "features/documents/pages/postDocumentRequest/hooks/useSignaturePositionToPx";
import { DocumentSigningViewForm } from "features/documents/pages/postDocumentRequest/pages/signing/DocumentSigningView";
import { useGenerateSignatureImageModalHandler } from "features/documents/pages/postDocumentRequest/pages/signing/hooks/useGenerateSignatureImageModalHandler";
import { useFormikContext } from "formik";
import { cloneDeep } from "lodash";
import React, {
  createContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { DocumentParticipant, SignatureSchemas } from "types/document";

type PDFSignatureSigningContextProps = {
  signatureSchemas: SignatureSchemas[];
  scale: number;
  isSignaturePreview: boolean;
  isSignatureDisplay: boolean;
  onSignatureClick: (schema: SignatureSchemas) => void;
  signatureMap: SignatureRecord;
  inputValues: Record<string, string>;
  pageWidth: number;
  sender?: DocumentParticipant;
  signers: DocumentParticipant[];
  step?: ButtonStep;
  isDiscarded?: boolean;
  currentActiveBoxId?: string;
  setStep?: React.Dispatch<React.SetStateAction<ButtonStep>>;
};

// https://github.com/bvaughn/react-window/issues/404
export const PDFSignatureSigningContext =
  createContext<PDFSignatureSigningContextProps>({
    signatureSchemas: [],
    scale: 1,
    isSignaturePreview: false,
    isSignatureDisplay: false,
    onSignatureClick: () => {},
    signatureMap: {},
    inputValues: {},
    pageWidth: 1,
    signers: [],
    isDiscarded: false,
  });

export type PDFSignatureSigningProps = {
  className?: string;
  onRemoveSignature?: () => void;
  selectedFile: PDFFile;
  isSignaturePreview: boolean;
  isSignatureDisplay: boolean;
  signatureSchemas: SignatureSchemas[];
  signers: DocumentParticipant[];
  step: ButtonStep;
  sender?: DocumentParticipant;
  isDiscarded?: boolean;
  currentActiveBoxId?: string;
  setStep?: React.Dispatch<React.SetStateAction<ButtonStep>>;
};
export type PDFSignatureSigningRefProps = {
  scrollToSignatureId: (id: string) => void;
};

export const PDFSignatureSigning = React.forwardRef<
  PDFSignatureSigningRefProps,
  PDFSignatureSigningProps
>(
  (
    {
      className,
      selectedFile,
      signatureSchemas,
      isSignaturePreview,
      isSignatureDisplay,
      signers,
      step,
      sender,
      isDiscarded,
      currentActiveBoxId,
      setStep,
    },
    ref
  ): JSX.Element => {
    const currentUserProfile = useCurrentUserProfile();

    const [inputRecord, setInputRecord] = useState<Record<string, string>>({});
    const [scale, setScale] = useState<number>(1);
    const pdfRef = useRef<PDFSignatureBasedRefBased | null>();
    const [pageDimensions, setPageDimensions] = useState<PageDimensions>();
    const [pageWidth, setPageWidth] = useState<number>(0);
    // signing
    const { setFieldValue, values } =
      useFormikContext<DocumentSigningViewForm>();

    useImperativeHandle(ref, () => ({
      scrollToSignatureId: (id: string) => {
        const target = pxSignatureRef.current.find(
          (signature) => signature.id === id
        );
        pdfRef.current?.scrollToOffset(target?.y ?? 0);
      },
    }));

    const { signatures_px } = useSignaturePositionToPx(
      pageDimensions,
      pageWidth,
      signatureSchemas
    );

    const { showModal } = useGenerateSignatureImageModalHandler({
      fileName: selectedFile.name,
      onSignatureApply: (schema, imageData, isApplyToAll, inputValue) => {
        if (schema.id) {
          const newValue = handleSignatureSigningApply(
            inputRecord,
            values.signatures,
            currentUserSignatureIds,
            schema.id,
            imageData,
            inputValue,
            isApplyToAll
          );

          setFieldValue(`signatures`, newValue.currSignatures);
          setInputRecord(newValue.currInputs);
        }
      },
    });
    const handleSignatureClick = (schema: SignatureSchemas) => {
      const isCurrSignaturesEmpty = Object.keys(values.signatures).length === 0;
      const inputValue = isCurrSignaturesEmpty
        ? ""
        : inputRecord[schema.id ?? ""] ?? "";
      showModal({ schema, inputValue });
    };

    const currentUserSignatureIds = useMemo(() => {
      return signatureSchemas
        .filter((signature) => signature.email === currentUserProfile?.email)
        .map((it) => it.id);
    }, [currentUserProfile?.email, signatureSchemas]);

    // fix useImperativeHandle not update value
    // https://github.com/facebook/react/issues/14782
    const pxSignatureRef = useRef(signatures_px);
    useEffect(() => {
      pxSignatureRef.current = signatures_px;
    });

    // Auto scroll to first signature box of current user
    useEffect(() => {
      const currentFirstSignature = signatures_px.find(
        (it) => it.id === currentUserSignatureIds[0]
      );
      if (currentFirstSignature) {
        pdfRef.current?.scrollToOffset(currentFirstSignature.y);
      }
    }, [currentUserSignatureIds, signatures_px]);

    //  inspired from https://github.com/michaeldzjap/react-pdf-sample/blob/master/src/Viewer.js
    return (
      <div className={className}>
        <PDFSignatureSigningContext.Provider
          value={{
            signatureSchemas: signatures_px,
            scale,
            isSignaturePreview,
            isSignatureDisplay,
            onSignatureClick: handleSignatureClick,
            signatureMap: values?.signatures ?? {},
            inputValues: inputRecord,
            pageWidth,
            signers,
            step,
            sender,
            isDiscarded,
            currentActiveBoxId: currentActiveBoxId,
            setStep,
          }}
        >
          <PDFSignatureBased
            ref={(el) => (pdfRef.current = el)}
            selectedFile={selectedFile}
            rndHandler={PostDocumentRequestRndHandler}
            scale={scale}
            setScale={setScale}
            pageDimensions={pageDimensions}
            setPageDimensions={setPageDimensions}
            setPageWidth={setPageWidth}
          />
        </PDFSignatureSigningContext.Provider>
      </div>
    );
  }
);

export const handleSignatureSigningApply = (
  inputRecord: Record<string, string>,
  signatureRecord: SignatureRecord,
  currentUserSignatureIds: string[],
  targetSchemaId: string,
  imageData: string,
  inputValue: string,
  isApplyToAll: boolean
) => {
  const currSignatures = cloneDeep(signatureRecord);
  const currInputs = cloneDeep(inputRecord);
  if (isApplyToAll) {
    currentUserSignatureIds.forEach((id) => {
      currSignatures[id] = imageData;
      currInputs[id] = inputValue;
    });
  } else {
    currSignatures[targetSchemaId] = imageData;
    currInputs[targetSchemaId] = inputValue;
  }
  return {
    currSignatures,
    currInputs,
  };
};
