import { FIELD_IS_REQUIRED_ERROR } from "constants/commonValidatorError";

import { Button } from "common/components/Button";
import { InputField } from "common/components/form";
import { Modal } from "common/components/Modal";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { FormSubmissionHandler } from "types/common";
import * as yup from "yup";

type CreateNewFolderPopupProps = {
  isOpen: boolean;
  onSubmit: FormSubmissionHandler<CreateNewFolderFormProps>;
  onCancel: () => void;
  isSubmitting: boolean;
};

export type CreateNewFolderFormProps = {
  folderName: string;
};

export const CreateNewFolderPopup = ({
  isOpen,
  onCancel,
  onSubmit,
  isSubmitting,
}: CreateNewFolderPopupProps): JSX.Element => {
  const { t } = useTranslation();
  const schema: yup.SchemaOf<CreateNewFolderFormProps> = yup.object().shape({
    folderName: yup.string().required().trim(t(FIELD_IS_REQUIRED_ERROR)),
  });
  return (
    <Modal open={isOpen} onClose={onCancel} size="exl">
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{ folderName: "" }}
        onSubmit={onSubmit}
      >
        <Form autoComplete="off" noValidate>
          <span className="flex items-center leading-[22px] mb-4 font-medium">
            {t("dashboard.createFolder.title")}
          </span>
          <InputField type="text" name="folderName" />
          <div className="flex justify-between mt-12">
            <Button onClick={onCancel} variant="secondary" type="button">
              {t("common.cancel")}
            </Button>
            <Button variant="primary" disabled={isSubmitting} type="submit">
              {t("dashboard.button.create")}
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
