import { Button } from "common/components/Button";
import { Modal } from "common/components/Modal";
import { SvgFolder } from "common/components/svg";
import { t } from "i18next";

type FolderExistPopupProps = {
  isOpen: boolean;
  folderNameExisted: string;
  isBackHintShow: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const FolderExistPopup = ({
  isOpen,
  folderNameExisted,
  isBackHintShow,
  onCancel,
  onConfirm,
}: FolderExistPopupProps): JSX.Element => {
  return (
    <Modal open={isOpen} onClose={onCancel}>
      <div className="flex items-center flex-col">
        <SvgFolder
          name="folder"
          width="48"
          height="48"
          className="text-light-blue mb-10"
        />
        <div className="w-full mb-10 text-center font-medium leading-[22px]">
          {t("dashboard.folderExist.title", { folderNameExisted })}
        </div>

        {isBackHintShow && (
          <p className="mb-4 text-center text-xs text-dark-grey">
            {t("dashboard.folderExist.titleHint")}
          </p>
        )}

        <Button className="w-full mb-4" onClick={onConfirm} variant="primary">
          {t("dashboard.button.goToBin")}
        </Button>

        <Button className="w-full" onClick={onCancel} variant="secondary">
          {t("common.back")}
        </Button>
      </div>
    </Modal>
  );
};
