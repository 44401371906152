import { documentAPI } from "api/document";
import { Button } from "common/components/Button";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { ToastContainerId } from "common/toast";
import { DocumentViewStatus } from "features/documents/components/StatusCell";
import { useDiscardDocumentHandler } from "features/documents/hooks/useDiscardDocumentHandler";
import { FileManagementTitle } from "features/documents/pages/postDocumentRequest/components/FileManagementTitle";
import { RecipientListEditView } from "features/documents/pages/postDocumentRequest/components/RecipientListEditView";
import { RecipientListView } from "features/documents/pages/postDocumentRequest/components/RecipientListView";
import { RelatedDocumentArea } from "features/documents/pages/postDocumentRequest/components/RelatedDocumentArea";
import useNotViewRejectDetails from "features/documents/pages/postDocumentRequest/hooks/useNotViewRejectDetails";
import useRecipientsRejectedFilter from "features/documents/pages/postDocumentRequest/hooks/useRecipientsRejectedFilter";
import { StyledFlexContainer } from "features/documents/pages/postDocumentRequest/pages/signing/components/DocumentSigningSetting";
import { isShowRecipientSetting } from "features/documents/pages/postDocumentRequest/utils/common";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";
import {
  DocumentStatePayload,
  DocumentStatePayloadMutate,
  ParticipantResponse,
} from "types/document";

type FileManagementViewProps = {
  documentStatePayload: DocumentStatePayload;
  documentViewStatus: DocumentViewStatus | null;
  mutate: DocumentStatePayloadMutate;
  isViewEditMode: boolean;
  setIsViewEditMode: (isViewEditMode: boolean) => void;
};

const FileManagementActions = styled.div(tw`flex justify-between w-full`);

export const FileManagementView = ({
  documentStatePayload,
  documentViewStatus,
  mutate,
  isViewEditMode,
  setIsViewEditMode,
}: FileManagementViewProps) => {
  const recipientValues = documentStatePayload.recipients;
  const hasBeenDiscarded = documentViewStatus === DocumentViewStatus.Discarded;
  const [isDiscarded, setIsDiscarded] = useState(hasBeenDiscarded);
  const [isRejectSectionEnable, setRejectSectionEnable] = useState(false);
  const currentUserProfile = useCurrentUserProfile();
  const { t } = useTranslation();

  // Filter recipients that reject document
  const { listRecipients } = useRecipientsRejectedFilter(
    isRejectSectionEnable,
    recipientValues
  );

  const handleBack = () => {
    if (isRejectSectionEnable) {
      setRejectSectionEnable(false);
    } else {
      if (isViewEditMode) {
        setIsViewEditMode(false);
      } else {
        // TODO: the mutate() in here is from useDocument, which is different from
        // dashboard useInfiniteDocumentList mutate, so we force it reload.
        window.location.href = `${window.location.origin}/documents`;
      }
    }
  };
  // TODO: when remove mutate() effect click discard call api will error
  const handleShowRejectDetails = async () => {
    setRejectSectionEnable(true);
    if (isNotViewRecipientsComment) {
      await documentAPI.resolve_all_comments(documentStatePayload.ref);
      mutate();
    }
  };

  /* TODO */
  const { handler: handleDiscardDocument } = useDiscardDocumentHandler(
    documentStatePayload.linearId,
    documentStatePayload.file.name,
    ToastContainerId.DocumentDetail,
    () => {
      mutate();
      setIsDiscarded(true);
    }
  );

  const isRecipientsRejected = useMemo(
    () =>
      recipientValues.some(
        (it) => it.response === ParticipantResponse.REJECTED
      ),
    [recipientValues]
  );

  const isDiscardAndReject = useMemo(() => {
    return !isRejectSectionEnable && isRecipientsRejected;
  }, [isRejectSectionEnable, isRecipientsRejected]);

  const isDiscardOnly = useMemo(() => {
    return (
      documentViewStatus === DocumentViewStatus.Completed ||
      (documentViewStatus === DocumentViewStatus.InProgress &&
        !isRecipientsRejected) ||
      documentViewStatus === DocumentViewStatus.Sent
    );
  }, [documentViewStatus, isRecipientsRejected]);

  const isAddMoreRecipients = useMemo(
    () =>
      isShowRecipientSetting(
        documentStatePayload,
        currentUserProfile,
        documentViewStatus
      ),
    [documentStatePayload, currentUserProfile, documentViewStatus]
  );

  const isNotViewRecipientsComment = useNotViewRejectDetails(
    documentStatePayload.recipients
  );

  return (
    <StyledFlexContainer>
      <FileManagementTitle
        title={
          isRejectSectionEnable
            ? t("docDetails.reject.title")
            : t("docDetails.title")
        }
        onClickBackButton={handleBack}
      />
      <RelatedDocumentArea
        documentStatePayload={documentStatePayload}
        mutate={mutate}
        isEnableForward={documentViewStatus === DocumentViewStatus.Completed}
      />
      {isViewEditMode ? (
        <StyledRecipientEditListContainer>
          <RecipientListEditView documentStatePayload={documentStatePayload} />
        </StyledRecipientEditListContainer>
      ) : (
        <div className="flex-1 flex flex-col justify-between">
          <RecipientListView
            isAddMoreRecipients={isAddMoreRecipients}
            isRejectSectionEnable={isRejectSectionEnable}
            listRecipients={listRecipients}
            onRejectDetails={handleShowRejectDetails}
            onClickRecipientSettings={() => setIsViewEditMode(true)}
            sender={documentStatePayload.sender}
            height="long"
          />
          {!isDiscarded && (
            <FileManagementActions>
              {isDiscardAndReject && (
                <>
                  <Button
                    className="w-44"
                    type="button"
                    variant="secondary"
                    onClick={handleDiscardDocument}
                  >
                    {t("dashboard.button.discard")}
                  </Button>
                  <Button
                    className="w-44"
                    type="button"
                    variant="primary"
                    onClick={handleShowRejectDetails}
                  >
                    {t("docDetails.button.rejectDetails")}
                  </Button>
                </>
              )}
              {isDiscardOnly && (
                <Button
                  className="w-full"
                  type="button"
                  variant="secondary"
                  onClick={handleDiscardDocument}
                >
                  {t("dashboard.button.discard")}
                </Button>
              )}
            </FileManagementActions>
          )}
        </div>
      )}
    </StyledFlexContainer>
  );
};

const StyledRecipientEditListContainer = styled.div(
  tw`max-h-[620px] h-auto flex-1 overflow-y-auto`
);
