import {
  NavLink,
  NavLinkProps,
  useMatch,
  useResolvedPath,
} from "react-router-dom";

export const StyledNavLink = ({
  children,
  to,
  ...props
}: NavLinkProps): JSX.Element => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <NavLink
      to={to}
      {...props}
      className={`px-4 py-2 block w-full rounded text-left font-medium text-sm leading-[1.375rem] text-grey hover:text-light-blue ${
        match && "text-light-blue bg-background-grey"
      }`}
      data-cy="sidebar-nav-link"
    >
      {children}
    </NavLink>
  );
};
