import { StyledReactTooltip } from "common/components/StyledReactTooltip";
import { SvgInformation } from "common/components/svg";
import { useReactTooltipRebuild } from "common/hooks/useReactTooltipRebuild";
import { useTranslation } from "react-i18next";
const DOC_SINGING_TOOLTIP_ID = "documentSigningTooltip";

export const DocumentSigningHintMessage = () => {
  const { t } = useTranslation();
  useReactTooltipRebuild();

  return (
    <>
      <SvgInformation
        width="15"
        height="15"
        className="text-grey cursor-pointer"
        data-tip
        data-for={DOC_SINGING_TOOLTIP_ID}
      />
      <StyledReactTooltip
        id={DOC_SINGING_TOOLTIP_ID}
        className="w-72"
        effect="solid"
        place="top"
        offset={{ right: 110, top: 10 }}
        $arrowPosition="left"
      >
        <div className="text-sm">
          {t("docDetails.actionPopup.signing.message")}
        </div>
      </StyledReactTooltip>
    </>
  );
};
export { StyledReactTooltip };
