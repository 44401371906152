import { SvgChevronDown } from "common/components/svg";
import { UserIcon, UserIconSize } from "common/components/UserIcon";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { useGenerateColorByString } from "common/hooks/useGenerateColorByString";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";
import { DocumentStatePayload } from "types/document";

type SignatureScrollControlProps = {
  documentStatePayload: DocumentStatePayload;
  onScrollToSignatureId: (id: string) => void;
  isError: boolean;
};
export const SignatureScrollControl = ({
  documentStatePayload,
  onScrollToSignatureId,
  isError,
}: SignatureScrollControlProps) => {
  const currentUserProfile = useCurrentUserProfile();
  const [filterIndex, setFilterIndex] = useState(0);
  const { t } = useTranslation();

  const handleScrollByIndex = (addIndex: number, maxLength: number) => {
    const newIndex = getNewIndex(addIndex, maxLength, filterIndex);
    setFilterIndex(newIndex);
    const id = currentUserSignatures[newIndex].id ?? "";
    onScrollToSignatureId(id);
  };

  const currentRecipient = useMemo(
    () =>
      documentStatePayload.recipients.find(
        (recipient) => recipient.email === currentUserProfile?.email
      ),
    [currentUserProfile?.email, documentStatePayload.recipients]
  );

  const currentUserSignatures = useMemo(
    () =>
      _.sortBy(
        documentStatePayload.signatureSchemas.filter(
          (schema) => schema.email === currentUserProfile?.email
        ),
        ["pageNumber", "y"]
      ),
    [currentUserProfile?.email, documentStatePayload.signatureSchemas]
  );

  const { color } = useGenerateColorByString(currentRecipient?.email);
  return (
    <>
      <h3 className="text-dark-blue">
        {t("docRequest.confirmDetail.signatureLocationsTitle")}
      </h3>
      <div className="max-w-fit">
        <p className="font-medium text-sm text-grey mb-5 mt-2">
          {t("docDetails.signing.scrollTitle")}
        </p>
      </div>
      <SignatureHandlerButton isError={isError}>
        <UserIcon
          variant={"initials"}
          content={currentRecipient?.profile.fullName ?? ""}
          size={UserIconSize.SMALL}
          color={color}
        />
        <span className="ml-2">{currentRecipient?.profile.fullName ?? ""}</span>
        <div className="flex-grow flex items-center justify-end">
          <div className="mr-8">
            {currentUserSignatures.length
              ? filterIndex < 0
                ? 1
                : filterIndex + 1
              : 0}
            /{currentUserSignatures.length}
          </div>
          <SvgChevronDown
            className="rotate-180 cursor-pointer"
            onClick={() =>
              handleScrollByIndex(-1, currentUserSignatures.length)
            }
          />
          <SvgChevronDown
            className="cursor-pointer"
            onClick={() => handleScrollByIndex(1, currentUserSignatures.length)}
          />
        </div>
      </SignatureHandlerButton>
    </>
  );
};

type SignatureRequiredProps = {
  isError: boolean;
};

const SignatureHandlerButton = styled.div(
  ({ isError }: SignatureRequiredProps) => [
    tw`flex items-center text-dark-grey justify-start py-3 px-5 rounded`,
    isError ? tw`bg-light-red-opacity` : tw`bg-background-grey`,
  ]
);

export const getNewIndex = (
  addIndex: number,
  maxLength: number,
  filterIndex: number
): number => {
  let newIndex = filterIndex + addIndex;
  if (newIndex < 0) {
    newIndex = maxLength - 1;
  } else if (newIndex >= maxLength) {
    newIndex = 0;
  }
  return newIndex;
};
