import { useSignatureBox } from "features/documents/components/SignatureBox/hooks/useSignatureBox";
import { SignatureBoxActionBased } from "features/documents/components/SignatureBox/SignatureBoxActionBased";
import { SignatureButtonRecipientsPopover } from "features/documents/components/SignatureBox/SignatureBoxRecipientsPopoverProps";
import { RecipientInfoProps } from "features/documents/pages/documentRequest/types";
import { MouseEventHandler } from "react";
import { CommonMouseEvents } from "types/common";

type SignatureBoxCreateSignActionProps = {
  onCrossClick?: MouseEventHandler<HTMLDivElement>;
  onSelectRecipient: (recipient: RecipientInfoProps) => void;
  isPopoverShow: boolean;
} & CommonMouseEvents;

export const SignatureBoxCreateSignAction = ({
  onSelectRecipient,
  isPopoverShow,
  onMouseEnter,
  onMouseLeave,
  onCrossClick,
  onClick,
}: SignatureBoxCreateSignActionProps) => {
  const { color, width, recipients, imageSrc } = useSignatureBox();
  return (
    <SignatureButtonRecipientsPopover
      recipients={recipients}
      onSelectRecipient={onSelectRecipient}
      isPopoverShow={isPopoverShow}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {({ setReferenceElement }) => (
        <SignatureBoxActionBased
          color={color}
          width={width}
          isCrossShow={true}
          setReferenceElement={setReferenceElement}
          imageSrc={imageSrc}
          onCrossClick={onCrossClick}
          onClick={onClick}
          isHoverByDefault={true}
        />
      )}
    </SignatureButtonRecipientsPopover>
  );
};
