import { SvgDot } from "common/components/svg";
import SvgRejectDetail from "common/components/svg/RejectDetail";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

export type RejectDetailButtonProps = {
  onClick?: () => void;
  className?: string;
  isDotIconShow?: boolean;
};
const StyleRejectDetailButton = tw.button`flex items-center text-light-blue text-sm font-medium gap-2.5 w-36 px-2 py-2.5 bg-background-grey rounded-lg`;
export const RejectDetailButton = ({
  onClick,
  className,
  isDotIconShow,
}: RejectDetailButtonProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyleRejectDetailButton
      type="button"
      onMouseDown={onClick}
      className={className}
    >
      {isDotIconShow && (
        <SvgDot className="text-light-red" width={6} height={6} role="img" />
      )}
      <SvgRejectDetail width={16} height={16} />
      <span>{t("docDetails.button.rejectDetails")}</span>
    </StyleRejectDetailButton>
  );
};
