import { InfiniteDocumentListResult } from "api/document";
import { Loader } from "common/components/Loader";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { KentroDocumentPopup } from "features/documents/pages/documentRequest/components/KentroDocumentPopup";
import { FileUploadField } from "features/documents/pages/documentRequest/pages/UploadDocument/components/FileUploadField";
import { PureUploadDocumentsCreateContext } from "features/documents/pages/documentRequest/pages/UploadDocument/context/PureUploadDocumentsCreateContext";
import { DocumentRequestStepViewProps } from "features/documents/pages/documentRequest/types";
import { useContext, useState } from "react";

type UploadDocumentAreaProps = {
  documentsFetchResult: InfiniteDocumentListResult;
  onSearchChange: (searchValue: string) => void;
} & DocumentRequestStepViewProps;

export const UploadDocumentArea = ({
  documentsFetchResult,
  onSearchChange,
}: UploadDocumentAreaProps) => {
  const [isOpenKentroPopUp, setIsOpenKentroPopUp] = useState<boolean>(false);
  const { isUploading } = useContext(PureUploadDocumentsCreateContext);
  return (
    <Loader
      isLoading={isUploading}
      overlay
      bgClassName={"bg-light-black-opacity"}
      hintContent={<LoadingSpinner />}
    >
      <div className="flex flex-col px-[132px] py-[40px] h-full ">
        <FileUploadField
          name="filesUpload"
          setIsOpenKentroPopUp={setIsOpenKentroPopUp}
        />

        <KentroDocumentPopup
          name="filesUpload"
          isOpen={isOpenKentroPopUp}
          setIsOpen={setIsOpenKentroPopUp}
          documentsFetchResult={documentsFetchResult}
          onSearchChange={onSearchChange}
        />
      </div>
    </Loader>
  );
};
