import * as React from "react";
import { SVGProps } from "react";

const SvgSignatureFail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5979 32.052L28.5982 18.0517L21.9483 11.4018L7.94796 25.4021C7.75522 25.5951 7.61831 25.8367 7.55174 26.1012L6 34L13.8973 32.4483C14.1625 32.382 14.405 32.2449 14.5979 32.052V32.052ZM33.1178 13.5321C33.6827 12.967 34 12.2008 34 11.4018C34 10.6029 33.6827 9.83661 33.1178 9.27157L30.7284 6.88219C30.1634 6.31732 29.3971 6 28.5982 6C27.7992 6 27.033 6.31732 26.4679 6.88219L24.0785 9.27157L30.7284 15.9215L33.1178 13.5321Z"
      fill="#00A3FF"
    />
    <path
      d="M29.1718 36.2421L31.9998 33.4141L34.8278 36.2421L36.2418 34.8281L33.4138 32.0001L36.2418 29.1721L34.8278 27.7581L31.9998 30.5861L29.1718 27.7581L27.7578 29.1721L30.5858 32.0001L27.7578 34.8281L29.1718 36.2421Z"
      fill="#00A3FF"
    />
    <path
      d="M32 42C37.514 42 42 37.514 42 32C42 26.486 37.514 22 32 22C26.486 22 22 26.486 22 32C22 37.514 26.486 42 32 42ZM32 24C36.411 24 40 27.589 40 32C40 36.411 36.411 40 32 40C27.589 40 24 36.411 24 32C24 27.589 27.589 24 32 24Z"
      fill="#00A3FF"
    />
  </svg>
);

export default SvgSignatureFail;
