import * as React from "react";
import { SVGProps } from "react";

const SvgSubmitSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 311 203"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M304.604 138.495c16.329 29.21-7.298 86.51-157.117 46.682-75.117-19.973-131.234 25.015-142.794-6.384-26.937-73.13 83.055-157.726 144.215-98.667 55.908 53.991 125.982 5.212 155.696 58.369Z"
      fill="#ECF2FE"
      stroke="#B7C9E4"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeDasharray="32.09 32.09"
    />
    <path
      d="M263.916 202.166H149.011c-5.343 0-9.683-4.338-9.683-9.68V27.712c0-5.342 4.34-9.68 9.683-9.68h114.905c5.343 0 9.683 4.338 9.683 9.68v164.774a9.679 9.679 0 0 1-9.683 9.68Z"
      fill="#3B82F4"
    />
    <path d="M261.974 25.445H151.461v160.5h110.513v-160.5Z" fill="#F6F5F5" />
    <path
      d="M261.987 25.445c.039 62.59 2.059 138.065-4.978 154.781-30.978 3.022-67.246 3.987-105.548 4.169l14.557-158.468 95.969-.482Z"
      fill="#E4E4E4"
    />
    <path
      d="M252.057 175.171c-48.271 7.726-80.539 8.13-106.707 6.188 3.388-36.089 4.756-96.047 6.112-155.9l58.176.13 52.65 3.478c.769 62.12.847 119.512-10.231 146.104Z"
      fill="#fff"
    />
    <path
      d="m234.984 41.62-58.132-1.368-.209 8.857 58.132 1.368.209-8.857ZM246.201 63.284l-52.439-1.234-.096 4.05 52.439 1.235.096-4.051Z"
      fill="#3B82F4"
    />
    <path
      d="m229.196 71.724-35.645-.839-.096 4.051 35.646.839.095-4.05Z"
      fill="#5B97EF"
    />
    <path
      d="m177.236 61.902-12.416-.292-.292 12.413 12.416.292.292-12.413Z"
      fill="#fff"
      stroke="#3B82F4"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M166.852 64.87c.378 1.472 1.095 7.609 4.171 5.889.703-.391 1.316-1.446 1.837-2.046a641.402 641.402 0 0 1 2.867-3.244c1.903-2.163 3.819-4.338 5.721-6.501.496-.56-1.668-1.954-2.28-1.25a1076.913 1076.913 0 0 1-5.096 5.77l-2.541 2.893c-.587.665-1.955 2.827-2.828 3.01.469.156.951.313 1.42.456.339.273-.286-1.68-.299-1.733-.235-.912-.47-1.811-.717-2.723-.183-.73-2.541-1.629-2.255-.521Z"
      fill="#50CD89"
    />
    <path
      d="m236.415 85.623-43.188-1.016-.096 4.05 43.189 1.017.095-4.05Z"
      fill="#3B82F4"
    />
    <path
      d="M245.458 94.682 193.02 93.45l-.096 4.05 52.439 1.234.095-4.05Z"
      fill="#5B97EF"
    />
    <path
      d="m176.703 84.454-12.416-.292-.292 12.413 12.416.292.292-12.413Z"
      fill="#fff"
      stroke="#3B82F4"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M166.305 87.787c.378 1.473 1.095 7.61 4.171 5.89.703-.392 1.316-1.447 1.837-2.046a641.416 641.416 0 0 1 2.867-3.244l5.721-6.502c.496-.56-1.668-1.954-2.28-1.25-1.695 1.928-3.389 3.856-5.096 5.771l-2.541 2.893c-.587.664-1.955 2.827-2.828 3.01.469.156.951.312 1.42.455.339.274-.286-1.68-.299-1.733-.235-.912-.47-1.81-.717-2.723-.17-.73-2.541-1.628-2.255-.52Z"
      fill="#50CD89"
    />
    <path
      d="m245.132 108.414-52.439-1.234-.095 4.051 52.439 1.234.095-4.051Z"
      fill="#3B82F4"
    />
    <path
      d="m215.945 116.568-23.451-.552-.095 4.051 23.451.552.095-4.051Z"
      fill="#5B97EF"
    />
    <path
      d="m176.182 107.02-12.416-.292-.293 12.412 12.416.292.293-12.412Z"
      fill="#fff"
      stroke="#3B82F4"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M165.954 110.509c.378 1.473 1.095 7.609 4.17 5.889.704-.391 1.316-1.446 1.838-2.045a560.211 560.211 0 0 1 2.867-3.244l5.721-6.502c.495-.56-1.668-1.954-2.281-1.25-1.694 1.928-3.388 3.856-5.095 5.771l-2.542 2.893c-.586.664-1.954 2.827-2.828 3.009a57 57 0 0 0 1.421.456c.339.274-.287-1.68-.3-1.732-.234-.912-.469-1.811-.717-2.723-.182-.73-2.554-1.629-2.254-.522Z"
      fill="#50CD89"
    />
    <path
      d="m244.611 130.967-52.439-1.234-.095 4.05 52.438 1.234.096-4.05Z"
      fill="#3B82F4"
    />
    <path
      d="m233.393 139.553-41.43-.975-.095 4.051 41.43.975.095-4.051Z"
      fill="#5B97EF"
    />
    <path
      d="m175.646 129.585-12.416-.292-.292 12.412 12.416.293.292-12.413Z"
      fill="#fff"
      stroke="#3B82F4"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M165.419 132.932c.378 1.472 1.094 7.609 4.17 5.889.704-.391 1.316-1.446 1.838-2.046.951-1.081 1.902-2.163 2.867-3.244 1.902-2.163 3.818-4.338 5.721-6.501.495-.56-1.668-1.955-2.281-1.251-1.694 1.928-3.388 3.856-5.095 5.772l-2.542 2.892c-.586.665-1.955 2.827-2.828 3.01.469.156.952.312 1.421.456.339.273-.287-1.681-.3-1.733-.235-.912-.469-1.811-.717-2.723-.182-.73-2.541-1.629-2.254-.521Z"
      fill="#50CD89"
    />
    <path
      d="m212.44 152.625-20.794-.489-.095 4.051 20.793.489.096-4.051Z"
      fill="#3B82F4"
    />
    <path
      d="m243.873 162.207-52.442-1.238-.091 4.052c20.291.482 46.121 1.42 51.399.613.795-.899 1.225-2.02 1.134-3.427Z"
      fill="#5B97EF"
    />
    <path
      d="m175.295 151.981-12.416-.292-.292 12.412 12.416.292.292-12.412Z"
      fill="#fff"
      stroke="#3B82F4"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M164.897 155.146c.378 1.472 1.095 7.608 4.171 5.889.703-.391 1.316-1.447 1.837-2.046a641.389 641.389 0 0 1 2.867-3.244l5.721-6.501c.496-.561-1.668-1.955-2.28-1.251-1.695 1.928-3.389 3.856-5.096 5.772l-2.541 2.892c-.587.664-1.955 2.827-2.828 3.01.469.156.951.312 1.42.456.339.273-.286-1.681-.299-1.733-.235-.912-.47-1.811-.717-2.723-.183-.73-2.542-1.629-2.255-.521Z"
      fill="#50CD89"
    />
    <path
      d="M231.361 19.14v12.39h-49.783V19.14c0-1.981.808-3.766 2.098-5.056.157-.156.326-.313.509-.456a7.105 7.105 0 0 1 4.561-1.642 8.483 8.483 0 0 0 5.043-1.667 8.419 8.419 0 0 0 3.05-4.326c.547-1.824 1.681-3.335 3.154-4.404A8.425 8.425 0 0 1 204.932 0h3.102c1.824 0 3.531.586 4.939 1.59a8.524 8.524 0 0 1 3.154 4.403 8.391 8.391 0 0 0 3.049 4.326c.43.312.886.599 1.381.834a8.484 8.484 0 0 0 3.663.833c1.733 0 3.323.613 4.561 1.642a7.181 7.181 0 0 1 2.58 5.511Z"
      fill="#B6CAE5"
    />
    <path
      d="M235.402 25.445v9.211h-57.863v-9.21h4.04v6.083h49.783v-6.084h4.04Z"
      fill="#B6CAE5"
    />
    <path
      d="M206.471 11.621c2.44 0 4.418-1.878 4.418-4.195s-1.978-4.195-4.418-4.195c-2.44 0-4.418 1.878-4.418 4.195s1.978 4.195 4.418 4.195Z"
      fill="#F5F8FA"
    />
    <path
      d="M111.062 65.417c-1.759-.339-3.414-3.283-3.988-4.417a1.422 1.422 0 0 1-.13-.899l.704-3.726s1.616.274 1.498 2.437a1.59 1.59 0 0 0 .118.65l.625 1.63 1.838-1.42-1.004-2.84s-1.342.364-2.046.142c-1.433-.455.443-1.563 1.03-1.68.586-.117 1.889-.586 2.489.417.586 1.003.951 1.602.951 1.602s.652-2.254.209-7.283c-.026-.221.547-.221.704-.065.482.482 1.238 1.967 1.277 6.45 0 0 .078-5.238.977-5.773.743-.443 1.108 2.111.587 6.463 0 0 .964-3.765 1.655-3.765 1.173-.014.013 10.045-2.92 13.041l-4.574-.964Z"
      fill="#FDC8A9"
    />
    <path
      d="M116.6 63.88c-.052.495-.104.99-.169 1.459a42.65 42.65 0 0 1-.769 4.43l-.039.182c-.886 3.648-1.056 5.446-4.092 4.964l-3.128-3.57-.026-.039-.234-.26.638-1.877.078-.247.47-1.368.052-.183.808-2.345.325-.95 6.086-.196Z"
      fill="#FDC8A9"
    />
    <path
      d="m58.4 193.684-.796 2.489c-5.473 1.798-8.653 3.856-8.862 6.254H64.55l1.264-8.339c-3.205.782-5.773.235-7.415-.404Z"
      fill="#1F3C88"
    />
    <path
      d="m60.38 187.483-1.982 6.188c1.655.639 4.223 1.186 7.416.404l.82-5.381-6.255-1.211ZM87.408 193.411l.47-6.306-7.43.391-.403 5.811c3.14 1.003 5.734.612 7.363.104Z"
      fill="#FDC8A9"
    />
    <path
      d="M96.35 202.427c-.21-2.398-2.998-5.694-9.123-6.476l.182-2.553c-1.629.508-4.222.899-7.376-.104l-.639 9.12H96.35v.013Z"
      fill="#1F3C88"
    />
    <path
      d="m81.387 67.046-8.953.052v-13.55h8.68l.012.834.04 4.3.064 6.891.157 1.473Z"
      fill="#FDC8A9"
    />
    <path
      d="M81.165 59.333c-1.616 1.693-5.813-.222-7.585-2.085l7.546-2.215.039 4.3Z"
      fill="#F1BA9F"
    />
    <path
      d="M83.252 50.421c0 1.003-.066 2.046-.235 3.049-.443 2.736-1.59 5.094-4.261 5.159-6.308.182-8.106-3.426-9.292-6.371-1.186-2.958.743-10.058.743-10.058l8.158-.808 4.483 3.674c-.013.013.43 2.502.404 5.355Z"
      fill="#FDC8A9"
    />
    <path
      d="M69.71 50.577s1.8-.078 2.112-4.73c3.219 1.082 5.565.092 7.428-2.045 1.59 2.098 6.034 2.763 7.546-1.537 1.082-3.1-1.916-7.726-6.281-4.925-4.275-2.163-10.765-2.006-12.642 3.283 0 0-6.49 2.997.378 9.577.144.13 1.46.377 1.46.377Z"
      fill="#15305D"
    />
    <path
      d="m94.523 115.734-4.952 74.876H77.92l2.92-72.648 1.772-.834 11.911-1.394Z"
      fill="#15325E"
    />
    <path
      d="m64.198 105.493-8.17 85.117H68.72l12.068-70.694 13.736-4.169.599-15.113-30.574-.704-.352 5.563Z"
      fill="#1F3A6F"
    />
    <path
      opacity={0.6}
      d="M66.022 105.676c6.933 2.488 23.184-.352 26.56-3.818l-2.398-4.794-26.743 3.557 2.58 5.055Z"
      fill="#102048"
    />
    <path
      d="M91.956 100.712c-.222.091-.457.195-.691.286a46.072 46.072 0 0 1-8.68 2.58 40.302 40.302 0 0 1-5.825.717c-2.998.156-6.086-.052-9.149-.743l3.884-36.194.47-4.416s5.082 6.253 10.685-.652l9.305 38.422Z"
      fill="#fff"
    />
    <path
      opacity={0.3}
      d="M76.76 104.282c-2.998.156-6.086-.052-9.149-.743l3.884-36.194 1.381-2.006c3.44 8.338 6.947 26.774 3.884 38.943Z"
      fill="#1F3C88"
    />
    <path
      d="M72.435 61.495c3.857 16.39 5.447 33.263-.235 51.985-4.222 1.602-8.81 1.589-13.723.169l1.212-34.044L31.058 99.28l-1.734-10.723c11-10.514 30.026-26.41 43.11-27.06Z"
      fill="#0163AE"
    />
    <path
      opacity={0.3}
      d="M91.265 100.985a46.072 46.072 0 0 1-8.68 2.58c-1.72-9.889-2.11-26.135-.703-33.47l9.383 30.89Z"
      fill="#1F3C88"
    />
    <path
      opacity={0.6}
      d="m115.648 69.782-.039.182c-.886 3.648-1.055 5.446-4.092 4.964l-3.128-3.57-.026-.039.404-2.137.078-.247.47-1.368.052-.183 6.281 2.398Z"
      fill="#F1BA9F"
    />
    <path
      d="M115.115 73.09s-7.689 16.287-12.25 16.678c-4.418.377-6.516-3.909-6.516-3.909l3.127 23.803c-3.675 1.72-7.689 2.554-12.341 1.733-4.131-13.42-5.695-30.773-6.008-50.095 3.35.208 9.97.495 12.355 2.02 4.261 2.735 9.513 12.507 9.513 12.507l6.125-8.338 5.995 5.602Z"
      fill="#0163AE"
    />
    <path
      d="M21.373 50.147s.47-7.79 1.264-7.426c.795.365 2.385 7.14 2.385 7.14l-1.707 2.749-1.942-2.463ZM44.78 190.831l.234 2.567c.404 4.521-2.932 8.521-7.468 8.937l-.664.066c-4.523.404-8.523-2.932-8.94-7.466l-.235-2.566 17.072-1.538Z"
      fill="#1F3C88"
    />
    <path
      d="M33.614 66.674 16.56 68.206l11.157 124.16 17.056-1.532-11.158-124.16Z"
      fill="#80C6FF"
    />
    <path
      d="m27.9 64.674-6.062.544 9.78 108.835 6.062-.545L27.9 64.674Z"
      stroke="#3B82F4"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="m44.78 190.831-17.06 1.538-.443-5.003-.313-3.479L26 173.203l17.06-1.537.95 10.684.313 3.478.456 5.003Z"
      fill="#3B82F4"
    />
    <path
      d="m44.017 182.362-17.056 1.532.313 3.478 17.055-1.533-.312-3.477Z"
      fill="#fff"
    />
    <path
      d="m16.564 68.205 4.744-18.983s1.63.73 3.623-.495l8.706 17.954-17.073 1.524ZM70.65 49.796c-.079-.274-1.356-.665-1.63-.743-.43-.117-.912-.182-1.329.013-1.538.69-1.33 2.892-.534 4.052.456.651 1.108 1.16 1.877 1.38.273.08 2.932.118 2.814-.312l-1.198-4.39ZM22.195 94.067c-.404-1.25-1.72-1.954-2.959-1.524-1.941.664-3.636 1.81-3.896 3.739-.235 1.759.639 7.387 4.574 8.104l-.326-2.893s2.411-.56 2.894-1.602c.338-.73.364-3.778-.287-5.824Z"
      fill="#FDC8A9"
    />
    <path
      d="M95.15 74.733c.208 1.224.378 2.462.547 3.7.17 1.237.313 2.475.47 3.713l.403 3.713c.118 1.238.235 2.489.313 3.726l-.208.026a114.973 114.973 0 0 1-.56-3.7l-.47-3.713c-.13-1.238-.287-2.475-.39-3.713a116.356 116.356 0 0 1-.314-3.726l.209-.026ZM60.092 74.537c0 1.381-.04 2.762-.078 4.143-.04 1.381-.104 2.762-.157 4.143l-.221 4.13c-.091 1.382-.183 2.75-.326 4.13l-.208-.013c0-1.38.026-2.762.065-4.143l.17-4.143c.077-1.38.142-2.762.234-4.13.091-1.38.182-2.749.313-4.13l.208.013Z"
      fill="#1F3C88"
    />
    <path
      d="M69.554 51.463c-.325-.221-.638-.469-.938-.612-.156-.078-.287-.117-.417-.104-.13.013-.274.078-.43.195l-.17-.104c.066-.209.222-.443.496-.534.273-.092.547-.013.756.09a1.9 1.9 0 0 1 .873.965l-.17.104Z"
      fill="#F1BA9F"
    />
  </svg>
);

export default SvgSubmitSuccess;
