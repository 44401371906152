import { documentAPI } from "api/document";
import {
  composeErrorHandlers,
  baseFallbackErrorHandler,
  GenericError,
} from "common/errorHandling";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { useDocumentRequest } from "common/hooks/useDocumentRequest";
import { ToastContainerId } from "common/toast";
import { useActionPopupHandler } from "features/documents/hooks/useActionPopupHandler";
import { DocumentRequestTemplate } from "features/documents/pages/documentRequest/components/DocumentRequestTemplate";
import { PDFSignatureDisplay } from "features/documents/pages/documentRequest/components/PDFSignatureDisplay";
import { PDFSignatureControlContextProvider } from "features/documents/pages/documentRequest/context/PDFSignatureControlContext";
import { AddRecipients } from "features/documents/pages/documentRequest/pages/AddRecipients/AddRecipients";
import { AddRolePermission } from "features/documents/pages/documentRequest/pages/AddRolePermission/AddRolePermission";
import {
  SubmitFailView,
  SubmitSuccessView,
} from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/SubmitView";
import { ConfirmDocumentDetail } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/ConfirmDocumentDetail";
import { generateDocumentRequestPayload } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/utils/PDFSignatureUtils";
import {
  DocumentRequestStep,
  PDFFile,
  SenderAsRecipientMode,
} from "features/documents/pages/documentRequest/types";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currentPartySelector } from "redux/slices/party/selectors";
import { useSWRConfig } from "swr";

export const DocumentRequest = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<DocumentRequestStep>(
    DocumentRequestStep.AddRecipients
  );

  const {
    filesUpload,
    recipients,
    rolePermission,
    signatures,
    authenticatedFiles,
    setForcePageLeave,
  } = useDocumentRequest();
  const currentParty = useSelector(currentPartySelector);
  const currentUserProfile = useCurrentUserProfile();

  const { cache } = useSWRConfig();

  const [isPreview, setIsPreview] = useState(false);

  const [selectedFile, setSelectedFile] = useState<PDFFile>(
    filesUpload[0] || {
      name: "",
      size: 0,
      id: "",
      URL: "",
    }
  );

  const isSenderSameAsRecipient = useMemo(
    () =>
      rolePermission.recipientRoles.some(
        (it) => it.recipient.email === currentUserProfile?.email
      ),
    [currentUserProfile?.email, rolePermission.recipientRoles]
  );

  const senderSignedFileNames = useMemo(() => {
    // Get the status of each file (sender=recipient) whether the sender has signed it
    const senderSignatureStatusOnFiles = signatures.map((sig1) =>
      sig1
        .filter((sig2) => currentUserProfile?.email === sig2.value?.email)
        .some((sig3) => sig3.imageSrc)
    );
    // Find out which files the sender has signed and save their names to an array
    const signedFileNames = filesUpload
      .filter((file, index) => senderSignatureStatusOnFiles[index])
      .map((file) => file.name);

    return signedFileNames;
  }, [currentUserProfile?.email, filesUpload, signatures]);

  const takenActionFileNames = useMemo(() => {
    const authenticatedFileNames = authenticatedFiles.map((file) => file.name);
    const SignedOrAuthFileNames = Array.from(
      new Set(senderSignedFileNames.concat(authenticatedFileNames))
    );
    return SignedOrAuthFileNames;
  }, [authenticatedFiles, senderSignedFileNames]);

  const submitRequest = async () => {
    try {
      await Promise.all(
        filesUpload.map(async (file, index) => {
          await documentAPI.create(
            generateDocumentRequestPayload(
              file,
              recipients.recipientInfo,
              rolePermission.recipientRoles,
              signatures[index] ?? [], // TODO hotfix, check context while refactoring to prevent empty
              currentUserProfile,
              authenticatedFiles.some((it) => _.isEqual(it, file)),
              currentParty?.x500Name ?? "",
              isSenderSameAsRecipient,
              file.isFromKentro,
              file.linearId
            )
          );
        })
      );
      setCurrentStep(DocumentRequestStep.SubmitSuccess);
    } catch (e) {
      composeErrorHandlers(() =>
        baseFallbackErrorHandler(ToastContainerId.DocumentRequest)
      )(e as GenericError);
      setCurrentStep(DocumentRequestStep.SubmitFail);
    }
  };

  const { handler: handleAuthenticatePopup } = useActionPopupHandler(
    SenderAsRecipientMode.AUTHENTICATE,
    takenActionFileNames,
    submitRequest
  );
  const { handler: handleSigningPopup } = useActionPopupHandler(
    SenderAsRecipientMode.SIGNING,
    takenActionFileNames,
    submitRequest
  );

  const { handler: handleSignAndAuthenticatePopup } = useActionPopupHandler(
    SenderAsRecipientMode.SIGNANDAUTHENTICATE,
    takenActionFileNames,
    submitRequest
  );

  const handleNext = () => {
    setCurrentStep((prev) => getNextPage(prev));
  };

  const handleBack = () => {
    setCurrentStep((prev) => getPreviousPage(prev));
  };

  const handleSubmitRequest = async () => {
    const isAuth = authenticatedFiles?.length > 0;
    const isSign = signatures?.some((sig1) =>
      sig1
        .filter((sig2) => currentUserProfile?.email === sig2.value?.email)
        .some((sig3) => sig3.imageSrc)
    );
    if (isSenderSameAsRecipient) {
      if (isAuth && isSign) {
        handleSignAndAuthenticatePopup();
      } else if (isAuth) {
        handleAuthenticatePopup();
      } else if (isSign) {
        handleSigningPopup();
      } else {
        await submitRequest(); // TODO hot fix
      }
    } else {
      await submitRequest();
    }
  };
  return (
    <PDFSignatureControlContextProvider>
      <DocumentRequestTemplate
        leftComponent={(() => {
          switch (currentStep) {
            default:
              return (
                <PDFSignatureDisplay
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  isPreview={isPreview}
                  isSignatureDisplay={
                    currentStep ===
                    DocumentRequestStep.ConfirmDocumentRequestDetails
                  }
                />
              );
          }
        })()}
        rightComponent={(() => {
          switch (currentStep) {
            case DocumentRequestStep.AddRecipients:
              return <AddRecipients onNext={handleNext} onBack={handleBack} />;
            case DocumentRequestStep.SetRolesPermissions:
              return (
                <AddRolePermission onNext={handleNext} onBack={handleBack} />
              );
            case DocumentRequestStep.ConfirmDocumentRequestDetails:
              return (
                <ConfirmDocumentDetail
                  onBack={handleBack}
                  isPreview={isPreview}
                  setIsPreview={setIsPreview}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  onSubmitRequest={handleSubmitRequest}
                />
              );
            case DocumentRequestStep.SubmitSuccess:
              if (setForcePageLeave) {
                setForcePageLeave(true);
                // For now mutate can only revalidate the first page
                // Can not mutate cache when new documents were added to page > 1
                // Clear cache to make sure when back to dashboard, all data will be mutated
                cache.clear();
              }
              return <SubmitSuccessView />;
            case DocumentRequestStep.SubmitFail:
              return <SubmitFailView onReSubmit={handleSubmitRequest} />;
            default:
              navigate("../upload-documents");
          }
        })()}
        step={currentStep}
      />
    </PDFSignatureControlContextProvider>
  );
};

export const getNextPage = (
  currentPage: DocumentRequestStep
): DocumentRequestStep => {
  switch (currentPage) {
    case DocumentRequestStep.UploadDocuments:
      return DocumentRequestStep.AddRecipients;
    case DocumentRequestStep.AddRecipients:
      return DocumentRequestStep.SetRolesPermissions;
    case DocumentRequestStep.SetRolesPermissions:
      return DocumentRequestStep.ConfirmDocumentRequestDetails;
    default:
      return currentPage;
  }
};

export const getPreviousPage = (
  currentPage: DocumentRequestStep
): DocumentRequestStep => {
  switch (currentPage) {
    case DocumentRequestStep.ConfirmDocumentRequestDetails:
      return DocumentRequestStep.SetRolesPermissions;
    case DocumentRequestStep.SetRolesPermissions:
      return DocumentRequestStep.AddRecipients;
    case DocumentRequestStep.AddRecipients:
      return DocumentRequestStep.UploadDocuments;
    default:
      return currentPage;
  }
};
