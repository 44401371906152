import {
  EMAIL_DUPLICATE_ERROR,
  EMAIL_FORMAT_INCORRECT_ERROR,
  FIELD_IS_REQUIRED_ERROR,
} from "constants/commonValidatorError";
import { REG_EMAIL_VALIDATOR_WITHOUT_TRIM } from "constants/emailValidatorKey";

import { documentAPI } from "api/document";
import { Button } from "common/components/Button";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import { Message } from "common/components/Message";
import { Modal } from "common/components/Modal";
import { Option } from "common/components/MultiValueRemove";
import { ForwardCreatableInput } from "features/documents/components/ForwardDocumentPopup/ForwardCreatableInput";
import { ForwardHintMessage } from "features/documents/components/ForwardDocumentPopup/ForwardHintMessage";
import { ForwardRecipientTooltip } from "features/documents/components/ForwardDocumentPopup/ForwardRecipientEmailTooltip";
import { Formik, Form } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreatableInputFormProps } from "stories/designSystem/CreatableInput.stories";
import tw from "twin.macro";
import { FormSubmissionHandler, LinearId } from "types/common";
import { DocumentStatePayload } from "types/document";
import * as yup from "yup";

/* Folder Management */
type ForwardDocumentPopupProps = {
  isOpen: boolean;
  dashboardItemLinearId: LinearId;
  documentName: string;
  isSubmitting: boolean;
  isSuccess: boolean;
  forwardedRecipients: string[];
  latestDocument?: DocumentStatePayload;
  setLatestDocument: (value: DocumentStatePayload) => void;
  setForwardedRecipients: (value: string[]) => void;
  onSubmit: FormSubmissionHandler<CreatableInputFormProps>;
  onCancel: () => void;
};

export const ForwardDocumentPopup = ({
  isOpen,
  dashboardItemLinearId,
  documentName,
  isSubmitting,
  isSuccess,
  latestDocument,
  forwardedRecipients,
  setLatestDocument,
  setForwardedRecipients,
  onCancel,
  onSubmit,
}: ForwardDocumentPopupProps): JSX.Element => {
  const { t } = useTranslation();
  const schema = (
    inputValue: string
  ): yup.SchemaOf<CreatableInputFormProps> => {
    return yup.object().shape({
      input: yup
        .array()
        .of(
          yup.object().shape({
            label: yup.string().required(),
            value: yup.string().required(),
          })
        )
        .test("email-format", t(EMAIL_FORMAT_INCORRECT_ERROR), () => {
          // handle case that the value(option) is not stored in form KT-511 - issue 3
          return inputValue
            ? !!inputValue.match(REG_EMAIL_VALIDATOR_WITHOUT_TRIM)
            : true;
        })
        .min(1, t(FIELD_IS_REQUIRED_ERROR)),
    });
  };

  const hasPreviousRecipients = useMemo(
    () => forwardedRecipients && forwardedRecipients.length,
    [forwardedRecipients]
  );

  const recipientAndForwardedEmails: string[] = useMemo(() => {
    if (latestDocument) {
      const recipients = latestDocument.recipients.map(
        (recipient) => recipient.email
      );
      return [...recipients, latestDocument.sender.email].concat(
        forwardedRecipients
      );
    } else return [...forwardedRecipients];
  }, [latestDocument, forwardedRecipients]);

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    let newForwardedRecipients: string[] = [];
    if (latestDocument) {
      newForwardedRecipients = latestDocument.forwardedRecipients.map(
        (it) => it.email
      );
    }
    setForwardedRecipients(newForwardedRecipients);
  }, [latestDocument, setForwardedRecipients]);

  useEffect(() => {
    const getLatestInfo = async () => {
      try {
        const data = await documentAPI.getLatestDocument(dashboardItemLinearId);
        setLatestDocument(data);
      } catch (e) {
        console.error(e);
      }
    };
    getLatestInfo();
  }, [dashboardItemLinearId, setLatestDocument]);

  return (
    <Modal open={isOpen} onClose={onCancel} size="exl">
      <Formik
        initialValues={{
          input: [] as Option[],
        }}
        onSubmit={onSubmit}
        validationSchema={schema(inputValue)}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ errors, submitForm }) => (
          <Form>
            <span className="flex items-center leading-[22px] mb-4 font-medium">
              {t("dashboard.forwardDocument.title")}
              <ForwardHintMessage />
            </span>
            <ForwardCreatableInput
              name="input"
              forwardedEmails={recipientAndForwardedEmails}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
            <div
              className={`mt-14 ${
                hasPreviousRecipients || isSubmitting ? "" : "hidden"
              }`}
            >
              <StyledPreviouslyTitle>
                {t("dashboard.forwardDocument.forwardedRecipients")}
              </StyledPreviouslyTitle>
              <div
                className={`h-[232px] ${
                  isSubmitting ? "flex items-center justify-center" : ""
                }`}
              >
                {isSubmitting && <LoadingSpinner iconSize="55" />}
                {!isSubmitting && (
                  <div>
                    <StyledPreviousContent>
                      {forwardedRecipients.map((email) => {
                        return (
                          <ForwardRecipientTooltip email={email} key={email} />
                        );
                      })}
                    </StyledPreviousContent>
                  </div>
                )}
              </div>
              <Message
                className={`text-xs ${isSuccess ? "" : "hidden"}`}
                variant="success"
                children={
                  <div className="flex items-center gap-1 max-w-[440px]">
                    <span className="shrink-0">
                      {t("dashboard.message.forwardSuccessHead")}
                    </span>
                    <span className="truncate w-fit">"{documentName}</span>
                    <span className="shrink-0">
                      " {t("dashboard.message.forwardSuccessTail")}
                    </span>
                  </div>
                }
                isBackgroundEnable={false}
              />
            </div>
            <div
              className={`flex justify-between ${
                isSuccess ? "mt-[18px]" : "mt-8"
              }`}
            >
              <Button onClick={onCancel} variant="secondary" type="button">
                {t("common.cancel")}
              </Button>
              <Button
                variant="primary"
                disabled={isSubmitting}
                type="button"
                onClick={() => {
                  if (errors.input !== t(EMAIL_DUPLICATE_ERROR)) {
                    submitForm();
                  }
                }}
              >
                {t("common.send")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const StyledPreviouslyTitle = tw.div`flex items-center leading-[22px] mb-4 text-sm text-dark-blue font-medium`;
const StyledPreviousContent = tw.div`grid grid-cols-2 gap-4 max-h-[182px] overflow-y-auto`;
export const StyledPreviousUserIcon = tw.div`flex items-center text-dark-grey text-sm`;
