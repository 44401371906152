import { useAuthorized } from "common/hooks/useAuthorized";
import { useWhitelist } from "common/hooks/useWhitelist";
import React from "react";
import { Location, Navigate, useLocation } from "react-router-dom";

type AuthorizedCheckProps = {
  children: React.ReactNode;
};

export const AuthorizedCheck = ({
  children,
}: AuthorizedCheckProps): JSX.Element => {
  const { isIgnoreWhitelist } = useWhitelist();
  const { canCreateRequest } = useAuthorized();

  const location = useLocation();
  const locationState = location.state as { from?: Location } | null;

  if (isIgnoreWhitelist) {
    return <>{children}</>;
  } else {
    if (canCreateRequest) {
      return <>{children}</>;
    } else {
      console.error("Unauthorized user"); // TODO TBD
      return <Navigate to={locationState?.from?.pathname ?? "/"} replace />;
    }
  }
};
