import { Button } from "common/components/Button";
import {
  SvgLeftCircleArrow,
  SvgSubmitFail,
  SvgSubmitSuccess,
} from "common/components/svg";
import { ControlPanel } from "features/documents/pages/documentRequest/components/ControlPanel";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";

const StyledSubmitTitle = tw.h1`text-dark-blue flex items-center justify-between my-5 mb-10`;
const StyledSubmitImage = tw.div`flex justify-center mb-8`;
const StyledSubmitText = tw.div`text-dark-blue text-sm leading-[1.25rem]`;
const StyledButton = tw(Button)`min-w-[185px]`;

type SubmitFailViewProps = {
  onReSubmit: () => void;
};

export const SubmitSuccessView = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const timer = window.setTimeout(
      () => navigate("../../../../documents"),
      3000
    );
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <ControlPanel
      contentSection={
        <>
          <StyledSubmitTitle data-cy="submit-title">
            {t("docRequest.completed.title")}
          </StyledSubmitTitle>
          <StyledSubmitImage>
            <SvgSubmitSuccess width={337} height={203} />
          </StyledSubmitImage>
          <StyledSubmitText className="ml-[133px]">
            <div className="mb-2">{t("docRequest.completed.titleHint")}</div>
            {t("docRequest.completed.titleNotify")}
          </StyledSubmitText>
        </>
      }
      buttonSection={<></>}
    />
  );
};

export const SubmitFailView = ({ onReSubmit }: SubmitFailViewProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleBackToDashBoard = () => navigate("../../../../documents");
  return (
    <ControlPanel
      contentSection={
        <>
          <StyledSubmitTitle>{t("docRequest.fail.title")}</StyledSubmitTitle>
          <StyledSubmitImage>
            <SvgSubmitFail width={337} height={204} />
          </StyledSubmitImage>
          <StyledSubmitText className="ml-[140px] grow">
            <div className="mb-2">{t("docRequest.fail.titleHint")}</div>
            {t("docRequest.fail.titleNotify")}
          </StyledSubmitText>
        </>
      }
      buttonSection={
        <>
          <StyledButton
            type="button"
            variant="secondary"
            onClick={handleBackToDashBoard}
          >
            {t("docRequest.button.backToDashboard")}
          </StyledButton>
          <StyledButton type="submit" variant="primary" onClick={onReSubmit}>
            <SvgLeftCircleArrow className="mr-4" />
            {t("docRequest.button.resubmit")}
          </StyledButton>
        </>
      }
    />
  );
};
