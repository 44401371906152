import _ from "lodash";
import { useCallback, useEffect } from "react";

export const useWindowResizeEvent = (eventHandler: () => void): void => {
  const debouncedEventHandler = useCallback(
    () => _.debounce(eventHandler, 200)(),
    [eventHandler]
  );
  useEffect(() => {
    window.addEventListener("resize", debouncedEventHandler);
    debouncedEventHandler();
    return () => window.removeEventListener("resize", debouncedEventHandler);
    // The event only add once, no need to update. disable to prevent infinitely update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
