import { LatestDocumentsContext } from "common/context/LatestDocumentsContext";
import { Dispatch, SetStateAction, useContext } from "react";

export const useCurrentFolderId = (): {
  currentFolderId: string | null;
  setCurrentFolderId: Dispatch<SetStateAction<string | null>>;
} => {
  const { currentFolderId, setCurrentFolderId } = useContext(
    LatestDocumentsContext
  );
  return { currentFolderId, setCurrentFolderId };
};
