import { useInfiniteDocumentList } from "api/document";
import { useCurrentFolderId } from "common/hooks/useCurrentFolderId";
import { useDashboardBreadcrumbs } from "common/hooks/useDashboardBreadcrumbs";
import { useGoToRowSelectedPage } from "common/hooks/useGoToRowSelectedPage";
import { useOriginalRoute } from "common/hooks/useOriginalRoute";
import { useUpdateDocuments } from "common/hooks/useUpdateDocuments";
import { DocumentListMutatorContext } from "features/documents/pages/documentManagement/DocumentListMutatorContext";
import { PureDocumentListView } from "features/documents/pages/documentManagement/PureDocumentListView";
import { DocumentFilterType } from "features/documents/pages/documentManagement/types";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Row } from "react-table";
import { DashboardResponse } from "types/common";
import { QueryDocumentRole } from "types/document";

type DocumentListViewProps = {
  filter: DocumentFilterType;
};

export const filterTypeToRole = (
  filter: DocumentFilterType
): QueryDocumentRole => {
  switch (filter) {
    case DocumentFilterType.All:
    case DocumentFilterType.Bin:
      return QueryDocumentRole.ALL;
    case DocumentFilterType.Sender:
      return QueryDocumentRole.SENDER;
    case DocumentFilterType.Recipient:
      return QueryDocumentRole.RECIPIENT;
  }
};

export const DocumentListView = ({
  filter,
}: DocumentListViewProps): JSX.Element => {
  const [pageSize, setPageSize] = useState<number>(10);
  const isArchived = filter === DocumentFilterType.Bin;

  const { isOriginalRoute } = useOriginalRoute();
  const { currentFolderId, setCurrentFolderId } = useCurrentFolderId();
  const { dashboardBreadcrumbsItems, setDashboardBreadcrumbsItems } =
    useDashboardBreadcrumbs();
  const [searchParams] = useSearchParams();
  const searchFileName = searchParams.get("name") || undefined;
  const isGlobalSearch =
    filterTypeToRole(filter) !== QueryDocumentRole.ALL || !!searchFileName;

  const { documents, total, fetchNext, mutate } = useInfiniteDocumentList(
    filterTypeToRole(filter),
    isArchived,
    currentFolderId,
    pageSize,
    isGlobalSearch,
    searchFileName,
    filterTypeToRole(filter) === QueryDocumentRole.ALL
  );
  useUpdateDocuments(documents); // updated the newest documents on LatestDocumentContext
  const { handleGoToRowSelectedPage } = useGoToRowSelectedPage();

  const backToOriginalRoute = useCallback(() => {
    setCurrentFolderId(null);
    setDashboardBreadcrumbsItems([]);
  }, [setCurrentFolderId, setDashboardBreadcrumbsItems]);

  // Reset original route whenever click on sidebar tab
  useEffect(() => {
    if (isOriginalRoute) {
      backToOriginalRoute();
    }
  }, [isOriginalRoute, backToOriginalRoute]);

  const handleRowSelected = (row: Row<DashboardResponse>) => {
    handleGoToRowSelectedPage(row.original);
  };

  return (
    <DocumentListMutatorContext.Provider value={{ mutate, setPageSize }}>
      <PureDocumentListView
        isPending={!documents}
        documents={documents ?? []}
        total={total}
        filter={filter}
        isArchived={isArchived}
        fetchNext={fetchNext}
        hasMore={() => documents?.length !== total}
        onRowSelected={handleRowSelected}
        currentFolderId={currentFolderId}
        isGlobalSearch={isGlobalSearch}
        dashboardBreadcrumbsItems={dashboardBreadcrumbsItems}
      />
    </DocumentListMutatorContext.Provider>
  );
};
