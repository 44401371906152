import { documentAPI } from "api/document";
import { composeErrorHandlers, GenericError } from "common/errorHandling";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { getCurrentParticipant } from "common/utils/document";
import { CancelLeaveSitePromptLayout } from "features/documents/pages/documentRequest/components/CancelLeaveSitePromptLayout";
import { DocumentSigningCancelLayout } from "features/documents/pages/postDocumentRequest/components/DocumentSigningCancelLayout";
import { SignatureScrollContextProvider } from "features/documents/pages/postDocumentRequest/context/SignatureScrollContext";
import { useAggregateDocument } from "features/documents/pages/postDocumentRequest/hooks/useAggregateDocument";
import { DocumentAuthenticateView } from "features/documents/pages/postDocumentRequest/pages/authentication/DocumentAuthenticateView";
import { DocumentPreviewView } from "features/documents/pages/postDocumentRequest/pages/preview/DocumentPreviewView";
import { DocumentViewerView } from "features/documents/pages/postDocumentRequest/pages/preview/DocumentViewerView";
import { DocumentSigningView } from "features/documents/pages/postDocumentRequest/pages/signing/DocumentSigningView";
import React, { useEffect, useMemo } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  ActionTaken,
  DocumentRole,
  DocumentStatus,
  ParticipantResponse,
} from "types/document";

enum RoutesPath {
  SIGNING = "signing",
  AUTHENTICATION = "authentication",
  VIEWER = "viewer",
  PREVIEW = "preview",
}

export const PostDocumentRequestRoutes = (): JSX.Element => {
  const navigate = useNavigate();
  const { linearId } = useParams();
  const {
    documentStatePayload,
    documentPath,
    signatureRecord,
    actionsTaken,
    isSenderAsRecipient,
    mutate,
    error,
  } = useAggregateDocument(linearId);
  const currentUser = useCurrentUserProfile();
  const currentRoles = useMemo(
    () =>
      documentStatePayload
        ? getCurrentParticipant(
            documentStatePayload.recipients,
            documentStatePayload.sender,
            currentUser?.email
          )?.roles
        : [],
    [documentStatePayload, currentUser]
  );

  const senderAsRecipientActionTaken = useMemo(
    () => (isSenderAsRecipient ? actionsTaken?.[0]?.action : undefined),
    [isSenderAsRecipient, actionsTaken]
  );

  const navigatePath = React.useMemo((): string => {
    switch (true) {
      case senderAsRecipientActionTaken === ActionTaken.SIGNED:
        return currentRoles?.includes(DocumentRole.AUTHENTICATOR)
          ? RoutesPath.AUTHENTICATION
          : RoutesPath.SIGNING;
      case senderAsRecipientActionTaken === ActionTaken.AUTHENTICATED:
        return currentRoles?.includes(DocumentRole.SIGNER)
          ? RoutesPath.SIGNING
          : RoutesPath.AUTHENTICATION;
      case currentRoles?.some((role) => role === DocumentRole.SIGNER):
        return RoutesPath.SIGNING;
      case currentRoles?.some((role) => role === DocumentRole.AUTHENTICATOR):
        return RoutesPath.AUTHENTICATION;
      case currentRoles?.some((role) => role === DocumentRole.VIEWER):
        return RoutesPath.VIEWER;
      default:
        return RoutesPath.PREVIEW;
    }
  }, [currentRoles, senderAsRecipientActionTaken]);

  useEffect(() => {
    if (error) {
      navigate("/documents");
    }
  }, [navigate, error]);

  useEffect(() => {
    const postView = async () => {
      const currentResponse = documentStatePayload
        ? getCurrentParticipant(
            documentStatePayload.recipients,
            documentStatePayload.sender,
            currentUser?.email
          )?.response
        : undefined;
      const isCurrentRolesValue = currentRoles && currentRoles.length !== 0;
      const ref = documentStatePayload?.ref;
      if (isCurrentRolesValue && ref) {
        // all data is prepare
        if (
          !currentRoles.includes(DocumentRole.SENDER) &&
          currentUser?.email !== documentStatePayload.sender.email &&
          currentResponse === ParticipantResponse.PENDING &&
          documentStatePayload.status !== DocumentStatus.DISCARDED
        ) {
          // Meet the conditions that require post api
          try {
            await documentAPI.view(ref);
            mutate();
          } catch (e) {
            composeErrorHandlers()(e as GenericError);
          }
        }
      }
    };

    postView();
  }, [currentRoles, documentStatePayload, currentUser?.email, mutate]);

  return (
    <Routes>
      <Route
        element={
          <SignatureScrollContextProvider>
            <Outlet />
          </SignatureScrollContextProvider>
        }
      >
        {/* // TODO add role check to preview user copy paste url KT-307*/}
        {currentRoles && currentRoles.length ? (
          <Route index element={<Navigate to={navigatePath} />} />
        ) : (
          (documentStatePayload?.status === DocumentStatus.COMPLETED ||
            documentStatePayload?.status === DocumentStatus.DIGITAL_SIGNED ||
            documentStatePayload?.status === DocumentStatus.DISCARDED) && (
            <Route index element={<Navigate to={RoutesPath.VIEWER} />} />
          )
        )}
        {documentStatePayload && (
          <>
            <Route
              path={RoutesPath.PREVIEW}
              element={
                <DocumentPreviewView
                  signatureRecord={signatureRecord}
                  documentStatePayload={documentStatePayload}
                  documentPath={documentPath}
                  mutate={mutate}
                />
              }
            />

            <Route
              path={RoutesPath.SIGNING}
              element={
                <CancelLeaveSitePromptLayout>
                  <DocumentSigningCancelLayout
                    documentStatePayload={documentStatePayload}
                  >
                    <DocumentSigningView
                      signatureRecord={signatureRecord}
                      documentStatePayload={documentStatePayload}
                      documentPath={documentPath}
                      currentRoles={currentRoles}
                      mutate={mutate}
                      isSender={!!isSenderAsRecipient}
                      senderAsRecipientActionTaken={
                        senderAsRecipientActionTaken
                      }
                    />
                  </DocumentSigningCancelLayout>
                </CancelLeaveSitePromptLayout>
              }
            />
            <Route
              path={RoutesPath.AUTHENTICATION}
              element={
                <DocumentAuthenticateView
                  signatureRecord={signatureRecord}
                  documentStatePayload={documentStatePayload}
                  documentPath={documentPath}
                  mutate={mutate}
                  isSender={!!isSenderAsRecipient}
                  senderAsRecipientActionTaken={senderAsRecipientActionTaken}
                />
              }
            />
            <Route
              path={RoutesPath.VIEWER}
              element={
                <DocumentViewerView
                  signatureRecord={signatureRecord}
                  documentStatePayload={documentStatePayload}
                  documentPath={documentPath}
                  mutate={mutate}
                />
              }
            />
            <Route path="*" element={<Navigate to="/documents" />} />
          </>
        )}
      </Route>
    </Routes>
  );
};
