import { Field } from "formik";
import tw, { styled } from "twin.macro";

export const StyledField = styled(Field)<{
  $hasError?: boolean;
  $hideErrorMsg?: boolean;
  $hideBorderError?: boolean;
  disabled?: boolean;
}>`
  ${tw`
    block
    bg-background-grey
    text-dark-blue
    rounded
    px-2
    py-3
    border
    border-background-grey
    focus:border-navy
    w-full
    h-12
    font-medium
  `}
  ${(props) => props.$hasError && tw`border-error-red`}
  ${(props) =>
    props.$hideErrorMsg && props.$hideBorderError && tw`border-background-grey`}
  ${(props) => props.disabled && tw`text-grey`}
`;
