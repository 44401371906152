import { RecipientFormValues } from "features/documents/pages/documentRequest/components/RecipientInputFields";
import { defaultRecipientInfo } from "features/documents/pages/documentRequest/pages/AddRecipients/AddRecipients";
import { AddRecipientButton } from "features/documents/pages/documentRequest/pages/AddRecipients/components/AddRecipientButton";
import {
  RecipientListFormValues,
  RecipientInfoProps,
} from "features/documents/pages/documentRequest/types";
import { FieldArray, FormikProps } from "formik";
import cloneDeep from "lodash/cloneDeep";
import { Dispatch, ReactNode, RefObject, SetStateAction, useRef } from "react";

type FieldArrayRemove = <T>(index: number) => T | undefined;
type HandleClickEdit = (
  index: number,
  recipientForm: FormikProps<RecipientListFormValues>
) => Promise<void>;
type HandleClickRemove = (index: number, remove: FieldArrayRemove) => void;

type RecipientListBasedProps = {
  value: {
    recipientInfo: RecipientInfoProps[];
  };
  setIsEditArray: Dispatch<SetStateAction<boolean[]>>;
  isEditArray: boolean[];
  afterClickAdd?: () => void;
  afterClickRemove?: (index: number) => void;
  customizedCard: (
    recipientForm: FormikProps<RecipientListFormValues>,
    recipient: RecipientFormValues,
    index: number,
    remove: FieldArrayRemove,
    handleClickEdit: HandleClickEdit,
    handleClickRemove: HandleClickRemove
  ) => JSX.Element;
  children?: ReactNode;
  className?: string;
};

export const getEmptyRecipientListIndex = (values: RecipientListFormValues) => {
  const emptyArray = values.recipientInfo.reduce(
    (acc: number[], current: RecipientFormValues, currentIndex: number) => {
      if (
        current.firstName === "" &&
        current.lastName === "" &&
        current.email === ""
      ) {
        acc.push(currentIndex);
      }
      return acc;
    },
    []
  );
  return emptyArray;
};

export const autoScrollToElementByIndex = (
  parentElement: RefObject<HTMLDivElement>,
  listRecipients: RecipientInfoProps[],
  index?: number
) => {
  if (parentElement.current) {
    // Default is auto scroll to last child
    const targetRecipient =
      parentElement.current.children[index ?? listRecipients.length - 1];
    //https://stackoverflow.com/questions/71181018/scrollintoview-doesnt-scroll-anywhere
    setTimeout(() => {
      targetRecipient.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 0);
  }
};

export const RecipientListBased = ({
  value,
  isEditArray,
  setIsEditArray,
  afterClickAdd,
  afterClickRemove,
  customizedCard,
  children,
  className,
}: RecipientListBasedProps): JSX.Element => {
  const listRecipientsRef = useRef<HTMLDivElement>(null);
  const handleClickEdit = async (
    index: number,
    recipientForm: FormikProps<RecipientListFormValues>
  ) => {
    try {
      // open other card edit mode only if current edit card is no error
      const errors = await recipientForm.validateForm();
      if (Object.keys(errors).length === 0) {
        setIsEditArray((preArray) => {
          return [
            ...preArray.slice(0, index).fill(false),
            (preArray[index] = true),
            ...preArray.slice(index + 1).fill(false),
          ];
        });
        autoScrollToElementByIndex(
          listRecipientsRef,
          value.recipientInfo,
          index
        );
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleClickAdd = async (
    push: (obj: RecipientFormValues) => void,
    recipientForm: FormikProps<RecipientListFormValues>
  ) => {
    try {
      const errors = await recipientForm.validateForm();
      if (Object.keys(errors).length === 0) {
        push(defaultRecipientInfo);
        setIsEditArray((preArray) => {
          return [...preArray.slice().fill(false), true];
        });
        afterClickAdd && afterClickAdd();
        autoScrollToElementByIndex(listRecipientsRef, value.recipientInfo);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleClickRemove = (
    index: number,
    remove: <T>(index: number) => T | undefined
  ) => {
    remove(index);
    afterClickRemove && afterClickRemove(index);
    setIsEditArray((prevArray) => {
      return prevArray.filter((_, i) => i !== index);
    });
  };

  return (
    <>
      <FieldArray
        name="recipientInfo"
        render={({ push, remove, form: recipientForm }) => (
          <div className={`${className} h-[85%] w-full`}>
            <div
              className="overflow-auto h-auto max-h-[80%]"
              ref={listRecipientsRef}
            >
              {value.recipientInfo.map(
                (recipient: RecipientFormValues, index: number) => {
                  const cloneRecipient = cloneDeep(recipient);
                  cloneRecipient.email = cloneRecipient.email.toLowerCase();
                  return customizedCard(
                    recipientForm,
                    cloneRecipient,
                    index,
                    remove,
                    handleClickEdit,
                    handleClickRemove
                  );
                }
              )}
            </div>
            <AddRecipientButton
              onClick={() => handleClickAdd(push, recipientForm)}
              className="mt-7"
            />
          </div>
        )}
      />
      {children}
    </>
  );
};
