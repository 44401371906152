import * as React from "react";
import { SVGProps } from "react";

const SvgFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m21.166 13.076-8.164 3.286a2.551 2.551 0 0 1-2.091 0l-8.164-3.286c-.996-.398-.996-1.792 0-2.19l1.294-.498 6.173 2.49c.597.198 1.195.298 1.792.298s1.195-.1 1.792-.299l6.173-2.489 1.294.498c.996.398.996 1.792-.1 2.19Zm-8.164-1.09L21.166 8.7c.995-.398.995-1.792 0-2.19l-8.164-3.286a2.551 2.551 0 0 0-2.091 0L2.747 6.51c-.996.398-.996 1.792 0 2.19l8.164 3.286c.697.298 1.493.298 2.09 0Z"
      fill="currentColor"
    />
    <path
      d="m21.166 13.076-8.164 3.286a2.551 2.551 0 0 1-2.091 0l-8.164-3.286c-.996-.398-.996-1.792 0-2.19l1.294-.498 6.173 2.49c.597.198 1.195.298 1.792.298s1.195-.1 1.792-.299l6.173-2.489 1.294.498c.996.398.996 1.792-.1 2.19Zm-8.164-1.09L21.166 8.7c.995-.398.995-1.792 0-2.19l-8.164-3.286a2.551 2.551 0 0 0-2.091 0L2.747 6.51c-.996.398-.996 1.792 0 2.19l8.164 3.286c.697.298 1.493.298 2.09 0Z"
      fill="currentColor"
    />
    <path
      d="m21.166 17.852-8.164 3.286a2.551 2.551 0 0 1-2.091 0l-8.164-3.286c-.996-.398-.996-1.792 0-2.19l1.294-.498 6.173 2.49c.597.198 1.195.298 1.792.298s1.195-.1 1.792-.299l6.173-2.489 1.294.498c.996.398.996 1.792-.1 2.19Z"
      fill="currentColor"
    />
    <path
      d="m21.166 17.852-8.164 3.286a2.551 2.551 0 0 1-2.091 0l-8.164-3.286c-.996-.398-.996-1.792 0-2.19l1.294-.498 6.173 2.49c.597.198 1.195.298 1.792.298s1.195-.1 1.792-.299l6.173-2.489 1.294.498c.996.398.996 1.792-.1 2.19Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFile;
