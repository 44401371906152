import { useModal } from "@liholiho/react-modal-hook";
import { folderAPI } from "api/folder";
import {
  composeErrorHandlers,
  GenericError,
  isAPIError,
} from "common/errorHandling";
import { toast } from "common/toast";
import {
  RenameFolderFormProps,
  RenameFolderPopup,
} from "features/documents/components/FolderManagement/components/RenameFolderPopup";
import { FOLDER_NAME_EXIST_ERROR } from "features/documents/hooks/useCreateNewFolderHandler";
import { useFolderExistErrorPopup } from "features/documents/hooks/useFolderExistErrorPopup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormSubmissionHandler, SubmissionHandler } from "types/common";

export const useRenameFolderHandler = (
  targetFolderId: string,
  targetFolderName: string | undefined,
  currentDashboardFolderId: string | null = null,
  onMoveToArchiveSection: () => void,
  onSuccess?: () => void
): SubmissionHandler => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [folderNameExisted, setFolderNameExisted] = useState<string>("");
  const { t } = useTranslation();

  const handleSubmit: FormSubmissionHandler<RenameFolderFormProps> = async (
    value,
    { setFieldError }
  ) => {
    try {
      await folderAPI.renameFolder(
        value.folderName,
        currentDashboardFolderId,
        targetFolderId
      );
      if (onSuccess) {
        onSuccess();
      }
      setSubmitting(false);
      hide();
      toast.success(
        t("common.toast.renameFolder", { folderName: value.folderName })
      );
    } catch (e) {
      setSubmitting(false);
      composeErrorHandlers((next) => (e) => {
        if (isAPIError(e)) {
          switch (e.errors[0]?.key) {
            case "Corda.FileSystem.InvalidFolderName":
              setFieldError("folderName", t(FOLDER_NAME_EXIST_ERROR));
              break;
            case "Corda.FileSystem.InvalidFolderName.Archived":
              setFolderNameExisted(value.folderName);
              hide();
              handleShowFolderExistError();
              break;
            default:
              next(e);
          }
        } else {
          next(e);
        }
      })(e as GenericError);
    }
  };

  const [show, hide] = useModal(
    ({ in: isOpen }) => {
      return (
        <RenameFolderPopup
          isOpen={isOpen}
          onCancel={hide}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          initFolderName={targetFolderName}
        />
      );
    },
    [onSuccess, isSubmitting]
  );

  const { handler: handleShowFolderExistError } = useFolderExistErrorPopup(
    folderNameExisted,
    show,
    onMoveToArchiveSection
  );

  return {
    isSubmitting,
    handler: show,
  };
};
