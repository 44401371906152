import { useAuthorized } from "common/hooks/useAuthorized";
import { useGoToSpecifiedFolderPath } from "common/hooks/useGoToSpecifiedFolderPath";
import { useWhitelist } from "common/hooks/useWhitelist";
import { DocumentTable } from "features/documents/components/DocumentTable";
import { DocumentTableButtonActionGroup } from "features/documents/components/DocumentTableButtonActionGroup";
import { DocumentTableWrapper } from "features/documents/components/DocumentTableWrapper";
import {
  BreadCrumbItemProps,
  DashboardBreadcrumbs,
} from "features/documents/components/FolderManagement/DashboardBreadcrumbs";
import { SearchResultPageTitle } from "features/documents/pages/documentManagement/components/SearchResultPageTitle";
import {
  DocumentFilterType,
  getDocumentFilterTypeText,
} from "features/documents/pages/documentManagement/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Row } from "react-table";
import { DashboardResponse } from "types/common";

/* Folder Management */
type PureDocumentListViewProps = {
  documents: DashboardResponse[];
  filter: DocumentFilterType;
  isArchived: boolean;
  currentFolderId: string | null;
  currentFolder?: DashboardResponse;
  fetchNext: () => void;
  total?: number;
  hasMore: () => boolean;
  onRowSelected?: (selected: Row<DashboardResponse>) => void;
  isGlobalSearch: boolean;
  isPending: boolean;
  dashboardBreadcrumbsItems: BreadCrumbItemProps[];
};

export const PureDocumentListView = ({
  documents,
  total,
  filter,
  isArchived,
  currentFolderId,
  hasMore,
  fetchNext,
  onRowSelected,
  isGlobalSearch,
  isPending,
  dashboardBreadcrumbsItems,
}: PureDocumentListViewProps): JSX.Element => {
  const { t } = useTranslation();
  const { isIgnoreWhitelist } = useWhitelist();
  const { canCreateRequest } = useAuthorized();
  const { handleBreadCrumbItemClicked } = useGoToSpecifiedFolderPath();
  const [searchParams] = useSearchParams();
  const searchFileName = searchParams.get("name");

  const isAllType = useMemo(() => {
    return getDocumentFilterTypeText(filter) === DocumentFilterType.All;
  }, [filter]);

  const currentActiveFolder = useMemo(() => {
    return dashboardBreadcrumbsItems[dashboardBreadcrumbsItems.length - 1];
  }, [dashboardBreadcrumbsItems]);

  return (
    <div className="px-8 py-12 flex flex-col h-full">
      <div className="flex justify-between">
        <h2 className="mb-3 flex items-baseline gap-2.5" data-cy="db-title">
          <div className="max-w-[700px] truncate">
            {searchFileName ? (
              <SearchResultPageTitle
                titleKey="dashboard.searchResult.title"
                searchWord={searchFileName}
              />
            ) : currentActiveFolder &&
              getDocumentFilterTypeText(filter) === DocumentFilterType.All ? (
              currentActiveFolder.label
            ) : (
              t(getDocumentFilterTypeText(filter))
            )}
          </div>
          {getDocumentFilterTypeText(filter) === DocumentFilterType.Bin && (
            <div className="font-medium text-sm leading-5.5 text-dark-grey">
              {t("dashboard.tab.binTitleHint")}
            </div>
          )}
          {searchFileName && (
            <div className="font-medium text-sm leading-5.5 text-dark-grey">
              {t("dashboard.searchResult.subTitle", { number: total })}
            </div>
          )}
        </h2>
        <DocumentTableButtonActionGroup
          isButtonDisplay={!isArchived}
          canCreateFolder={isAllType}
          canCreateRequest={isIgnoreWhitelist ? true : canCreateRequest}
          currentFolderId={currentFolderId}
        />
      </div>
      <DashboardBreadcrumbs
        data={dashboardBreadcrumbsItems}
        onItemClick={handleBreadCrumbItemClicked}
        isOnSearchResultPage={!!searchFileName}
        className="mb-6"
      />
      <DocumentTableWrapper>
        <DocumentTable
          documents={documents}
          isArchived={isArchived}
          fetchNext={fetchNext}
          hasMore={hasMore}
          hasMenu
          onRowSelected={onRowSelected}
          currentFolderId={currentFolderId}
          isGlobalSearch={isGlobalSearch}
          isPending={isPending}
          isOnSearchResultPage={!!searchFileName}
        />
      </DocumentTableWrapper>
    </div>
  );
};
