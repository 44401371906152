import { UserIcon } from "common/components/UserIcon";
import { generateFullName } from "common/utils/common";
import { Colors } from "enums/colors";
import { StyledCard } from "features/documents/pages/documentRequest/components/RecipientCreateCard";
import { t } from "i18next";
import { DocumentParticipant } from "types/document";

interface SenderCardProps {
  senderInfo: DocumentParticipant;
  currentColor: Colors;
}

export const SenderCard = ({ senderInfo, currentColor }: SenderCardProps) => {
  return (
    <StyledCard isShadow={false}>
      <div className="grow flex justify-between">
        <div className="flex items-center gap-x-3.5">
          <UserIcon
            variant={"initials"}
            content={generateFullName(
              senderInfo.profile.firstName,
              senderInfo.profile.lastName
            )}
            color={currentColor}
          />
        </div>
        <div className="flex flex-col grow justify-center ml-3.5">
          <div className="flex justify-between items-center">
            <div>
              <label className="font-medium">
                {generateFullName(
                  senderInfo.profile.firstName,
                  senderInfo.profile.lastName
                )}
              </label>
              <div className="text-dark-grey">{senderInfo.email}</div>
            </div>
            <span className="font-medium text-dark-grey">
              {t("common.role.sender")}
            </span>
          </div>
        </div>
      </div>
    </StyledCard>
  );
};
