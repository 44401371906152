import { useBlocker } from "common/hooks/useBlocker";
import { useLastDocumentFilterType } from "common/hooks/useLastDocumentFilterType";
import { isUploadDocumentPage } from "common/utils/document";
import { isDetailPage } from "common/utils/document";
import type { Transition } from "history";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { NavigationType } from "react-router-dom";

type CallbackPromptTypes = {
  showPrompt: boolean;
  confirmNavigation: () => void;
  cancelNavigation: () => void;
};
/*reference: https://dev.to/bangash1996/detecting-user-leaving-page-with-react-router-dom-v602-33ni */
export const useCallbackPrompt = (
  when: boolean,
  allowPathname: string[],
  forceLeavePage: boolean
): CallbackPromptTypes => {
  const navigate = useNavigate();
  const location = useLocation();
  const prevDocumentFilterType = useLastDocumentFilterType();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<Transition | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  // pathname that allow to navigate without showing popup
  const [isAllowPathname, setAllowPathname] = useState(false);

  // When in detail page, get the pathname of the previous page with a document filter type parameter
  const getNextLocation = useCallback(
    (nextLocation: Transition) => {
      if (nextLocation.action === NavigationType.Pop) {
        if (isDetailPage(nextLocation.location.pathname)) {
          const newLocation = {
            ...nextLocation,
            location: {
              ...nextLocation.location,
              pathname: `/documents/${prevDocumentFilterType}`,
            },
          };
          return newLocation;
        }

        if (isUploadDocumentPage(location.pathname)) {
          const newLocation = {
            ...nextLocation,
            location: {
              ...nextLocation.location,
              pathname: `/documents/${prevDocumentFilterType}`,
            },
          };
          return newLocation;
        }
      }
      return nextLocation;
    },
    [location.pathname, prevDocumentFilterType]
  );
  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation: Transition) => {
      const finalNextLocation = getNextLocation(nextLocation);
      // check pathname allow
      if (
        (allowPathname &&
          allowPathname.includes(finalNextLocation.location.pathname)) ||
        forceLeavePage
      ) {
        if (!lastLocation) {
          setLastLocation(finalNextLocation);
        }
        setAllowPathname(true);
        return false;
      }
      // in if condition we are checking next location and current location are equals or not
      if (
        !confirmedNavigation &&
        nextLocation.location.pathname !== location.pathname
      ) {
        if (!lastLocation) {
          setLastLocation(finalNextLocation);
        }
        setShowPrompt(true);
        return false;
      }

      return true;
    },
    [
      getNextLocation,
      allowPathname,
      forceLeavePage,
      confirmedNavigation,
      location.pathname,
      lastLocation,
    ]
  );

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (lastLocation) {
      const pathName = lastLocation.location.pathname;
      const searchValue = lastLocation.location.search;
      if (isAllowPathname && pathName !== location.pathname) {
        setAllowPathname(false);
        searchValue
          ? navigate(`${pathName}${searchValue}`, { replace: true })
          : navigate(pathName);
        setLastLocation(null);
      }

      if (confirmedNavigation) {
        searchValue
          ? navigate(`${pathName}${searchValue}`, { replace: true })
          : navigate(pathName);
      }
    }
  }, [
    confirmedNavigation,
    isAllowPathname,
    lastLocation,
    location.pathname,
    navigate,
    prevDocumentFilterType,
  ]);

  useBlocker(handleBlockedNavigation, when);

  return { showPrompt, confirmNavigation, cancelNavigation };
};
