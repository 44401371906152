import { SvgX } from "common/components/svg";
import { ToastContainer } from "react-toastify";
import tw, { styled } from "twin.macro";

export const KentroToastContainer = styled(ToastContainer)`
  --toastify-z-index: 0;
  &.Toastify__toast-container {
    ${tw`w-full z-default p-0`}

    &--top-center {
      ${tw`absolute left-0 top-0`}
      transform: unset;
    }
  }

  .Toastify {
    &__toast {
      ${tw`min-h-[48px] rounded-none shadow-none px-6 mb-0`}
      ${tw`flex items-center`}
      ${tw`font-medium`}

      &--success {
        ${tw`text-light-green bg-green/10`}
      }

      &--warning {
        ${tw`text-light-red bg-error-red/10`}
      }

      &--error {
        ${tw`text-light-red bg-error-red/10`}
      }
    }

    &__toast-body {
      ${tw`p-0 m-0 flex flex-1`}
    }

    &__toast-icon {
      ${tw`w-5 mr-3`}
    }
  }
`;

type ToastCloseButtonProps = {
  closeToast: () => void;
};
export const ToastCloseButton = ({ closeToast }: ToastCloseButtonProps) => (
  <SvgX onClick={closeToast} className="text-dark-grey" />
);
