import { APIError } from "common/errorHandling";
import { request } from "common/HOC/WithRequest";
import queryString from "query-string";
import useSWRInfinite from "swr/infinite";
import { DashboardResponse, Page, ParentFolderProps, UUID } from "types/common";
import { QueryDocumentRole } from "types/document";
type NewFolderResponse = ParentFolderProps;
type renameFolderResponse = ParentFolderProps;

export const folderAPI = {
  createNewFolder: (
    folderName: string,
    parentFolderId: string | null = null
  ): Promise<NewFolderResponse> => {
    const bodyData = {
      folderName,
      parentFolderId,
    };
    return request("/dashboard/folder", "POST", bodyData);
  },
  renameFolder: (
    folderName: string,
    parentFolderId: string | null = null,
    targetFolderId: string
  ): Promise<renameFolderResponse> => {
    const bodyData = {
      folderName,
      parentFolderId,
    };
    return request(`/dashboard/folder/${targetFolderId}`, "PUT", bodyData);
  },
  moveFolder: (
    folderLinearId: UUID,
    newParentFolderId: UUID | null,
    folderName: string
  ) =>
    request(`dashboard/folder/${folderLinearId}`, "PUT", {
      folderName: folderName,
      parentFolderId: newParentFolderId,
    }),
  archive: (linearId: UUID) =>
    request(`/dashboard/folder/${linearId}/archive`, "POST"),
  retrieve: (linearId: UUID) =>
    request(`/dashboard/folder/${linearId}/archive`, "DELETE"),
  deleteArchive: (linearId: UUID) =>
    request(`/dashboard/folder/${linearId}`, "DELETE"),
};

export type InfiniteDashboardItemListResult = {
  data?: DashboardResponse[];
  total?: number;
  isLoading: boolean;
  fetchNext: () => void;
  mutate: () => Promise<Page<DashboardResponse>[] | undefined>;
};

// https://stackoverflow.com/questions/70241414/how-to-mutate-with-query-params-in-swr-hook
// GET Folders & Documents inside
export const useFolderItems = (
  role: QueryDocumentRole,
  parentFolderId: string | null,
  isDocumentShow: boolean
): InfiniteDashboardItemListResult => {
  const {
    data: dashboardItemList,
    size,
    setSize,
    mutate,
  } = useSWRInfinite<Page<DashboardResponse>, APIError>(
    getFolderItemListPath(role, parentFolderId, isDocumentShow),
    request
  );
  const isLoading: boolean =
    !dashboardItemList ||
    (size > 0 &&
      !!dashboardItemList &&
      dashboardItemList[size - 1] === undefined);
  const total = dashboardItemList
    ? dashboardItemList[dashboardItemList.length - 1].totalNumOfItems
    : 0;
  const cumulativeDashboardItemList: DashboardResponse[] = (
    dashboardItemList ?? []
  )
    .map((page) => page.data)
    .flat();

  return {
    data: cumulativeDashboardItemList,
    total,
    isLoading,
    fetchNext: () => setSize(size + 1),
    mutate,
  };
};

const getFolderItemListPath =
  (
    role: QueryDocumentRole,
    parentFolderId: string | null,
    isDocumentShow: boolean = true
  ) =>
  (
    pageIndex: number,
    previousPageData: Page<DashboardResponse> | null
  ): string | null => {
    if (previousPageData && previousPageData.totalNumOfItems === 0) {
      return null;
    }
    const pageNum = pageIndex.toString();
    const showDocument = isDocumentShow.toString();
    const pageSize = "10";
    const queryParams = queryString.stringify({
      role,
      parentFolderId,
      pageNum,
      pageSize,
      showDocument,
    });

    return `dashboard?${queryParams.toString()}`;
  };
