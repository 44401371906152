import { useModal } from "@liholiho/react-modal-hook";
import { documentAPI } from "api/document";
import { folderAPI } from "api/folder";
import {
  composeErrorHandlers,
  GenericError,
  isAPIError,
} from "common/errorHandling";
import { toast, ToastContainerId } from "common/toast";
import { MoveDashboardItemPopup } from "features/documents/components/FolderManagement/components/MoveDashboardItemPopup";
import { useFolderExistErrorPopup } from "features/documents/hooks/useFolderExistErrorPopup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DashboardResponse, SubmissionHandler } from "types/common";

const FOLDER_NAME_EXIST_ON_MOVING_ERROR = "dashboard.message.folderExistMoving";

const generateMoveSuccessMessage = (
  dashboardItem: DashboardResponse,
  targetFolder?: DashboardResponse
) => {
  if (dashboardItem.isFolder) {
    return targetFolder
      ? {
          key: "dashboard.message.moveFolderToFolder",
          data: {
            start: dashboardItem.folderName,
            end: targetFolder?.folderName,
          },
        }
      : {
          key: "dashboard.message.moveFolderToDashboard",
          data: { start: dashboardItem.folderName },
        };
  } else {
    return targetFolder
      ? {
          key: "dashboard.message.moveDocumentToFolder",
          data: {
            start: dashboardItem.documentName,
            end: targetFolder?.folderName,
          },
        }
      : {
          key: "dashboard.message.moveDocumentToDashboard",
          data: { start: dashboardItem.documentName },
        };
  }
};

export const useMoveDashboardItemHandler = (
  dashboardItem: DashboardResponse,
  toastContainerId: ToastContainerId = ToastContainerId.AfterLogin,
  isGlobalSearch: boolean,
  currentDashboardFolderId: string | null,
  onMoveToArchiveSection: () => void,
  onSuccess?: () => void
): SubmissionHandler => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [currentInternalFolder, setCurrentInternalFolder] =
    useState<DashboardResponse>();
  const [folderNameExisted, setFolderNameExisted] = useState<string>("");
  const [currentError, setCurrentError] = useState<string>();
  const { t } = useTranslation();

  const handleMoveItem = async (
    mutate?: () => void,
    targetFolder?: DashboardResponse
  ) => {
    setSubmitting(true);
    try {
      dashboardItem.isFolder && dashboardItem.folderName
        ? await folderAPI.moveFolder(
            dashboardItem.id,
            targetFolder?.id ?? null,
            dashboardItem.folderName
          )
        : await documentAPI.moveDocument(
            dashboardItem.id,
            targetFolder?.id ?? null
          );
      const { key, data } = generateMoveSuccessMessage(
        dashboardItem,
        targetFolder
      );
      toast.success(t(key, { place: data }), toastContainerId);

      if (onSuccess) {
        onSuccess();
      }
      if (mutate) {
        mutate();
      }
      hide();
    } catch (e) {
      composeErrorHandlers((next) => (e) => {
        if (isAPIError(e)) {
          switch (e.errors[0]?.key) {
            case "Corda.FileSystem.InvalidFolderName":
              setCurrentError(t(FOLDER_NAME_EXIST_ON_MOVING_ERROR));
              break;
            case "Corda.FileSystem.InvalidFolderName.Archived":
              if (dashboardItem && dashboardItem.folderName) {
                setFolderNameExisted(dashboardItem.folderName);
                handleShowFolderExistError();
              }
              break;
            default:
              next(e);
          }
        } else {
          next(e);
        }
      })(e as GenericError);
    } finally {
      setSubmitting(false);
    }
  };

  const [show, hide] = useModal(
    ({ in: isOpen }) => {
      return (
        <MoveDashboardItemPopup
          dashboardItem={dashboardItem}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
          currentDashboardFolderId={currentDashboardFolderId}
          currentInternalFolder={currentInternalFolder}
          setCurrentInternalFolder={setCurrentInternalFolder}
          onConfirm={handleMoveItem}
          onCancel={() => {
            hide();
            // Auto refresh when move popup close
            if (onSuccess) {
              onSuccess();
            }
          }}
          onMoveToArchiveSection={onMoveToArchiveSection}
          isGlobalSearch={isGlobalSearch}
          currentError={currentError}
        />
      );
    },
    [isSubmitting, dashboardItem, currentInternalFolder]
  );

  const { handler: handleShowFolderExistError } = useFolderExistErrorPopup(
    folderNameExisted,
    show,
    onMoveToArchiveSection,
    false
  );

  return {
    isSubmitting,
    handler: show,
  };
};
