import { SelectField } from "common/components/form";
import { SignaturePxProps } from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { SelectOption } from "common/components/form/SelectField";
import { SvgChevronDown } from "common/components/svg";
import { generateColorByString } from "enums/colors";
import { OptionLabelWithIcon } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/OptionLabelWithIcon";
import { relatedRecipientsStyles } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/RelatedRecipientsStyle";
import { SELECT_ALL } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/SignatureControlPanel";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";

type SignatureLocationsControlProps = {
  handleSubmit: () => void;
  recipientOptions: SelectOption[];
  filterValue: string;
  currSignature: SignaturePxProps[];
  filterIndex: number;
  onClickScrollToIndex: (
    addIndex: number,
    filterValue: string,
    upperLimitation: number
  ) => void;
};
export const SignatureLocationsControl = ({
  handleSubmit,
  recipientOptions,
  filterValue,
  currSignature,
  filterIndex,
  onClickScrollToIndex,
}: SignatureLocationsControlProps) => {
  const { t } = useTranslation();
  const generateColorByEmail = useCallback(
    (email: string) => generateColorByString(email),
    []
  );
  const formatOptionLabel = (
    option: SelectOption,
    formatOptionLabelMeta: FormatOptionLabelMeta<SelectOption>
  ) => (
    <OptionLabelWithIcon
      color={generateColorByEmail(option.value)}
      option={option}
      formatOptionLabelMeta={formatOptionLabelMeta}
    />
  );

  const filteredSignatureNum = useMemo(() => {
    const validSignature = currSignature.filter((it) => !!it.value);
    return filterValue === SELECT_ALL
      ? validSignature.length
      : validSignature.filter(
          (signature) => signature.value?.email === filterValue
        ).length;
  }, [currSignature, filterValue]);

  return (
    <>
      <h3 className="text-dark-blue">
        {t("docRequest.confirmDetail.signatureLocationsTitle")}
      </h3>
      <p className="text-sm font-medium text-dark-blue">
        {t("docRequest.confirmDetail.relatedRecipient")}
      </p>
      <div className="flex items-center text-dark-grey justify-start bg-background-grey py-2 pr-5 max-w-full">
        <SelectField
          onAfterChange={handleSubmit}
          name={"filterValue"}
          label={""}
          options={recipientOptions}
          className="font-medium text-sm"
          selectStyles={relatedRecipientsStyles}
          maxHeight={150}
          isSearchable={false}
          formatOptionLabel={formatOptionLabel}
        />
        <div className="flex-grow flex items-center justify-end">
          <div className="mr-8">
            {filteredSignatureNum ? filterIndex + 1 : 0}/{filteredSignatureNum}
          </div>
          <SvgChevronDown
            className="rotate-180 cursor-pointer"
            onClick={() =>
              onClickScrollToIndex(
                filterIndex - 1,
                filterValue,
                filteredSignatureNum - 1
              )
            }
          />
          <SvgChevronDown
            className="cursor-pointer"
            onClick={() =>
              onClickScrollToIndex(
                filterIndex + 1,
                filterValue,
                filteredSignatureNum - 1
              )
            }
          />
        </div>
      </div>
    </>
  );
};
