import * as React from "react";
import { SVGProps } from "react";

const SvgFilePdf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 19.5V7.625L14 2H6.66667C5.95942 2 5.28115 2.26339 4.78105 2.73223C4.28095 3.20107 4 3.83696 4 4.5V19.5C4 20.163 4.28095 20.7989 4.78105 21.2678C5.28115 21.7366 5.95942 22 6.66667 22H17.3333C18.0406 22 18.7189 21.7366 19.219 21.2678C19.719 20.7989 20 20.163 20 19.5ZM14 5.75C14 6.24728 14.2107 6.72419 14.5858 7.07583C14.9609 7.42746 15.4696 7.625 16 7.625H18.6667V19.5C18.6667 19.8315 18.5262 20.1495 18.2761 20.3839C18.0261 20.6183 17.687 20.75 17.3333 20.75H6.66667C6.31304 20.75 5.97391 20.6183 5.72386 20.3839C5.47381 20.1495 5.33333 19.8315 5.33333 19.5V4.5C5.33333 4.16848 5.47381 3.85054 5.72386 3.61612C5.97391 3.3817 6.31304 3.25 6.66667 3.25H14V5.75Z"
      fill="currentColor"
    />
    <path
      d="M8.202 16.6107C8.733 16.6107 9.189 16.4807 9.498 16.1907C9.734 15.9657 9.864 15.6347 9.865 15.2427C9.865 14.8527 9.693 14.5217 9.439 14.3197C9.172 14.1067 8.776 14.0007 8.219 14.0007C7.81112 13.9951 7.40345 14.0225 7 14.0827V18.0187H7.894V16.5927C7.99613 16.6058 8.09904 16.6118 8.202 16.6107Z"
      fill="currentColor"
    />
    <path
      d="M11.465 18.0607C12.246 18.0607 12.885 17.8947 13.305 17.5457C13.689 17.2207 13.968 16.6937 13.968 15.9307C13.968 15.2257 13.708 14.7347 13.293 14.4267C12.909 14.1367 12.417 14.0007 11.66 14.0007C11.2521 13.9971 10.8446 14.0248 10.441 14.0837V18.0007C10.666 18.0307 10.997 18.0607 11.465 18.0607Z"
      fill="currentColor"
    />
    <path
      d="M15.468 14.7707H17V14.0307H14.562V18.0197H15.468V16.4157H16.9V15.6817H15.468V14.7707Z"
      fill="currentColor"
    />
    <path
      d="M8.25823 14.6749C8.07423 14.6749 7.95023 14.6929 7.88623 14.7109V15.8889C7.96223 15.9069 8.05723 15.9119 8.18823 15.9119C8.66723 15.9119 8.96223 15.6699 8.96223 15.2609C8.96223 14.8949 8.70823 14.6749 8.25823 14.6749ZM11.7452 14.6869C11.5452 14.6869 11.4152 14.7049 11.3382 14.7229V17.3329C11.4152 17.3509 11.5392 17.3509 11.6512 17.3509C12.4682 17.3569 13.0002 16.9069 13.0002 15.9549C13.0062 15.1249 12.5212 14.6869 11.7452 14.6869Z"
      fill="white"
    />
  </svg>
);

export default SvgFilePdf;
