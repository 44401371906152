import { useDocumentRequest } from "common/hooks/useDocumentRequest";
import { RecipientFormValues } from "features/documents/pages/documentRequest/components/RecipientInputFields";
import { uploadRecipients } from "features/documents/pages/documentRequest/context/DocumentRequestActions";
import { RecipientsControlPanel } from "features/documents/pages/documentRequest/pages/AddRecipients/components/RecipientsControlPanel";
import {
  RecipientInfoProps,
  RecipientListFormValues,
} from "features/documents/pages/documentRequest/types";
import { Form, Formik } from "formik";
import { FormSubmissionHandler } from "types/common";
import * as yup from "yup";

export const defaultRecipientInfo: RecipientFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  title: "",
};
export const schema: yup.SchemaOf<
  RecipientListFormValues,
  RecipientInfoProps[]
> = yup.object().shape({
  recipientInfo: yup.array(),
});

export type AddRecipientsProps = {
  onNext: () => void;
  onBack: () => void;
};
export const AddRecipients = ({ onNext, onBack }: AddRecipientsProps) => {
  const { dispatch, recipients } = useDocumentRequest();
  const handleSubmitForm: FormSubmissionHandler<
    RecipientListFormValues
  > = async (values) => {
    if (dispatch) {
      dispatch(uploadRecipients(values));
      onNext();
    }
  };

  const recipientInfoInitialValue: RecipientInfoProps[] =
    recipients.recipientInfo.length !== 0
      ? recipients.recipientInfo
      : [defaultRecipientInfo];

  return (
    <Formik
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmitForm}
      initialValues={{
        recipientInfo: recipientInfoInitialValue,
      }}
    >
      <Form autoComplete="off" noValidate className="w-full h-full">
        <RecipientsControlPanel onBack={onBack} />
      </Form>
    </Formik>
  );
};
