import { useModalWithData } from "common/hooks";
import { GenerateSignatureImageModal } from "features/documents/pages/postDocumentRequest/pages/signing/components/GenerateSignatureImageModal";
import { SignatureSchemas } from "types/document";

type useGenerateSignatureImageModalHandlerProps = {
  fileName: string;
  onSignatureApply: (
    currentSchemas: SignatureSchemas,
    imageData: string,
    isApplyToAll: boolean,
    inputValue: string
  ) => void;
};

type ShowModal = (value: {
  schema: SignatureSchemas;
  inputValue: string;
}) => void;
export const useGenerateSignatureImageModalHandler = ({
  fileName,
  onSignatureApply,
}: useGenerateSignatureImageModalHandlerProps): { showModal: ShowModal } => {
  const [showModal, hideModal] = useModalWithData<{
    schema: SignatureSchemas;
    inputValue: string;
  }>((value) => ({ in: isOpen }) => {
    return value ? (
      // force to rerender the modal once it closed
      isOpen && (
        <GenerateSignatureImageModal
          signatureSchemas={value.schema}
          isOpen={isOpen}
          hideModal={hideModal}
          fileName={fileName}
          onSignatureApply={onSignatureApply}
          inputValue={value.inputValue}
        />
      )
    ) : (
      <div>Invalid Value</div>
    );
  });
  return { showModal };
};
