import { DocumentViewStatus } from "features/documents/components/StatusCell";
import { ButtonStep } from "features/documents/pages/postDocumentRequest/components/ButtonStepGroup";
import {
  PDFSignatureSigning,
  PDFSignatureSigningRefProps,
} from "features/documents/pages/postDocumentRequest/components/PDFSignatureSigning";
import { setRef } from "features/documents/pages/postDocumentRequest/context/SignatureScrollActions";
import { useIsActionTaken } from "features/documents/pages/postDocumentRequest/hooks/useIsActionTaken";
import { useSignatureScroll } from "features/documents/pages/postDocumentRequest/hooks/useSignatureScroll";
import { useSortedRecipientList } from "features/documents/pages/postDocumentRequest/hooks/useSortedRecipientList";
import React, { useEffect, useRef } from "react";
import {
  DocumentParticipant,
  DocumentRole,
  DocumentStatePayload,
} from "types/document";

type PreviewDocumentWithSignaturesProps = {
  documentStatePayload: DocumentStatePayload | undefined;
  documentPath: string | undefined;
  isPreview: boolean;
  step: ButtonStep;
  documentViewStatus?: DocumentViewStatus | null;
  setStep?: React.Dispatch<React.SetStateAction<ButtonStep>>;
  currentActiveBoxId?: string;
};

export const PreviewDocumentWithSignatures = ({
  documentStatePayload,
  documentPath,
  isPreview,
  step,
  documentViewStatus,
  currentActiveBoxId,
  setStep,
}: PreviewDocumentWithSignaturesProps) => {
  const isActionTaken = useIsActionTaken(documentStatePayload);
  const pdfRef = useRef<PDFSignatureSigningRefProps | null>();
  const { dispatch } = useSignatureScroll();

  const hasBeenDiscarded = documentViewStatus === DocumentViewStatus.Discarded;

  const sortedRecipients = useSortedRecipientList(
    documentStatePayload?.recipients || []
  );

  const signers: DocumentParticipant[] = React.useMemo(
    () =>
      sortedRecipients.filter((it) => it.roles.includes(DocumentRole.SIGNER)),
    [sortedRecipients]
  );

  const files = React.useMemo(
    () => ({
      name: documentStatePayload?.file.name ?? "",
      URL: documentPath ?? "",
      size: 10,
    }),
    [documentPath, documentStatePayload?.file.name]
  );

  useEffect(() => {
    if (dispatch) {
      dispatch(setRef({ ref: pdfRef.current }));
    }
  }, [dispatch]);

  return (
    <PDFSignatureSigning
      ref={(el) => (pdfRef.current = el)}
      isSignaturePreview={isActionTaken || isPreview}
      className="h-full"
      selectedFile={files}
      isSignatureDisplay={true}
      signatureSchemas={documentStatePayload?.signatureSchemas ?? []}
      signers={signers}
      step={step}
      setStep={setStep}
      sender={documentStatePayload?.sender}
      isDiscarded={hasBeenDiscarded}
      currentActiveBoxId={currentActiveBoxId}
    />
  );
};
