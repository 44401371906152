import {
  documentAPI,
  DocumentUpdateRequestPayload,
  SignatureRecord,
} from "api/document";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { ToastContainerId } from "common/toast";
import { getDashboardItemViewStatus } from "features/documents/components/StatusCell";
import { RecipientUpdateInfoProps } from "features/documents/pages/documentRequest/types";
import { PreviewDocumentWithSignatures } from "features/documents/pages/postDocumentRequest/components//PreviewDocumentWithSignatures";
import { ButtonStep } from "features/documents/pages/postDocumentRequest/components/ButtonStepGroup";
import { FileManagementView } from "features/documents/pages/postDocumentRequest/components/FileManagementView";
import { PostDocumentRequestTemplate } from "features/documents/pages/postDocumentRequest/components/PostDocumentRequestTemplate";
import useRecipientProfileList from "features/documents/pages/postDocumentRequest/hooks/useRecipientProfileList";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useSelector } from "redux/hooks";
import { currentPartySelector } from "redux/slices/party/selectors";
import { FormSubmissionHandler } from "types/common";
import {
  DocumentParticipant,
  DocumentRole,
  DocumentStatePayload,
  DocumentStatePayloadMutate,
} from "types/document";
import { MapSchema } from "types/mapSchema";
import { Party } from "types/party";
import * as yup from "yup";

const schema: yup.SchemaOf<any> = yup.object().shape({
  recipientInfo: yup.array().of(
    yup.object().shape({
      email: yup.string().trim().required().email(),
      firstName: yup.string().trim().required(),
      lastName: yup.string().trim().required(),
    })
  ),
  signatures: new MapSchema(yup.string().required(), yup.string().required()),
});

export type DocumentPreviewForm = {
  recipientInfo: RecipientUpdateInfoProps[];
  signatures: SignatureRecord;
};

type DocumentPreviewViewProps = {
  documentStatePayload: DocumentStatePayload;
  documentPath: string | undefined;
  mutate: DocumentStatePayloadMutate;
  signatureRecord: SignatureRecord;
};
export const DocumentPreviewView = ({
  documentStatePayload,
  documentPath,
  mutate,
  signatureRecord,
}: DocumentPreviewViewProps): JSX.Element => {
  const [isRecipientListEditMode, setIsRecipientListEditMode] = useState(false);
  const currentUserProfile = useCurrentUserProfile();
  const currentParty = useSelector(currentPartySelector);
  const viewStatus = getDashboardItemViewStatus(
    documentStatePayload.recipients,
    documentStatePayload.sender,
    documentStatePayload.status,
    currentUserProfile?.email
  );
  const listRecipients = documentStatePayload.recipients;
  const { recipientProfileList } = useRecipientProfileList(listRecipients);

  const handleSubmit: FormSubmissionHandler<DocumentPreviewForm> = async (
    values,
    { setSubmitting }
  ) => {
    const ref = documentStatePayload?.ref;
    const payload = generateDocumentUpdatePayload(
      currentParty,
      values.recipientInfo,
      listRecipients
    );
    try {
      setSubmitting(true);
      await documentAPI.update(ref, payload);
      setSubmitting(false);
      setIsRecipientListEditMode(false);
      mutate();
    } catch (e) {
      composeErrorHandlers(() =>
        baseFallbackErrorHandler(ToastContainerId.DocumentDetail)
      )(e as GenericError);
    }
  };

  return (
    <div className="h-full">
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        initialValues={{
          recipientInfo: recipientProfileList ?? [],
          signatures: signatureRecord,
        }}
        enableReinitialize
      >
        <Form autoComplete="off" noValidate className="h-full">
          <PostDocumentRequestTemplate
            leftComponent={
              <PreviewDocumentWithSignatures
                documentStatePayload={documentStatePayload}
                documentPath={documentPath}
                isPreview={true}
                step={ButtonStep.DONE}
              />
            }
            rightComponent={
              <FileManagementView
                isViewEditMode={isRecipientListEditMode}
                setIsViewEditMode={setIsRecipientListEditMode}
                documentStatePayload={documentStatePayload}
                documentViewStatus={viewStatus}
                mutate={mutate}
              />
            }
          />
        </Form>
      </Formik>
    </div>
  );
};

export const generateDocumentUpdatePayload = (
  currentParty: Party | null,
  recipientFormValues: RecipientUpdateInfoProps[],
  originRecipientData: DocumentParticipant[]
): DocumentUpdateRequestPayload => {
  // https://stackoverflow.com/questions/5501581/javascript-new-arrayn-and-array-prototype-map-weirdness
  return {
    recipients: Array.apply(null, Array(recipientFormValues.length)).map(
      (recipient, index) => {
        let findCurrentEmailInFormIndex = recipientFormValues.findIndex(
          (el) => el.email.trim() === originRecipientData[index]?.email
        );
        // If can not find email value in originRecipientData,it mean a new recipient, use RecipientForm order
        if (findCurrentEmailInFormIndex === -1) {
          findCurrentEmailInFormIndex = index;
        }
        return (recipient = {
          // double check if have old email value can not be change
          email:
            originRecipientData[index]?.email ??
            recipientFormValues[findCurrentEmailInFormIndex].email.trim(),
          party: originRecipientData[index]?.party ?? currentParty?.x500Name,
          profile: {
            companyName:
              originRecipientData[index]?.profile.companyName?.trim() || null,
            companyTitle:
              originRecipientData[index]?.profile.companyTitle?.trim() || null,
            firstName:
              recipientFormValues[findCurrentEmailInFormIndex].firstName.trim(),
            lastName:
              recipientFormValues[findCurrentEmailInFormIndex].lastName.trim(),
          },
          roles: originRecipientData[index]?.roles ?? [DocumentRole.VIEWER],
        });
      }
    ),
  };
};
