import { DocumentCreateRequestPayload, SignatureRecord } from "api/document";
import {
  Dimension,
  PageDimensions,
  SignaturePercentageProps,
  SignaturePxProps,
} from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { isEqualIgnoreCase, searchInsertPosition } from "common/utils/common";
import {
  PDFFile,
  RecipientInfoProps,
  RecipientRolesProps,
  SenderAsRecipientMode,
  SignatureControlPanelStep,
  SignatureBoxValuesNotSignedBeforeCreationProps,
} from "features/documents/pages/documentRequest/types";
import _ from "lodash";
import { LinearId } from "types/common";
import { DocumentRole } from "types/document";
import { CurrentUserProfile } from "types/user";

export const cleanPureTestValues = (values: any[], filesUpload: PDFFile[]) => {
  // Some cases the values array will be sparse like [sign1, empty, empty, sign4]
  // Replace sparse values in array
  for (let i = 0, n = values.length; i < n; ++i) {
    if (!(i in values)) {
      values[i] = [];
    }
  }

  // Replace undefined value when delete signature location
  const newValues = values.map((it) => {
    if (!Array.isArray(it)) {
      return [];
    } else return it;
  });

  // Make sure the signatures array length always same as filesUpload array length to validate
  if (newValues.length < filesUpload.length) {
    filesUpload.forEach((file, index) => {
      if (index >= newValues.length) {
        newValues.push([]);
      }
    });
  }
  return newValues;
};

/*TODO: Need to update for both signing & authentication flow when sender == recipient */
// current support Sign + Auth at the same time, or Sign only, or auth only
// waiting for BE to implement Sign only, but no auth (Sender is signer + auth role)

export const generateDocumentRequestPayload = (
  file: PDFFile,
  recipientInfo: RecipientInfoProps[],
  recipientRoles: RecipientRolesProps[],
  signatures: SignaturePercentageProps[],
  currUserProfile: CurrentUserProfile | null,
  isSenderAuth: boolean,
  party: string,
  isSenderSameAsRecipient: boolean,
  isFileFromKentro: boolean = false,
  linearId?: LinearId
): DocumentCreateRequestPayload => {
  const signOnlySignatures = signatures.reduce((acc, signature) => {
    if (
      currUserProfile?.email === signature.value?.email &&
      signature.imageSrc
    ) {
      acc[signature.id] = signature.imageSrc;
    }
    return acc;
  }, {} as SignatureRecord);
  const payload: DocumentCreateRequestPayload = {
    file: {
      id: file.id ?? "",
      name: file.name,
    },
    recipients: recipientInfo.map((recipient, index) => {
      return {
        email: recipient.email.trim(),
        party,
        profile: {
          companyName: recipient.company?.trim() || null,
          companyTitle: recipient.title?.trim() || null,
          firstName: recipient.firstName.trim(),
          lastName: recipient.lastName.trim(),
        },
        roles:
          recipientRoles.find((it) =>
            isEqualIgnoreCase(it.recipient.email.trim(), recipient.email.trim())
          )?.roles ?? [],
        canDownload: !!recipientRoles.find((it) =>
          isEqualIgnoreCase(it.recipient.email.trim(), recipient.email.trim())
        )?.canDownload,
      };
    }),
    sender: {
      profile: {
        companyName: currUserProfile?.companyName || null,
        companyTitle: currUserProfile?.companyTitle || null,
        firstName: currUserProfile?.firstName ?? "",
        lastName: currUserProfile?.lastName ?? "",
      },
    },
    signatureSchemas:
      signatures && signatures.length > 0
        ? signatures.map((signatures) => {
            return {
              email: signatures.value?.email?.trim() ?? "",
              height: signatures.height_percentage,
              party,
              width: signatures.width_percentage,
              x: signatures.x_percentage,
              y: signatures.y_percentage,
              pageNumber: signatures.pageNumber,
              id: signatures.id,
            };
          })
        : [],
    isFileFromKentro,
  };
  if (isSenderSameAsRecipient) {
    payload.senderSignOrAuthenticate = {
      signature: !_.isEmpty(signOnlySignatures)
        ? { signatures: signOnlySignatures }
        : null,
      authenticate: isSenderAuth,
    };
  }

  if (isFileFromKentro) {
    payload.linearId = linearId;
  }

  return payload;
};

export const getInitialStep = (
  isAuthenOnly: boolean,
  isSenderSameAsRecipient: boolean
) => {
  return isAuthenOnly
    ? isSenderSameAsRecipient
      ? SignatureControlPanelStep.SENDER_AUTHENTICATE
      : SignatureControlPanelStep.PREVIEW_TO_SEND
    : SignatureControlPanelStep.ADD_SIGNATURE;
};

export const getSenderRole = (
  recipientRoles: RecipientRolesProps[],
  currentUserEmail: string
) => {
  const sender = recipientRoles.find(
    (it) => it.recipient.email === currentUserEmail
  );
  const isPureRecipientIncludeSigner = recipientRoles
    .filter((it) => it.recipient.email !== currentUserEmail)
    .some((it) => it.roles?.includes(DocumentRole.SIGNER));

  if (sender && sender.roles && sender.roles.length > 0) {
    if (sender.roles.length > 1) {
      return SenderAsRecipientMode.SIGNANDAUTHENTICATE;
    }
    return sender.roles.includes(DocumentRole.SIGNER)
      ? SenderAsRecipientMode.SIGNING
      : isPureRecipientIncludeSigner
      ? SenderAsRecipientMode.SIGNANDAUTHENTICATE
      : SenderAsRecipientMode.AUTHENTICATE;
  }
  return undefined;
};

export const getBackStep = (
  currentStep: SignatureControlPanelStep,
  isAuthenOnly: boolean,
  isSenderSameAsRecipient: boolean,
  senderRole: SenderAsRecipientMode | undefined
): SignatureControlPanelStep => {
  switch (currentStep) {
    case SignatureControlPanelStep.PREVIEW_TO_SEND:
      if (isSenderSameAsRecipient) {
        return senderRole === SenderAsRecipientMode.SIGNING
          ? SignatureControlPanelStep.ADD_SIGNATURE
          : SignatureControlPanelStep.SENDER_AUTHENTICATE;
      } else {
        return isAuthenOnly
          ? SignatureControlPanelStep.SET_PERMISSION
          : SignatureControlPanelStep.ADD_SIGNATURE;
      }
    case SignatureControlPanelStep.SENDER_AUTHENTICATE:
      return senderRole === SenderAsRecipientMode.SIGNANDAUTHENTICATE
        ? SignatureControlPanelStep.ADD_SIGNATURE
        : SignatureControlPanelStep.SET_PERMISSION;
    case SignatureControlPanelStep.ADD_SIGNATURE:
      return SignatureControlPanelStep.SET_PERMISSION;
    default:
      throw new Error("Invalid Steps");
  }
};

export const getNextStep = (
  currentStep: SignatureControlPanelStep,
  isSenderSameAsRecipient: boolean,
  senderAsRecipientMode: SenderAsRecipientMode | undefined
): SignatureControlPanelStep => {
  switch (currentStep) {
    case SignatureControlPanelStep.ADD_SIGNATURE:
      return isSenderSameAsRecipient &&
        senderAsRecipientMode === SenderAsRecipientMode.SIGNANDAUTHENTICATE
        ? SignatureControlPanelStep.SENDER_AUTHENTICATE
        : SignatureControlPanelStep.PREVIEW_TO_SEND;
    case SignatureControlPanelStep.SENDER_AUTHENTICATE:
      return SignatureControlPanelStep.PREVIEW_TO_SEND;
    default:
      return currentStep;
  }
};

export const handleSignatureBoxValuesNotSignedBeforeCreation = (
  values: any[],
  filesUpload: PDFFile[],
  currentUserProfile: CurrentUserProfile | null
): SignatureBoxValuesNotSignedBeforeCreationProps[] => {
  const senderAsRecipientValues = values?.map((value: SignaturePxProps[]) =>
    value?.filter((it) => it?.value?.email === currentUserProfile?.email)
  );

  const notSignedBoxInitial = {
    fileName: "",
    amount: 0,
  };

  const notSignedBoxesDetails: SignatureBoxValuesNotSignedBeforeCreationProps[] =
    senderAsRecipientValues
      .map((value, index) => {
        const notSignedBoxes: SignatureBoxValuesNotSignedBeforeCreationProps =
          value?.reduce((result, it) => {
            return {
              fileName: filesUpload[index].name,
              amount:
                it.imageSrc === undefined ? result.amount + 1 : result.amount,
            };
          }, notSignedBoxInitial as SignatureBoxValuesNotSignedBeforeCreationProps);

        return notSignedBoxes.amount !== 0 &&
          notSignedBoxes.amount !== value?.length
          ? notSignedBoxes
          : notSignedBoxInitial;
      })
      .filter((it) => it.fileName !== "");
  return notSignedBoxesDetails;
};

export const parseFromPxToPercentage = (
  dimensions: Dimension[],
  pxSignature: SignaturePxProps,
  userPageWidth: number
): SignaturePercentageProps => {
  if (!dimensions || !dimensions[0]) {
    return {
      pageNumber: 0,
      x_percentage: 0,
      y_percentage: 0,
      value: undefined,
      width_percentage: 0,
      height_percentage: 0,
      id: "",
    };
  }

  const displayDimensions = getDisplayDimensions(dimensions, userPageWidth);
  const pageIndex =
    searchInsertPosition(
      displayDimensions.map((it) => it.totalOffset),
      pxSignature.y_px
    ) - 1;
  const adjustedIndex = pageIndex >= 0 ? pageIndex : 0;
  const currDisplayDimension = displayDimensions[adjustedIndex];

  return {
    pageNumber: adjustedIndex,
    x_percentage: pxSignature.x_px / currDisplayDimension.width,
    y_percentage:
      (pxSignature.y_px - currDisplayDimension.totalOffset) /
      currDisplayDimension.height,
    width_percentage: pxSignature.width_px / currDisplayDimension.width,
    height_percentage: pxSignature.height_px / currDisplayDimension.height,
    value: pxSignature.value,
    imageSrc: pxSignature.imageSrc,
    id: pxSignature.id,
  };
};

/**
 * Calculate the dimensions show on user's browser
 * @param dimensions real size based on the pdf
 * @param displayPageWidth user browser size
 * @returns current display dimension
 */
export const getDisplayDimensions = (
  dimensions: Dimension[],
  displayPageWidth: number
): DimensionOffset[] => {
  const displayDimension = dimensions.reduce((acc, dimension, index) => {
    const realWidth = dimension.width;
    const ratio = displayPageWidth / realWidth;
    const totalOffset =
      index > 0 ? acc[index - 1].totalOffset + acc[index - 1].height : 0;
    acc.push({
      width: realWidth * ratio,
      height: dimensions[index].height * ratio,
      totalOffset,
    });
    return acc;
  }, [] as DimensionOffset[]);
  return displayDimension;
};

export type DimensionOffset = Dimension & {
  totalOffset: number;
};

export const parseToDimensionList = (
  pageDimension: PageDimensions | undefined
): Dimension[] => {
  if (pageDimension) {
    return Array.from(pageDimension.values());
  } else {
    return [];
  }
};
