import { useGenerateColorByString } from "common/hooks/useGenerateColorByString";
import { SenderCard } from "features/documents/pages/postDocumentRequest/components/SenderCard";
import { useTranslation } from "react-i18next";
import { DocumentStatePayload } from "types/document";

type DocumentSenderInfoProps = {
  documentStatePayload: DocumentStatePayload;
};
export const DocumentSenderInfo = ({
  documentStatePayload,
}: DocumentSenderInfoProps) => {
  const { t } = useTranslation();
  const { color } = useGenerateColorByString(documentStatePayload.sender.email);
  return (
    <div>
      <div className="text-h3 font-medium mb-3 h-md:mb-6">
        {t("docDetails.documentSender")}
      </div>
      <SenderCard
        senderInfo={documentStatePayload.sender}
        currentColor={color}
      />
    </div>
  );
};
