import { generateColorByString } from "enums/colors";
import {
  RecipientDisplayMode,
  RecipientWithRoleCard,
} from "features/documents/pages/documentRequest/components/RecipientWithRoleCard";
import { StyledHeader } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/SignatureControlPanel";
import { RecipientRolesProps } from "features/documents/pages/documentRequest/types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type RecipientListProps = {
  recipients: RecipientRolesProps[];
  className: string;
};
export const RecipientList = ({
  recipients,
  className,
}: RecipientListProps) => {
  const { t } = useTranslation();
  const generateColorByEmail = useCallback(
    (email: string) => generateColorByString(email),
    []
  );
  return (
    <div className={`${className} relative h-[45%]`}>
      <StyledHeader className="mb-7">
        {t("docRequest.addRecipient.title")}
      </StyledHeader>
      <div className="overflow-y-auto absolute h-3/4 w-full">
        {recipients.map((recipient, index) => (
          <RecipientWithRoleCard
            key={`recipient_${index}`}
            mode={RecipientDisplayMode.PREVIEW}
            recipientInfo={recipient.recipient}
            recipientRole={recipient?.roles}
            recipientDownloadPermission={recipient.canDownload}
            currentColor={generateColorByEmail(recipient.recipient.email)}
          />
        ))}
      </div>
    </div>
  );
};
