import { Colors } from "enums/colors";
import { SignatureBoxEditOverlay } from "features/documents/components/SignatureBox/SignatureBoxEditOverlay";
import {
  SignatureButtonContainer,
  SignatureButtonContentContainer,
  SignatureImage,
  SignaturePencil,
} from "features/documents/components/SignatureBox/SignatureButtonBased";
import { SignatureContent } from "features/documents/components/SignatureBox/SignatureCreateButton";
import { useContentRatio } from "features/documents/hooks/useContentRatio";
import { Dispatch, MouseEventHandler, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommonMouseEvents } from "types/common";

type SignatureBoxActionBasedProps = {
  imageSrc?: string;
  onCrossClick?: MouseEventHandler<HTMLDivElement>;
  isCrossShow: boolean;
  setReferenceElement: Dispatch<SetStateAction<HTMLElement | null | undefined>>;
  isHoverByDefault?: boolean; // TODO temp solution, find a better way
  color: Colors;
  width: number;
  isGradientBorderShow?: boolean;
} & CommonMouseEvents;
export const SignatureBoxActionBased = ({
  color,
  onClick,
  imageSrc,
  onCrossClick,
  width,
  isCrossShow,
  setReferenceElement,
  onMouseEnter,
  onMouseLeave,
  isHoverByDefault = false,
  isGradientBorderShow,
}: SignatureBoxActionBasedProps) => {
  const [isHover, setIsHover] = useState(isHoverByDefault);
  const { scaleRatio, contentRef } = useContentRatio(width, 0.7);
  const { t } = useTranslation();
  return (
    <SignatureButtonContainer
      color={color}
      data-cy="signature-btn"
      isBackgroundShow={true}
      isBorderShow={true}
      ref={setReferenceElement}
      onClick={onClick}
      onMouseEnter={(e) => {
        setIsHover(true);
        onMouseEnter && onMouseEnter(e);
      }}
      isGradientBorderShow={isGradientBorderShow}
      onMouseLeave={(e) => {
        setIsHover(false);
        onMouseLeave && onMouseLeave(e);
      }}
    >
      <SignatureButtonContentContainer scaleRatio={scaleRatio}>
        {imageSrc ? (
          <>
            {isHover && (
              <SignatureBoxEditOverlay
                isCrossShow={isCrossShow}
                onCrossClick={onCrossClick}
                scaleRatio={scaleRatio}
              />
            )}
            <SignatureImage src={imageSrc} />
          </>
        ) : (
          <>
            <SignaturePencil
              scaleRatio={scaleRatio}
              className={`text-${color}`}
            />
            <SignatureContent ref={contentRef} className="grow">
              {t("docRequest.signatureBox.clickToSign")}
            </SignatureContent>
          </>
        )}
      </SignatureButtonContentContainer>
    </SignatureButtonContainer>
  );
};
