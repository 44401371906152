import { getParentFolderBreadCrumbData } from "common/utils/document";
import { TitleCell } from "features/documents/components/Cells";
import { SearchBreadcrumbs } from "features/search/components/SearchBreadcrumbs";
import { DashboardResponse } from "types/common";

type AlignPlace = "start" | "title-text";

type TitleWithBreadCrumbsCellProps = {
  value: DashboardResponse;
  isDiscarded: boolean;
  breadCrumbAlignPlace?: AlignPlace;
  onClickSearchBreadcrumbs?: () => void;
};

export const TitleWithBreadCrumbsCell = ({
  value,
  isDiscarded,
  breadCrumbAlignPlace = "start",
  onClickSearchBreadcrumbs,
}: TitleWithBreadCrumbsCellProps): JSX.Element => {
  return (
    <div className="min-w-0 flex-1">
      <TitleCell value={value} isDiscarded={isDiscarded} />
      <SearchBreadcrumbs
        className={`w-fit max-w-full truncate ${
          breadCrumbAlignPlace === "title-text" ? `ml-[30px]` : undefined
        }`}
        data={getParentFolderBreadCrumbData(value)}
        onClick={onClickSearchBreadcrumbs}
      />
    </div>
  );
};
