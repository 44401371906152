import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";

type AuthHintSectionProps = {
  className?: string;
};
export const AuthHintSection = ({ className }: AuthHintSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className="absolute top-[60%] h-[35%] max-w-fit">
      <div className="text-h3 font-medium mb-6 text-dark-blue">
        {t("docRequest.confirmDetail.authenicateTitle")}
      </div>
      <HintText className="mb-4">
        {t("docRequest.confirmDetail.authenicateHint")}
      </HintText>
      <HintText>{t("docRequest.confirmDetail.skipAuthenicateHint")}</HintText>
    </div>
  );
};

const HintText = styled.div(tw`text-grey text-sm leading-[22px]`);
