import { RadioGroup } from "@headlessui/react";
import { SignaturePxProps } from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { SvgFilePdf, SvgPencil } from "common/components/svg";
import { PDFFile } from "features/documents/pages/documentRequest/types";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";

type FileWithSignatureListProps = {
  signatures: SignaturePxProps[][];
  files: PDFFile[];
  className?: string;
  selectedFile: PDFFile;
  setSelectedFile: Dispatch<SetStateAction<PDFFile>>;
  afterFileChange?: (file: PDFFile) => void;
  isPreview: boolean;
  invalidFiles?: (string | undefined)[];
};

export const FileWithSignatureList = ({
  signatures,
  files,
  selectedFile,
  setSelectedFile,
  afterFileChange,
  isPreview,
  invalidFiles,
}: FileWithSignatureListProps) => {
  const { t } = useTranslation();
  const handleChange = (file: PDFFile) => {
    setSelectedFile(file);
    if (afterFileChange) afterFileChange(file);
  };

  return (
    <RadioGroup
      value={selectedFile}
      onChange={handleChange}
      className="overflow-auto h-fit max-h-[40%] w-full mb-8"
      disabled={isPreview}
    >
      {files.map((file, index) => {
        const signedNum = signatures?.[index]?.filter(
          (signature) => !!signature.value
        ).length;

        return (
          <RadioGroup.Option key={`file_${index}`} value={file}>
            {({ checked }) => (
              <div
                className={`flex justify-start items-center p-2 rounded-lg ${
                  checked && !isPreview ? "bg-background-grey" : null
                } ${
                  invalidFiles?.includes(file.name) && "bg-light-red-opacity"
                }`}
              >
                {!isPreview ? (
                  <StyledInput
                    role="radio"
                    type="radio"
                    name="fileList"
                    checked={checked}
                    onChange={() => {}}
                  />
                ) : null}
                <SvgFilePdf className="mr-4 text-light-blue block my-auto min-w-[32px]" />
                <div className="my-auto max-w-[470px] text-ellipsis overflow-hidden whitespace-nowrap">
                  {file.name}
                </div>
                {signedNum ? (
                  <>
                    {!isPreview ? (
                      <IconWrapper>
                        <SvgPencil className="mr-2" />
                        {signedNum}
                      </IconWrapper>
                    ) : (
                      <StyledText>
                        {t("docRequest.confirmDetail.signatureSpecified", {
                          signedNumber: signedNum,
                        })}
                      </StyledText>
                    )}
                  </>
                ) : null}
              </div>
            )}
          </RadioGroup.Option>
        );
      })}
    </RadioGroup>
  );
};

const StyledInput = styled.input(() => [tw`mr-6 inline-block my-auto`]);
export const IconWrapper = styled.div(() => [
  tw`flex text-grey border border-grey rounded px-2 py-1 ml-3 justify-center items-center min-w-[61px]`,
]);

export const StyledText = styled.div(() => [
  tw`text-dark-grey text-base font-regular ml-3 min-w-[176px] inline-block shrink-0`,
]);
