import { useCurrentFolderId } from "common/hooks/useCurrentFolderId";
import { useDashboardBreadcrumbs } from "common/hooks/useDashboardBreadcrumbs";
import { DocumentFilterTabType } from "common/hooks/useLastDocumentFilterType";
import { useOriginalRoute } from "common/hooks/useOriginalRoute";
import { getParentFolderBreadCrumbData } from "common/utils/document";
import { BreadCrumbItemProps } from "features/documents/components/FolderManagement/DashboardBreadcrumbs";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DashboardResponse } from "types/common";

type GoToRowSelectedPageProps = {
  handleGoToRowSelectedPage: (
    item: DashboardResponse,
    docFilterType?: DocumentFilterTabType
  ) => void;
};

export const useGoToRowSelectedPage = (): GoToRowSelectedPageProps => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const { setDashboardBreadcrumbsItems } = useDashboardBreadcrumbs();
  const { setCurrentFolderId } = useCurrentFolderId();
  const { setIsOriginalRoute } = useOriginalRoute();

  const handleGoToRowSelectedPage = useCallback(
    (item: DashboardResponse, docFilterType?: DocumentFilterTabType) => {
      if (item.isFolder && item.folderName) {
        if (docFilterType) {
          navigate(`/documents/${docFilterType}`);
        } else {
          navigate(currentLocation.pathname);
        }
        const newPageBreadcrumbsItem: BreadCrumbItemProps = {
          label: item.folderName,
          value: item.id,
        };
        const selectItemBreadCrumb: BreadCrumbItemProps[] =
          getParentFolderBreadCrumbData(item).concat(newPageBreadcrumbsItem);
        setIsOriginalRoute(false);
        setDashboardBreadcrumbsItems(selectItemBreadCrumb);
        setCurrentFolderId(item.id);
      } else {
        navigate(`/documents/details/${item.id}`);
      }
    },
    [
      currentLocation.pathname,
      navigate,
      setCurrentFolderId,
      setDashboardBreadcrumbsItems,
      setIsOriginalRoute,
    ]
  );

  return { handleGoToRowSelectedPage };
};
