import { useAttachment } from "api/attachment";
import {
  SignatureRecord,
  useDocument,
  useSignatureListByDocumentLinearId,
} from "api/document";
import { APIError } from "common/errorHandling";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { useMemo } from "react";
import {
  DocumentStatePayload,
  DocumentStatePayloadMutate,
  DocumentStatus,
  ParticipantActionTaken,
} from "types/document";

export const useAggregateDocument = (
  documentLinearId?: string
): {
  documentStatePayload?: DocumentStatePayload;
  documentPath?: string;
  signatureRecord: SignatureRecord;
  actionsTaken?: ParticipantActionTaken[];
  isSenderAsRecipient?: boolean;
  mutate: DocumentStatePayloadMutate;
  error: APIError | undefined;
} => {
  const {
    data: documentStatePayload,
    mutate,
    error,
  } = useDocument(documentLinearId);

  const currentUser = useCurrentUserProfile();
  const isSenderAsRecipient = useMemo(() => {
    return currentUser?.email === documentStatePayload?.sender.email;
  }, [currentUser?.email, documentStatePayload?.sender.email]);

  const actionsTaken = useMemo(
    () =>
      documentStatePayload?.recipients.find(
        (it) => it.email === currentUser?.email
      )?.actionTaken,
    [documentStatePayload?.recipients, currentUser?.email]
  );

  const isSignatureImageCanShow = useMemo(() => {
    return (
      documentStatePayload?.recipients.some(
        (it) => it.email === documentStatePayload?.sender.email
      ) || documentStatePayload?.status !== DocumentStatus.CREATED
    );
  }, [
    documentStatePayload?.status,
    documentStatePayload?.sender.email,
    documentStatePayload?.recipients,
  ]);

  const { data: signatureList } = useSignatureListByDocumentLinearId(
    isSignatureImageCanShow ? documentLinearId : undefined
  );

  const { data: file } = useAttachment(
    documentStatePayload?.linearId,
    documentStatePayload?.file.id
  );

  const signatureRecord = useMemo(() => {
    return signatureList?.reduce((acc, state) => {
      Object.keys(state.signatures).forEach((key) => {
        acc[key] = state.signatures[key];
      });
      return acc;
    }, {} as SignatureRecord);
  }, [signatureList]);

  return {
    documentStatePayload,
    documentPath: file ? URL.createObjectURL(file) : file,
    signatureRecord: signatureRecord ?? {},
    actionsTaken,
    isSenderAsRecipient,
    mutate,
    error,
  };
};
