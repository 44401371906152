import { FILE_TYPE } from "constants/file";

import { ProgressBar } from "common/components/ProgressBar";
import {
  SvgFileJpg,
  SvgFilePdf,
  SvgFilePng,
  SvgX,
} from "common/components/svg";
import { getFileTypeByFileName } from "common/utils/common";
import { PDFFile } from "features/documents/pages/documentRequest/types";
import fileSize from "filesize";
import { t } from "i18next";

export const UploadFileItem = ({
  fileItem,
  onRemoveFile,
}: {
  fileItem: PDFFile;
  onRemoveFile: () => void;
}) => {
  const uploadCompleted =
    fileItem &&
    fileItem.id &&
    fileItem.loaded &&
    fileItem.loaded >= fileItem.size;
  const fileType = getFileTypeByFileName(fileItem.name);
  return (
    <div className="flex flex-nowrap items-center justify-between mx-3 mb-7 gap-2">
      <div className="flex flex-nowrap items-center flex-1">
        {(() => {
          switch (fileType) {
            case FILE_TYPE.PDF:
              return (
                <SvgFilePdf
                  className="text-light-blue mr-8"
                  width={37}
                  height={46}
                />
              );
            case FILE_TYPE.JPG:
            case FILE_TYPE.JPEG:
              return (
                <SvgFileJpg
                  className="text-light-blue mr-8"
                  width={37}
                  height={46}
                />
              );
            case FILE_TYPE.PNG:
              return (
                <SvgFilePng
                  className="text-light-blue mr-8"
                  width={37}
                  height={46}
                />
              );
            default:
              return null;
          }
        })()}
        <div className="flex flex-col flex-1 items-start sm:w-72">
          <span className="w-full text-dark-blue text-base mb-[6px] text-ellipsis overflow-hidden whitespace-nowrap">
            {fileItem.name}
          </span>

          {!uploadCompleted && (
            <ProgressBar
              className="h-[3px] mb-[6px] file-progressbar max-w-[274px]"
              progressPercentage={Math.floor(
                (100 * Number(fileItem?.loaded)) / fileItem.size
              )}
            />
          )}
          <span className="text-xs font-medium text-dark-grey">
            {uploadCompleted
              ? fileSize(fileItem.size)
              : `${fileSize(Number(fileItem?.loaded))} ${t(
                  "docRequest.of"
                )} ${fileSize(fileItem.size)}`}
          </span>
        </div>
      </div>
      <SvgX
        className="text-grey cursor-pointer"
        onClick={onRemoveFile}
        role="svg"
      />
    </div>
  );
};
