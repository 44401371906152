import { PRIVACY_POLICY_URL } from "constants/common";

import { Button } from "common/components/Button";
import { Modal } from "common/components/Modal";
import { ToggleSwitch } from "common/components/ToggleSwitch";
import { exportAsImageDataString } from "common/utils/common";
import { useContentRatio } from "features/documents/hooks/useContentRatio";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { SignatureSchemas } from "types/document";

type GenerateSignatureImageModalProps = {
  isOpen: boolean;
  hideModal: () => void;
  fileName: string;
  onSignatureApply: (
    currentSchema: SignatureSchemas,
    imageData: string,
    isApplyToAll: boolean,
    inputValue: string
  ) => void;
  signatureSchemas: SignatureSchemas;
  inputValue: string;
};

// Inspired from https://blog.logrocket.com/export-react-components-as-images-html2canvas/
export const GenerateSignatureImageModal = ({
  isOpen,
  hideModal,
  fileName,
  onSignatureApply,
  signatureSchemas,
  inputValue,
}: GenerateSignatureImageModalProps) => {
  const imageWidth = signatureSchemas.width;
  const imageHeight = signatureSchemas.height;
  const { contentRef, scaleRatio, mutate } = useContentRatio(imageWidth, 0.85);
  const ref = useRef<HTMLElement | null>();
  const [text, setText] = useState<string>(inputValue);
  const { t } = useTranslation();

  const handleClick = async () => {
    if (ref.current) {
      const imageData = await exportAsImageDataString(ref.current, {
        width: imageWidth,
        height: imageHeight,
        scale: 5,
      });
      onSignatureApply(signatureSchemas, imageData, isToggleOn, text);
      hideModal();
    }
  };

  const [isToggleOn, setIsToggleOn] = useState<boolean>(true);
  return (
    <Modal open={isOpen} onClose={hideModal} size="lg">
      <div className="text-dark-blue font-medium text-base">
        {t("docRequest.signaturePopup.title")}
      </div>
      <div className="border-b-2 border-grey">
        <StyledInput
          name="inputSignature"
          value={text}
          onChange={(event) => {
            mutate();
            setText(event.target.value);
          }}
          onBlur={() => mutate()} // force to recalculate ratio
          data-cy="signature-input"
        />
      </div>
      <StyledToggleContainer>
        <ToggleSwitch
          isSelected={isToggleOn}
          onSelect={setIsToggleOn}
          className="mr-1"
        />
        <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
          <span>
            {t("docRequest.signaturePopup.applySignatureHint", {
              fileName,
            })}
          </span>
        </div>
      </StyledToggleContainer>
      <WarningContainer>
        {t("docRequest.signaturePopup.warning1", { fileName })}
        <a
          className="text-light-blue"
          href={PRIVACY_POLICY_URL}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          {t("common.privacyPolicy")}
        </a>
        . {t("docRequest.signaturePopup.warning2", { fileName })}
      </WarningContainer>
      {/* Hint: generate outside the view for html2canvas to capture */}
      <HiddenTextWrapper>
        {/* Hint: Input tag doesn't work properly, need to render into the dom */}
        <div
          ref={(el) => (ref.current = el)}
          className="flex items-start justify-center p-0"
          style={{
            width: imageWidth,
            height: imageHeight,
          }}
        >
          <div style={{ transform: `scale(${scaleRatio})` }}>
            {/* TODO hot fix, leading 0 with * 0.5 is the biggest font that can create correct image.
                If encounter any image issues, can try to adjust the number */}
            <span
              ref={contentRef}
              className="!leading-[0] break-normal whitespace-pre font-signing"
              style={{
                fontSize: imageHeight * 0.5,
              }}
            >
              {text.trim()}
            </span>
          </div>
        </div>
      </HiddenTextWrapper>
      <Button
        className="w-full mt-12"
        onClick={handleClick}
        disabled={!text.trim().length}
        type="button"
        variant="primary"
        data-cy="apply-signature-btn"
      >
        {t("docRequest.button.apply")}
      </Button>
      <Button
        className="w-full mt-4"
        type="button"
        variant="secondary"
        onClick={hideModal}
      >
        {t("common.cancel")}
      </Button>
    </Modal>
  );
};

const StyledInput = tw.input`w-full text-center text-[36px] h-20 font-signing`;
const StyledToggleContainer = tw.div`text-dark-blue text-sm font-regular mt-14 flex`;
const WarningContainer = tw.div`font-medium text-xs text-dark-grey leading-4 mt-6 whitespace-normal`;
const HiddenTextWrapper = tw.div`absolute left-[-99999px]`;
