import { useSignatureBox } from "features/documents/components/SignatureBox/hooks/useSignatureBox";
import { SignatureBoxNotSignedPreview } from "features/documents/components/SignatureBox/SignatureBoxNotSignedPreview";
import { SignatureButtonRecipientsPopover } from "features/documents/components/SignatureBox/SignatureBoxRecipientsPopoverProps";
import { PanelState } from "features/documents/pages/documentRequest/context/PDFSignatureControlContext";
import { RecipientInfoProps } from "features/documents/pages/documentRequest/types";
import { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommonMouseEvents } from "types/common";

type SignatureBoxCreateAssignActionProps = {
  onCrossClick?: MouseEventHandler<HTMLDivElement>;
  onSelectRecipient: (recipient: RecipientInfoProps) => void;
  isPopoverShow: boolean;
  isSenderEqualsToRecipient: boolean;
} & CommonMouseEvents;

export const SignatureBoxCreateAssignAction = ({
  onSelectRecipient,
  isPopoverShow,
  onMouseEnter,
  onMouseLeave,
  isSenderEqualsToRecipient,
  onClick,
  onCrossClick,
}: SignatureBoxCreateAssignActionProps) => {
  const { t } = useTranslation();
  const { recipients, fullName, isGradientBorderShow, panelState } =
    useSignatureBox();
  const [isHover, setIsHover] = useState(false);

  return (
    <SignatureButtonRecipientsPopover
      recipients={recipients}
      onSelectRecipient={onSelectRecipient}
      isPopoverShow={isPopoverShow}
      onMouseEnter={(e) => {
        setIsHover(true);
        onMouseEnter && onMouseEnter(e);
      }}
      onMouseLeave={(e) => {
        setIsHover(false);
        onMouseLeave && onMouseLeave(e);
      }}
    >
      {({ setReferenceElement }) => (
        <SignatureBoxNotSignedPreview
          isCrossShow={true}
          ref={setReferenceElement}
          displayMsg={
            isSenderEqualsToRecipient && isHover
              ? t("docRequest.signatureBox.clickToSign")
              : fullName
          }
          onClick={onClick}
          onCrossClick={(e) => {
            e.stopPropagation();
            onCrossClick && onCrossClick(e);
          }}
          isGradientBorderShow={isGradientBorderShow}
          isGradientEnable={panelState === PanelState.SIGNATURE_FILTER}
        />
      )}
    </SignatureButtonRecipientsPopover>
  );
};
