import { BaseBreadCrumbs } from "common/components/Breadcrumbs/BaseBreadcrumbs";
import { SvgLeftArrow } from "common/components/svg";
import React, { useMemo } from "react";
import { DashboardResponse } from "types/common";

export type FolderBreadCrumbsProps = {
  folderPaths: string[];
  currentInternalFolder?: DashboardResponse;
  onBack: () => void;
  onItemClick: (index: number) => void;
  className?: string;
};

export enum BreadCrumbsType {
  PAGE = "page",
  FOLDER = "folder",
}

export const FolderBreadCrumbs = ({
  folderPaths,
  currentInternalFolder,
  onBack,
  onItemClick,
  className,
}: FolderBreadCrumbsProps) => {
  const historyPathsShortcut = useMemo(() => {
    const newHistoryPath = new Set(
      folderPaths.map((it, index) => {
        return index > 1 && index < folderPaths.length - 1 ? "..." : it;
      })
    );
    return Array.from(newHistoryPath);
  }, [folderPaths]);

  return (
    <div className="flex items-center gap-2 max-w-[80%]">
      <SvgLeftArrow
        onClick={onBack}
        className={`shrink-0 ${
          folderPaths.length > 2 ? "cursor-pointer text-dark-blue" : "text-grey"
        }`}
        role="svg"
      />
      <BaseBreadCrumbs containerClassName={className}>
        {historyPathsShortcut.map((it, index) => (
          <span
            key={index}
            className={`capitalize flex-1 truncate ${
              index !== 0 &&
              index !== historyPathsShortcut.length - 1 &&
              it !== "..." &&
              "hover:underline cursor-pointer"
            } ${index === historyPathsShortcut.length - 1 && ""}`}
            onClick={() => onItemClick(index)}
          >
            {index > 1 ? it.split(currentInternalFolder?.id ?? "")[0] : it}
          </span>
        ))}
      </BaseBreadCrumbs>
    </div>
  );
};
