import { emptyPromise } from "common/utils/common";
import { createContext } from "react";
import { DashboardResponse, Page } from "types/common";

/* Folder Management */
type DocumentListMutator = {
  mutate: () => Promise<Page<DashboardResponse>[] | undefined>;
  setPageSize?: (pageSize: number) => void;
};

export const DocumentListMutatorContext = createContext<DocumentListMutator>({
  mutate: emptyPromise,
});
