import { ReactNode, useEffect } from "react";

export const CancelLeaveSitePromptLayout = ({
  children,
}: {
  children: ReactNode;
}) => {
  useEffect(() => {
    const disableLeaveSitePrompt = (e: BeforeUnloadEvent) => {
      e.stopImmediatePropagation();
    };
    window.addEventListener("beforeunload", disableLeaveSitePrompt);

    return () =>
      window.removeEventListener("beforeunload", disableLeaveSitePrompt);
  }, []);

  return <>{children}</>;
};
