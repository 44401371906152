import {
  SvgCheckCircle,
  SvgCrossCircle,
  SvgWarning,
} from "common/components/svg";
import { toast as baseToast, ToastContent } from "react-toastify";

export enum ToastContainerId {
  AfterLogin = "AfterLogin",
  DocumentRequest = "DocumentRequest",
  DocumentDetail = "DocumentDetail",
}

export const toast = {
  success: (
    content: ToastContent,
    containerId: ToastContainerId = ToastContainerId.AfterLogin
  ) =>
    baseToast.success(content, {
      containerId,
      icon: SvgCheckCircle,
      autoClose: 5000,
      hideProgressBar: true,
      closeButton: false,
    }),
  warning: (
    content: ToastContent,
    containerId: ToastContainerId = ToastContainerId.AfterLogin
  ) =>
    baseToast.warning(content, {
      containerId,
      icon: SvgWarning,
    }),
  error: (
    content: ToastContent,
    containerId: ToastContainerId = ToastContainerId.AfterLogin
  ) =>
    baseToast.error(content, {
      containerId,
      icon: SvgCrossCircle,
    }),
};
