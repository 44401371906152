import { FilesMenu } from "common/components/form/PDF/FilesMenu";
import { SectionWrapper } from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { useDocumentRequest } from "common/hooks/useDocumentRequest";
import { usePDFSignatureControl } from "features/documents/pages/documentRequest/hooks/usePDFSignatureControl";
import {
  PDFSignatureCreateRefProps,
  PDFSignatureCreate,
} from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/PDFSignatureCreate";
import { PDFFile } from "features/documents/pages/documentRequest/types";
import React, { useEffect, useMemo } from "react";
import { useRef } from "react";
import { DocumentRole } from "types/document";

type PDFSignatureDisplayProps = {
  isPreview: boolean;
  isSignatureDisplay: boolean;
  selectedFile: PDFFile;
  setSelectedFile: React.Dispatch<React.SetStateAction<PDFFile>>;
};

export const PDFSignatureDisplay = ({
  isPreview,
  isSignatureDisplay,
  selectedFile,
  setSelectedFile,
}: PDFSignatureDisplayProps): JSX.Element => {
  // Request Flow Context
  const { filesUpload, rolePermission: recipientRoles } = useDocumentRequest();
  const { setPdfRef } = usePDFSignatureControl();
  const pdfRef = useRef<PDFSignatureCreateRefProps | null>();

  useEffect(() => {
    setPdfRef(pdfRef);
  }, [setPdfRef, pdfRef]);

  const signers = useMemo(
    () =>
      recipientRoles.recipientRoles
        .filter((recipientRole) =>
          recipientRole.roles?.includes(DocumentRole.SIGNER)
        )
        .map((recipientRole) => recipientRole.recipient),
    [recipientRoles.recipientRoles]
  );

  return (
    <div className="flex flex-col h-full">
      <SectionWrapper className="text-dark-blue text-base font-medium ">
        <FilesMenu
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          files={filesUpload}
        />
      </SectionWrapper>
      <PDFSignatureCreate
        isPreview={isPreview}
        recipients={signers}
        ref={(el) => (pdfRef.current = el)}
        className="h-full"
        selectedFile={selectedFile}
        isSignatureDisplay={isSignatureDisplay}
      />
    </div>
  );
};
