import useLocalStorage from "common/hooks/useLocalStorage";
import { LOCAL_STORAGE_KEY } from "common/utils/cross-domain/react/helper";
import { useSelector } from "redux/hooks";
import { RootState } from "redux/rootReducer";
import { AuthState } from "redux/slices/auth/authSlice";

type AuthType = AuthState & {
  accessToken: string | null;
  refreshToken: string | null;
  timeout: string | null;
  setAccessToken: (value: string) => void;
  setRefreshToken: (value: string) => void;
  setTokenTimeout: (value: string) => void;
  clearAuthToken: () => void;
};

const useAuth = (): AuthType => {
  const authState = useSelector((state: RootState) => state.auth);
  const [getAccessToken, setAccessToken, clearAccessToken] = useLocalStorage(
    LOCAL_STORAGE_KEY.ACCESS_TOKEN
  );
  const [getRefreshToken, setRefreshToken, clearRefreshToken] = useLocalStorage(
    LOCAL_STORAGE_KEY.REFRESH_TOKEN
  );
  const [getTokenTimeout, setTokenTimeout, clearTokenTimeout] = useLocalStorage(
    LOCAL_STORAGE_KEY.TIMEOUT
  );

  const clearAuthToken = () => {
    clearAccessToken();
    clearRefreshToken();
    clearTokenTimeout();
  };

  return {
    ...authState,
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
    timeout: getTokenTimeout(),
    setTokenTimeout,
    setAccessToken,
    setRefreshToken,
    clearAuthToken,
  };
};

export default useAuth;
