import { UploadFileItem } from "features/documents/pages/documentRequest/pages/UploadDocument/components/UploadFileItem";
import { PureUploadDocumentsCreateContext } from "features/documents/pages/documentRequest/pages/UploadDocument/context/PureUploadDocumentsCreateContext";
import { PDFFile } from "features/documents/pages/documentRequest/types";
import { useContext } from "react";
import tw, { styled } from "twin.macro";

export type BaseUploadedFilesProps = {
  values: PDFFile[];
  size?: "sm" | "lg";
  className?: string;
};

const UploadedFilesWrapper = styled.div(
  ({ size }: Pick<BaseUploadedFilesProps, "size">) => [
    tw`flex flex-col overflow-y-auto`,
    size === "sm" && tw`sm:max-h-[140px]`,
    size === "lg" && tw`sm:max-h-[486px]`,
  ]
);

export const BaseUploadedFiles = ({
  values,
  size,
  className,
}: BaseUploadedFilesProps) => {
  const { handleDeleteFile } = useContext(PureUploadDocumentsCreateContext);

  return (
    <UploadedFilesWrapper className={className} size={size ?? "sm"}>
      {values.length > 0 &&
        values.map((fileItem: PDFFile, index: number) => (
          <UploadFileItem
            key={index}
            onRemoveFile={() => handleDeleteFile(fileItem)}
            fileItem={fileItem}
          />
        ))}
    </UploadedFilesWrapper>
  );
};
