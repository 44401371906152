import { documentAPI } from "api/document";
import { folderAPI } from "api/folder";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import { toast, ToastContainerId } from "common/toast";
import { MenuButton } from "features/documents/pages/documentManagement/components/common";
import { DocumentListMutatorContext } from "features/documents/pages/documentManagement/DocumentListMutatorContext";
import { DocumentActionButtonProps } from "features/documents/pages/documentManagement/types";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

/*Folder Management*/
export const ArchiveDashboardItemButton = ({
  className,
  dashboardItem,
  afterClick = () => {},
}: DocumentActionButtonProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutate } = useContext(DocumentListMutatorContext);
  const { t } = useTranslation();

  const handleClick = async () => {
    try {
      setIsSubmitting(true);
      dashboardItem.isFolder
        ? await folderAPI.archive(dashboardItem.id)
        : await documentAPI.archive(dashboardItem.id);
      setIsSubmitting(false);
      mutate();

      toast.success(
        dashboardItem.isFolder
          ? t("common.toast.moveFolderToBin", {
              folderName: dashboardItem.folderName,
            })
          : t("common.toast.moveDocumentToBin", {
              docName: dashboardItem.documentName,
            })
      );
    } catch (e) {
      composeErrorHandlers(() =>
        baseFallbackErrorHandler(ToastContainerId.AfterLogin)
      )(e as GenericError);
    }
    afterClick();
  };

  return (
    <MenuButton
      className={className}
      onClick={handleClick}
      data-cy="archive-button"
      disabled={isSubmitting}
    >
      {t("dashboard.button.moveToBin")}
    </MenuButton>
  );
};
