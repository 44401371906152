import { DocumentFilterTabType } from "common/hooks/useLastDocumentFilterType";
import { BreadCrumbItemProps } from "features/documents/components/FolderManagement/DashboardBreadcrumbs";
import { cloneDeep } from "lodash";
import { DashboardResponse } from "types/common";
import { DocumentParticipant, DocumentRole } from "types/document";

/*
  1: Sort by role: Signer&Authen -> Signer -> Authen
  2: Sort by name
*/
export const sortRecipientList = (
  recipientList: DocumentParticipant[]
): DocumentParticipant[] => {
  const rolesOrder = [
    DocumentRole.SIGNER,
    DocumentRole.AUTHENTICATOR,
    DocumentRole.VIEWER,
  ];
  const sortedList = cloneDeep(recipientList).sort((a, b) => {
    if (a.roles.length === 1 && b.roles.length === 1) {
      if (a.roles[0] === b.roles[0]) {
        if (a.profile.fullName < b.profile.fullName) {
          return -1;
        }
        if (a.profile.fullName > b.profile.fullName) {
          return 1;
        }
        return 0;
      }
      // handle have recipient role not in rolesOrder, will order in last
      if (
        rolesOrder.indexOf(a.roles[0]) < 0 ||
        rolesOrder.indexOf(b.roles[0]) < 0
      ) {
        return rolesOrder.indexOf(b.roles[0]) - rolesOrder.indexOf(a.roles[0]);
      }
      return rolesOrder.indexOf(a.roles[0]) - rolesOrder.indexOf(b.roles[0]);
    }
    return b.roles.length - a.roles.length;
  });
  return sortedList;
};

/* Folder Management */
export const getDocumentRecipientRoleSet = (
  recipients?: DocumentParticipant[]
): Set<DocumentRole> | null => {
  if (recipients) {
    const aggregatedRoles: DocumentRole[] = recipients
      .map((it) => it.roles)
      .flat();
    return new Set([...aggregatedRoles]);
  }
  return null;
};

export const getCurrentParticipant = (
  recipients?: DocumentParticipant[],
  sender?: DocumentParticipant,
  currentUserEmail?: string
): DocumentParticipant | undefined => {
  /*Sender is recipient*/
  if (recipients && recipients.some((it) => it.email === sender?.email)) {
    return recipients.find((it) => it.email === currentUserEmail);
  }
  /*Sender is not recipient*/
  if (sender && sender.email === currentUserEmail) {
    return sender;
  } else {
    return recipients?.find((it) => it.email === currentUserEmail);
  }
};

export const assertCurrentUserRole = (
  expectedRole: DocumentRole,
  recipients?: DocumentParticipant[],
  sender?: DocumentParticipant,
  currentUserEmail?: string
): boolean => {
  if (expectedRole === DocumentRole.SENDER) {
    return (
      sender?.email ===
      getCurrentParticipant(recipients, sender, currentUserEmail)?.email
    );
  }

  return (
    getCurrentParticipant(recipients, sender, currentUserEmail)?.roles.includes(
      expectedRole
    ) ?? false
  );
};

export const isCurrentUserWithDownloadPermission = (
  recipients?: DocumentParticipant[],
  sender?: DocumentParticipant,
  currentUserEmail?: string
): boolean => {
  const isSender = assertCurrentUserRole(
    DocumentRole.SENDER,
    recipients,
    sender,
    currentUserEmail
  );
  if (isSender) {
    return true;
  } else {
    return !!getCurrentParticipant(recipients, sender, currentUserEmail)
      ?.canDownload;
  }
};

export const isDetailPage = (pathname: string): boolean => {
  return /\/documents\/details\/([^/]+)/.test(pathname);
};
export const isSigningPage = (pathname: string): boolean => {
  return /\/documents\/details\/([^/]+)\/signing$/.test(pathname);
};

export const isDocRequestPage = (pathname: string): boolean => {
  return /\/documents\/create-request\/([^/]+)/.test(pathname);
};

export const isUploadDocumentPage = (pathname: string): boolean => {
  return /\/documents\/create-request\/upload-documents$/.test(pathname);
};

export const isDashboardPage = (pathname: string): boolean => {
  const documentFilterTypes = Object.values(DocumentFilterTabType).join("|");
  const isDashboard = new RegExp(`/documents/${documentFilterTypes}$`).test(
    pathname
  );
  return isDashboard;
};

export const getDisplayFileNameOnActionPopup = (
  allFileName: string | string[]
) => {
  return typeof allFileName === "string"
    ? allFileName
    : allFileName.length < 2
    ? allFileName[0]
    : "";
};

export const getParentFolderBreadCrumbData = (
  item: DashboardResponse
): BreadCrumbItemProps[] => {
  if (item && item.parentFolder.length > 0) {
    return item.parentFolder.map((folder) => ({
      label: folder.folderName,
      value: folder.id,
    }));
  } else {
    return [];
  }
};
