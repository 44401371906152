import {
  A4_PAGE_WIDTH,
  SignaturePxProps,
} from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { PDFSignatureCreateRefProps } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/PDFSignatureCreate";
import { SELECT_ALL } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/SignatureControlPanel";
import React, { createContext, RefObject, useState } from "react";

type PDFRef = RefObject<PDFSignatureCreateRefProps | undefined>;

export enum PanelState {
  ADD_SIGNATURE = "ADD_SIGNATURE",
  SIGNATURE_FILTER = "SIGNATURE_FILTER",
}

type DocumentRequestPdfControlContextProps = {
  pdfRef: PDFRef | undefined;
  setPdfRef: (pdf: PDFRef) => void;
  currSignature: SignaturePxProps[];
  setCurrSignature: (currSignature: SignaturePxProps[]) => void;
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  pageWidth: number;
  setPageWidth: React.Dispatch<React.SetStateAction<number>>;
  setUpdateFormValue: React.Dispatch<
    React.SetStateAction<(value: SignaturePxProps[]) => void>
  >;
  updateFormValue: (value: SignaturePxProps[]) => void;
  filterIndex: number;
  setFilterIndex: React.Dispatch<React.SetStateAction<number>>;
  filterValue: string;
  setFilterValue: React.Dispatch<React.SetStateAction<string>>;
  panelState: PanelState;
  setPanelState: React.Dispatch<React.SetStateAction<PanelState>>;
};

export const DocumentRequestPdfControlContext =
  createContext<DocumentRequestPdfControlContextProps>({
    setPdfRef: () => {},
    pdfRef: React.createRef(),
    currSignature: [],
    setCurrSignature: () => {},
    scale: 0,
    setScale: () => {},
    pageWidth: A4_PAGE_WIDTH,
    setPageWidth: () => {},
    updateFormValue: () => {},
    setUpdateFormValue: () => {},
    filterIndex: 0,
    setFilterIndex: () => {},
    filterValue: "",
    setFilterValue: () => {},
    panelState: PanelState.ADD_SIGNATURE,
    setPanelState: () => {},
  });
type PDFSignatureControlContextProviderProps = {
  children: React.ReactNode;
};
export const PDFSignatureControlContextProvider = ({
  children,
}: PDFSignatureControlContextProviderProps) => {
  const [scale, setScale] = useState(1);
  const [pageWidth, setPageWidth] = useState<number>(0);
  const [pdfRef, setPdfRef] = useState<PDFRef>();
  const [currSignature, setCurrSignature] = useState<SignaturePxProps[]>([]);
  const [updateFormValue, setUpdateFormValue] = useState<
    (value: SignaturePxProps[]) => void
  >(() => {});
  // Recipient Filter
  const [filterIndex, setFilterIndex] = useState<number>(0);
  const [filterValue, setFilterValue] = useState<string>(SELECT_ALL);
  const [panelState, setPanelState] = useState<PanelState>(
    PanelState.ADD_SIGNATURE
  );
  return (
    <DocumentRequestPdfControlContext.Provider
      value={{
        pdfRef,
        setPdfRef,
        currSignature,
        setCurrSignature,
        scale,
        setScale,
        pageWidth,
        setPageWidth,
        updateFormValue,
        setUpdateFormValue,
        filterIndex,
        setFilterIndex,
        filterValue,
        setFilterValue,
        panelState,
        setPanelState,
      }}
    >
      {children}
    </DocumentRequestPdfControlContext.Provider>
  );
};
