import { Popover, Transition } from "@headlessui/react";
import { useCommonPopover } from "common/hooks/useCommonPopover";
import { RecipientPopover } from "features/documents/pages/documentRequest/components/RecipientPopover";
import { RecipientInfoProps } from "features/documents/pages/documentRequest/types";
import { Dispatch, MouseEvent, SetStateAction } from "react";
import { CommonMouseEvents } from "types/common";

type SignatureButtonRecipientsPopoverProps = {
  recipients: RecipientInfoProps[];
  onSelectRecipient: (recipient: RecipientInfoProps) => void;
  children: (props: {
    setReferenceElement: Dispatch<
      SetStateAction<HTMLElement | undefined | null>
    >;
    update: (() => void) | null;
  }) => React.ReactNode;
  isPopoverShow: boolean;
} & CommonMouseEvents;
export const SignatureButtonRecipientsPopover = ({
  children,
  recipients,
  onSelectRecipient,
  onMouseEnter,
  onMouseLeave,
  isPopoverShow,
}: SignatureButtonRecipientsPopoverProps) => {
  const { setReferenceElement, setPopperElement, styles, attributes, update } =
    useCommonPopover({
      placement: "top-start",
    });
  return (
    <Popover
      className="relative w-full h-full"
      onMouseEnter={onMouseEnter}
      onMouseLeave={(e: MouseEvent<HTMLDivElement>) => {
        update && update();
        onMouseLeave && onMouseLeave(e);
      }}
    >
      {children({ setReferenceElement, update })}
      <Transition
        unmount={false} // fix initial wrong position https://github.com/tailwindlabs/headlessui/issues/985#issuecomment-1031922396
        show={isPopoverShow}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="bottom-0 w-44 border-b-4 border-transparent"
          static
          tabIndex={-1}
        >
          <RecipientPopover
            recipients={recipients}
            onSelectRecipient={onSelectRecipient}
          />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
