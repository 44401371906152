import { DocumentUpdateSaveButton } from "features/documents/pages/postDocumentRequest/components/DocumentUpdateSaveButton";
import { RecipientUpdateForm } from "features/documents/pages/postDocumentRequest/components/RecipientUpdateForm";
import { DocumentPreviewForm } from "features/documents/pages/postDocumentRequest/pages/preview/DocumentPreviewView";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { DocumentStatePayload } from "types/document";

type RecipientListEditViewProps = {
  documentStatePayload: DocumentStatePayload;
};

export const RecipientListEditView = ({
  documentStatePayload,
}: RecipientListEditViewProps) => {
  const { t } = useTranslation();
  const { submitForm, isSubmitting } = useFormikContext<DocumentPreviewForm>();
  const handleSave = () => {
    submitForm();
  };

  return (
    <>
      <div className="text-h3 font-medium mb-7">
        {t("docRequest.addRecipient.title")}
      </div>
      <RecipientUpdateForm documentProps={documentStatePayload}>
        <DocumentUpdateSaveButton
          onClickSave={handleSave}
          isDisabled={isSubmitting}
        />
      </RecipientUpdateForm>
    </>
  );
};
