import { SvgX } from "common/components/svg";
import { MouseEventHandler } from "react";

type CrossButtonProps = {
  scaleRatio: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
};
export const CrossButton = ({ scaleRatio, onClick }: CrossButtonProps) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <SvgX
        width={24 / scaleRatio}
        height={24 / scaleRatio}
        className={"text-dark-grey block"}
        data-cy="signature-box-remove"
      />
    </div>
  );
};
