import { Popover } from "@headlessui/react";
import { Card } from "common/components/Card";
import { SvgChevronDown, SvgFilePdf } from "common/components/svg";
import { useCommonPopover } from "common/hooks/useCommonPopover";
import { PDFFile } from "features/documents/pages/documentRequest/types";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type FilesMenuProps = {
  files: PDFFile[];
  selectedFile: PDFFile;
  setSelectedFile: React.Dispatch<React.SetStateAction<PDFFile>>;
};
export const FilesMenu = ({
  files,
  selectedFile,
  setSelectedFile,
}: FilesMenuProps) => {
  const { setReferenceElement, setPopperElement, styles, attributes } =
    useCommonPopover({
      placement: "bottom-start",
    });
  const { t } = useTranslation();

  return (
    <Popover className="relative">
      <Popover.Button className="w-full" ref={setReferenceElement}>
        <div className="flex w-full">
          <div className="font-medium text-dark-blue text-base truncate w-fit">
            {selectedFile.name}
          </div>
          <StyledIcon className="min-w-[15px]">
            <SvgChevronDown height="15" width="15" className="text-white " />
          </StyledIcon>
        </div>
      </Popover.Button>
      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="z-popover"
      >
        {({ close }) => (
          <StyledCard>
            <span className="w-full">{t("docRequest.files")}</span>
            <div className="overflow-y-auto w-full">
              {files?.map((file, index) => {
                return (
                  <HoveredContainer
                    key={index}
                    onClick={() => {
                      setSelectedFile(file);
                      close();
                    }}
                  >
                    <div className="block mx-3">
                      <SvgFilePdf
                        width="25"
                        height="25"
                        className="text-light-blue"
                      />
                    </div>
                    <StyledFileName className="text-ellipsis overflow-hidden">
                      {file.name}
                    </StyledFileName>
                  </HoveredContainer>
                );
              })}
            </div>
          </StyledCard>
        )}
      </Popover.Panel>
    </Popover>
  );
};

const StyledCard = tw(
  Card
)`w-[220px] max-h-[200px] bg-white p-4 flex flex-col my-2 flex-nowrap`;

const HoveredContainer = tw.div`flex justify-start items-center hover:bg-background-grey min-h-[10px] rounded my-1 py-2 cursor-pointer`;
const StyledIcon = tw.div`bg-light-blue rounded-full block my-auto cursor-pointer ml-2`;
const StyledFileName = tw.div`max-w-[140px] text-dark-grey text-sm font-medium whitespace-nowrap`;
