import {
  EMAIL_DUPLICATE_ERROR,
  EMAIL_FORMAT_INCORRECT_ERROR,
} from "constants/commonValidatorError";
import { REG_EMAIL_VALIDATOR } from "constants/emailValidatorKey";

import { isEqualIgnoreCase } from "common/utils/common";
import { Colors, generateColorByString } from "enums/colors";
import {
  getEmptyRecipientListIndex,
  RecipientListBased,
} from "features/documents/components/RecipientListBased";
import { ControlPanel } from "features/documents/pages/documentRequest/components/ControlPanel";
import { RecipientCreateCard } from "features/documents/pages/documentRequest/components/RecipientCreateCard";
import { RecipientInputFields } from "features/documents/pages/documentRequest/components/RecipientInputFields";
import { defaultRecipientInfo } from "features/documents/pages/documentRequest/pages/AddRecipients/AddRecipients";
import {
  RecipientInfoProps,
  RecipientListFormValues,
} from "features/documents/pages/documentRequest/types";
import { Formik, useFormikContext } from "formik";
import cloneDeep from "lodash/cloneDeep";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export type RecipientCreateFormProps = {
  children: React.ReactNode;
};

export const RecipientCreateForm = ({
  children,
}: RecipientCreateFormProps): JSX.Element => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    recipientInfo: yup
      .array()
      .of(
        yup.object().shape({
          email: yup
            .string()
            .trim()
            .required()
            .test(
              "email-validate",
              t(EMAIL_FORMAT_INCORRECT_ERROR),
              (value) => {
                return REG_EMAIL_VALIDATOR.test(String(value));
              }
            )
            .test(
              "email-duplicate",
              t(EMAIL_DUPLICATE_ERROR),
              (value, context: any) => {
                const currentEmails: RecipientInfoProps[] =
                  context.from[context.from.length - 1].value.recipientInfo;
                const isDuplicateEmail =
                  currentEmails.filter((it) =>
                    isEqualIgnoreCase(it.email?.trim(), value?.trim())
                  ).length > 1;
                return !isDuplicateEmail;
              }
            ),
          firstName: yup.string().trim().required(),
          lastName: yup.string().trim().required(),
          company: yup.string().trim(),
          title: yup.string().trim(),
        })
      )
      .required(),
  });
  const { values, setFieldValue } = useFormikContext<RecipientListFormValues>();

  const initialIsEditArray = useMemo(() => {
    const initialArray: boolean[] = Array(
      values.recipientInfo?.length ?? 1
    ).fill(false);
    const initialIsEditArray = [
      ...initialArray.map((item, index) => {
        return getEmptyRecipientListIndex(values).includes(index);
      }),
    ];
    return initialIsEditArray;
  }, [values]);
  const [isEditArray, setIsEditArray] = useState<boolean[]>(initialIsEditArray);
  const [isExpandedArray, setIsExpandedArray] = useState<boolean[]>([false]);

  const handleClickExpanded = (index: number) => {
    setIsExpandedArray((preArray) => {
      return [
        ...preArray.slice(0, index),
        !preArray[index],
        ...preArray.slice(index + 1),
      ];
    });
  };

  const handleRecipientsFormSubmit = (values: RecipientListFormValues) => {
    const cloneValues = cloneDeep(values);
    cloneValues.recipientInfo.forEach((it) => {
      it.email = it.email.toLowerCase();
      it.cardColor = generateColorByString(it.email);
    });
    const emptyRecipient = getEmptyRecipientListIndex(cloneValues);
    setIsEditArray((preArray) => {
      return [
        ...preArray.map((item, i) => {
          // When the return value of Array.prototype.find() is zero, it will be ! ! judged to be falsy
          return emptyRecipient.includes(i);
        }),
      ];
    });
    setIsExpandedArray((prevArray) => {
      return prevArray.map((item) => false);
    });

    setFieldValue("recipientInfo", cloneValues.recipientInfo);
  };

  return (
    <div className="relative w-full h-full">
      <Formik
        initialValues={{
          recipientInfo: values.recipientInfo ?? [defaultRecipientInfo],
        }}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleRecipientsFormSubmit}
      >
        {({ values }) => (
          <>
            <ControlPanel
              contentSection={
                <>
                  <h3 className="mb-7">{t("docRequest.addRecipient.title")}</h3>
                  <RecipientListBased
                    className="absolute"
                    value={values}
                    isEditArray={isEditArray}
                    setIsEditArray={setIsEditArray}
                    afterClickAdd={() => {
                      setIsExpandedArray((preArray) => {
                        return [...preArray.slice(), false];
                      });
                    }}
                    afterClickRemove={(index) => {
                      setIsExpandedArray((prevArray) => {
                        return prevArray.filter((_, i) => i !== index);
                      });
                    }}
                    customizedCard={(
                      form,
                      recipient,
                      index,
                      remove,
                      handleClickEdit,
                      handleClickRemove
                    ) => {
                      return (
                        <RecipientCreateCard
                          key={index}
                          id={index}
                          currentColor={
                            values.recipientInfo[index].cardColor ||
                            Colors.LIGHT_GREY
                          }
                          recipientInfo={recipient}
                          isDisplayRemoveButton={
                            form.values.recipientInfo.length > 1
                          }
                          isEdit={isEditArray[index]}
                          isExpanded={isExpandedArray[index]}
                          onClickEdit={() => handleClickEdit(index, form)}
                          onClickExpanded={() => handleClickExpanded(index)}
                          onClickRemove={() => handleClickRemove(index, remove)}
                        >
                          <RecipientInputFields
                            name="recipientInfo"
                            index={index}
                            isExpanded={isExpandedArray[index]}
                          />
                        </RecipientCreateCard>
                      );
                    }}
                  />
                </>
              }
              buttonSection={<>{children}</>}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
