import { Action } from "@reduxjs/toolkit";
import { PDFSignatureSigningRefProps } from "features/documents/pages/postDocumentRequest/components/PDFSignatureSigning";
import SignatureScrollReducer from "features/documents/pages/postDocumentRequest/context/SignatureScrollReducer";
import React, { useReducer, Dispatch } from "react";

type SignatureScrollContextProps = {
  children: React.ReactNode;
};

export type SignatureScrollStateProps = {
  ref: PDFSignatureSigningRefProps | null | undefined;
  dispatch?: (action: Action) => Dispatch<any>;
};

const initialState: SignatureScrollStateProps = {
  ref: null,
};

type SignatureScrollContextValues = {
  ref: PDFSignatureSigningRefProps | null | undefined;
};

export const SignatureScrollContext = React.createContext(initialState);

export const SignatureScrollContextProvider = ({
  children,
}: SignatureScrollContextProps) => {
  const [state, dispatch] = useReducer(SignatureScrollReducer, initialState);
  const contextValues: SignatureScrollContextValues = {
    dispatch,
    ...state,
  };
  return (
    <SignatureScrollContext.Provider value={{ ...contextValues }}>
      {children}
    </SignatureScrollContext.Provider>
  );
};
