import * as React from "react";
import { SVGProps } from "react";

const SvgUserPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 8h-2v3h-3v2h3v3h2v-3h3v-2h-3V8ZM4 8a3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4 3.91 3.91 0 0 0-4 4Zm6 0a1.91 1.91 0 0 1-2 2 1.911 1.911 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2ZM4 18a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v1h2v-1a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v1h2v-1Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgUserPlus;
