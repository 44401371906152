import { useSignatureBox } from "features/documents/components/SignatureBox/hooks/useSignatureBox";
import {
  SignatureButtonContainer,
  SignatureImage,
} from "features/documents/components/SignatureBox/SignatureButtonBased";
import { SignatureButtonRecipientInfoLabelPopover } from "features/documents/components/SignatureBox/SignatureButtonRecipientInfoLabelPopover";
import { CommonMouseEvents } from "types/common";

type SignatureBoxSignedPreviewProps = CommonMouseEvents;
export const SignatureBoxSignedPreview = ({
  onMouseEnter,
  onMouseLeave,
}: SignatureBoxSignedPreviewProps) => {
  const { color, fullName, email, imageSrc } = useSignatureBox();
  return (
    <SignatureButtonRecipientInfoLabelPopover fullName={fullName} email={email}>
      {({ setReferenceElement, isPopoverShow, setIsPopoverShow }) => (
        <SignatureButtonContainer
          ref={setReferenceElement}
          onMouseEnter={(e) => {
            onMouseEnter && onMouseEnter(e);
            setIsPopoverShow(true);
          }}
          onMouseLeave={(e) => {
            onMouseLeave && onMouseLeave(e);
            setIsPopoverShow(false);
          }}
          color={color}
          isBackgroundShow={isPopoverShow}
          isBorderShow={isPopoverShow}
          data-cy="signature-btn"
        >
          <SignatureImage src={imageSrc ?? ""} />
        </SignatureButtonContainer>
      )}
    </SignatureButtonRecipientInfoLabelPopover>
  );
};
