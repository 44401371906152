import { PDFPageRenderer } from "common/components/form/PDF/PDFPageRenderer";
import { LoadingSpinner } from "common/components/LoadingSpinner";
import {
  SvgDownArrow,
  SvgUpArrow,
  SvgZoomIn,
  SvgZoomOut,
} from "common/components/svg";
import { useWindowResizeEvent } from "common/hooks/useWindowResizeEvent";
import {
  PDFFile,
  RecipientInfoProps,
} from "features/documents/pages/documentRequest/types";
import _ from "lodash";
import {
  PDFDocumentProxy,
  PDFPageProxy,
} from "pdfjs-dist/types/src/display/api";
import React, { useImperativeHandle, useRef, useState } from "react";
import { Document, pdfjs } from "react-pdf";
import AutoSizer from "react-virtualized-auto-sizer";
import { ReactElementType, VariableSizeList } from "react-window";
import tw, { styled } from "twin.macro";

// According to our package-lock.json, the pdfjs.version we are using is 2.12.313
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const SectionWrapper = styled.div(() => [
  tw`bg-white border-2 border-greyscale py-3 px-8 max-h-[48px]`,
]);

export const A4_PAGE_WIDTH = 595;

type SignatureBasedProps = {
  value: RecipientInfoProps | undefined;
  id: string;
  imageSrc?: string;
};

// use in FE, x and y are calculated in px (based on all the pages)
export type SignaturePxProps = SignatureBasedProps &
  SignaturePxSizeWithPosition;

export type SignaturePxSizeWithPosition = {
  width_px: number;
  height_px: number;
  x_px: number;
  y_px: number;
};

export type SignaturePercentageSizeWithPosition = {
  width_percentage: number;
  height_percentage: number;
  x_percentage: number;
  y_percentage: number;
  pageNumber: number;
};
// use in BE, x and y are calculated in percentage (based on per page)
export type SignaturePercentageProps = SignatureBasedProps &
  SignaturePercentageSizeWithPosition;

export type VariableListRef = {
  state: {
    scrollOffset: number;
  };
  props: {
    height: number;
  };
};

export type Dimension = {
  width: number;
  height: number;
};

export type PageDimensions = Map<number, Dimension>;

export type PDFSignatureBasedProps = {
  selectedFile: PDFFile;
  rndHandler: ReactElementType | undefined;
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  setPageDimensions: React.Dispatch<
    React.SetStateAction<PageDimensions | undefined>
  >;
  pageDimensions: PageDimensions | undefined;
  setPageWidth?: React.Dispatch<React.SetStateAction<number>>;
  isLoaderShowByDefault?: boolean;
};

export type PDFSignatureBasedRefBased = {
  scrollToOffset: (offset: number) => void;
  targetOffset: number;
};

export const PDFSignatureBased = React.forwardRef<
  PDFSignatureBasedRefBased,
  PDFSignatureBasedProps
>(
  (
    {
      selectedFile,
      rndHandler,
      scale,
      setScale,
      pageDimensions,
      setPageDimensions,
      setPageWidth,
      isLoaderShowByDefault = true,
    },
    ref
  ): JSX.Element => {
    // PDF preview
    const listRef = useRef<VariableSizeList | null>();
    const pageRef = useRef<(HTMLDivElement | null)[]>([]);

    const [numPages, setNumPages] = useState(0);
    const [currentPageIndex, setCurrentPage] = useState(0);
    const [isShowLoader, setIsShowLoader] = useState(isLoaderShowByDefault);

    useImperativeHandle(ref, () => ({
      scrollToOffset,
      targetOffset: targetOffset(),
    }));

    const scrollToOffset = (offset: number) => {
      const ref = listRef.current as unknown as VariableListRef;
      const targetOffset = offset - (ref?.props?.height ?? 0) / 2;
      listRef.current?.scrollTo(targetOffset);
    };

    const targetOffset = () => {
      const ref = listRef.current as unknown as VariableListRef;
      return (ref?.state?.scrollOffset ?? 0) + (ref?.props?.height ?? 0) / 2;
    };

    const onDocumentLoadSuccess = (pdf: PDFDocumentProxy, width: number) => {
      setPageWidth && setPageWidth(width);
      setNumPages(pdf.numPages);
      setCurrentPage(0);
      setPageDimensions(undefined);
      handlePageDimensions(pdf);
      setIsShowLoader(false);
    };

    const handleScrollToIndex = (index: number) => {
      if (index < 0 || index >= numPages) {
        return;
      }
      listRef.current?.scrollToItem(index, "center");
    };

    const handlePageDimensions = async (pdf: PDFDocumentProxy) => {
      const pageDimensions = await parseToPageDimensions(pdf);
      setPageDimensions(pageDimensions);
    };

    const handleZoom = (addScale: number) => {
      if (scale + addScale < 0.8 || scale + addScale > 2.0) {
        return;
      }
      setScale((prevScale) => prevScale + addScale);
      listRef.current?.resetAfterIndex(0, true); // recalculate all the page size
      // handleScrollToIndex(currentPageIndex);  // TODO fix in KT-260
    };

    const handleScroll = React.useMemo(
      () =>
        _.debounce((height: number) => {
          const currOffsetTop = (listRef.current as unknown as VariableListRef)
            ?.state.scrollOffset;
          const nextPageIndex = pageRef.current.findIndex((page) => {
            const currPageOffsetTop = page?.offsetTop ?? 0;
            const middlePageOffset = currOffsetTop + height / 2;

            return currPageOffsetTop > middlePageOffset;
          });

          const adjustedNextPage =
            nextPageIndex === -1 ? numPages : nextPageIndex;
          setCurrentPage(adjustedNextPage - 1);
        }, 100),
      [numPages]
    );

    const containerRef = useRef<HTMLDivElement | null>();
    const handleResize = () => {
      const width = containerRef.current?.clientWidth;
      if (width) {
        setPageWidth && setPageWidth(width);
        listRef.current?.resetAfterIndex(0, true);
      }
    };

    useWindowResizeEvent(handleResize);
    //  inspired from https://github.com/michaeldzjap/react-pdf-sample/blob/master/src/Viewer.js
    return (
      <div className="w-full h-full flex flex-col">
        <div
          className="h-full w-full"
          ref={(el) => (containerRef.current = el)}
        >
          {isShowLoader && (
            <div className="absolute inset-0 flex items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          <AutoSizer>
            {({ width, height }) => {
              return (
                <Document
                  file={selectedFile?.URL}
                  noData=""
                  loading={
                    <div className="absolute inset-0 flex items-center justify-center">
                      <LoadingSpinner />
                    </div>
                  }
                  onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf, width)}
                  onLoadError={(error) => console.error(error)}
                  // Support for non-latin characters
                  // https://www.npmjs.com/package/react-pdf
                  options={{
                    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
                    cMapPacked: true,
                  }}
                >
                  {pageDimensions && (
                    <VariableSizeList
                      className="scroll-smooth"
                      onScroll={() => handleScroll(height)}
                      ref={(el) => (listRef.current = el)}
                      height={height}
                      width={width}
                      itemSize={(index) =>
                        computeRatio(index, pageDimensions) * width * scale
                      }
                      itemCount={numPages ?? 0}
                      itemData={{
                        pageRef,
                        scale,
                        numPages,
                      }}
                      innerElementType={rndHandler}
                    >
                      {PDFPageRenderer}
                    </VariableSizeList>
                  )}
                </Document>
              );
            }}
          </AutoSizer>
        </div>
        <SectionWrapper className="flex content-center justify-center space-x-8 text-dark-grey text-base font-medium">
          <SvgZoomIn
            className="cursor-pointer"
            onClick={() => handleZoom(0.1)}
          />
          <SvgZoomOut
            className="cursor-pointer"
            onClick={() => handleZoom(-0.1)}
          />

          <SvgUpArrow
            width={14}
            height={14}
            className="cursor-pointer block m-auto"
            onClick={() => handleScrollToIndex(currentPageIndex - 1)}
          />

          <SvgDownArrow
            width={14}
            height={14}
            className="cursor-pointer block m-auto"
            onClick={() => handleScrollToIndex(currentPageIndex + 1)}
          />
          <p>{numPages ? currentPageIndex + 1 : "--"}</p>
          <p>/ </p>
          <p>{numPages || "--"}</p>
        </SectionWrapper>
      </div>
    );
  }
);

export const getPageDimensions = (pages: PDFPageProxy[]): PageDimensions => {
  return pages.reduce((map, page) => {
    // rotate property does not change the content, it only changes the way to preview
    const isRotated = page.rotate === 90 || page.rotate === 270;

    const width = page.view[2];
    const height = page.view[3];

    // if is rotated, swap height and width
    const val = isRotated
      ? { height: width, width: height }
      : { width, height };

    return map.set(page._pageIndex, val);
  }, new Map<number, Dimension>());
};

export const computeRatio = (
  index: number,
  pageDimensions: PageDimensions | undefined
) => {
  const page = pageDimensions?.get(index);
  return !!page ? page.height / page.width : 1;
};

export const parseToPageDimensions = async (pdf: PDFDocumentProxy) => {
  const promises = Array.from({ length: pdf.numPages }, (v, i) => i + 1).map(
    (pageNumber) => pdf.getPage(pageNumber)
  );
  const pages = await Promise.all(promises);
  return getPageDimensions(pages);
};
