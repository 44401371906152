import { useModal } from "@liholiho/react-modal-hook";
import { composeErrorHandlers, GenericError } from "common/errorHandling";
import { toast } from "common/toast";
import { CancelDocumentRequestModal } from "features/documents/pages/documentRequest/components/CancelDocumentRequestModal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useCancelDocumentRequestHandler = (
  onSuccess?: () => void,
  onCancel?: () => void
): { handler: () => void } => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, hide] = useModal(
    ({ in: isOpen }) => {
      const handleConfirm = async () => {
        try {
          hide();
          navigate("/documents");
          toast.success(t("dashboard.cancelDocumentRequest.successMessage"));
          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          composeErrorHandlers()(e as GenericError);
        }
      };

      return (
        <CancelDocumentRequestModal
          isOpen={isOpen}
          onConfirm={handleConfirm}
          onCancel={() => {
            if (onCancel) {
              onCancel();
            }
            hide();
          }}
        />
      );
    },
    [onSuccess, document]
  );

  return {
    handler: show,
  };
};
