import { SignatureRndHandlerBased } from "common/components/form/PDF/SignatureRndHandler/SignatureRndHandlerBased";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { SignatureCreateButton } from "features/documents/components/SignatureBox/SignatureCreateButton";
import { usePDFSignatureControl } from "features/documents/pages/documentRequest/hooks/usePDFSignatureControl";
import { PDFSignatureCreateContext } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/PDFSignatureCreate";
import { SELECT_ALL } from "features/documents/pages/documentRequest/pages/ConfirmDocumentDetail/components/SignatureControlPanel";
import React, { useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { currentPartySelector } from "redux/slices/party/selectors";
import { SignatureSchemas } from "types/document";

type SignatureCreateRndHandlerProps = {
  style: React.CSSProperties;
  children: React.ReactNode;
};

export enum SignatureBoxStep {
  INIT = "INIT",
  ASSIGN_RECIPIENT = "ASSIGN_RECIPIENT",
  CLICK_TO_SIGN = "CLICK_TO_SIGN",
  SENDER_SIGNED = "SENDER_SIGNED",
  DONE = "DONE",
}

export const SignatureCreateRndHandler = React.forwardRef(
  (
    { style, children, ...rest }: SignatureCreateRndHandlerProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const {
      signatures,
      scale,
      handleRemove,
      handlePosition,
      handleSize,
      recipients,
      handleSelectRecipient,
      isPreview,
      isSignatureDisplay,
      pageWidth,
      onSignatureClick,
    } = useContext(PDFSignatureCreateContext);

    const currentParty = useSelector(currentPartySelector);
    const currentUserProfile = useCurrentUserProfile();
    const { filterIndex, filterValue, panelState } = usePDFSignatureControl();
    const resizeConfig = React.useMemo(
      () => ({
        bottom: false,
        bottomLeft: false,
        bottomRight: isPreview ? false : true,
        left: false,
        right: false,
        top: false,
        topLeft: false,
        topRight: false,
      }),
      [isPreview]
    );

    const signatureSchemas: SignatureSchemas[] = useMemo(
      () =>
        signatures.map((signature) => ({
          height: signature.height_px,
          width: signature.width_px,
          email: signature.value?.email ?? "",
          party: currentParty?.x500Name ?? "",
          x: signature.x_px,
          y: signature.y_px,
          id: signature.id,
          imageSrc: signature.imageSrc,
        })),
      [currentParty?.x500Name, signatures]
    );

    const signatureSchemasByRecipient: SignatureSchemas[] = useMemo(() => {
      const newSignatureSchemas = [...signatureSchemas];
      newSignatureSchemas.sort((a, b) => a.y - b.y);
      if (filterValue === SELECT_ALL) {
        return newSignatureSchemas;
      }
      return newSignatureSchemas.filter((it) => it.email === filterValue);
    }, [filterValue, signatureSchemas]);

    const currentActiveBox = useMemo(() => {
      return signatureSchemasByRecipient[filterIndex];
    }, [filterIndex, signatureSchemasByRecipient]);

    const getRecipientInfoByIndex = useCallback(
      (index: number) => {
        return recipients.find(
          (it) => it.email === signatures[index].value?.email
        );
      },
      [recipients, signatures]
    );

    return (
      <SignatureRndHandlerBased
        pageWidth={pageWidth}
        ref={ref}
        style={style}
        children={children}
        onDragStop={(event, data, index) => {
          handlePosition(index, data.x / scale, data.y / scale);
        }}
        onResizeStop={(e, direction, ref, index) => {
          handleSize(index, ref.offsetWidth / scale, ref.offsetHeight / scale);
        }}
        buttonComponent={(
          schema,
          index,
          isHover,
          setIsHover,
          resizingSpace,
          isDrag,
          isResizing
        ) => (
          <SignatureCreateButton
            scale={scale}
            space={resizingSpace}
            width={schema.width}
            recipients={recipients}
            onRemove={() => {
              handleRemove(index);
            }}
            onSelectRecipient={(recipient) => {
              handleSelectRecipient(index, recipient);
            }}
            recipientInfo={getRecipientInfoByIndex(index)}
            setIsHover={setIsHover}
            isHover={isHover}
            isPreview={isPreview}
            onSignatureClick={() => {
              if (schema.email === currentUserProfile?.email && !isPreview) {
                onSignatureClick(schema);
              }
            }}
            imageSrc={signatures[index].imageSrc}
            isDrag={isDrag}
            isResizing={isResizing}
            isGradientBorderShow={currentActiveBox?.id === schema.id}
            panelState={panelState}
          />
        )}
        resizeConfig={resizeConfig}
        isSignatureDisplay={isSignatureDisplay}
        signatureSchemas={signatureSchemas}
        isDraggable={!isPreview}
        scale={scale}
      />
    );
  }
);
