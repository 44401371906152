import { DocumentMenu } from "features/documents/pages/documentManagement/components/DocumentMenu";
import React from "react";
import { DashboardResponse } from "types/common";

/* Folder Management */
type DocumentMenuCellProps = {
  dashboardItem: DashboardResponse;
  isArchived: boolean;
  currentDashboardFolderId: string | null;
  rootDashboardItems: DashboardResponse[];
  isGlobalSearch: boolean;
};

export const DocumentMenuCell = ({
  dashboardItem,
  isArchived,
  currentDashboardFolderId,
  rootDashboardItems,
  isGlobalSearch,
}: DocumentMenuCellProps): JSX.Element => (
  <div className="cursor-pointer text-dark-grey">
    <DocumentMenu
      dashboardItem={dashboardItem}
      isArchived={isArchived}
      currentDashboardFolderId={currentDashboardFolderId}
      rootDashboardItems={rootDashboardItems}
      isGlobalSearch={isGlobalSearch}
    />
  </div>
);
