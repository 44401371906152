import { useMemo } from "react";
import { DocumentParticipant } from "types/document";

const useNotViewRejectDetails = (
  recipients: DocumentParticipant[]
): boolean => {
  return useMemo(
    () =>
      recipients.some((recipient) => {
        return recipient.comments.some((it) => !it.resolved);
      }),
    [recipients]
  );
};

export default useNotViewRejectDetails;
