import ReactTooltip from "react-tooltip";
import tw, { styled } from "twin.macro";

export const StyledReactTooltip = styled(ReactTooltip)<{
  $arrowPosition?: "left" | "center"; // Plugin preset arrow appear on the right
}>`
  ${tw`!p-2.5 !rounded-lg !opacity-70`}
  &.__react_component_tooltip.place-top:after {
    ${(props) => props.$arrowPosition === "left" && tw`!left-[10%]`}
    ${(props) => props.$arrowPosition === "center" && tw`!left-[50%]`}
  }
`;
