import { Dimension } from "common/components/form/PDF/PDFSignature/PDFSignatureBased";
import { ActionType } from "features/documents/pages/documentRequest/context/DocumentRequestReducer";
import {
  SettingRolePermissionFormValue,
  RecipientListFormValues,
  UploadDocumentFormValue,
  PDFSignaturesPercentageFormValue,
  AuthenticateFilesFormValue,
} from "features/documents/pages/documentRequest/types";

export const uploadDocuments = (payload: UploadDocumentFormValue) => {
  return {
    type: ActionType.UPLOAD_DOCUMENT,
    payload,
  };
};

export const setRolePermission = (payload: SettingRolePermissionFormValue) => {
  return {
    type: ActionType.SET_ROLE_PERMISSION,
    payload,
  };
};

export const uploadSignatures = (payload: PDFSignaturesPercentageFormValue) => {
  return {
    type: ActionType.HANDLE_SIGNATURES,
    payload,
  };
};

export const uploadRecipients = (payload: RecipientListFormValues) => {
  return {
    type: ActionType.ADD_RECIPIENTS,
    payload,
  };
};

export const uploadAuthenticatedFiles = (
  payload: AuthenticateFilesFormValue
) => {
  return {
    type: ActionType.AUTHENTICATE_DOCUMENT,
    payload,
  };
};
export const uploadFileDimensions = (payload: {
  fileDimensions: Dimension[][];
}) => {
  return {
    type: ActionType.FILE_DIMENSIONS,
    payload,
  };
};
