import { BreadCrumbItemProps } from "features/documents/components/FolderManagement/DashboardBreadcrumbs";
import { createContext, Dispatch, SetStateAction, useState } from "react";
import { DashboardResponse } from "types/common";

type LatestDocumentsContextProps = {
  docFilterType: string;
  setDocFilterType: Dispatch<SetStateAction<string>>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  documents: DashboardResponse[] | undefined;
  setDocuments: Dispatch<SetStateAction<DashboardResponse[] | undefined>>;
  isOriginalRoute: boolean;
  setIsOriginalRoute: Dispatch<SetStateAction<boolean>>;
  currentFolderId: string | null;
  setCurrentFolderId: Dispatch<SetStateAction<string | null>>;
  dashboardBreadcrumbsItems: BreadCrumbItemProps[];
  setDashboardBreadcrumbsItems: Dispatch<SetStateAction<BreadCrumbItemProps[]>>;
};

export const LatestDocumentsContext =
  createContext<LatestDocumentsContextProps>({
    docFilterType: "all",
    setDocFilterType: () => {},
    searchValue: "",
    setSearchValue: () => {},
    documents: undefined,
    setDocuments: () => {},
    isOriginalRoute: true,
    setIsOriginalRoute: () => {},
    currentFolderId: null,
    setCurrentFolderId: () => {},
    dashboardBreadcrumbsItems: [],
    setDashboardBreadcrumbsItems: () => {},
  });

type LatestDocumentsContextProviderProps = {
  children: React.ReactNode;
};

export const LatestDocumentsContextProvider = ({
  children,
}: LatestDocumentsContextProviderProps) => {
  const [docFilterType, setDocFilterType] = useState<string>("all");
  const [searchValue, setSearchValue] = useState<string>("");
  const [documents, setDocuments] = useState<DashboardResponse[] | undefined>(
    undefined
  );
  const [isOriginalRoute, setIsOriginalRoute] = useState(true); // Default original route is Documents/TabName
  const [currentFolderId, setCurrentFolderId] = useState<string | null>(null);
  const [dashboardBreadcrumbsItems, setDashboardBreadcrumbsItems] = useState<
    BreadCrumbItemProps[]
  >([]);

  return (
    <LatestDocumentsContext.Provider
      value={{
        docFilterType,
        setDocFilterType,
        searchValue,
        setSearchValue,
        documents,
        setDocuments,
        isOriginalRoute,
        setIsOriginalRoute,
        currentFolderId,
        setCurrentFolderId,
        dashboardBreadcrumbsItems,
        setDashboardBreadcrumbsItems,
      }}
    >
      {children}
    </LatestDocumentsContext.Provider>
  );
};
